import React, { FC } from 'react'
import ChannelBlock from './ChannelBlock'
import { Col, Tag } from 'antd'

interface ChannelInfoType {
  loading: boolean;
  data: any;
}

const ChannelInfo: FC<ChannelInfoType> = ({ loading, data }) => {
  return (
    <>
      <Col span={8}>
        <ChannelBlock label={'Channel ID'} loading={loading} text={data.channelId} />
        <ChannelBlock label={'Channel Name'} loading={loading} text={data.name} />
        <ChannelBlock label={'Claim Past Uploads'} loading={loading}
               text={data.claimUploads ? <Tag color='green'>This channel's uploads are claimed</Tag> :
                 <Tag color='volcano'>This channel's uploads are not claimed</Tag>} />
        <ChannelBlock label={'This Channel is made for kids'} loading={loading}
               text={data.censorship ? <Tag color='green'>This channel is made for kids</Tag> :
                 <Tag color='volcano'>This channel is not made for kids</Tag>} />
        <ChannelBlock label={'This Channel is not monetized'} loading={loading}
               text={data.monetized ? <Tag color='green'>This channel is monetized</Tag> :
                 <Tag color='volcano'>This channel is not monetized</Tag>} />
      </Col>
      <Col span={8}>
        <ChannelBlock label={'Artist'} loading={loading} text={data.artist} />
        <ChannelBlock label={'Default Playlist'} loading={loading} text={data.playlist} />
        <ChannelBlock label={'Region'} loading={loading} text={data.region || 'N/A'} />
        <ChannelBlock label={'Genre'} loading={loading} text={data.genre} />
      </Col>
      <Col span={8}>
        <ChannelBlock label={'Subscribers'} loading={loading} text={data.subscribers} />
        <ChannelBlock label={'Channel Views'} loading={loading} text={data.channelViews} />
        <ChannelBlock label={'# of videos'} loading={loading} text={`${data.videos}`} />
        <ChannelBlock label={'Date Added'} loading={loading} text={data.dateAdded} />
        <ChannelBlock label={'Date Created'} loading={loading} text={data.dateCreated} />
      </Col>
    </>
  )
}

export default ChannelInfo
