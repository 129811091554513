import { FC } from 'react'
import { Input, InputProps } from 'antd'
import { Controller, useController, useFormContext } from 'react-hook-form'

import FormInputProps from '../types/FormInputProps'
import FormItem from '../FormItem'

type FormControlProps = InputProps & FormInputProps & { labelWidth?: string, className?: string, inputStyle?: any }

const FormControl: FC<FormControlProps> = ({ name, label, labelWidth, required = false, className, placeholder, inputStyle, ...restProps }) => {
  const { control } = useFormContext()
  const { fieldState } = useController({ name, control })

  return (
    <FormItem labelWidth={labelWidth || 'auto'} label={label} error={fieldState.error} className={className}>
      <Controller
        name={name}
        rules={{ required }}
        control={control}
        render={({ field }) => <Input {...field} id={name} placeholder={placeholder} style={inputStyle} {...restProps} />}
      />
    </FormItem>
  )
}

export default FormControl
