import EndScreenElementInputWithPositionAndHeight
  from 'shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight'


const addElementsGeometryProperties = (
  elems: any[],
  prevState: any[] | []
): EndScreenElementInputWithPositionAndHeight[] | [] => {
  return elems.map((el: any) => {
    const sameElem = prevState.find((item: any) => item.id === el.id)
    return {
      ...el,
      top: sameElem ? sameElem.top : el.top,
      left: sameElem ? sameElem.left : el.left,
      // height: getEndScreenHeight(el.type)
    }
  })
}

export default addElementsGeometryProperties
