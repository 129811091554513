import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import AdditionalReportCardProps from './AdditionalReportCard.props'
import styles from './AdditionalReportCard.module.scss'
import { Button, Card } from 'antd'
import { Line } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const AdditionalReportCard: FC<AdditionalReportCardProps> = ({ report }) => {
  return (
    <Card
      className={styles.container}
      size='small'
      title={<b>{report.title}</b>}
      extra={
        <Link to={report.url}>
          <Button type='link' shape='round'>More</Button>
        </Link>
      }
    >
      <Line
        height={150}
        data={{
          labels: ['Label 1', 'Label 2'],
          datasets: [
            {
              label: 'Line 1',
              data: report.data[0],
              borderColor: '#4998f3',
              backgroundColor: '#4998f3'
            },
            {
              label: 'Line 2',
              data: report.data[1],
              borderColor: '#7cb0e8',
              backgroundColor: '#7cb0e8'
            }
          ]
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false
            },
            title: {
              display: false
            }
          }
        }}
      />
    </Card>
  )
}

export default AdditionalReportCard
