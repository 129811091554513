import { FC, useCallback, useMemo, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import {
  useGetAddVideoLoadingStatusQuery,
  useAddManyVideosToCampaignMutation,
  useAddSelectedVideosMutation,
  useCreateVideoExportLinkMutation
} from 'shared/graphql'
import { CampaignParamTypes } from '../../../Campaigns/types/campaign.params.type'
import styles from './TableExtras.module.scss'
import { TableExtrasProps } from './TableExtrasProps'
import { DownloadButton } from '../../../../components'
import downloadFile from '../../../../shared/utils/downloadFile'
import MCNButton from '../../../../components/Button'
import { gql, useSubscription } from '@apollo/client'

const TableExtras: FC<TableExtrasProps> = ({ addAllVideosQuery, filters, disabledAddAllButton }) => {
  const { campaignId } = useParams<CampaignParamTypes>()

  const [addVideosButtonLoading, setAddVideosButtonLoading] = useState(true)

  const getAddVideoLoadingStatusQuery = useGetAddVideoLoadingStatusQuery({
    variables: {
      input: {
        campaignId
      }
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  const getAddVideoLoadingStatus = useMemo(() => {
    const requested = getAddVideoLoadingStatusQuery.data?.getAddVideoLoadingStatus?.requested
    if (requested) {
      return requested
    }

    return false
  }, [getAddVideoLoadingStatusQuery.data?.getAddVideoLoadingStatus?.requested])

  useEffect(() => {
    setAddVideosButtonLoading(getAddVideoLoadingStatus)
  }, [getAddVideoLoadingStatus])

  const SUBSCRIPTION_ON_UPDATE_ADD_VIDEOS_LOADING_STATUS = gql`
    subscription OnUpdateAddVideoLoadingStatus {
      onUpdateAddVideoLoadingStatus {
        requested
        campaignId
      }
    }
  `
  const addVideosLoading = useSubscription(SUBSCRIPTION_ON_UPDATE_ADD_VIDEOS_LOADING_STATUS)

  useEffect(() => {
    console.log('addVideosLoading effect')
    if (addVideosLoading?.data?.onUpdateAddVideoLoadingStatus) {
      console.log('addVideosLoading onUpdateAddVideoLoadingStatus data received')
      if (addVideosLoading.data.onUpdateAddVideoLoadingStatus.campaignId === campaignId) {
        console.log('addVideosLoading onUpdateAddVideoLoadingStatus campaignId matches')
        console.log(
          'requested',
          addVideosLoading.data.onUpdateAddVideoLoadingStatus.requested
        )
        setAddVideosButtonLoading(addVideosLoading.data.onUpdateAddVideoLoadingStatus.requested)
      }
    }
  }, [addVideosLoading?.data?.onUpdateAddVideoLoadingStatus, campaignId])

  const [addSelectedVideos, { loading: loadingSelectedVideos }] = useAddSelectedVideosMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Added...')
      cache.modify({
        fields: {
          useGetCampaignListQuery() {}
        }
      })
    }
  })

  const addSelectedVideosHandler = useCallback(() => {
    addSelectedVideos({
      variables: {
        input: { campaignId }
      }
    })
  }, [addSelectedVideos, campaignId])

  const [addAllVideos, { loading: loadingAllVideos }] = useAddManyVideosToCampaignMutation({
    onError: (error) => {
      // toast.error(error.message)
    },
    update(cache) {
      cache.modify({
        fields: {
          useGetCampaignListQuery() {}
        }
      })
      toast.success('Videos added...')
    }
  })

  const addAllVideosHandler = useCallback(() => {
    addAllVideos({
      variables: {
        input: addAllVideosQuery
      }
    })
  }, [addAllVideos, addAllVideosQuery])

  const [downloadVideos, { loading: loadingDownloadVideos }] = useCreateVideoExportLinkMutation({
    onCompleted(data: any) {
      downloadFile(data?.createVideoExportLink?.url, 'videos_export.csv')
    }
  })

  const handleDownload = useCallback(() => {
    downloadVideos({
      variables: {
        input: {
          campaignId,
          filters: filters
        }
      }
    })
  }, [downloadVideos, filters, campaignId])

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <DownloadButton handler={handleDownload} loading={loadingDownloadVideos} />
        <MCNButton onClick={addSelectedVideosHandler} loading={loadingSelectedVideos} text="Add Selected Videos" />
        <div>
          <MCNButton
            onClick={addAllVideosHandler}
            loading={loadingAllVideos || addVideosButtonLoading}
            text="Add All Videos"
            disabled={loadingAllVideos || addVideosButtonLoading}
          />
        </div>
      </div>
    </div>
  )
}

export default TableExtras
