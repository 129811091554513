import { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Popconfirm, Tooltip } from 'antd'
import { Switch } from 'antd'
import { UserListActionsProps } from './UserListActions.props'

const UserListActions: FC<UserListActionsProps> = ({ id, onDelete, onDeactivate, isActive }) => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
    <Tooltip title={isActive ? 'Deactivate' : 'Activate'}>
      <Switch defaultChecked={isActive} onChange={() => onDeactivate(id)} size="small" />
    </Tooltip>
    <Tooltip title="Edit">
      <Link to={`/admin/edit/${id}`} style={{ color: '#000000d9' }}>
        <Button size="small" icon={<FormOutlined />} />
      </Link>
    </Tooltip>
    <Popconfirm title="Sure to remove?" onConfirm={() => onDelete(id)}>
      <Tooltip title="Delete">
        <Button size="small" danger icon={<DeleteOutlined />} />
      </Tooltip>
    </Popconfirm>
  </div>
)

export default UserListActions
