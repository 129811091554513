import { FC, useCallback, useState, useEffect } from 'react'
import { Checkbox } from 'antd'
import { useParams } from 'react-router-dom'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { toast } from 'react-toastify'
import { useCreateVideoSelectionMutation, useDeleteVideoSelectionMutation } from 'shared/graphql'
import { CampaignParamTypes } from '../../../Campaigns/types/campaign.params.type'

interface SelectProps {
  id: string
  selected: boolean
  disabled: boolean
  query: ApolloQueryResult<any>
}

const SelectVideoItem: FC<SelectProps> = ({ id, selected, disabled, query }) => {
  const { campaignId } = useParams<CampaignParamTypes>()

  const [loading, setLoading] = useState(false)

  const [createVideoSelection] = useCreateVideoSelectionMutation({
    onError: () => {
      toast.error('ERROR')
    },
    update(cache) {
      cache.modify({
        fields: {
          getVideosFullList() {}
        }
      })
    }
  })

  const select = useCallback(() => {
    setLoading(true)
    createVideoSelection({
      variables: {
        input: { video: id, campaign: campaignId }
      }
    })
  }, [createVideoSelection, id, campaignId])

  const [deleteVideoSelection] = useDeleteVideoSelectionMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      cache.modify({
        fields: {
          getVideosFullList() {}
        }
      })
    }
  })

  const unSelect = useCallback(() => {
    deleteVideoSelection({
      variables: {
        input: { video: id, campaign: campaignId }
      }
    })
  }, [deleteVideoSelection, id, campaignId])

  const toggleSelect = () => {
    if (!selected) {
      select()
    } else {
      unSelect()
    }
  }

  useEffect(() => {
    setLoading(false)
    return () => {}
  }, [query])

  return <Checkbox onChange={toggleSelect} checked={selected && !disabled} disabled={disabled} indeterminate={loading} />
}

export default SelectVideoItem
