import isOverlapping from 'shared/features/EndScreenElements/utils/isOverlapping'

const validateElements = (elements: any) => {
  let isError = false
  elements.forEach((currentElement: any, currentElementIndex: number) => {
    elements.forEach((element: any, elementIndex: number) => {
      if (currentElementIndex !== elementIndex) {
        if (
          isOverlapping(
            {
              left: Number(currentElement.left),
              top: Number(currentElement.top),
              width: Number(currentElement.width),
              height: currentElement.height
            },
            {
              left: Number(element.left),
              top: Number(element.top),
              width: Number(element.width),
              height: element.height
            }
          )
        ) {
          isError = true
        }
      }
    })
  })
  return isError
}
export default validateElements
