import React, { FC } from 'react'
import { Button, Popconfirm, Table, Tag, Tooltip } from 'antd'
import VideListProps from './VideoList.props'
import ColumnHeading from 'features/MasterChannelList/components/TableColumns/ColumnHeading'
import styles from './VideoList.module.scss'
import { CampaignVideoListFieldName } from '../../../../graphql'
import FilterDropdown from '../../../../../features/MasterChannelList/components/FilterDropdown'
import { FilterDropdownProps as AntFilterDropdownProps } from 'antd/lib/table/interface'
import { CampaignVideoStatus, CampaignStatusTags } from '../../../../constants/campaignStatuses'
import { getEnumKeyByEnumValue } from '../../../../utils/getEnumKeyByEnumValue'
import { Checkbox } from 'antd'

const VideoList: FC<VideListProps> = ({
  videos,
  loading,
  onRemove,
  pagination,
  handleTableChange,
  selectVideos,
  selectedVideos,
  filters,
  setFilters
}) => {
  const columns = [
    {
      title: <Checkbox checked={false} onChange={() => selectVideos(videos.map((record: any) => record.id))} />,
      dataIndex: 'select',
      key: 'select',
      width: 40,
      render: (_: any, record: any) => {
        return (
          <Checkbox
            onChange={() => selectVideos([record.id])}
            checked={selectedVideos.includes(record.id)}
            disabled={false}
            indeterminate={loading}
          />
        )
      }
    },
    {
      title: <ColumnHeading title={`Thumbnail`} tooltip={`Video image cover`} />,
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 100,
      render: (_: any, record: any) => {
        return (
          <a href={`https://www.youtube.com/watch?v=${record.youtubeId}`} style={{ color: '#000000d9' }}>
            <img className={styles.thumbnail} src={record.thumbnail} alt={record.video} />
          </a>
        )
      }
    },
    {
      title: <ColumnHeading title={`Video`} tooltip={`Video title`} />,
      dataIndex: CampaignVideoListFieldName.Name,
      sorter: true,
      showSorterTooltip: false,
      key: 'video',
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, videoName: searchText }))}
        />
      )
    },
    {
      title: <ColumnHeading title={`Channel`} tooltip={`Channel name`} />,
      dataIndex: CampaignVideoListFieldName.Channel,
      sorter: true,
      showSorterTooltip: false,
      key: 'channel',
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, channelName: searchText }))}
        />
      )
    },
    {
      title: (
        <ColumnHeading
          title={`Status`}
          tooltip={
            <div>
              The current campaign video status. <br /> Supported Status types: <br />
              <span style={{ color: 'darkorange' }}>Processing </span>: The video is in the process of having its end
              screens programmatically changed.
              <br />
              <span style={{ color: 'greenyellow' }}> In-Progress </span>
              The video has been processed and the campaign in in progress.
              <br />
              <span style={{ color: 'yellow' }}> Processing (Post) </span>
              Campaign is complete, the video is in the process of having its end screens returned to the default end
              screen.
              <br />
              <span style={{ color: 'green' }}> Campaign Complete </span>
              The Campaign end date has passed, and the video's end screens have been returned to default.
              <br />
              <span style={{ color: 'cyan' }}> Pending </span>
              The campaign has not yet started because it is before the Start Date
              <br />
              <span style={{ color: 'red' }}>Cancelled </span>
              The campaign is done because it's been cancelled.
            </div>
          }
        />
      ),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: CampaignVideoListFieldName.Status,
      key: 'status',
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) =>
            setFilters((filters: any) => ({
              ...filters,
              [CampaignVideoListFieldName.Status]: searchText
            }))
          }
        />
      ),
      render: (_: string, record: any) => {
        if (record.reason) {
          return (
            <Tooltip title={record.reason}>
              <Tag color={CampaignStatusTags[_ as keyof typeof CampaignStatusTags]}>
                {getEnumKeyByEnumValue(CampaignVideoStatus, _)}
              </Tag>
            </Tooltip>
          )
        }
        return (
          <Tag color={CampaignStatusTags[_ as keyof typeof CampaignStatusTags]}>
            {record?.reason}
            {getEnumKeyByEnumValue(CampaignVideoStatus, _)}
          </Tag>
        )
      }
    },
    {
      title: <ColumnHeading title={`Actions`} tooltip={`Videos can be removed from a campaign`} />,
      dataIndex: 'actions',
      key: 'actions',
      width: 150,
      render: (_: any, record: any) => {
        return (
          <Popconfirm title="Sure to remove?" onConfirm={() => onRemove(record.id)}>
            <Button type="text" danger>
              Remove
            </Button>
          </Popconfirm>
        )
      }
    }
  ]

  return (
    <Table
      size="small"
      pagination={pagination}
      onChange={handleTableChange}
      rowKey={(record) => record.id}
      loading={loading}
      columns={columns}
      dataSource={videos}
      sticky
    />
  )
}

export default VideoList
