import React, { FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { Select, Spin } from 'antd'
import FormItem from '../FormItem'
import FormSelectProps from './FormSelect.props'

const FormSelect: FC<FormSelectProps> = ({
  name,
  label,
  className,
  selectClassName,
  labelWidth,
  required = false,
  withEmptyOption = false,
  width,
  onSelectChange,
  mode,
  loading,
  showSearch,
  onSearch,
  selectStyle,
  options = [],
  filterOption
}) => {
  const { control } = useFormContext()
  const { fieldState } = useController({ name, control })
  const newOptions = withEmptyOption ? [{ value: '', label: '' }, ...options] : options

  return (
    <FormItem labelWidth={labelWidth} label={label} error={fieldState.error} width={width} className={className}>
      <Controller
        name={name}
        rules={{ required }}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            className={selectClassName}
            mode={mode}
            filterOption={filterOption}
            showSearch={showSearch}
            onSearch={onSearch}
            loading={loading}
            style={selectStyle}
            dropdownRender={(menu: any) => loading ? <Spin size="small" /> : <div>{menu}</div>}
            onChange={(e) => {
              if (onSelectChange) {
                onSelectChange(e)
              }
              onChange(e)
            }}
            onBlur={onBlur}
            value={value}
            ref={ref}
          >
            {!loading && newOptions.map(
              (option, i) =>
                option.label && (
                  <Select.Option key={option.value} value={option.value} disabled={option.disabled}>
                    {option.label}
                  </Select.Option>
                )
            )}
          </Select>
        )}
      />
    </FormItem>
  )
}

export default FormSelect
