import React, { FC } from 'react'
import { EndScreenElementType } from 'shared/graphql'
import getElementTypeLabel from '../../../EndScreenTable/utils/getElementTypeLabel'
import styles from './EndscreenRoundedItem.module.scss'

type EndscreenRoundedItemProps = {
  position: number,
  thumbnail?: string
  elementName?: string
  endScreenType: EndScreenElementType
}

const EndscreenRoundedItem: FC<EndscreenRoundedItemProps> = ({ position, thumbnail, endScreenType }) => {
  return <div className={styles.container}>
      {!thumbnail &&
      <div>
        <div className={`${styles.endScreenNumber}`}>{position}</div>
        <div className={`${styles.endScreenText}`}>{getElementTypeLabel(endScreenType)}</div>
      </div>
      }
    { thumbnail && <img className={styles.thumbnail} src={thumbnail} alt={thumbnail} /> }
    <div className={styles.containerInner}></div>
  </div>
}

export default EndscreenRoundedItem
