import React, { FC, useCallback, useMemo, useState } from 'react'
import { Table } from 'antd'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import { TablePaginationConfig } from 'antd/lib/table/interface'
import { ChannelListFieldName, OrderDirection, useGetChannelListQuery } from 'shared/graphql'
import {
  MCN_PAGINATION_CONFIG,
} from 'shared/utils/tableHelpers'

type MasterChannelTableProps = {
  filters: any
  columns: any
  sortOrder: any
  setSortOrder: any
  switcher: any
}
const MasterChannelTable: FC<MasterChannelTableProps> = ({ filters, columns, sortOrder, setSortOrder, switcher }) => {
  const [currentPage, setCurrentpage] = useState(1)
  const [pageSize, setPagesize] = useState(10)


  const getChannelListQuery = useGetChannelListQuery({
    variables: {
      input: {
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        sortOrder,
        filter: filters
      }
    },
    onError (error) {
      toast.error(error.message)
    }
  })

  const data = useMemo(() => {
    const items = getChannelListQuery.data?.getChannelList?.items
    const dateFormat = 'MMM DD, YYYY'
    if (items) {
      return items.map((item) => ({
        key: item?.id,
        channelId: item?.youtubeId,
        [ChannelListFieldName.Name]: item?.name,
        artist: item?.artist?.name,
        playlist: item?.playlist,
        [ChannelListFieldName.CreatedAt]: dayjs(item?.createdAt).format(dateFormat),
        [ChannelListFieldName.AddedAt]: dayjs(item?.addedAt).format(dateFormat),
        [ChannelListFieldName.Tier]: item?.tier?.name,
        [ChannelListFieldName.Region]: item?.region?.name,
        [ChannelListFieldName.Genre]: item?.genre?.name,
        [ChannelListFieldName.SubscriberCount]: item?.subscriberCount,
        [ChannelListFieldName.ViewCount]: item?.viewCount,
        parentLabel: item?.parentLabel?.name,
        subLabel: item?.subLabel?.name,
        prioritized: item?.prioritized,
      }))
    }

    return []
  }, [getChannelListQuery.data?.getChannelList?.items])

  const pagination: TablePaginationConfig = {
    ...MCN_PAGINATION_CONFIG,
    total: getChannelListQuery.data?.getChannelList?.count,
    current: currentPage,
    pageSize,
    onChange: (page: number, size: number) => {
      setCurrentpage(page)
      setPagesize(size)
    }
  }

  const columnsToShow = useMemo(() => {
    return columns.filter((column: any) => !column.hidden)
  }, [columns])

  const handleTableChange = useCallback((pagination, filters, sorter) => {
    setSortOrder({
      field: sorter.field,
      direction: sorter.order === 'ascend' ? OrderDirection.Asc : OrderDirection.Desc
    })
  }, [setSortOrder])

  const applySwitcher = (data: any) => {
    if (data.length) {
      return data.map((item:any) => {
        if (item.key in switcher) {
          item.prioritized = switcher[item.key]
        }
        return item
      })
    }
    return data
  }

  return (
    <>
      <Table
        loading={getChannelListQuery.loading}
        columns={columnsToShow}
        showSorterTooltip={false}
        dataSource={applySwitcher(data)}
        scroll={{ x: 1200 }}
        sticky
        pagination={pagination}
        onChange={handleTableChange}
      />
    </>
  )
}

export default MasterChannelTable
