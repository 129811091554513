import EndScreenElementInputWithPositionAndHeight from "features/Defaults/types/EndScreenDefaultElementInputWithPositionAndHeight";
import { EndScreenElementType } from "shared/graphql";
import { getEndScreenHeight } from "shared/utils";
import getEndScreenWidth from "shared/utils/getEndScreenWidth";
import setPosition from "./setPosition";
import { v4 as uuidv4 } from 'uuid';

const addInitialElement = (
    type: EndScreenElementType,
    setElements: (x: (prevState: EndScreenElementInputWithPositionAndHeight[]) => EndScreenElementInputWithPositionAndHeight[]) => void
    ) => {
        const newId = uuidv4();

        setElements((prevState) => ([...prevState, {
            id: newId,
            type,
            left: 150,
            position: prevState && prevState.length ? setPosition(prevState) : 1,
            durationMs: 20000,
            top: 60,
            urlId: '',
            message: '',
            height: getEndScreenHeight(type),
            width: getEndScreenWidth(type),
            createdAt: '',
            updatedAt: ''
        }]))
}

export default addInitialElement;