import { useEffect } from 'react'
import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client'

type AccessTokenVar = string | null

type useGoogleAccessTokenResult = [AccessTokenVar, ReactiveVar<AccessTokenVar>]

const accessTokenVar = makeVar<AccessTokenVar>(null)

const useGoogleAccessToken = (accessToken?: AccessTokenVar): useGoogleAccessTokenResult => {
  useEffect(() => {
    if (accessToken !== undefined) {
      accessTokenVar(accessToken)
    }
  }, [accessToken])

  return [useReactiveVar(accessTokenVar), accessTokenVar]
}

export default useGoogleAccessToken
