import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Modal, Upload } from 'antd'
import ExternalLinkModalProps from './ExternalLinkModal.props'
import styles from './ExternalLinkModal.module.scss'
import { UploadOutlined } from '@ant-design/icons'
import { RcFile } from 'antd/lib/upload'
import FormSelect from 'components/form/FormSelect'
import { FormProvider, useForm } from 'react-hook-form'
import { getExternalLinkCta } from '../../../../shared/features/EndScreenElements/utils/getExternalLinkCta'
import { ExternalLinkCta } from 'shared/graphql'
import Compressor from 'compressorjs';
import FormControl from 'components/form/FormControl'

const ExternalLinkModal: FC<ExternalLinkModalProps> = ({ isVisible, onClose, onOk, externalLinkElement }) => {
  const [fileInBase64, setFileInBase64] = useState('');
  const [compressedFile, setCompressedFile] = useState<any>();
  const [showError, setShowError] = useState(false);
  const formMethods = useForm({
    defaultValues: {
      cta: ExternalLinkCta.CtaLearnMore,
      message: ''
    }
  })

  const handleRemoveFile = useCallback(
    (data) => {
      setFileInBase64('');
    }, []
  )

  const handleUpload = useCallback(
    (file: RcFile) => {
      setShowError(false);
      new Compressor(file, {
        quality: 0.6,
        convertSize: 100000,
        convertTypes: ['image/jpeg'],
        resize: 'cover',
        width: 300,
        height: 300,
        success: (compressedResult: any) => {
          setCompressedFile(compressedResult)
        },
      });

      return false
    }, []
  )

  useEffect(() => {
    if (compressedFile) {
      const reader = new FileReader()
      reader.onload = e => {
        if (e?.target?.result) {
          if(new TextEncoder().encode(e.target.result as string).length > 320000) {
            setShowError(true);
            return;
          }
          setFileInBase64(e.target.result as string);
        }
      }

      reader.readAsDataURL(compressedFile as Blob);
    }
  }, [compressedFile])

  const onSave = useCallback(() => {
    const cta = formMethods.getValues('cta');
    const message = formMethods.getValues('message');
    onOk(fileInBase64, cta, message)
    onClose()
  }, [fileInBase64, formMethods, onClose, onOk])

  useEffect(() => {
    if (externalLinkElement?.externalLinkCTA) {
      formMethods.setValue('cta', externalLinkElement.externalLinkCTA)
    }

    if (externalLinkElement?.message) {
      formMethods.setValue('message', externalLinkElement.message)
    }

    if (externalLinkElement?.imageDataUrl) {
      setFileInBase64(externalLinkElement.imageDataUrl)
    }
  }, [externalLinkElement, formMethods])

  return (
    <Modal visible={isVisible} onCancel={onClose} title="Add details" footer={null} width="500px">
    <FormProvider {...formMethods}>
      <div className={styles.container}>
        <div className={styles.selectContainer}>
          <div className={styles.heading}>Special message:</div>
          <FormControl name="message" required className={styles.child} />
        </div>
        <div className={styles.selectContainer}>
          <div className={styles.heading}>Сall to action:</div>
            <FormSelect
              name='cta'
              width={250}
              options={
                [
                  {
                    value: ExternalLinkCta.CtaBuy,
                    label: getExternalLinkCta(ExternalLinkCta.CtaBuy)
                  },
                  {
                    value: ExternalLinkCta.CtaDownload,
                    label: getExternalLinkCta(ExternalLinkCta.CtaDownload)
                  },
                  {
                    value: ExternalLinkCta.CtaLearnMore,
                    label: getExternalLinkCta(ExternalLinkCta.CtaLearnMore)
                  },
                  {
                    value: ExternalLinkCta.CtaOrder,
                    label: getExternalLinkCta(ExternalLinkCta.CtaOrder)
                  },
                  {
                    value: ExternalLinkCta.CtaShop,
                    label: getExternalLinkCta(ExternalLinkCta.CtaShop)
                  },
                  {
                    value: ExternalLinkCta.CtaSignUp,
                    label: getExternalLinkCta(ExternalLinkCta.CtaSignUp)
                  },
                  {
                    value: ExternalLinkCta.CtaSupport,
                    label: getExternalLinkCta(ExternalLinkCta.CtaSupport)
                  },
                  {
                    value: ExternalLinkCta.CtaVisit,
                    label: getExternalLinkCta(ExternalLinkCta.CtaVisit)
                  },
                ]
              }
            />
        </div>
        <div className={styles.uploadContainer}>
          <div className={styles.heading}>Picture</div>
          { showError && <div className={styles.errorContainer}>File is too large</div> }
          <Upload
            listType="picture"
            accept='.jpg, .jpeg, .png, .gif'
            beforeUpload={handleUpload}
            onRemove={handleRemoveFile}
            defaultFileList={externalLinkElement?.imageDataUrl ? [{
              uid: '1',
              name: 'externalLink.jpeg',
              status: 'done',
              url: externalLinkElement.imageDataUrl,
            }] : []}
            maxCount={1}
          >
            <Button icon={<UploadOutlined />} size='large'>
              Upload File
            </Button>
          </Upload>
        </div>
        <div className={styles.footerContainer}>
          <Button
            style={{ marginRight: '10px' }}
            shape="round"
            onClick={onSave}
          >
            Ok
          </Button>

          <Button
            style={{ marginLeft: '10px' }}
            shape="round"
            onClick={onClose}
          >
            Close
          </Button>
        </div>
      </div>
      </FormProvider>
    </Modal>
  )
}

export default ExternalLinkModal
