import React, {FC} from 'react'
import styles from './Footer.module.scss'

const Footer: FC = () => {
  return (
    <div className={styles.footer}>
    </div>
  )
}

export default Footer
