import React, { FC } from 'react'
import cn from 'classnames'
import { EndScreenElementType } from 'shared/graphql'
import EndscreenPreviewItem from './components/EndscreenPreviewItem/EndscreenPreviewItem'
import EndscreenRoundedItem from './components/EndscreenRoundedItem/EndscreenRoundedItem'
import EndscreenSquaredItem from './components/EndscreenSquaredItem/EndscreenSquaredItem'
import EndScreenEditorProps from './EndScreenEditor.props'
import styles from './EndScreenEditor.module.scss'
import { EndScreenIconsContainer } from './components/EndScreenIcons'
import EndScreenExternalLinkItem from './components/EndScreenExternalLinkItem'
import thresholds from './constants/resizeThresholds'

const EndScreenEditor: FC<EndScreenEditorProps> = ({ elements, onChange, error, readMode, className }) => {
  return (
    <div className={cn(styles.container, className)}>
      {error && (
        <div className={styles.errorMessage}>
          End Screen elements cannot overlap or be too close together on the video. Please adjust the element spacing
        </div>
      )}
      <div className={styles.containerOuter}>
        <div className={styles.containerInner}>
          <div className={styles.containerLimiter} />
          {elements.map((element) => {
            const isRounded =
              element.type === EndScreenElementType.SubscribeChannelById ||
              element.type === EndScreenElementType.SubscribeChannel
            const isExternalLink = element.type === EndScreenElementType.ExternalLink
            return (
              <EndscreenPreviewItem
                key={element.position}
                lockAspectRatio
                disableDragging={readMode}
                resizeThresholds={thresholds[element.type]}
                enableResizing={
                  !isRounded &&
                  !readMode && {
                    bottom: false,
                    bottomLeft: true,
                    bottomRight: true,
                    left: false,
                    right: false,
                    top: false,
                    topLeft: true,
                    topRight: true
                  }
                }
                elementPosition={element.position}
                onChange={onChange}
                position={{
                  x: element.left || 0,
                  y: element.top || 0
                }}
                size={{
                  width: Number(element.width),
                  height: Number(element.height)
                }}
              >
                {isRounded && (
                  <EndscreenRoundedItem
                    position={element.position}
                    thumbnail={element.thumbnail?.thumbnail}
                    elementName={element.thumbnail?.name}
                    endScreenType={element.type}
                  />
                )}
                {isExternalLink && (
                  <EndScreenExternalLinkItem
                    position={element.position}
                    elementType={element.type}
                    thumbnail={element.imageDataUrl || ''}
                  />
                )}
                {!isRounded && !isExternalLink && (
                  <EndscreenSquaredItem
                    position={element.position}
                    elementType={element.type}
                    thumbnail={element.thumbnail?.thumbnail}
                    elementName={element.thumbnail?.name}
                  />
                )}
              </EndscreenPreviewItem>
            )
          })}
        </div>

        <EndScreenIconsContainer />
      </div>
    </div>
  )
}

export default EndScreenEditor
