import { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Heading } from 'components'
import { useGetUserQuery, useUpdateUserMutation } from 'shared/graphql'
import { UserFormEdit } from '../components'

const EditUserContainer = () => {
  const history = useHistory()
  const { userId } = useParams<{ userId: string }>()

  const getUserListQuery = useGetUserQuery({
    variables: {
      input: {
        id: userId
      }
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  const user = useMemo(() => {
    return getUserListQuery.data?.getUser
  }, [getUserListQuery.data?.getUser])

  const [updateUser] = useUpdateUserMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update() {
      toast.success('User is successfully updated')
      history.push('/admin')
    }
  })

  const updateUserHandler = useCallback(
    (data) => {
      updateUser({
        variables: {
          input: {
            id: user?.id || '',
            name: data.name,
            role: data.role,
            status: data.status
          }
        }
      })
    },
    [updateUser, user]
  )

  return (
    <>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Heading>User Detail</Heading>
      </div>
      <UserFormEdit data={user} onSave={(data) => updateUserHandler(data)} />
    </>
  )
}

export default EditUserContainer
