import { FC, useState, useCallback, useMemo } from 'react'
import { Button, List, Tooltip } from 'antd'
import { toast } from 'react-toastify'
import { Heading } from 'components'
import {
  EndScreenElementType,
  EndScreenTemplate,
  useGetEndScreenDefaultListQuery,
  useGetEndScreenDefaultThumbnailLazyQuery,
  useUpdateEndScreenDefaultListMutation
} from 'shared/graphql'
import { DefaultActions } from '../components'
import getElementTypeLabel from 'shared/features/EndScreenElements/components/EndScreenTable/utils/getElementTypeLabel'
import { useUpdateEffect } from 'react-use'
import EndScreenElementInputWithPositionAndHeight from 'shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight'
import EndScreenDefaultElementsWrapper from './EndScreenDefaultElementsWrapper'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import disabledElementsDefault from 'shared/features/EndScreenElements/components/EndScreenElements/functions/disabledElementsDefault'
import handleChangeEditor from 'shared/features/EndScreenElements/components/EndScreenElements/functions/handleChangeEditor'
import handleChangeTableRow from 'shared/features/EndScreenElements/components/EndScreenElements/functions/handleChangeTableRow'
import removeEndScreenElement from 'shared/features/EndScreenElements/components/EndScreenElements/functions/removeEndScreenElement'
import addInitialElement from 'shared/features/EndScreenElements/components/EndScreenElements/functions/addInitialElement'

const EditEndScreenDefault: FC = () => {

  const [defaultEndScreens, setDefaultEndScreens] = useState<EndScreenElementInputWithPositionAndHeight[] | []>([])
  const [errorByRestriction, setErrorByRestriction] = useState<string>('')
  const [isShowCreateEndScreen, setIsShowCreateEndScreen] = useState<boolean>(false)

  const mapper = useCallback(
    (endscreens) =>
      endscreens && endscreens.length
        ? endscreens.map((item: any) => ({
            ...item
          }))
        : [],
    []
  )

  useUpdateEffect(() => {
    setIsShowCreateEndScreen(true)
    if (defaultEndScreens.length >= 4) {
      setIsShowCreateEndScreen(false)
    }
  }, [defaultEndScreens])

  useGetEndScreenDefaultListQuery({
    onCompleted({ getEndScreenDefaultList }) {
      const endScreens = getEndScreenDefaultList?.items

      setDefaultEndScreens(mapper(endScreens))
    },
    onError(error: any) {
      toast.error(error.message)
    }
  })

  const [updateEndScreenDefaults] = useUpdateEndScreenDefaultListMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted({ updateEndScreenDefaultList }) {

      const nextUpdate = mapper(updateEndScreenDefaultList?.items ?? [])

      setDefaultEndScreens(nextUpdate)

      toast.success('Default endscreens are updated')
    }
  })

  const updateEndScreenDefaultHandler = useCallback(
    (input) => {
      if (!errorByRestriction) {
        updateEndScreenDefaults({
          variables: { input }
        })
      }
    },
    [errorByRestriction, updateEndScreenDefaults]
  )

  const handleApplyTemplate = useCallback(
    (template: EndScreenTemplate) => {
      const input = {
        elements: template.endscreens.map(({ templateId, __typename, createdAt, updatedAt, ...item }: any) => ({
          ...item,
          id: defaultEndScreens.find((endScreen) => endScreen.position === item.position)?.id,
          imageDataUrl: item?.imageDataUrl || '',
          externalLinkCTA: item?.externalLinkCTA || 'CTA_VISIT'
        }))
      }
      updateEndScreenDefaults({
        variables: {
          input
        }
      })
    },
    [defaultEndScreens, updateEndScreenDefaults]
  )

  const [getEndScreenDefaultThumbnailQuery, { data: getEndScreenDefaultThumbnailQueryData }] =
    useGetEndScreenDefaultThumbnailLazyQuery({
      onError: (error) => {
        toast.error(error.message)
      }
    })

  const getEndScreenDefaultThumbnailHandler = useCallback(
    (endscreenId: string, youtubeId: string, endscreenType: EndScreenElementType) => {
      getEndScreenDefaultThumbnailQuery({
        variables: {
          input: {
            endscreenId,
            youtubeId,
            endscreenType
          }
        }
      })
    },
    [getEndScreenDefaultThumbnailQuery]
  )

  useMemo(() => {
    const elem = getEndScreenDefaultThumbnailQueryData?.getEndScreenDefaultThumbnail

    if (elem && elem.endscreenId) {
      const element = defaultEndScreens.find((item) => item.id === elem.endscreenId)
      if (element) {
        setDefaultEndScreens((prevState) => [
          ...prevState.filter((item) => item.id !== elem.endscreenId),
          { ...element, thumbnail: { thumbnail: elem.thumbnail, name: elem.name }, youtubeId: elem.youtubeId }
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEndScreenDefaultThumbnailQueryData?.getEndScreenDefaultThumbnail])


  return (
    <>
      <Heading level={3} noMargin>
        Default EndScreen elements
        <Tooltip
          color="#fff"
          trigger={['click']}
          title={
            <List
              bordered
              dataSource={Object.values(EndScreenElementType)}
              renderItem={(type) => (
                <Button
                  onClick={() => addInitialElement(type, setDefaultEndScreens)}
                  block
                  disabled={disabledElementsDefault(type, defaultEndScreens)}
                >
                  {getElementTypeLabel(type)}
                </Button>
              )}
            />
          }
        >
          <Button style={{ margin: '0 20px', borderRadius: '15px' }} disabled={isShowCreateEndScreen ? false : true}>
            +
          </Button>
        </Tooltip>
      </Heading>
      {/* <Typography.Paragraph>
        Please select the default end screen values below. Any Master Channel List that does not have a campaign applied
        will default to these end screen elements.
      </Typography.Paragraph> */}
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ color: 'red', fontWeight: 700 }}>{errorByRestriction}</span>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '20px' }}>
          <DefaultActions onApplyTemplate={handleApplyTemplate} />
        </div>
      </div>
      {defaultEndScreens && (
        <EndScreenDefaultElementsWrapper
          setErrorByRestriction={setErrorByRestriction}
          elements={defaultEndScreens}
          onSave={updateEndScreenDefaultHandler}
          removeEndScreenElement={(id) => removeEndScreenElement(id, setDefaultEndScreens)}
          handleChangeTableRow={(data: EndScreenElementInputWithPositionAndHeight) =>
            handleChangeTableRow(data, setDefaultEndScreens, getEndScreenDefaultThumbnailHandler)
          }
          handleChangeEditor={(position: number, data: EndScreenPreviewItemChangeResult) =>
            handleChangeEditor(position, data, setDefaultEndScreens)
          }
        />
      )}
    </>
  )
}

export default EditEndScreenDefault
