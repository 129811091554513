import * as yup from 'yup'
import ColumnHeading from '../../MasterChannelList/components/TableColumns/ColumnHeading'

export const USER_STATUS = {
    ACTIVE: 'active',
    DEACTIVATED: 'deactivated'
}

export const userStatusArray = ['active', 'deactivated']

export const userListColumns = [
    { title: () => <ColumnHeading title={`Name`} tooltip={`User's name`} />, dataIndex: 'name', key: 'name', width:160 },
    { title: () => <ColumnHeading title={`Email`} tooltip={`User's email, used in Okta Authentication`} />, dataIndex: 'email', key: 'email' },
    { title: () => <ColumnHeading title={`Creation Date`} tooltip={`User's first login date`} />, dataIndex: 'createdAt', key: 'createdAt' },
    { title: () => <ColumnHeading title={`Last Login`} tooltip={`User's last login date`} />, dataIndex: 'lastAction', key: 'lastAction' },
    { title: () => <ColumnHeading title={`Role`} tooltip={`User's role. Can be: Viewer, Editor, Admin`} />, dataIndex: 'role', key: 'role', width:120 },
    { title: () => <ColumnHeading title={`Status`} tooltip={`Status. Deactivated user's cannot use the application`} />, dataIndex: 'status', key: 'status', width:120 },
    { title: () => <ColumnHeading title={`Actions`} tooltip={`Available actions: Activate/deactivate, edit, delete`} />, dataIndex: 'actions', key: 'actions' }
]
export const userRoles = ['admin', 'editor', 'viewer']

export const updateUserValidationSchema = yup.object({
    name: yup.string().required(),
    role: yup.string().oneOf(userRoles).required(),
    status: yup.string().oneOf([USER_STATUS.ACTIVE, USER_STATUS.DEACTIVATED]).required()
})

export const createUserValidationSchema = yup.object({
    name: yup.string().required(),
    email: yup.string().email().required(),
    role: yup.string().oneOf(userRoles).required(),
    status: yup.string().oneOf([USER_STATUS.ACTIVE, USER_STATUS.DEACTIVATED]).required()
})
