enum MediaQueriesKeys {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

type MediaQueries = {
  [MediaQueriesKeys.xs]: string
  [MediaQueriesKeys.sm]: string
  [MediaQueriesKeys.md]: string
  [MediaQueriesKeys.lg]: string
}

const breakpoints: [MediaQueriesKeys, number][] = [
  [MediaQueriesKeys.xs, 576],
  [MediaQueriesKeys.sm, 768],
  [MediaQueriesKeys.md, 992],
  [MediaQueriesKeys.lg, 1200]
]

const mq: MediaQueries = breakpoints.reduce<MediaQueries>((acc, [key, value]) => {
  acc[key] = `@media (min-width: ${value}px)`
  return acc
}, {} as MediaQueries)

export default mq as MediaQueries
