import * as yup from 'yup'

export const videoFiltersValidationSchema = yup.object({
  name: yup.string(),
  region: yup.string(),
  artist: yup.string(),
  channel: yup.string(),
  genre: yup.string(),
  applied: yup.boolean()
})
