import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'
import { Logo } from '@wmg-ae/legato'
import { Dropdown, Menu } from 'antd'
import { useOktaAuth } from '@okta/okta-react'
import { UserClaims } from '@okta/okta-auth-js/lib/types'
import { DownOutlined } from '@ant-design/icons'
import styles from './Header.module.scss'
import { useCheckGoogleAuthQuery } from '../../shared/graphql'
import { getSearchParam } from '../../shared/utils/getSearchParam'
import YouTubeAuth from "../YouTubeAuth/YouTubeAuth";

const headerItems = [
  { label: 'MCL', value: '' },
  { label: 'Campaigns', value: 'campaigns' },
  { label: 'Defaults', value: 'defaults' },
  { label: 'Reports', value: 'reports' },
  { label: 'Admin', value: 'admin' }
]

const Header: FC = () => {
  const location = useLocation()
  const { oktaAuth, authState } = useOktaAuth()
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null)
  const [googleLoginUri, setGoogleLoginUri] = useState<string>('')
  const [activePage, setActivePage] = useState<string>('/')

  const handleLogOut = () => oktaAuth.signOut()

  const setActivePageHandler = useCallback((e) => {
    setActivePage(e.key)
  }, [])

  useEffect(() => {
    const currentTab = headerItems.find(({ value }) => value && location.pathname.includes(value)) || headerItems[0]

    setActivePage(currentTab.value)
  }, [location.pathname])

  useEffect(() => {
    if (authState && authState.accessToken) {
      oktaAuth.getUser().then(setUserInfo)
    }
  }, [authState, oktaAuth])

  const checkGoogleAuthQuery = useCheckGoogleAuthQuery()

  useEffect(() => {
    const code = getSearchParam('code')
    const valid = checkGoogleAuthQuery?.data?.checkGoogleAuth?.valid
    if (googleLoginUri && !valid && !code) {
      window.location.href = googleLoginUri
    }
    return () => {}
  }, [googleLoginUri, checkGoogleAuthQuery?.data?.checkGoogleAuth?.valid])

  useMemo(() => {
    const item = checkGoogleAuthQuery?.data?.checkGoogleAuth?.valid
    const uri = checkGoogleAuthQuery?.data?.checkGoogleAuth?.googleLoginUri
    if (uri) {
      setGoogleLoginUri(uri)
    }
    return !!item
  }, [checkGoogleAuthQuery.data])

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapperInner}>
        <Link to="/" className={styles.logoLink}>
          <Logo logo="https://legato.dev.wmg.com/assets/wmg/wmg_logo_black.svg" title="" />
        </Link>

        {userInfo && (
          <>
            <Menu mode="horizontal" selectedKeys={[activePage]} onClick={setActivePageHandler} className={styles.menu}>
              {headerItems.map(({ label, value }) => (
                <Menu.Item key={value} className={styles.menuItem}>
                  <NavLink to={`/${value}`}>{label}</NavLink>
                </Menu.Item>
              ))}
              {process.env.REACT_APP_STAGE === 'dev' ? <Menu.Item key={Date.now()} className={styles.menuItem}  onClick={() => {
                // @ts-ignore
                __ASF_FNS__ && __ASF_FNS__.show()
              }}>
                Help
              </Menu.Item> : null}
            </Menu>

            <div className={styles.rightMenuContainer}>
            <div style={{marginRight: 8}}>
              <YouTubeAuth/>
            </div>
              <Dropdown
                trigger={['click']}
                overlay={
                  <Menu>
                    <Menu.Item key="1" onClick={handleLogOut}>
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              >
                <span className={styles.menuDropdown}>
                  {userInfo.name} <DownOutlined />
                </span>
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Header
