import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import DefaultActionsProps from './DefaultActions.props'
import CustomTemplatesButton from '../CustomTemplatesButton'
import { useGetEndScreenTemplateListQuery } from 'shared/graphql'
import { usePagination } from 'shared/hooks'
import styles from './DefaultActions.module.scss'
import { Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'

const DefaultActions: FC<DefaultActionsProps> = ({ onApplyTemplate }) => {
  const { push } = useHistory()
  const templateListPagination = usePagination()

  const { data: templateListData } = useGetEndScreenTemplateListQuery({
    variables: {
      input: {
        limit: templateListPagination.limit,
        offset: templateListPagination.offset
      }
    }
  })
  console.log(!!templateListData)
  return (
    <div className={styles.container}>
      <Button shape='round' icon={<PlusOutlined />} onClick={() => push('/defaults/createTemplate')}>
        Create Custom Template
      </Button>

        <CustomTemplatesButton onApplyTemplate={onApplyTemplate} applyTemplateButtonText="Set as default" />

    </div>
  )
}

export default DefaultActions
