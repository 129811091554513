import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'antd'
import { EndScreenElement } from 'shared/graphql'
import EndScreenTable from 'shared/features/EndScreenElements/components/EndScreenTable'
import EndScreenEditor from 'shared/features/EndScreenElements/components/EndScreenEditor'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import styles from './EndScreenElements.module.scss'
import checkRestriction from 'shared/features/EndScreenElements/utils/checkRestrictions'
import validateElements from './functions/validateElements'

type EndScreenElementsProps = {
  elements: any[] | []
  removeEndScreenElement: (id: string) => void
  handleChangeTableRow: (value: any) => void
  handleChangeEditor: (position: number, data: EndScreenPreviewItemChangeResult) => void
  setErrorByRestriction: (error: string) => void
}

const EndScreenElements: FC<EndScreenElementsProps> = ({
  elements,
  handleChangeTableRow,
  handleChangeEditor,
  setErrorByRestriction,
  removeEndScreenElement
}) => {
  const [error, setError] = useState(false)

  useEffect(() => {
    const validatedElements = validateElements(elements)
    setError(validatedElements)
    setErrorByRestriction(checkRestriction(elements as unknown as EndScreenElement[]))
  }, [elements, setErrorByRestriction])

  return (
    <Row gutter={[16, 50]} className={styles.container}>
      <Col flex="auto">
        <EndScreenTable elements={elements} onChangeRow={handleChangeTableRow} remove={removeEndScreenElement} />
      </Col>
      <Col flex="500px">
        <EndScreenEditor elements={elements} onChange={handleChangeEditor} error={error} />
      </Col>
    </Row>
  )
}

export default EndScreenElements
