export enum CampaignVideoStatus {
  Pending = 'pending',
  Processing = 'processing',
  'Post Processing' = 'postprocessing',
  Complete = 'complete',
  Failed = 'failed',
  'In Progress' = 'in_progress',
  Cancelled = 'cancelled',
  Deleted = 'deleted'
}

type CampaignVideoStatusTagsType = {
  'pending': string,
  'processing': string,
  'postprocessing': string,
  'complete': string,
  'failed': string,
  'in_progress': string,
  'cancelled': string,
  'deleted': string,
}
export const CampaignStatusTags: CampaignVideoStatusTagsType = {
  'pending': 'cyan',
  'processing': 'orange',
  'postprocessing': 'gold',
  'complete': 'green',
  'failed': 'volcano',
  'in_progress': 'lime',
  'cancelled': 'red',
  'deleted': 'purple'
}
