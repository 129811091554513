import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button, Popconfirm, Tooltip } from 'antd'
import { EyeOutlined, FormOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons'
import { CampaignsListActionsProps } from './CampaignsListActions.props'
import styles from './CampaignsListActions.module.scss'

const CampaignsListActions: FC<CampaignsListActionsProps> = ({ id, onDelete, onDuplicate }) => (
  <div className={styles.container}>
    <Tooltip title="Details">
      <Link to={`/campaigns/details/${id}`} style={{ color: '#000000d9' }}>
        <Button type="primary" size="small" icon={<EyeOutlined />} />
      </Link>
    </Tooltip>
    <Tooltip title="Edit">
      <Link to={`/campaigns/edit/${id}`} style={{ color: '#000000d9' }}>
        <Button size="small" icon={<FormOutlined />} />
      </Link>
    </Tooltip>
    <Tooltip title="Duplicate">
      <Button size="small" onClick={() => onDuplicate(id)} icon={<CopyOutlined />} />
    </Tooltip>
    <Popconfirm title="Sure to remove?" onConfirm={() => onDelete(id)}>
      <Tooltip title="Delete">
        <Button title="Delete" size="small" danger icon={<DeleteOutlined />} />
      </Tooltip>
    </Popconfirm>
  </div>
)

export default CampaignsListActions
