import { Typography } from 'antd'
import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { useCreateUserMutation } from 'shared/graphql'
import { UserFormCreate } from '../components'

const CreateUserContainer = () => {
  const history = useHistory()
  const [createUser] = useCreateUserMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      cache.modify({
        fields: {
          getUserList() {}
        }
      })
      toast.success('User is successfully created')
      history.push('/admin')
    }
  })

  const createUserHandler = useCallback(
    (data) => {
      if (data.email.indexOf('@wmg.com') === -1) {
        toast.error('User must have WMG e-mail')
      } else {
        createUser({
          variables: {
            input: {
              name: data.name,
              email: data.email,
              role: data.role,
              status: data.status
            }
          }
        })
      }
    },
    [createUser]
  )

  return (
    <>
      <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between' }}>
        <Typography.Title>User Detail</Typography.Title>
      </div>
      <UserFormCreate onSave={(data) => createUserHandler(data)} />
    </>
  )
}

export default CreateUserContainer
