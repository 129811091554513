import { FC, useCallback, useEffect } from 'react'
import moment from 'moment'
import { Button, Row, Col } from 'antd'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'

import styles from './CampaignForm.module.scss'
import { createCampaignValidationSchema } from 'features/Campaigns/consts'
import { FormControl, FormRangePicker } from 'components/form'
import {
  Campaign,
  useApplyAllCampaignVideoEndscreensMutation,
  useEndAllCampaignVideoEndscreensMutation
} from 'shared/graphql'
import { toast } from 'react-toastify'
import MCNButton from '../../../../components/Button'

interface ParamTypes {
  onSave: (data: any) => void
  data?: Campaign | null
  isEdit?: boolean
}

const CampaignForm: FC<ParamTypes> = ({ onSave, data, isEdit }) => {
  const formMethods = useForm({ resolver: yupResolver(createCampaignValidationSchema) })

  useEffect(() => {
    if (data) {
      formMethods.setValue('name', data.name)
      formMethods.setValue('date', [moment(data.startDate), moment(data.endDate)])
    }
  }, [data, formMethods])

  const [applyNow] = useApplyAllCampaignVideoEndscreensMutation({
    onError: (error: any) => {
      // toast.error(error.message)
    },
    onCompleted() {
      toast.success('Campaign is in progress')
    }
  })

  const applyNowHandler = useCallback(() => {
    applyNow({
      variables: {
        input: { id: data?.id || '' }
      }
    })
  }, [applyNow, data])

  const [endNow] = useEndAllCampaignVideoEndscreensMutation({
    onError: (error: any) => {
      // toast.error(error.message)
    },
    onCompleted() {
      toast.success('Campaign is in progress')
    }
  })

  const endNowHandler = useCallback(() => {
    endNow({
      variables: {
        input: { id: data?.id || '' }
      }
    })
  }, [endNow, data])

  return (
    <FormProvider {...formMethods}>
      <form
        className={styles.form}
        onSubmit={formMethods.handleSubmit((data) => {
          onSave(data)
        })}
      >
        <Row gutter={[8, 8]}>
          <Col span={8}>
            <FormControl name="name" label="Campaign Name" required />
          </Col>

          <Col span={10}>
            <FormRangePicker name="date" label="Start Date - End Date" required />
          </Col>

          {isEdit && (
            <Col span={2}>
              <div className={styles.buttonContainer}>
                <Button shape='round' htmlType="button" onClick={applyNowHandler}>
                  Start now
                </Button>
              </div>
            </Col>
          )}

          {isEdit && (
            <Col span={2}>
              <div className={styles.buttonContainer}>
                <Button shape='round' htmlType="button" onClick={endNowHandler}>
                  End now
                </Button>
              </div>
            </Col>
          )}

          <Col span={2}>
            <div className={styles.buttonContainer}>
              <MCNButton text={'Save'} type='primary'  htmlType="submit"/>
            </div>
          </Col>
        </Row>
      </form>
    </FormProvider>
  )
}

export default CampaignForm
