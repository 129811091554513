import React, { FC, useCallback, useState } from 'react'
import { Button, Form, Row, Col } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { Typography } from '@wmg-ae/legato'
import { DownloadButton, Paper } from 'components'
import { FormRangePicker, FormSelect } from 'components/form'
import { DATE_FORMAT } from 'shared/constants'
import SearchCriteriaFilters from '../../types/SearchCriteriaFilters'
import { DateRange } from '../../consts'
import { calculateDateRange } from '../../utils'
import SearchCriteriaProps from './SearchCriteria.props'
import { ReportTags } from '../index'
import styles from './SearchCriteria.module.scss'
import { FormSelectOption } from '../../../../components/form/FormSelect/FormSelect.props'

const SearchCriteria: FC<SearchCriteriaProps> = ({ onSearch, tagsLabel, tags, onSearchSelect, loadingSelect, downloadEnabled, onDownload, loadingDownload }) => {
  const [tagLabels, setTagLabels] = useState([])

  const formMethods = useForm({
    defaultValues: {
      dateRange: DateRange.LAST_SEVEN_DAYS,
      date: calculateDateRange(DateRange.LAST_SEVEN_DAYS),
      tags: []
    }
  })
  const handleChangeRange = useCallback(
    (dateRange: DateRange) => {
      const newDate = calculateDateRange(dateRange)

      if (newDate) {
        // @ts-ignore
        formMethods.setValue('date', newDate)
      }
    },
    [formMethods]
  )
  const handleChangeDate = useCallback(() => {
    formMethods.setValue('dateRange', DateRange.CUSTOM)
  }, [formMethods])

  const onSubmit = useCallback(
    (data: any) => {
      console.log({
        tags: data.tags,
        startDate: data.date[0].format(DATE_FORMAT),
        endDate: data.date[1].format(DATE_FORMAT)
      })
      onSearch({
        tags: data.tags,
        startDate: data.date[0].format(DATE_FORMAT),
        endDate: data.date[1].format(DATE_FORMAT)
      } as SearchCriteriaFilters)

    },
    [onSearch]
  )

  const handleRemoveTag = useCallback(
    (tag: string) => {
      const formTags = formMethods.getValues('tags')
      formMethods.setValue(
        'tags',
        formTags.filter((t) => t !== tags.find((tagsTag) => tagsTag.label === tag)?.value)
      )
      setTagLabels((prevState: any) => prevState.filter((t: any) => t !== tag))
      formMethods.handleSubmit(onSubmit)()
    },
    [formMethods, tags, onSubmit]
  )

  return (
    <FormProvider {...formMethods}>
      <Typography.Heading level={4} className={styles.heading}>
        Search Criteria
      </Typography.Heading>
      <Paper>
        <Form layout="vertical" onFinish={formMethods.handleSubmit(onSubmit)}>
          <Row gutter={16}>
            <Col xxl={21} xl={21} md={22} lg={22} sm={22} xs={22}>
              <FormSelect
                filterOption={true}
                showSearch={false}
                loading={loadingSelect}
                className={styles.tags}
                name="tags"
                label={tagsLabel}
                mode="tags"
                options={tags}
                onSearch={onSearchSelect}
                onSelectChange={(selected) => {
                  setTagLabels(
                    selected.map((datatag: any) => tags.find((tag: FormSelectOption) => tag.value === datatag)?.label)
                  )
                  onSearchSelect('')
                }}
              />
            </Col>
            <Col xxl={3} xl={3} md={2} lg={2} sm={2} xs={2}>
              <div className={styles.searchButton}>
                <Button shape="round" htmlType="submit" size="large" type="primary">
                  Search
                </Button>
              </div>
            </Col>
          </Row>

          <div className={styles.controlsWrapper}>
            <FormRangePicker
              className={styles.date}
              rangePickerClassName={styles.rangePickerClassName}
              onChange={handleChangeDate}
              name="date"
              label="Start Date - End Date"
              required
            />
            <FormSelect
              className={styles.dateRange}
              name="dateRange"
              label="Date Range"
              onSelectChange={handleChangeRange}
              options={[
                { value: DateRange.LAST_SEVEN_DAYS, label: DateRange.LAST_SEVEN_DAYS },
                { value: DateRange.LAST_THIRTY_DAYS, label: DateRange.LAST_THIRTY_DAYS },
                { value: DateRange.LAST_WEEK, label: DateRange.LAST_WEEK },
                { value: DateRange.LAST_MONTH, label: DateRange.LAST_MONTH },
                { value: DateRange.CUSTOM, label: DateRange.CUSTOM }
              ]}
            />
          </div>
        </Form>
      </Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <ReportTags tags={tagLabels} onRemoveTag={handleRemoveTag} />
        {downloadEnabled ? <DownloadButton handler={onDownload} loading={loadingDownload} style={{marginTop: 8}} /> : null}
      </div>
    </FormProvider>
  )
}

export default SearchCriteria
