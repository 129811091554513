import * as yup from 'yup'
import { EndScreenElementType } from 'shared/graphql'
import withoutUrl from '../components/EndScreenTable/utils/withoutUrl'
import chechYoutubeLink from '../utils/chechYoutubeLink'

const endScreenValidationSchema = yup.object({
  elementType: yup.mixed().oneOf(Object.values(EndScreenElementType)).required(),
  playTime: yup.number().min(5).max(20).required(),
  urlId: yup.string()
  .when('elementType', {
    is: (elementType: EndScreenElementType,) => !withoutUrl(elementType),
    then: yup.string().required('You should enter URL')
  })
  .when('elementType', (elementType: EndScreenElementType, schema: any) => schema.test({
    test: (url: string) => !(elementType === EndScreenElementType.PlaylistById && !chechYoutubeLink(elementType, url)),
    message: 'Link must be type https://youtube.com/playlist?list==playlistId'
  }))
  .when('elementType', (elementType: EndScreenElementType, schema: any) => schema.test({
    test: (url: string) => !(elementType === EndScreenElementType.SubscribeChannelById && !chechYoutubeLink(elementType, url)),
    message: 'Link must be type https://youtube.com/channel/channelId (id contains 24 symbols)'
  }))
  .when('elementType', (elementType: EndScreenElementType, schema: any) => schema.test({
    test: (url: string) => !(elementType === EndScreenElementType.VideoById && !chechYoutubeLink(elementType, url)),
    message: 'Link must be type https://youtube.com/watch?v=videoId'
  }))
  .when('elementType', (elementType: EndScreenElementType, schema: any) => schema.test({
    test: (url: string) => !(elementType === EndScreenElementType.ExternalLink && !chechYoutubeLink(elementType, url)),
    message: 'Link must be valid with http or https'
  }))
})

export default endScreenValidationSchema
