import React from 'react'
import { EditCustomTemplate } from 'features/Defaults/containers'

const EditDefaultsTemplatePage = () => {
  return (
    <>
      <EditCustomTemplate />
    </>
  )
}

export default EditDefaultsTemplatePage
