import { EndScreenElementType } from "shared/graphql"

const chechYoutubeLink = (type: EndScreenElementType, youtubeUrlOrID: string): boolean => {
    try {
        const url = new URL(youtubeUrlOrID);
        const searchParams = url.searchParams;
        const protocol = url.protocol;
        const videoId = searchParams.get('v');
        const playlistId = searchParams.get('list');

        if (!(protocol === 'http:' || protocol === 'https:')) {
            return false;
        }

        if (type === EndScreenElementType.VideoById) {
            if (videoId) return true;
            return false;
        }
        if (type === EndScreenElementType.PlaylistById) {
            if (playlistId) return true;
            return false;
        }

        const indexOfChannel = url.pathname.indexOf('channel');
        if (type === EndScreenElementType.SubscribeChannelById && indexOfChannel === -1) {
            return false
        }
        if (url.pathname.substring(indexOfChannel + 8, indexOfChannel + 8 + 24).length === 24) {
            return true;
        }

        if (type === EndScreenElementType.ExternalLink) {
            return true;
        }

        return false;
    } catch (err) {
      return false;
    }
}
  
export default chechYoutubeLink