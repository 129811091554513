import { ReactiveVar, makeVar } from '@apollo/client'
import { Campaign, Maybe } from 'shared/graphql'

export const CampaignsStore = {
  campaignList: {
    read: () => campaignListVar()
  }
}

export const campaignListVar: ReactiveVar<Maybe<Campaign>[]> = makeVar<Maybe<Campaign>[]>([])
