import EndScreenElementInputWithPositionAndHeight from 'shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight';
import { EndScreenElementType } from 'shared/graphql'

const disabledElementsDefault = (type: EndScreenElementType, activeElements: EndScreenElementInputWithPositionAndHeight[]): boolean => {
  if (activeElements.length >= 4) return true;

  switch (type) {
    case EndScreenElementType.ExternalLink: { return !!activeElements.find(item => item.type === EndScreenElementType.ExternalLink) }
    case EndScreenElementType.PlaylistById: { return false }
    case EndScreenElementType.SubscribeChannel: { return !!activeElements.find(item => item.type === EndScreenElementType.SubscribeChannel) }
    case EndScreenElementType.SubscribeChannelById: { return false }
    case EndScreenElementType.VideoBestForViewers: { return !!activeElements.find(item => item.type === EndScreenElementType.VideoBestForViewers) }
    case EndScreenElementType.VideoById: { return false }
    case EndScreenElementType.VideoRecentUpload: { return !!activeElements.find(item => item.type === EndScreenElementType.VideoRecentUpload) }
    default: { return true }
  }
}

export default disabledElementsDefault
