import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Form, Table } from 'antd'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import { EndScreenElementType } from 'shared/graphql'
import EditableCell from '../EditableCell/EditableCell'
import Record from './types/Record'
import EndScreenElementInputWithPositionAndHeight from '../../types/EndScreenElementInputWithPositionAndHeight'
import withoutUrl from './utils/withoutUrl'
import endScreenValidationSchema from '../../consts/endScreenValidationSchema'
import CellData from './types/CellData'
import getEndScreenTableColumns from '../../utils/getEndScreenTableColumns'
import { ExternalLinkModal } from 'features/Defaults/components'

type EndScreenTableProps = {
  elements: EndScreenElementInputWithPositionAndHeight[]
  onChangeRow: (data: EndScreenElementInputWithPositionAndHeight) => void
  remove: (id: string) => void
}

const EndScreenTable: FC<EndScreenTableProps> = ({ elements, onChangeRow, remove }) => {
  const [isVisibleModal, setIsVisibleModal] = useState<boolean>(false);
  const formMethods = useForm({
    resolver: yupResolver(endScreenValidationSchema),
    defaultValues: {
      elementType: EndScreenElementType.VideoById,
      playTime: 5,
      urlId: ''
    }
  })

  const data = useMemo(() => {
    return elements
      .map((element) => ({
        id: element.id,
        key: String(element.position),
        position: element.position,
        elementType: element.type,
        height: element.height,
        width: Number(element.width),
        playTime: element.durationMs ? element.durationMs / 1000 : 0,
        urlId: element.urlId || '',
        left: element.left,
        top: element.top,
        message: element.message,
        thumbnail: element.thumbnail,
        youtubeId: element.youtubeId,
        externalLinkCTA: element.externalLinkCTA,
        imageDataUrl: element.imageDataUrl
      }))
      .sort((a, b) => a.position - b.position)
  }, [elements])

  const [editingKey, setEditingKey] = useState('')
  const watchElementType = formMethods.watch('elementType')

  useEffect(() => {
    if (withoutUrl(watchElementType)) {
      formMethods.setValue('urlId', '')
    }
  }, [formMethods, watchElementType])

  const isEditing = (record: Record) => record.key === editingKey

  const cancel = () => {
    setEditingKey('')
    formMethods.reset()
  }

  const edit = (record: Partial<Record> & { key: React.Key }) => {
    console.log('record.urlId', record.urlId)
    formMethods.setValue('elementType', record.elementType!)
    formMethods.setValue('playTime', record.playTime!)
    formMethods.setValue('urlId', record.urlId!)
    setEditingKey(record.key)
  }

  const save = async (key: React.Key) => {
    console.log('save')
    const elementType = formMethods.getValues('elementType')
    const playTime = formMethods.getValues('playTime')
    const url = formMethods.getValues('urlId')

    const isValid = await formMethods.trigger(['elementType', 'urlId', 'playTime'])

    if (!isValid) return

    const newData = [...data]
    const index = newData.findIndex((item) => key === item.key)
    const item = newData[index]
    const urlId = withoutUrl(elementType) ? '' : url

    onChangeRow({
      type: elementType,
      position: item.position,
      durationMs: Number(playTime) * 1000,
      width: item.width,
      height: item.height,
      left: item.left,
      top: item.top,
      urlId: urlId ?? '',
      id: item.id,
      message: item.message,
      thumbnail: item.thumbnail,
      youtubeId: item.youtubeId,
      externalLinkCTA: item.externalLinkCTA,
      imageDataUrl: item.imageDataUrl
    })

    newData.splice(index, 1, {
      ...item,
      elementType,
      playTime,
      urlId: urlId ?? ''
    })

    const showError = !newData.some((item) =>
      [
        EndScreenElementType.PlaylistById,
        EndScreenElementType.VideoRecentUpload,
        EndScreenElementType.VideoBestForViewers,
        EndScreenElementType.VideoById
      ].includes(item.elementType)
    )

    if (showError) {
      toast.error('At least one element must be a video or a playlist.')
    }

    setEditingKey('')
  }

  const columns = getEndScreenTableColumns({ isEditing, editingKey, cancel, edit, remove, save, setIsVisibleModal })

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }

    return {
      ...col,
      onCell: (record: Record) => {
        const editing = isEditing(record)

        const result: CellData = {
          record,
          dataIndex: col.dataIndex,
          title: col.title,
          editing
        }

        if (editing && col.dataIndex === 'elementType') {
          result.disabledElementTypes = data.reduce<EndScreenElementType[]>((acc, item) => {
            if (withoutUrl(item.elementType)) {
              acc.push(item.elementType)
            }
            return acc
          }, [])
        }

        return result
      }
    }
  })

  const externalLinkModalOnOk = useCallback((fileInBase64, cta, message) => {
    const row = elements.find((item) => item.type === EndScreenElementType.ExternalLink);
    if (!row) {
      // set error
      return;
    }

    onChangeRow({
      type: row.type,
      position: row.position,
      durationMs: row.durationMs,
      width: row.width,
      height: row.height,
      left: row.left,
      top: row.top,
      urlId: row.urlId,
      id: row.id,
      thumbnail: row.thumbnail,
      youtubeId: row.youtubeId,
      externalLinkCTA: cta ?? row.externalLinkCTA,
      imageDataUrl: fileInBase64 ?? row.imageDataUrl,
      message: message ?? row.message
    })
  }, [elements, onChangeRow]);

  const externalLinkElement = useMemo(() => elements.find(item => item.type === EndScreenElementType.ExternalLink), [elements])
  return (
    <>
      <FormProvider {...formMethods}>
        <Form>
          <Table
            components={{
              body: {
                cell: EditableCell
              }
            }}
            style={{ flexFlow: 'row' }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            pagination={false}
          />
        </Form>
      </FormProvider>
      <ExternalLinkModal
        externalLinkElement={externalLinkElement}
        isVisible={isVisibleModal}
        onClose={() => setIsVisibleModal(false)}
        onOk={externalLinkModalOnOk}/>
    </>
  )
}

export default EndScreenTable
