import moment from 'moment'
import { DateRange } from '../consts'

const calculateDateRange = (range: DateRange): moment.Moment[] | null => {
  switch (range) {
    case DateRange.LAST_SEVEN_DAYS:
      return [moment().subtract(7, 'd'), moment().subtract(1, 'd')]
    case DateRange.LAST_THIRTY_DAYS:
      return [moment().subtract(30, 'd'), moment().subtract(1, 'd')]
    case DateRange.LAST_WEEK:
      return [moment().subtract(1, 'weeks').startOf('week'), moment().subtract(1, 'weeks').endOf('week')]
    case DateRange.LAST_MONTH:
      return [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    case DateRange.CUSTOM:
      return null
    default:
      return null
  }
}

export default calculateDateRange
