import { FC, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { formatDate } from 'shared/utils'
import { useGetUserListQuery, User, UserRole, UserStatus } from 'shared/graphql'
import { UserList } from '../components'
import {
  MCN_PAGINATION_CONFIG} from '../../../shared/utils/tableHelpers'

const UserListContainer: FC = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [users, setUsers] = useState<User[]>([]);
  const [pageSize, setPageSize] = useState(10)

  const getUserListQuery = useGetUserListQuery({
    variables: {
      input: {
        offset: (currentPage - 1) * pageSize,
        limit: pageSize
      }
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    const items = getUserListQuery.data?.getUserList?.items
    const dateFormat = 'MMM DD, YYYY HH:mm'
    if (items) {
      setUsers(items.map((item) => ({
        id: `${item?.id}`,
        name: `${item?.name}`,
        email: `${item?.email}`,
        lastAction: formatDate(item?.lastAction, dateFormat),
        createdAt: formatDate(item?.createdAt, dateFormat),
        updatedAt: formatDate(item?.updatedAt, dateFormat),
        role: `${item?.role}` as UserRole,
        status: `${item?.status}` as UserStatus
      })));
    }
  }, [getUserListQuery.data?.getUserList?.items]);

  const pagination = {
    ...MCN_PAGINATION_CONFIG,
    total: getUserListQuery.data?.getUserList?.count ? getUserListQuery.data?.getUserList?.count : 0,
    current: currentPage,
    pageSize,
    onChange: (page: number, size: number) => {
      setCurrentPage(page)
      setPageSize(size)
    }
  }
  return <UserList loading={false} users={users} pagination={pagination} />
}

export default UserListContainer
