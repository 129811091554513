import { PaginationProps } from 'antd'
import React from 'react'

export const MCN_PAGINATION_CLASS = 'mcn-pagination'

export const MCN_PAGINATION_SIZE_OPTIONS = ['10', '20', '30', '40', '50']

export const showTotalRenderFn: PaginationProps['showTotal'] = (total, [from, to]) => {
  return <span>Showing records <b>{from}</b> to <b>{to}</b> of <b>{total}</b></span>
}

export const MCN_PAGINATION_CONFIG = {
  className: MCN_PAGINATION_CLASS,
  showSizeChanger: true,
  locale: { items_per_page: '' },
  position: ['bottomCenter' as const],
  size: 'default' as const,
  pageSizeOptions: MCN_PAGINATION_SIZE_OPTIONS,
  showTotal: showTotalRenderFn
}

