import React, {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {Col, Row} from 'antd'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {
  CampaignVideoListFieldName,
  OrderDirection,
  useDeleteAllVideosFromCampaignMutation,
  useDeleteCampaignVideoMutation,
  useDeleteCampaignVideosMutation,
  useGetCampaignStatusQuery,
  useGetCampaignVideosListQuery
} from 'shared/graphql'
import {VideoList, VideoListActions, VideoListInfo} from '../components'
import {CampaignParamTypes} from '../../../../features/Campaigns/types/campaign.params.type'
import {gql, useSubscription} from '@apollo/client'

import {MCN_PAGINATION_CONFIG} from '../../../utils/tableHelpers'
import {debounce} from "../../../utils";

type VideoListContainerProps = {
  unSavedWarning: boolean
  saveCampaignHandler?: any
}
const VideoListContainer: FC<VideoListContainerProps> = ({unSavedWarning, saveCampaignHandler}) => {
  const {campaignId} = useParams<CampaignParamTypes>()

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [videosCompleted, setVideosCompleted] = useState(0)
  const [videosFailed, setVideosFailed] = useState(0)
  const [videosTotal, setVideosTotal] = useState(0)
  const [filters, setFilters] = useState({})
  const [videos, setVideos] = useState<any[]>([])

  const [sortOrder, setSortOrder] = useState({
    field: CampaignVideoListFieldName.Name,
    direction: OrderDirection.Asc
  })

  const getCampaignVideosListQuery = useGetCampaignVideosListQuery({
    variables: {
      input: {
        campaignId,
        offset: (currentPage - 1) * pageSize,
        sortOrder,
        filter: filters,
        limit: pageSize
      }
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    getCampaignVideosListQuery.refetch()
    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useMemo(() => {
    const items = getCampaignVideosListQuery.data?.getCampaignVideosFullList?.items

    if (items) {
      setVideos(
        items.map((item) => ({
          id: `${item?.id}`,
          thumbnail: `${item?.thumbnail}`,
          name: `${item?.name}`,
          channel: `${item?.channel?.name}`,
          views: `${item?.viewCount}`,
          status: `${item?.status}`,
          youtubeId: `${item?.youtubeId}`,
          reason: `${item?.reason}`
        }))
      )
      return
    }

    return setVideos([])
  }, [getCampaignVideosListQuery.data?.getCampaignVideosFullList?.items])

  const pagination = {
    ...MCN_PAGINATION_CONFIG,
    total: getCampaignVideosListQuery.data?.getCampaignVideosFullList?.count
      ? getCampaignVideosListQuery.data?.getCampaignVideosFullList?.count
      : 0,
    current: currentPage,
    pageSize
  }

  const [deleteCampaignVideo] = useDeleteCampaignVideoMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Video removed')
      cache.modify({
        fields: {
          getCampaignVideosFullList() {
          },
          getVideosFullList() {
          }
        }
      })
    }
  })

  const deleteCampaignVideoHandler = useCallback(
    (videoId: any) => {
      deleteCampaignVideo({
        variables: {
          input: {videoId, campaignId}
        }
      })
    },
    [deleteCampaignVideo, campaignId]
  )

  const getCampaignStatusQuery = useGetCampaignStatusQuery({
    variables: {
      input: {
        campaignId
      }
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  const videoListInfo = useMemo(() => {
    const item = getCampaignStatusQuery.data?.getCampaignStatus
    if (item) {
      return item
    }
    return {total: 0, failed: 0, completed: 0}
  }, [getCampaignStatusQuery.data?.getCampaignStatus])

  useEffect(() => {
    if (videoListInfo) {
      setVideosCompleted(videoListInfo.completed)
      setVideosFailed(videoListInfo?.failed)
      setVideosTotal(videoListInfo.total)
    }
  }, [videoListInfo])

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      setCurrentPage(pagination.current)
      setPageSize(pagination.pageSize)
      if (sorter) {
        setSortOrder({
          field: sorter.field || CampaignVideoListFieldName.Name,
          direction: sorter.order === 'ascend' ? OrderDirection.Asc : OrderDirection.Desc
        })
      }
    },
    [setSortOrder, setCurrentPage, setPageSize]
  )

  const downloadButtonOptions = {
    filter: filters,
    sortOrder
  }

  const SUBSCRIPTION_ONUPDATE_CAMPAIGN_VIDEO_STATUS = gql`
      subscription OnUpdateCampaignVideoProcessingStatus {
          onUpdateCampaignVideoProcessingStatus {
              campaignId
              videoId
              status
              reason
              start
              total
              stats {
                  pending
                  processing
                  postprocessing
                  complete
                  failed
                  in_progress
                  cancelled
                  delete
              }
          }
      }
  `

  const {data: onUpdateCampaignVideoStatusEvent} = useSubscription(SUBSCRIPTION_ONUPDATE_CAMPAIGN_VIDEO_STATUS)

  const debounceUpdateStatus =  useMemo(() => {
    return debounce(getCampaignStatusQuery.refetch, 5000)
  }, [getCampaignStatusQuery.refetch])

  useEffect(() => {
    if (onUpdateCampaignVideoStatusEvent?.onUpdateCampaignVideoProcessingStatus?.videoId) {
      const status = onUpdateCampaignVideoStatusEvent.onUpdateCampaignVideoProcessingStatus
      setVideos((prevState: any) =>
        prevState.map((video: any) => {
          if (
            video.id === status.videoId ??
            campaignId === status.campaignId
          ) {
            video.status = status.status
            video.reason = status.reason
          }
          return video
        })
      )

      const failed = status.stats.failed
      const start = status.start

      setVideosFailed(failed)
      setVideosCompleted(start ? status.stats.in_progress || 0 : status.stats.cancelled || 0)
      setVideosTotal(status.total)

      debounceUpdateStatus()
    }
  }, [onUpdateCampaignVideoStatusEvent, campaignId, debounceUpdateStatus])

  const [selectedVideos, setSelectedVideos] = useState<any[]>([])

  const selectVideos = useCallback(
    (videoIds: [string]) => {
      videoIds.forEach((videoId: string) => {
        if (!selectedVideos.includes(videoId)) {
          setSelectedVideos((prevState) => prevState.concat(videoId))
        } else {
          setSelectedVideos((prevState: any) => prevState.filter((el: any) => el !== videoId))
        }
      })
    },
    [selectedVideos, setSelectedVideos]
  )

  const [deleteAllVideos] = useDeleteAllVideosFromCampaignMutation({
    onError: (error) => {
      // toast.error(error.message)
    },
    update(cache) {
      // toast.success('Channel Updated')
      cache.modify({
        fields: {
          getCampaignVideosFullList() {
          },
          getVideosFullList() {
          }
        }
      })
    }
  })

  const deleteAllVideosHandler = useCallback(
    (data: any) => {
      deleteAllVideos({
        variables: {
          input: {campaignId}
        }
      })
    },
    [deleteAllVideos, campaignId]
  )

  const [deleteSelectedVideos] = useDeleteCampaignVideosMutation({
    onError: (error) => {
    },
    update(cache) {
      cache.modify({
        fields: {
          getCampaignVideosFullList() {
          },
          getVideosFullList() {
          }
        }
      })
    }
  })

  const deleteSelectedVideosHandler = useCallback(() => {
    deleteSelectedVideos({
      variables: {
        input: {campaignId, videoIds: selectedVideos}
      }
    })
  }, [deleteSelectedVideos, selectedVideos, campaignId])

  return (
    <Row gutter={[0, 20]}>
      <Col span={24}>
        <VideoListInfo total={videosTotal} successed={videosCompleted} failed={videosFailed}
                       stats={videoListInfo.stats}/>
      </Col>
      <Col span={24}>
        <VideoListActions
          downloadButtonOptions={downloadButtonOptions}
          unSavedWarning={unSavedWarning}
          saveCampaignHandler={saveCampaignHandler}
          disableDeleteSelected={!!selectedVideos.length}
          disableDeleteAll={!!videos.length}
          deleteAll={deleteAllVideosHandler}
          deleteSelected={deleteSelectedVideosHandler}
        />
      </Col>
      <Col span={24}>
        <VideoList
          selectVideos={selectVideos}
          selectedVideos={selectedVideos}
          videos={videos}
          loading={false}
          onRemove={deleteCampaignVideoHandler}
          pagination={pagination}
          handleTableChange={handleTableChange}
          filters={filters}
          setFilters={setFilters}
        />
      </Col>
    </Row>
  )
}

export default VideoListContainer
