import { EndScreenElementType } from 'shared/graphql'
import EndScreenRoundedSizes from 'shared/features/EndScreenElements/types/EndScreenRoundedSizes'
import EndScreenSquaredSizes from 'shared/features/EndScreenElements/types/EndScreenSquaredSizes'
import EndScreenExternalLinkSizes from 'shared/features/EndScreenElements/types/EndScreenExternalLinkSizes';

const getEndScreenHeight = (type: EndScreenElementType | undefined, height?: string | number): number => {
  if (height) { return +height; }
  if (type === EndScreenElementType.ExternalLink) { return EndScreenExternalLinkSizes.height; }
  if (type === EndScreenElementType.SubscribeChannelById || type === EndScreenElementType.SubscribeChannel) {
    return EndScreenRoundedSizes.height;
  }
  
  return EndScreenSquaredSizes.height
}

export default getEndScreenHeight
