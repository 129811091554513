import React from "react";
import { Tag } from 'antd'
import ColumnHeading from "../../MasterChannelList/components/TableColumns/ColumnHeading";
import { CampaignListOrderFieldName } from "../../../shared/graphql";
import { FilterDropdownProps as AntFilterDropdownProps, SortOrder } from "antd/lib/table/interface";
import FilterDropdown from "../../MasterChannelList/components/FilterDropdown";
import { getEnumKeyByEnumValue } from '../../../shared/utils/getEnumKeyByEnumValue'
import { CampaignVideoStatus, CampaignStatusTags } from '../../../shared/constants/campaignStatuses'

export const campaignsListColumns = (setFilters: any) => {
  return [
    {
      title: () => {
        return (
          <ColumnHeading title={`Name`} tooltip={`Custom campaign name`} />
        );
      },
      dataIndex: "name",
      key: "name",
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, name: searchText }))}
        />
      ),
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`Creation Date`} tooltip={`Date when the campaign was created`} />
        );
      },
      sorter: true,
      hidden: false,
      dataIndex: CampaignListOrderFieldName.CreatedAt,
      defaultSortOrder: "descend" as SortOrder,
      key: "createdAt"
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`Start Date`} tooltip={`Estimated campaign start date`} />
        );
      }, sorter: true, dataIndex: CampaignListOrderFieldName.StartDate, key: "startDate"
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`End Date`}
                         tooltip={`Estimated date, when campaign should be stopped, and EndScreens should be reverted to default`} />
        );
      }, sorter: true, dataIndex: CampaignListOrderFieldName.EndDate, key: "endDate"
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`Views`} tooltip={`Sum of views of all videos of campaign`} />
        );
      },
      sorter: true,
      dataIndex: CampaignListOrderFieldName.Views,
      width: 120,
      key: "views",
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`Status`} placement={`bottomRight`} tooltip={<div>
            The current campaign status. <br /> Supported Status types: <br />
            <span style={{ color: "darkorange" }}>Processing </span>: The videos are in the process of having their end
            screens programmatically changed. This displays whether
            its the first time (at the beginning of a campaign) or an update in mid-campaign (new videos are added or
            removed).
            <br />
            <span style={{ color: "greenyellow" }}> Campaign In-Progress </span>
            The videos have been processed and the campaign in in progress.
            <br />
            <span style={{ color: "yellow" }}> Processing (Post) </span>
            After a campaign is complete, the videos are in the process of having their end screens returned to the
            default end screen.
            <br />
            <span style={{ color: "green" }}> Campaign Complete </span>
            The Campaign end date has passed, the campaign is done, and the end screens have been returned to their
            default screens.
            <br />
            <span style={{ color: "cyan" }}> Pending </span>
            The campaign has not yet started because it is before the Start Date
            <br />
            <span style={{ color: "red" }}>Cancelled </span>
            The campaign is done because it's been cancelled.
          </div>} />
        );
      },
      sorter: true,
      dataIndex: CampaignListOrderFieldName.Status,
      key: "status",
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, status: searchText }))}
        />
      ),
      render: (_: string, record: any) => {
        return (
            <Tag color={CampaignStatusTags[_ as keyof typeof CampaignStatusTags]}>
              {getEnumKeyByEnumValue(CampaignVideoStatus, _)}
            </Tag>
        )
      }
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`Last Update`} tooltip={`Date when campaign was changed the last time`} />
        );
      },
      dataIndex: "updatedAt",
      key: "updatedAt"
    },
    {
      title: () => {
        return (
          <ColumnHeading title={`Actions`} tooltip={<div>
            Available actions. <br />
            <span>Campaign details </span>: View the results of a campaign. Learn about endscreen CTR, impressions, click
            throughs, etc
            <br />
            <span> Edit Campaign  </span>
            Edit campaign name, dates, end screens, manage videos
            <br />
            <span> Duplicate Campaign </span>
            Create a copy of a campaign with same name, dates, end screens & videos
            <br />
            <span> Delete Campaign </span>
            Delete a campaign. It cannot be manually restored
            <br />
          </div>} />
        );
      },
      dataIndex: "actions",
      key: "actions",
    }
  ]
};
