import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { Button, Modal, Popconfirm, Table, Tooltip } from 'antd'
import { Typography } from '@wmg-ae/legato'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ApolloError } from 'apollo-client'
import { usePagination } from 'shared/hooks'
import {
  EndScreenElement,
  EndScreenTemplate,
  useDeleteEndScreensTemplateMutation,
  useGetEndScreenTemplateListQuery
} from 'shared/graphql'
import EndScreenEditor from 'shared/features/EndScreenElements/components/EndScreenEditor'
import CustomTemplatesModalProps from './CustomTemplatesModal.props'
import { ColumnsType } from 'antd/lib/table/interface'
import { DeleteOutlined, FormOutlined } from '@ant-design/icons'
import styles from './CustomTemplatesModal.module.scss'

const CustomTemplatesModal: FC<CustomTemplatesModalProps> = ({ isVisible, onClose, onOk, buttonText, showActions }) => {
  const { push } = useHistory()
  const templateListPagination = usePagination()
  const [selectedTemplate, setSelectedTemplate] = useState<EndScreenTemplate | null>(null)

  const { data: templateListData } = useGetEndScreenTemplateListQuery({
    variables: {
      input: {
        limit: 200,
        offset: templateListPagination.offset
      }
    }
  })

  const [deleteTemplate] = useDeleteEndScreensTemplateMutation({
    refetchQueries: ['GetEndScreenTemplateList'],
    onCompleted() {
      toast.success('The template has been deleted')
    },
    onError(error: ApolloError) {
      toast.error(error.message)
    }
  })

  const handleDelete = useCallback(
    (endScreenTemplateId: string) => {
      deleteTemplate({ variables: { input: { endScreenTemplateId } } })
    },
    [deleteTemplate]
  )

  const handleEdit = useCallback(
    (e: SyntheticEvent, endScreenTemplateId: string) => {
      e.stopPropagation()
      push(`/defaults/edit/${endScreenTemplateId}`)
    },
    [push]
  )

  const dataSource = useMemo(() => {
    return (
      templateListData?.getEndScreenTemplateList?.items?.map((template) => ({
        key: template.id,
        id: template.id,
        endscreens: template.endscreens,
        name: template.name
      })) || []
    )
  }, [templateListData?.getEndScreenTemplateList?.items])

  const columns = useMemo(() => {
    const result: ColumnsType<EndScreenTemplate & { key: string }> = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'Template',
        dataIndex: 'endscreens',
        key: 'endscreens',
        width: 150,
        render: (endscreens: EndScreenElement[]) => {
          return (
            <div className={styles.elementsItem}>
              <EndScreenEditor
                className={styles.smallEndScreen}
                readMode
                elements={
                  endscreens.map<EndScreenElement>((item) => {
                    return { ...item } as any
                  }) as any
                }
              />
            </div>
          )
        }
      }
    ]

    if (showActions) {
      result.push({
        title: 'Actions',
        key: 'actions',
        dataIndex: 'actions',
        width: 80,
        render(_, record) {
          return (
            <div className={styles.actions}>
              <Button
                type="primary"
                shape="round"
                size="small"
                icon={<FormOutlined />}
                onClick={(e) => handleEdit(e, record.key)}
              >
                Edit
              </Button>
              <Popconfirm title="Sure to remove?" onConfirm={() => handleDelete(record.key)}>
                <Tooltip title="Delete">
                  <Button shape="round" size="small" danger icon={<DeleteOutlined />}>
                    Delete
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
          )
        }
      })
    }

    return result
  }, [handleDelete, handleEdit, showActions])

  return (
    <Modal visible={isVisible} onCancel={onClose} title="Select a template" footer={null} width="80vw">
      <div className={styles.container}>
        <Table
          bordered
          size="small"
          pagination={{
            pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
            pageSize: 5,
            total: templateListData?.getEndScreenTemplateList?.count
          }}
          rowClassName={styles.row}
          onRow={(record) => {
            return {
              onClick() {
                setSelectedTemplate({
                  id: record.id,
                  name: record.name,
                  endscreens: record.endscreens
                } as EndScreenTemplate)
              }
            }
          }}
          dataSource={dataSource}
          columns={columns as any}
        />
        {!selectedTemplate && (
          <div className={styles.preview}>
            <Typography.Heading level={3}>Please choose a template</Typography.Heading>
          </div>
        )}
        {selectedTemplate && (
          <div className={styles.preview}>
            <Typography.Heading level={2} noMargin>
              Preview
            </Typography.Heading>
            <EndScreenEditor
              readMode
              elements={
                selectedTemplate.endscreens?.map<EndScreenElement>((item) => {
                  return { ...item } as any
                }) as any
              }
            />
            <Button
              shape="round"
              onClick={() => {
                onOk(selectedTemplate)
                onClose()
              }}
            >
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default CustomTemplatesModal
