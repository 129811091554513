import React, { FC } from 'react'
import { Controller, useController, useFormContext } from 'react-hook-form'
import { Checkbox } from '@wmg-ae/legato'
import { ICheckboxProps } from '@wmg-ae/legato/build/components/Checkbox/Checkbox'
import FormItem from '../FormItem'
import FormInputProps from '../types/FormInputProps'
import styles from './FormCheckbox.module.scss'

type FormCheckboxProps = Omit<ICheckboxProps, 'id' | 'onChange'> & FormInputProps & {className?: string}

const FormCheckbox: FC<FormCheckboxProps> = ({ name, required = false, className, ...restProps }) => {
  const { control } = useFormContext()
  const { fieldState } = useController({ name, control })

  return (
    <FormItem error={fieldState.error} className={className}>
      <Controller
        name={name}
        rules={{ required }}
        control={control}
        render={({ field }) => {
          const { ref, ...restField } = field

          return (
            <Checkbox
              id={name}
              {...restField}
              {...restProps}
              onChange={(_, checked) => restField.onChange(checked)}
              className={styles.container}
            />
          )
        }}
      />
    </FormItem>
  )
}

export default FormCheckbox
