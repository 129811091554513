import React, { FC } from 'react'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import { EndScreenElements } from 'shared/features/EndScreenElements/components'

type EndScreenElementsProps = {
  elements: any[] | []
  removeEndScreenElement: (id: string) => void
  handleChangeTableRow: (value: any) => void
  handleChangeEditor: (position: number, data: EndScreenPreviewItemChangeResult) => void
  setErrorByRestriction: (error: string) => void
}

const EndScreenElementsWrapper: FC<EndScreenElementsProps> = ({ ...props}) => <EndScreenElements {...props}/>

export default EndScreenElementsWrapper
