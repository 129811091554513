import React, { FC, useCallback, useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Heading } from 'components'
import { CampaignForm } from 'features/Campaigns/components'
import { VideoListContainer } from 'shared/features/VideoList/containers'
import { DATE_TEXT_FORMAT, DATE_FORMAT } from 'shared/constants'
import {
  Campaign,
  EndScreenElementType,
  useGetCampaignQuery,
  useGetEndScreenTemplateListQuery,
  useUpdateCampaignWithEndScreensMutation,
  useGetEndScreenThumbnailLazyQuery
} from 'shared/graphql'
import { usePagination } from 'shared/hooks'
import { CampaignParamTypes } from '../../types/campaign.params.type'
import CustomTemplatesButton from '../../../Defaults/components/CustomTemplatesButton'
import { Button, List, Tooltip } from 'antd'
import getElementTypeLabel from 'shared/features/EndScreenElements/components/EndScreenTable/utils/getElementTypeLabel'
import { v4 as uuidv4 } from 'uuid'
import { useUpdateEffect } from 'react-use'
import SwitchMode from '../../components/SwitchMode/SwitchMode'
import EndScreenElementInputWithPositionAndHeight from '../../../../shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight'
import getEndScreenHeight from '../../../../shared/utils/getEndScreenHeight'
import EndScreenPreviewItemChangeResult from '../../../../shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import getEndScreenWidth from 'shared/utils/getEndScreenWidth'
import EndScreenElementsWrapper from './EndScreenElementsWrapper'
import addElementsGeometryProperties from 'shared/features/EndScreenElements/components/EndScreenElements/functions/addElementsGeometryProperties'
import disabledElements from 'shared/features/EndScreenElements/components/EndScreenElements/functions/disabledElement'
import setPosition from 'shared/features/EndScreenElements/components/EndScreenElements/functions/setPosition'
import checkRestriction from 'shared/features/EndScreenElements/utils/checkRestrictions'

const EditCampaignContainer: FC = () => {
  const [defaultMode, setSwitchMode] = useState(false)
  const { campaignId } = useParams<CampaignParamTypes>()
  const templateListPagination = usePagination()
  const [completiveElements, setCompletiveElements] = useState<EndScreenElementInputWithPositionAndHeight[] | []>([])
  const [operativeElements, setOperativeElements] = useState<EndScreenElementInputWithPositionAndHeight[] | []>([])
  const [campaign, setCampaign] = useState<Campaign | null>(null)
  const [errorByRestriction, setErrorByRestriction] = useState<string>('')

  const [isShowCreateEndScreen, setIsShowCreateEndScreen] = useState<boolean>(false)
  const [hasUnSavedChanges, setHasUnSavedChanges] = useState<boolean>(false)

  const { data: templateListData } = useGetEndScreenTemplateListQuery({
    variables: {
      input: {
        limit: templateListPagination.limit,
        offset: templateListPagination.offset
      }
    }
  })

  useUpdateEffect(() => {
    const activeElements = defaultMode ? completiveElements : operativeElements
    setIsShowCreateEndScreen(true)
    if (activeElements.length >= 4) {
      setIsShowCreateEndScreen(false)
    }
  }, [completiveElements, operativeElements, defaultMode])

  const getCampaignQuery = useGetCampaignQuery({
    variables: {
      input: {
        id: campaignId
      }
    },
    onError(error: any) {
      toast.error(error.message)
    }
  })

  const [getEndScreenThumbnailQuery, { data: getEndScreenThumbnailQueryData }] = useGetEndScreenThumbnailLazyQuery({
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const getEndScreenThumbnailHandler = useCallback(
    (endscreenId: string, youtubeId: string, endscreenType: EndScreenElementType) => {
      getEndScreenThumbnailQuery({
        variables: {
          input: {
            endscreenId,
            youtubeId,
            endscreenType
          }
        }
      })
    },
    [getEndScreenThumbnailQuery]
  )

  useMemo(() => {
    const item = getCampaignQuery.data?.getCampaign?.item

    if (item) {
      setOperativeElements((prevState) => {
        return addElementsGeometryProperties(
          item.endScreens.filter((i) => i.isDefault === false),
          prevState
        )
      })

      setCompletiveElements((prevState) => {
        return addElementsGeometryProperties(
          item.endScreens.filter((i) => i.isDefault === true),
          prevState
        )
      })

      setCampaign({
        id: item.id,
        name: item.name,
        startDate: moment(item.startDate).format(DATE_TEXT_FORMAT),
        endDate: moment(item.endDate).format(DATE_TEXT_FORMAT),
        createdAt: moment(item.createdAt).format(DATE_TEXT_FORMAT),
        updatedAt: moment(item.updatedAt).format(DATE_TEXT_FORMAT),
        views: item.views,
        status: item.status
      })
    }
  }, [getCampaignQuery.data?.getCampaign?.item])

  useMemo(() => {
    const elem = getEndScreenThumbnailQueryData?.getEndScreenThumbnail

    if (elem && elem.endscreenId) {
      const operativeElement = operativeElements.find((item) => item.id === elem.endscreenId)
      if (operativeElement) {
        setOperativeElements((prevState) => [
          ...prevState.filter((item) => item.id !== elem.endscreenId),
          { ...operativeElement, thumbnail: { thumbnail: elem.thumbnail, name: elem.name }, youtubeId: elem.youtubeId }
        ])
      }

      const completiveElement = completiveElements.find((item) => item.id === elem.endscreenId)
      if (completiveElement) {
        setCompletiveElements((prevState) => [
          ...prevState.filter((item) => item.id !== elem.endscreenId),
          { ...completiveElement, thumbnail: { thumbnail: elem.thumbnail, name: elem.name }, youtubeId: elem.youtubeId }
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEndScreenThumbnailQueryData?.getEndScreenThumbnail])

  const [updateCampaignWithEndScreens] = useUpdateCampaignWithEndScreensMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted() {
      toast.success('Campaign is updated')
      getCampaignQuery.refetch()
    }
  })

  const formatPayloadElement = useCallback((elem: any) => {
    return {
      id: elem.id,
      type: elem?.type,
      message: elem?.message,
      top: elem?.top,
      durationMs: elem?.durationMs,
      position: elem?.position,
      urlId: elem?.urlId,
      left: elem?.left,
      width: elem?.width,
      height: elem?.height,
      isDefault: elem?.isDefault,
      ...(elem.imageDataUrl && { imageDataUrl: elem.imageDataUrl }),
      ...(elem.externalLinkCTA && { externalLinkCTA: elem.externalLinkCTA })
    }
  }, [])

  const updateCampaignHandler = useCallback(
    (data: { name: string; date: moment.Moment[] }) => {
      const completive = completiveElements.map(formatPayloadElement)
      const operative = operativeElements.map(formatPayloadElement)

      setErrorByRestriction(checkRestriction(completive))
      setErrorByRestriction(checkRestriction(operative))
      if (!errorByRestriction) {
        setHasUnSavedChanges(false)
        updateCampaignWithEndScreens({
          variables: {
            campaignInput: {
              id: campaignId,
              name: data.name,
              startDate: data.date[0].format(DATE_FORMAT),
              endDate: data.date[1].format(DATE_FORMAT)
            },
            campaignEndScreensInput: {
              campaignId,
              completive,
              operative
            }
          }
        })
      } else {
        toast.error(`${checkRestriction(operative)} ${checkRestriction(completive)}`)
        toast.error(`Saving declined`)
      }
    },
    [
      campaignId,
      operativeElements,
      completiveElements,
      errorByRestriction,
      formatPayloadElement,
      updateCampaignWithEndScreens
    ]
  )

  const saveCampaignHandler = useCallback(() => {
    if (campaign?.name) {
      updateCampaignHandler({ name: campaign.name, date: [moment(campaign.startDate), moment(campaign.endDate)] })
    }
  }, [updateCampaignHandler, campaign])

  const handleApplyTemplate = useCallback(
    (template: any, mode: boolean) => {
      const setElements = mode ? setCompletiveElements : setOperativeElements
      setHasUnSavedChanges(true)
      setElements(() => {
        return template.endscreens.map((elem: any) => {
          const newId = uuidv4()

          const id = (mode ? completiveElements : operativeElements).find(
            (element) => element.position === elem?.position
          )?.id

          return {
            id: `${id || newId}`,
            type: `${elem?.type}`,
            message: elem?.message,
            top: elem?.top || 0,
            durationMs: elem.durationMs || 15,
            position: elem.position || 4,
            urlId: elem.urlId,
            left: elem.left || 0,
            width: elem.width,
            height: elem.height,
            externalLinkCTA: elem.externalLinkCTA,
            imageDataUrl: elem.imageDataUrl,
            thumbnail: elem.thumbnail,
            isDefault: mode
          }
        })
      })
    },
    [setCompletiveElements, setOperativeElements, completiveElements, operativeElements]
  )

  const onSwitchMode = (mode: boolean) => setSwitchMode(mode)

  const addInitialElement = (type: EndScreenElementType, mode: boolean) => {
    const newId = uuidv4()
    const elementSetter = mode ? setCompletiveElements : setOperativeElements
    setHasUnSavedChanges(true)

    elementSetter((prevState) => [
      ...prevState,
      {
        id: newId,
        isDefault: defaultMode,
        type,
        left: 150,
        position: prevState && prevState.length ? setPosition(prevState) : 1,
        durationMs: 20000,
        height: getEndScreenHeight(type),
        top: 60,
        urlId: '',
        message: '',
        width: getEndScreenWidth(type),
        createdAt: '',
        updatedAt: ''
      }
    ])
  }

  const isDisabled = (type: EndScreenElementType, mode: boolean): boolean =>
    disabledElements(type, mode ? completiveElements : operativeElements)

  const removeEndScreenElement = (id: string, mode: boolean) => {
    setHasUnSavedChanges(true)
    const setElements = mode ? setCompletiveElements : setOperativeElements
    setElements((prevState) => [...prevState.filter((item) => item.id !== id)])
  }

  const replaceEndScreenElement = (
    oldElements: EndScreenElementInputWithPositionAndHeight[],
    position: any,
    data: any
  ) => {
    const newElements = [...oldElements]
    setHasUnSavedChanges(true)
    const elementIndex = newElements.findIndex((el) => el.position === position)
    const newElement = {
      ...newElements[elementIndex],
      ...data,
      width: getEndScreenWidth(data.type, data.width)
      // height: getEndScreenHeight(data.type, data.height)
    }

    newElements.splice(elementIndex, 1, newElement)
    return newElements
  }

  const handleChangeEditor = useCallback(
    (position: number, data: EndScreenPreviewItemChangeResult) => {
      setHasUnSavedChanges(true)

      if (defaultMode) {
        setCompletiveElements((oldElements: EndScreenElementInputWithPositionAndHeight[]) =>
          replaceEndScreenElement(oldElements, position, data)
        )
      } else {
        setOperativeElements((oldElements: EndScreenElementInputWithPositionAndHeight[]) =>
          replaceEndScreenElement(oldElements, position, data)
        )
      }
    },
    [setCompletiveElements, setOperativeElements, defaultMode]
  )

  const handleChangeTableRow = useCallback(
    (data: EndScreenElementInputWithPositionAndHeight) => {
      setHasUnSavedChanges(true)

      if (defaultMode) {
        setCompletiveElements((oldElements: EndScreenElementInputWithPositionAndHeight[]) => {
          if (
            data.urlId &&
            (data.type === EndScreenElementType.VideoById || data.type === EndScreenElementType.SubscribeChannelById)
          ) {
            getEndScreenThumbnailHandler(data.id, data.urlId, data.type)
          }
          return replaceEndScreenElement(oldElements, data.position, data)
        })
      } else {
        setOperativeElements((oldElements: EndScreenElementInputWithPositionAndHeight[]) => {
          if (
            data.urlId &&
            (data.type === EndScreenElementType.VideoById || data.type === EndScreenElementType.SubscribeChannelById)
          ) {
            getEndScreenThumbnailHandler(data.id, data.urlId, data.type)
          }

          return replaceEndScreenElement(oldElements, data.position, data)
        })
      }
    },
    [setOperativeElements, setCompletiveElements, defaultMode, getEndScreenThumbnailHandler]
  )

  return (
    <>
      <CampaignForm onSave={updateCampaignHandler} data={campaign} isEdit={true} />
      <Heading level={3} noMargin>
        Endscreen elements
        <Tooltip
          color="#fff"
          trigger={['click']}
          title={
            <List
              bordered
              dataSource={Object.values(EndScreenElementType)}
              renderItem={(type) => (
                <Button
                  onClick={() => addInitialElement(type, defaultMode)}
                  block
                  disabled={isDisabled(type, defaultMode)}
                >
                  {getElementTypeLabel(type)}
                </Button>
              )}
            />
          }
        >
          <Button
            style={{
              margin: '0 20px',
              borderRadius: '15px'
            }}
            disabled={isShowCreateEndScreen ? false : true}
          >
            +
          </Button>
        </Tooltip>
      </Heading>
      {!!templateListData?.getEndScreenTemplateList?.items && (
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: 'red', fontWeight: 700 }}>{errorByRestriction}</span>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 456,
              marginRight: 14
            }}
          >
            <div style={{ display: 'flex' }}>
              <SwitchMode
                onSwitchMode={onSwitchMode}
                switchMode={defaultMode}
                preCampaignTooltip={'These Elements will be applied when campaign starts'}
                postCampaignTooltip={'These Elements will be applied when campaign ends'}
              />
            </div>
            <CustomTemplatesButton
              showActions={false}
              onApplyTemplate={(template) => handleApplyTemplate(template, defaultMode)}
              applyTemplateButtonText="Use this template"
            />
          </div>
        </div>
      )}

      <EndScreenElementsWrapper
        handleChangeTableRow={handleChangeTableRow}
        handleChangeEditor={handleChangeEditor}
        elements={defaultMode ? completiveElements : operativeElements}
        removeEndScreenElement={(id) => removeEndScreenElement(id, defaultMode)}
        setErrorByRestriction={setErrorByRestriction}
      />
      <VideoListContainer unSavedWarning={hasUnSavedChanges} saveCampaignHandler={saveCampaignHandler} />
    </>
  )
}

export default EditCampaignContainer
