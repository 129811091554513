import React from 'react'
import FormControl from 'components/form/FormControl'
import FormSelect from 'components/form/FormSelect'
import { EndScreenElementType } from 'shared/graphql'
import { useFormContext } from 'react-hook-form'
import getElementTypeLabel from '../EndScreenTable/utils/getElementTypeLabel'
import withoutUrl from '../EndScreenTable/utils/withoutUrl'
import CellData from '../EndScreenTable/types/CellData'

type EditableCellProps = React.HTMLAttributes<HTMLElement> &
  CellData & {
    index: number
    children: React.ReactNode
  }

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  record,
  index,
  children,
  disabledElementTypes,
  ...restProps
}) => {
  let text = children
  let inputNode = <FormControl name={dataIndex} placeholder="Please enter a link or ID" />

  const { watch } = useFormContext()
  const watchElementType = watch('elementType')

  if (editing && record && withoutUrl(watchElementType)) {
    inputNode = <div />
  }

  if (record && dataIndex === 'elementType') {
    text = getElementTypeLabel(record.elementType)
  }

  if (editing && record && dataIndex === 'elementType') {
    inputNode = (
      <FormSelect
        name={dataIndex}
        options={[
          {
            value: EndScreenElementType.PlaylistById,
            label: getElementTypeLabel(EndScreenElementType.PlaylistById)
          },
          {
            value: EndScreenElementType.VideoById,
            label: getElementTypeLabel(EndScreenElementType.VideoById)
          },
          {
            value: EndScreenElementType.SubscribeChannelById,
            label: getElementTypeLabel(EndScreenElementType.SubscribeChannelById)
          },
          {
            value: EndScreenElementType.SubscribeChannel,
            label: getElementTypeLabel(EndScreenElementType.SubscribeChannel)
          },
          {
            value: EndScreenElementType.ExternalLink,
            label: getElementTypeLabel(EndScreenElementType.ExternalLink),
            disabled: disabledElementTypes?.includes(EndScreenElementType.ExternalLink)
          },
          {
            value: EndScreenElementType.VideoBestForViewers,
            label: getElementTypeLabel(EndScreenElementType.VideoBestForViewers),
            disabled: disabledElementTypes?.includes(EndScreenElementType.VideoBestForViewers)
          },
          {
            value: EndScreenElementType.VideoRecentUpload,
            label: getElementTypeLabel(EndScreenElementType.VideoRecentUpload),
            disabled: disabledElementTypes?.includes(EndScreenElementType.VideoRecentUpload)
          }
        ]}
      />
    )
  }

  if (dataIndex === 'playTime') {
    inputNode = <FormControl name={dataIndex} type="number" />
  }

  return <td {...restProps}>{editing && inputNode ? inputNode : text}</td>
}

export default EditableCell
