import React, { FC, memo } from 'react'
import { Props as RNDProps, Rnd } from 'react-rnd'
import EndScreenPreviewItemChangeResult from '../../../../types/EndScreenPreviewItemChangeResult'

type EndscreenPreviewItemProps = RNDProps & {
  onChange?: (position: number, result: EndScreenPreviewItemChangeResult) => void
  elementPosition: number
  resizeThresholds: any
}

const EndscreenPreviewItem: FC<EndscreenPreviewItemProps> = ({
  children,
  position,
  size,
  onChange,
  elementPosition,
  resizeThresholds,
  ...restProps
}) => {
  return (
    <Rnd
      position={position}
      size={size}
      minHeight={resizeThresholds.height.min}
      maxHeight={resizeThresholds.height.max}
      minWidth={resizeThresholds.width.min}
      maxWidth={resizeThresholds.width.max}
      enableResizing={{
        bottom: false,
        bottomLeft: false,
        bottomRight: false,
        left: false,
        right: false,
        top: false,
        topLeft: false,
        topRight: false
      }}
      bounds="parent"
      onDragStop={(e, d) => {
        let top = d.y
        let left = d.x
        if (top < 9) {
          top = 9
        }

        if (top > 121) {
          top = 121
        }

        if (left > 380) {
          left = 380
        }
        onChange?.(elementPosition, { width: d.node.clientWidth, height: d.node.clientHeight, left, top })
      }}
      onResizeStop={(e, direction, ref, delta, position) => {
        const resized = {
          width: parseInt(ref.style.width, 10),
          height: parseInt(ref.style.height, 10),
          left: position.x,
          top: position.y
        }
        console.log(resized)
        onChange?.(elementPosition, resized)
      }}
      style={{ overflow: 'hidden' }}
      {...restProps}
    >
      {children}
    </Rnd>
  )
}

export default memo(EndscreenPreviewItem)
