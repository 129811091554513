import { FC } from 'react'
import { DatePicker } from 'antd'
import { RangePickerProps } from 'antd/lib/date-picker'
import { Controller, useController, useFormContext } from 'react-hook-form'
import FormInputProps from '../types/FormInputProps'
import FormItem from '../FormItem'

const { RangePicker } = DatePicker

type FormRangePickerProps = RangePickerProps &
  FormInputProps & {
    rangePickerClassName?: string
  }

const FormRangePicker: FC<FormRangePickerProps> = ({
  name,
  label,
  className,
  rangePickerClassName,
  required = false,
  onChange
}) => {
  const { control } = useFormContext()
  const { fieldState } = useController({ name, control })

  return (
    <FormItem label={label} className={className} error={fieldState.error}>
      <Controller
        name={name}
        rules={{ required }}
        control={control}
        render={({ field }) => (
          <RangePicker
            {...field}
            className={rangePickerClassName}
            onChange={(date, formatString) => {
              field.onChange(date)
              onChange?.(date, formatString)
            }}
          />
        )}
      />
    </FormItem>
  )
}

export default FormRangePicker
