import EndScreenElementInputWithPositionAndHeight from "shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight"
import getEndScreenWidth from "shared/utils/getEndScreenWidth"

const replaceEndScreenElement = (oldElements: EndScreenElementInputWithPositionAndHeight[], position: any, data: any) => {
    const newElements = [...oldElements]
    const elementIndex = newElements.findIndex((el) => el.position === position)
    const newElement = {
        ...newElements[elementIndex],
        ...data,
        width: getEndScreenWidth(data.type, data.width),
        height: data.height
    }

    newElements.splice(elementIndex, 1, newElement)
    return newElements
}

export default replaceEndScreenElement
