import { EndScreenElementType } from '../../../../../graphql'

const rectangleItem = {
  height: {
    min: 82,
    max: 115
  },
  width: {
    min: 141,
    max: 200
  }
}

const notResizable = {
  height: {
    min: 10,
    max: 9000
  },
  width: {
    min: 10,
    max: 9000
  }
}

const thresholds: any = {
  [EndScreenElementType.ExternalLink]: {
    ...notResizable
  },
  [EndScreenElementType.SubscribeChannel]: {
    ...notResizable
  },
  [EndScreenElementType.SubscribeChannelById]: {
    ...notResizable
  },
  [EndScreenElementType.PlaylistById]: {
    ...rectangleItem
  },
  [EndScreenElementType.VideoBestForViewers]: {
    ...rectangleItem
  },
  [EndScreenElementType.VideoById]: {
    ...rectangleItem
  },
  [EndScreenElementType.VideoRecentUpload]: {
    ...rectangleItem
  }
}


export default thresholds
