import React, { FC, FormEvent } from 'react'
import { Input } from '@wmg-ae/legato'
import DoubleFilterDropdownProps from './DoubleFilterDropdown.props'
import styles from './DoubleFilterDropdown.module.scss'
import { Button } from 'antd'

const DoubleFilterDropdown: FC<DoubleFilterDropdownProps> = ({
  confirm,
  value1,
  value2,
  onSearch1,
  onSearch2,
  placeholder1,
  placeholder2
}) => {

  const handleSearch = (e: FormEvent) => {
    e.preventDefault()
    confirm()
  }

  return (
    <form className={styles.container} onSubmit={handleSearch}>
      <Input placeholder={placeholder1 || 'Search'} value={value1} onChange={onSearch1} />
      <Input placeholder={placeholder2 || 'Search'}  value={value2} onChange={onSearch2} />
      <Button shape="round" htmlType="submit">
        Ok
      </Button>
    </form>
  )
}

export default DoubleFilterDropdown
