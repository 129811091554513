import { Dispatch, SetStateAction, useState } from 'react'
import calculateOffset from '../utils/calculateOffset'

type UsePaginationResult = {
  page: number
  limit: number
  offset: number
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
}

type UsePaginationInput = {
  page?: number
  limit?: number
}

const usePagination = ({
  page: initialPage = 1,
  limit: initialLimit = 10
}: UsePaginationInput = {}): UsePaginationResult => {
  const [page, setPage] = useState(initialPage)
  const [limit, setLimit] = useState(initialLimit)

  return {
    page,
    setPage,
    limit,
    setLimit,
    offset: calculateOffset(page, limit)
  }
}

export default usePagination
