
export default function uniqueArrayOfObjectsByField(arr: any[], field: string): Array<any> {
  const uniqueIds = new Set();

  const unique = arr.filter((element) => {
    const isDuplicate = uniqueIds.has(element[field]);

    uniqueIds.add(element[field]);

    if (!isDuplicate) {
      return true;
    }

    return false;
  });
  return unique;
}
