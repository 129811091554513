import { FC } from 'react'
import styles from './CenteredRow.module.scss'

type CenteredRowProps = {
  children?: any
}
const CenteredRow: FC<CenteredRowProps> = ({ children }) => {
  return <div className={styles.centered}>{children}</div>
}

export default CenteredRow
