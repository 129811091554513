import { FC } from 'react'
import { Button } from 'antd'

type ButtonProps = {
  text?: string
  onClick?: any
  icon?: any
  size?: any
  type?: any
  shape?: any
  children?: any
  htmlType?: any
  style?: any
  className?: any
  loading?: boolean
  disabled?: boolean
}

const MCNButton: FC<ButtonProps> = ({ text, loading, icon, type, shape, size, htmlType, children, className, style, disabled, onClick }) => {
  return (
    <Button
      htmlType={htmlType || 'button'}
      shape={shape || 'round'}
      size={size || 'middle'}
      icon={icon || false}
      type={type || 'default'}
      loading={loading}
      disabled={disabled}
      className={className}
      style={style}
      onClick={onClick}
    >
      {text || ''}
    </Button>
  )
}

export default MCNButton
