import React, { FC } from 'react'
import RangePickerProps from './RangePicker.props'
import styles from './DoubleFilterDropdown.module.scss'
import { nFormatter } from '../../../../shared/utils/NumberFormatter'

const RangePicker: FC<RangePickerProps> = ({ onSearch, min, max }) => {
  return (
   <div className={styles.container} onClick={() => {
     onSearch(min || '', max || '')
   }}>
     <p>{nFormatter(min || '')} - {nFormatter(max || '')}</p>
   </div>
  )
}

export default RangePicker
