import { FC } from 'react'
import { Switch, Tooltip } from 'antd'
import styles from './SwitchMode.module.scss'

type SwitchModeProps = {
  onSwitchMode: (switchMode: boolean) => void
  switchMode: boolean
  preCampaignTooltip: string
  postCampaignTooltip: string
  containerStyle?: any
}

const SwitchMode: FC<SwitchModeProps> = ({ onSwitchMode, switchMode, preCampaignTooltip, postCampaignTooltip, containerStyle }) => {
  return (
    <div style={{ display: 'flex',...containerStyle }}>
      <Switch onChange={onSwitchMode} />
      <div style={{ margin: '1px 0 0 2px' }}>
        {switchMode ? (
          <Tooltip title={postCampaignTooltip}>
            <span className={styles.pointer}>Post Campaign Setup</span>{' '}
          </Tooltip>
        ) : (
          <Tooltip title={preCampaignTooltip}>
            <span className={styles.pointer}> Campaign Setup </span>{' '}
          </Tooltip>
        )}
      </div>
    </div>
  )
}

export default SwitchMode
