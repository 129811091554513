import React, { FC } from 'react'
import { Line } from 'react-chartjs-2'
import ReportChartProps from './ReportChart.props'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js'
import styles from './ReportChart.module.scss'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

const ReportChart: FC<ReportChartProps> = ({ datasets, labels }) => {
  return (
    <div className={styles.container}>
      <Line
        data={{
          labels,
          datasets
        }}
        options={{
          responsive: true,
          scales: {
            x: {
              title: {
                font: {
                  size: 24,
                  weight: 'bold'
                },
                display: true,
                text: 'Day of Campaign'
              }
            },
            y: {
              title: {
                font: {
                  size: 24,
                  weight: 'bold'
                },
                display: true,
                text: 'CTR'
              }
            }
          },
          plugins: {
            legend: {
              labels: {
                usePointStyle: true,
                padding: 20,
                font: {
                  size: 18
                }
              },
              position: 'right'
            },
            title: {
              display: false
            }
          }
        }}
      />
    </div>
  )
}

export default ReportChart
