import { useMemo, FC, useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  useGetCampaignDetailsPerformanceLazyQuery,
  useGetCampaignQuery,
  useGetCampaignViewsQuery
} from 'shared/graphql'
import { useParams } from 'react-router-dom'
import moment from 'moment'

import { DATE_TEXT_FORMAT } from 'shared/constants'
import { CampaignParamTypes } from '../types/campaign.params.type'
import CampaignDetails from '../components/CampaignDetails/CampaignDetails'
import { EGraphDataPeriod } from '../types/EGraphDataPeriod'

const convertDatePeriod = (period: string) => {
  let daysCount = 0
  switch (period) {
    case EGraphDataPeriod.LAST_WEEK: {
      daysCount = 7
      break
    }
    case EGraphDataPeriod.LAST_MONTH: {
      daysCount = 30
      break
    }
    case EGraphDataPeriod.LAST_YEAR: {
      daysCount = 365
      break
    }
  }

  const startDate = moment(new Date().getTime() - daysCount * 24 * 60 * 60 * 1000).format('YYYY-MM-DD')
  const endDate = moment(new Date()).format('YYYY-MM-DD')

  return {
    startDate,
    endDate
  }
}

const CampaignDetailsContainer: FC = () => {
  const { campaignId } = useParams<CampaignParamTypes>()

  const [endscreens, setEndscreens] = useState<any[]>([])
  const [aggregatedMetrics, setAggregatedMetrics] = useState({ clicksCount: 0, impressionsCount: 0, ctr: 0 })
  const [allDates, setAllDates] = useState<string[]>([])
  const [period, setPeriod] = useState(EGraphDataPeriod.LAST_WEEK)
  const [switchMode, onSwitchMode] = useState(false)
  const [startDate, setStartDate] = useState(convertDatePeriod(period).startDate)
  const [endDate, setEndDate] = useState(convertDatePeriod(period).endDate)

  const getCampaignQuery = useGetCampaignQuery({
    variables: {
      input: {
        id: campaignId
      }
    },
    onError(error: any) {
      toast.error(error.message)
    }
  })

  const { campaign } = useMemo(() => {
    const item = getCampaignQuery.data?.getCampaign?.item

    return item
      ? {
          campaign: {
            id: item.id,
            name: item.name,
            startDate: moment(item.startDate).format(DATE_TEXT_FORMAT),
            endDate: moment(item.endDate).format(DATE_TEXT_FORMAT),
            createdAt: moment(item.createdAt).format(DATE_TEXT_FORMAT),
            updatedAt: moment(item.updatedAt).format(DATE_TEXT_FORMAT),
            views: item.views,
            status: item.status
          },
          endScreens: item.endScreens || []
        }
      : { campaign: null, endScreens: [] }
  }, [getCampaignQuery])

  const [getCampaignDetailsPerformanceLazyQuery, { data: getCampaignDetailsPerformanceLazyQueryData }] =
    useGetCampaignDetailsPerformanceLazyQuery({
      variables: {
        input: {
          campaignId: campaignId,
          startDate,
          endDate
        }
      },
      onError: (error) => {
        toast.error(error.message)
      }
    })

  const getCampaignDetailsPerformanceLazyQueryHandler = useCallback(
    (startDate: string, endDate: string) => {
      getCampaignDetailsPerformanceLazyQuery({
        variables: {
          input: {
            campaignId: campaignId,
            startDate: startDate,
            endDate: endDate
          }
        }
      })
    },
    [campaignId, getCampaignDetailsPerformanceLazyQuery]
  )

  useEffect(() => {
    const startDate = convertDatePeriod(period).startDate
    const endDate = convertDatePeriod(period).endDate
    setStartDate(startDate)
    setEndDate(endDate)
    getCampaignDetailsPerformanceLazyQueryHandler(startDate, endDate)
  }, [getCampaignDetailsPerformanceLazyQueryHandler, period])

  useMemo(() => {
    const data = getCampaignDetailsPerformanceLazyQueryData?.getCampaignDetailsPerformance?.endscreens.filter(
      (elem: any) => elem.isDefault === switchMode
    )
    if (data && data.length) {
      const allCoveredDays = data
        .map((item) => item?.metrics.map((item) => item.dayCovered))
        .flat(1)
        .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))

      const dateArray: string[] = []
      let startDate = moment(allCoveredDays[0])
      const endDate = moment(allCoveredDays[allCoveredDays.length - 1])
      while (startDate <= endDate) {
        dateArray.push(moment(startDate).format('YYYY-MM-DD'))
        startDate = moment(startDate).add(1, 'days')
      }

      setAllDates(dateArray)

      const filledGapsEndscreens = data.map((item, index, selfArr) => {
        const elementCoveredDays = item?.metrics.map((item) => item.dayCovered)
        return {
          ...item,
          metrics: dateArray.map((day) => {
            const indexOfDay = elementCoveredDays?.indexOf(day || '')
            if (indexOfDay === -1) {
              return {
                dayCovered: day,
                clicksCount: 0,
                impressionsCount: 0,
                ctr: 0
              }
            }

            return selfArr[index]?.metrics[indexOfDay || 0]
          })
        }
      })
      setEndscreens(filledGapsEndscreens)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCampaignDetailsPerformanceLazyQueryData?.getCampaignDetailsPerformance, switchMode])

  useEffect(() => {
    if (endscreens.length && endscreens[0].metrics) {
      const currentModeScreens = endscreens.filter((elem: any) => elem.isDefault === switchMode)
      const clicksArr = currentModeScreens.map((item) => item.metrics.map((item: any) => item.clicksCount)).flat(1)
      const impressionsArr = currentModeScreens
        .map((item) => item.metrics.map((item: any) => item.impressionsCount))
        .flat(1)
      const clicksCount = (clicksArr?.length && clicksArr.reduce((prev, cur) => prev + cur)) || 0
      const impressionsCount = (impressionsArr?.length && impressionsArr.reduce((prev, cur) => prev + cur)) || 0
      setAggregatedMetrics({
        clicksCount,
        impressionsCount,
        ctr: clicksCount && impressionsCount ? Math.round((clicksCount / impressionsCount) * 100) / 100 : 0
      })
    }
  }, [endscreens, switchMode])

  const getCampaignViewsQuery = useGetCampaignViewsQuery({
    variables: {
      input: {
        campaignId,
        startDate: convertDatePeriod(period).startDate,
        endDate: convertDatePeriod(period).endDate
      }
    },
    onError(error: any) {
      toast.error(error.message)
    }
  })

  const campaignViews = useMemo(() => {
    const views = getCampaignViewsQuery.data?.getCampaignViews?.views

    if (views) {
      return views
    }

    return 0
  }, [getCampaignViewsQuery.data?.getCampaignViews?.views])

  return (
    <CampaignDetails
      campaign={campaign}
      campaignViews={campaignViews}
      aggregatedMetrics={aggregatedMetrics}
      period={period}
      setPeriod={setPeriod}
      getCampaignDetailsPerformanceLazyQueryHandler={getCampaignDetailsPerformanceLazyQueryHandler}
      endscreens={endscreens.filter((elem: any) => elem.isDefault === switchMode)}
      allDates={allDates}
      onSwitchMode={onSwitchMode}
      switchMode={switchMode}
    />
  )
}

export default CampaignDetailsContainer
