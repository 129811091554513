import React, { FC, ReactNode } from 'react'
import { Typography, Skeleton } from 'antd'

const { Paragraph } = Typography

interface ChannelBlockType {
  label: string;
  text: ReactNode;
  loading: boolean;
}

const ChannelBlock: FC<ChannelBlockType> = ({ label, loading, text }) => {
  return (
    <Paragraph>
      {label}: {loading ?
      <Skeleton.Input style={{ width: 200 }} active size={`small`} /> : text}
    </Paragraph>
  )
}

export default ChannelBlock
