import moment from 'moment'
import React, { useState, useMemo, useEffect, useCallback } from 'react'
import SearchCriteriaFilters from '../types/SearchCriteriaFilters'
import { ReportChart, SearchCriteria } from '../components'
import { colors } from '../utils'
import { Heading } from '../../../components'
import {
  useCreateVideoPerformanceExportLinkMutation,
  useGetVideoPerformanceQuery,
  useGetVideosQuery
} from '../../../shared/graphql'
import SelectTags from '../types/SelectTags'
import uniqueArrayOfObjectsByField from '../../../shared/utils/uniqueArrayOfObjectsByField'
import downloadFile from '../../../shared/utils/downloadFile'

const VideoReport = () => {
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaFilters | null>(null)
  const [searchVideos, setSearchVideos] = useState('')
  const [videoOptions, setVideoOptions]= useState<SelectTags[]>([])
  const [allDates, setAllDates] = useState<string[]>([]);

  const { data: videosList, loading } = useGetVideosQuery({
    variables: {
      input: {
        offset: 0,
        limit: 50,
        filters: {
          name: searchVideos
        },
      }
    }
  })

  const videoList = useMemo(() => {
    const videos = videosList?.getVideos?.items
    if (videos) {
      return videos.map((video) => ({
        value: video?.id!,
        label: video?.name!
      }))
    }

    return []
  }, [videosList?.getVideos?.items])

  useEffect(() => {
    setVideoOptions((prevState: SelectTags[]) => {
      const newList = [...videoList, ...prevState]
      return uniqueArrayOfObjectsByField(newList, 'value')
    })

    return () => {}
  }, [videoList])



  const { data: getVideoPerformanceQueryData } = useGetVideoPerformanceQuery({
    variables: {
      input: {
        videoIds: searchCriteria?.tags || [],
        startDate: searchCriteria?.startDate || '',
        endDate: searchCriteria?.endDate || ''
      }
    }
  })

  const videoPerformance = useMemo(() => {
    setAllDates([]);
    const data = getVideoPerformanceQueryData?.getVideoPerformance?.data;
    if (data && data.length) {
      const allCoveredDays = data
        .map(item => item?.metrics
          .map(item => item?.dayCovered))
        .flat(1)
        .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any));

      const dateArray: string[] = [];
      let startDate = moment(allCoveredDays[0]);
      const endDate = moment(allCoveredDays[allCoveredDays.length - 1]);
      while (startDate <= endDate) {
        dateArray.push( moment(startDate).format('YYYY-MM-DD') )
        startDate = moment(startDate).add(1, 'days');
      }

      setAllDates(dateArray)

      const list = data
        .map((item, index, selfArr) => {
          const elementCoveredDays = item?.metrics.map(item => item?.dayCovered);
          return {
            ...item,
            metrics: dateArray.map(day => {
              const indexOfDay = elementCoveredDays?.indexOf(day || '');
              if (indexOfDay === -1) {
                return {
                  dayCovered: day,
                  clicksCount: 0,
                  impressionsCount: 0,
                  ctr: 0
                }
              }

              return selfArr[index]?.metrics[indexOfDay || 0]
            })
          }
        })
      return list
    }
  }, [getVideoPerformanceQueryData?.getVideoPerformance])


   const [downloadVideoPerformance, { loading: loadingDownloadVideoPerformance }] = useCreateVideoPerformanceExportLinkMutation({
      onCompleted(data: any) {
        downloadFile(data?.createVideoPerformanceExportLink?.url, 'video_performance_export.csv')
      }
    })

    const handleDownload = useCallback(() => {
      downloadVideoPerformance({
        variables: {
          input: {
            videoIds: searchCriteria?.tags || [],
            startDate: searchCriteria?.startDate || '',
            endDate: searchCriteria?.endDate || ''
          }
        }
      })
    }, [downloadVideoPerformance, searchCriteria])

  return (
    <div>
      <Heading>Video Performance Report</Heading>

      <SearchCriteria
        onSearch={setSearchCriteria}
        onSearchSelect={setSearchVideos}
        loadingSelect={loading}
        tagsLabel="Video(s)"
        tags={videoOptions}
        downloadEnabled={!!videoPerformance?.length}
        onDownload={handleDownload}
        loadingDownload={loadingDownloadVideoPerformance}
      />
      {searchCriteria && videoPerformance && videoPerformance.length && (
        <ReportChart
          datasets={videoPerformance.map((item, index) => ({
            label: item.name,
            data: item.metrics.map((item: any) => item.clicksCount),
            tension: 0.1,
            ...colors[index]
          }))}

          labels={allDates}
        />
      )}
    </div>
  )
}

export default VideoReport
