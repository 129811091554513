import { yupResolver } from '@hookform/resolvers/yup'
import { Col, Row } from 'antd'
import { FormControl, FormSelect } from 'components/form'
import React, { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styles from './UserFormCreate.module.scss'
import { UserFormCreateProps } from './UserFormCreate.props'
import { createUserValidationSchema } from '../../constants/constants'
import { useHistory } from 'react-router-dom'
import { userRoles, userStatusArray } from '../../constants/constants'
import MCNButton from '../../../../components/Button'

const UserFormCreate: FC<UserFormCreateProps> = ({ onSave }) => {
  const history = useHistory()

  const formMethods = useForm({ resolver: yupResolver(createUserValidationSchema) })

  const redirectToListPage = () => history.push('/admin')

  return (
    <FormProvider {...formMethods}>
      <form
        className={styles.form}
        onSubmit={formMethods.handleSubmit((data) => {
          onSave(data)
        })}
      >
        <Row gutter={[8, 8]}>
          <Col span={7}></Col>
          <Col span={10}>
            <FormControl labelWidth="60px" name="name" label="Name" required />
            <FormControl labelWidth="60px" name="email" label="Email" required />
            <FormSelect
              labelWidth="60px"
              name="role"
              label="Role"
              options={userRoles.map((item) => ({ value: item, label: item }))}
            />
            <FormSelect
              labelWidth="60px"
              name="status"
              label="Status"
              options={userStatusArray.map((item) => ({ value: item, label: item }))}
            />

            <Row gutter={[8, 8]}>
              <Col span={7}></Col>
              <Col span={10}>
                <div style={{ display: 'flex', justifyContent: 'center', gap: 20,alignItems: 'center' }}>
                  <MCNButton htmlType="submit" text="Save" type="primary" />
                  <MCNButton onClick={redirectToListPage} text="Cancel" />
                </div>
              </Col>
            </Row>
          </Col>

        </Row>
      </form>
    </FormProvider>
  )
}

export default UserFormCreate
