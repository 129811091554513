import React, { useState, useMemo , useEffect, useCallback} from 'react'
import SearchCriteriaFilters from '../types/SearchCriteriaFilters'
import { ReportChart, SearchCriteria } from '../components'
import { colors } from '../utils'
import { Heading } from '../../../components'
import {
  useGetCountryPerformanceQuery,
  useGetCountryListQuery,
  useCreateCountryPerformanceExportLinkMutation
} from '../../../shared/graphql'
import moment from 'moment'
import uniqueArrayOfObjectsByField from '../../../shared/utils/uniqueArrayOfObjectsByField'
import SelectTags from '../types/SelectTags'
import downloadFile from '../../../shared/utils/downloadFile'

const RegionPerformance = () => {
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaFilters | null>(null)
  const [searchCountries, setSearchCountries] = useState('')
  const [allDates, setAllDates] = useState<string[]>([]);

  const [countryOptions, setCountryOptions]= useState<SelectTags[]>([])

  const { data: countriesList, loading } = useGetCountryListQuery({
    variables: {
      input: {
        limit: 50,
        offset: 0,
        search: searchCountries
      }
    }
  })

 const countryList = useMemo(() => {
    const countries = countriesList?.getCountryList?.items
    if (countries) {
      return countries.map((country) => ({
        value: country?.id!,
        label: country?.code!
      }))
    }

    return []
  }, [countriesList?.getCountryList?.items])

  useEffect(() => {
    setCountryOptions((prevState: SelectTags[]) => {
      const newList = [...countryList, ...prevState]
      return uniqueArrayOfObjectsByField(newList, 'value')
    })

    return () => {}
  }, [countryList])

  const { data: getCountryPerformanceQueryData } = useGetCountryPerformanceQuery({
    variables: {
      input: {
        countryIds: searchCriteria?.tags || [],
        startDate: searchCriteria?.startDate || '',
        endDate: searchCriteria?.endDate || ''
      }
    }
  })

  const countriesPerformance = useMemo(() => {
    setAllDates([]);
    const data = getCountryPerformanceQueryData?.getCountryPerformance?.data;
    if (data && data.length) {
      const allCoveredDays = data
        .map(item => item?.metrics
          .map(item => item?.dayCovered))
        .flat(1)
        .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any));

      const dateArray: string[] = [];
      let startDate = moment(allCoveredDays[0]);
      const endDate = moment(allCoveredDays[allCoveredDays.length - 1]);
      while (startDate <= endDate) {
          dateArray.push( moment(startDate).format('YYYY-MM-DD') )
          startDate = moment(startDate).add(1, 'days');
      }

      setAllDates(dateArray)

      const fullFilledCountries = data
        .map((item, index, selfArr) => {
          const elementCoveredDays = item?.metrics.map(item => item?.dayCovered);
          return {
            ...item,
            metrics: dateArray.map(day => {
              const indexOfDay = elementCoveredDays?.indexOf(day || '');
              if (indexOfDay === -1) {
                return {
                  dayCovered: day,
                  clicksCount: 0,
                  impressionsCount: 0,
                  ctr: 0
                }
              }

              return selfArr[index]?.metrics[indexOfDay || 0]
            })
          }
        })
      return fullFilledCountries
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCountryPerformanceQueryData?.getCountryPerformance])

    const [downloadCountryPerformance, { loading: loadingDownloadCountryPerformance }] = useCreateCountryPerformanceExportLinkMutation({
      onCompleted(data: any) {
        downloadFile(data?.createCountryPerformanceExportLink?.url, 'genre_performance_export.csv')
      }
    })

    const handleDownload = useCallback(() => {
      downloadCountryPerformance({
        variables: {
          input: {
            countryIds: searchCriteria?.tags || [],
            startDate: searchCriteria?.startDate || '',
            endDate: searchCriteria?.endDate || ''
          }
        }
      })
    }, [downloadCountryPerformance, searchCriteria])

  return (
    <div>
      <Heading>Region Performance Report</Heading>

      <SearchCriteria
        onSearch={setSearchCriteria}
        onSearchSelect={setSearchCountries}
        loadingSelect={loading}
        tagsLabel="Region(s)"
        tags={countryOptions}
        downloadEnabled={!!countriesPerformance?.length}
        onDownload={handleDownload}
        loadingDownload={loadingDownloadCountryPerformance}
      />
      {searchCriteria && countriesPerformance?.length &&  (
        <ReportChart
          datasets={countriesPerformance.map((item, index) => ({
            label: item.code,
            data: item.metrics.map((item: any) => item.clicksCount),
            tension: 0.1,
            ...colors[index]
          }))}
          labels={allDates}
        />
      )}
    </div>
  )
}

export default RegionPerformance
