import Record from '../components/EndScreenTable/types/Record'
import { Button, Popconfirm, Tooltip, Typography } from 'antd'
import React from 'react'
import ColumnHeading from '../../../../features/MasterChannelList/components/TableColumns/ColumnHeading'
import { DeleteOutlined, FormOutlined, WarningOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { EndScreenElementType } from 'shared/graphql'
import styles from './getEndScreenTableColumns.module.scss'

type GetEndScreenTableColumnsProps = {
  isEditing: (record: Record) => boolean
  editingKey: string
  cancel: () => void
  edit: (record: Partial<Record> & { key: React.Key }) => void
  save: (key: React.Key) => void
  remove: (id: string) => void
  setIsVisibleModal: (isVisible: boolean) => void
}

const getEndScreenTableColumns = ({
  isEditing,
  save,
  cancel,
  edit,
  remove,
  setIsVisibleModal,
  editingKey
}: GetEndScreenTableColumnsProps) => [
  {
    title: () => {
      return <ColumnHeading title={`Position`} tooltip={`Element's serial number`} />
    },
    width: 50,
    dataIndex: 'position',
    key: 'position',
    render: (_: any, record: Record) => {
      return (
        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
          <div style={{ width: '10px', flex: 1 }}>{record.position}</div>
          {record.thumbnail?.thumbnail && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '50px' }}>
              <a
                href={`https://www.youtube.com/watch?v=${record.youtubeId}`}
                target="_blank"
                style={{ color: '#000000d9' }}
                rel="noreferrer"
              >
                <img
                  style={{
                    maxWidth: '60px',
                    maxHeight: '36px',
                    borderRadius: record.elementType === EndScreenElementType.SubscribeChannelById ? '50%' : 0
                  }}
                  src={record.thumbnail.thumbnail}
                  alt={record.thumbnail.name}
                />
              </a>
            </div>
          )}
        </div>
      )
    }
  },
  {
    title: () => {
      return (
        <ColumnHeading
          title={`Element type`}
          tooltip={`EndScreen Element type. Click "Actions" column, then "Edit" to change`}
        />
      )
    },
    width: 180,
    dataIndex: 'elementType',
    key: 'elementType',
    editable: true,
    render: (_: any, record: Record) => {
      return <p className={styles.flexCell}>{_}</p>
    }
  },
  {
    title: () => {
      return (
        <ColumnHeading
          title={`Play time (sec)`}
          tooltip={`The number of seconds before the video end, when the element appears on the viewer's screen`}
        />
      )
    },
    width: 100,
    dataIndex: 'playTime',
    key: 'playTime',
    editable: true,
    render: (_: any, record: Record) => {
      return <p className={styles.flexCell}>{_}</p>
    }
  },
  {
    title: () => {
      return <ColumnHeading title={`URL`} tooltip={`The URL to which the viewer is redirected on click`} />
    },
    className: styles['endscreen-url-column'],
    width: 150,
    dataIndex: 'urlId',
    key: 'urlId',
    editable: true,
    render: (_: any, record: Record) => {
      return <p className={styles.flexCell}>{_}</p>
    }
  },
  {
    title: () => {
      return <ColumnHeading title={`Action`} tooltip={`Allows edititing the element's row`} isForEndScreen={true} />
    },
    className: styles['endscreen-actions-column'],
    width: 100,
    dataIndex: 'action',
    key: 'action',
    valueType: 'option',
    render: (_: any, record: Record) => {
      const editable = isEditing(record)
      const isExternalLink = record.elementType === EndScreenElementType.ExternalLink
      return editable ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
            <CheckOutlined />
          </Typography.Link>
          <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
            <Typography.Link>
              <CloseOutlined  style={{ color: 'red' }}/>

            </Typography.Link>
          </Popconfirm>
        </div>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Tooltip title="Edit">
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              <Button size="small" icon={<FormOutlined />} style={{ marginRight: isExternalLink ? '8px' : 0 }} />
            </Typography.Link>
          </Tooltip>
          {isExternalLink && (
            <Tooltip title="Add details">
              <Typography.Link disabled={editingKey !== ''} onClick={() => setIsVisibleModal(true)}>
                <Button
                  style={{ borderColor: 'orange' }}
                  size="small"
                  icon={<WarningOutlined style={{ color: 'orange' }} />}
                />
              </Typography.Link>
            </Tooltip>
          )}
          <Popconfirm title="Sure to remove?" onConfirm={() => remove(record.id)}>
            <Tooltip title="Delete">
              <Button title="Delete" size="small" danger icon={<DeleteOutlined />} style={{ marginLeft: '8px' }} />
            </Tooltip>
          </Popconfirm>
        </div>
      )
    }
  }
]

export default getEndScreenTableColumns
