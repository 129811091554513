import React, { FC, useCallback, useState, useEffect } from 'react'
import { Heading } from 'components'
import { MasterChannelActions, masterChannelColumns } from './components'
import MasterChannelTable from './components/MasterChannelTable'
import {
  ChannelListFieldName,
  OrderDirection,
  useSyncVideosFromOneChannelMutation,
  useUpdateChannelMutation
} from '../../shared/graphql'
import { toast } from 'react-toastify'

const MasterChannelList: FC = () => {
  const [filters, setFilters] = useState({})



  const [syncChannel] = useSyncVideosFromOneChannelMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Channel Synced')
      cache.modify({
        fields: {
          getChannelList() {},
          getChannel() {}
        }
      })
    }
  })

  const syncChannelHandler = useCallback(
    (id: string) => {
      syncChannel({
        variables: {
          input: { id }
        }
      })
    },
    [syncChannel]
  )



  const [updateChannel] = useUpdateChannelMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Channel Updated')
      cache.modify({
        fields: {
          getChannelList() {},
          getChannel() {}
        }
      })
    }
  })

  const updateChannelHandler = useCallback(
    (formData: any) => {
      updateChannel({
        variables: {
          input: { id: formData.id, prioritized: !formData.prioritized }
        }
      })
    },
    [updateChannel]
  )


  const [switcher, setSwitcher] = useState({})

  const [columns, setColumns] = useState(masterChannelColumns({ setFilters, updatePriority: updateChannelHandler, setSwitcher, syncChannelHandler, filters }))

  useEffect(() => {
    setColumns(masterChannelColumns({ setFilters, updatePriority: updateChannelHandler, setSwitcher, syncChannelHandler, filters }))
    return () => {}
  }, [filters, syncChannelHandler, updateChannelHandler])

  const handleChangeColumn = useCallback((dataIndex: string, checked: boolean) => {
    setColumns((columns) =>
      columns.map((column) => {
        if (column.dataIndex === dataIndex) {
          column.hidden = !checked
        }
        return column
      })
    )
  }, [])

  const [sortOrder, setSortOrder] = useState({
    field: ChannelListFieldName.Name,
    direction: OrderDirection.Desc
  })

  const downloadButtonOptions = {
    sortOrder,
    filter: filters
  }

  return (
    <>
      <Heading>Master Channel List</Heading>
      <MasterChannelActions onChangeColumn={handleChangeColumn} columns={columns} downloadButtonOptions={downloadButtonOptions}/>
      <MasterChannelTable columns={columns} filters={filters} sortOrder={sortOrder} setSortOrder={setSortOrder} switcher={switcher}/>
    </>
  )
}

export default MasterChannelList
