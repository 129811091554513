import React from 'react'
import AdditionalReportCardType from '../../types/AdditionalReportCardType'
import AdditionalReportCard from '../AdditionalReportCard'
import styles from './AdditionalReportsList.module.scss'

const reports: AdditionalReportCardType[] = [
  {
    title: 'Campaign Performance',
    url: '/reports/campaignPerformance',
    data: [
      [2, 24, 70],
      [3, 30, 55]
    ]
  },
  {
    title: 'Artist (Channel) Report',
    url: '/reports/artistReport',
    data: [
      [2, 24, 70],
      [3, 30, 55]
    ]
  },
  {
    title: 'Genre Performance',
    url: '/reports/genrePerformance',
    data: [
      [2, 24, 70],
      [3, 30, 55]
    ]
  },
  {
    title: 'Region Performance',
    url: '/reports/regionPerformance',
    data: [
      [2, 24, 70],
      [3, 30, 55]
    ]
  },
  {
    title: 'Video Report',
    url: '/reports/videoReport',
    data: [
      [2, 24, 70],
      [3, 30, 55]
    ]
  }
]

const AdditionalReportsList = () => {
  return (
    <div className={styles.container}>
      {reports.map((report) => (
        <AdditionalReportCard key={report.url} report={report} />
      ))}
    </div>
  )
}

export default AdditionalReportsList
