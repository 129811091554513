import React, { FC, useMemo, useCallback } from 'react'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { Row, Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { DATE_TEXT_FORMAT } from 'shared/constants'
import ChannelForm from './components/ChannelForm/ChannelForm'
import ChannelInfo from './components/ChannelInfo/ChannelInfo'
import { useGetChannelQuery, useUpdateChannelMutation } from 'shared/graphql'

interface ParamTypes {
  channelId: string
}

const MasterChannelDetails: FC = () => {
  const { channelId } = useParams<ParamTypes>()

  const getChannelQuery = useGetChannelQuery({
    variables: {
      input: {
        id: channelId
      }
    },
    onError(error) {
      toast.error(error.message)
    }
  })

  const data = useMemo(() => {
    const item = getChannelQuery.data?.getChannel?.item

    if (item) {
      return {
        key: item?.id,
        channelId: item?.id,
        name: item?.name,
        claimUploads: item?.claimUploads,
        censorship: item?.censorship,
        monetized: item?.monetized,
        region: item?.region?.name,
        genre: item?.genre?.name,
        artist: item?.artist?.name,
        playlist: item?.playlist,
        dateAdded: dayjs(item?.addedAt).format(DATE_TEXT_FORMAT),
        dateCreated: dayjs(item?.createdAt).format(DATE_TEXT_FORMAT),
        subscribers: item?.subscriberCount,
        videos: item?.videoCount,
        channelViews: item?.viewCount,
        tier: item?.tier?.name
      }
    }

    return {}
  }, [getChannelQuery])

  const [updateChannel] = useUpdateChannelMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Channel Updated')
      cache.modify({
        fields: {
          getChannelList() {},
          getChannel() {}
        }
      })
    }
  })

  const updateChannelHandler = useCallback(
    (formData: any) => {
      updateChannel({
        variables: {
          input: { id: formData.id, tier: formData.Tier }
        }
      })
    },
    [updateChannel]
  )

  return (
    <>
      <Typography.Title>Channel Detail</Typography.Title>

      <Row gutter={[8, 8]} style={{ padding: '50px 0' }}>
        <ChannelInfo loading={getChannelQuery.loading} data={data} />
      </Row>

      <Row>
        <ChannelForm onSave={updateChannelHandler} data={data} />
      </Row>
    </>
  )
}

export default MasterChannelDetails
