import { FC, useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Paper } from 'components'
import {
  ChannelListFieldName,
  GetVideoListFilters,
  OrderDirection,
  useGetChannelListQuery,
  useGetGenreFullListQuery, useGetLabelFullListQuery,
  useGetRegionFullListQuery
} from 'shared/graphql'
import { usePagination } from 'shared/hooks'
import { FormControl, FormSelect, FormCheckbox } from 'components/form'
import { defaultFormValues, videoFiltersValidationSchema } from '../../consts'
import VideoFiltersProps from './VideoFilters.props'
import styles from './VideoFilters.module.scss'
import { Button } from 'antd'

const inputStyles = {maxWidth: 288, marginBottom: 8}
const VideoFilters: FC<VideoFiltersProps> = ({ onSearch, searching }) => {
  const parentLabelPagination = usePagination()
  const channelPagination = usePagination()
  const genrePagination = usePagination()
  const regionPagination = usePagination()
  const [parentLabelSearch, setParentLabelSearch] = useState('')
  const [channelSearch, setChannelSearch] = useState('')
  const [genreSearch, setGenreSearch] = useState('')
  const [regionSearch, setRegionSearch] = useState('')
  const formMethods = useForm({ resolver: yupResolver(videoFiltersValidationSchema), defaultValues: defaultFormValues })

  const { data: regionFullListData, loading: regionFullListLoading } = useGetRegionFullListQuery({
    variables: {
      input: {
        limit: 1000,
        offset: regionPagination.offset,
        search: regionSearch
      }
    }
  })

  const { data: genreFullListData, loading: genreFullListLoading } = useGetGenreFullListQuery({
    variables: {
      input: {
        offset: genrePagination.offset,
        limit: 1000,
        search: genreSearch
      }
    }
  })

  // const { data: artistListData, loading: artistListLoading } = useGetArtistListQuery({
  //   variables: {
  //     input: {
  //       limit: 1000,
  //       offset: artistPagination.offset,
  //       search: artistSearch
  //     }
  //   }
  // })


  const { data: parentLabelListData, loading: parentLabelListLoading } = useGetLabelFullListQuery({
    variables: {
      input: {
        limit: 1000,
        offset: parentLabelPagination.offset,
        search: parentLabelSearch
      }
    }
  })

  const { data: channelListData, loading: channelListLoading } = useGetChannelListQuery({
    variables: {
      input: {
        limit: 1000,
        offset: channelPagination.offset,
        search: channelSearch,
        sortOrder: {
          direction: OrderDirection.Desc,
          field: ChannelListFieldName.Name
        }
      }
    }
  })

  const onSubmit = useCallback(
    (data: GetVideoListFilters) => {
      onSearch(data)
    },
    [onSearch]
  )

  const regionOptions = useMemo(() => {
    const regions = regionFullListData?.getRegionFullList?.items

    if (regions) {
      return regions.map((region) => ({
        value: region?.name!,
        label: region?.name!
      }))
    }

    return []
  }, [regionFullListData?.getRegionFullList?.items])

  // const artistOptions = useMemo(() => {
  //   const artists = artistListData?.getArtistList?.items
  //
  //   if (artists) {
  //     return artists.map((artist) => ({
  //       value: artist?.name!,
  //       label: artist?.name!
  //     }))
  //   }
  //
  //   return []
  // }, [artistListData?.getArtistList?.items])

  const labelOptions = useMemo(() => {
    const labels = parentLabelListData?.getLabelList?.items

    if (labels) {
      return labels.map((label) => ({
        value: label?.name!,
        label: label?.name!
      }))
    }

    return []
  }, [parentLabelListData?.getLabelList?.items])

  const genreOptions = useMemo(() => {
    const genres = genreFullListData?.getGenreFullList?.items

    if (genres) {
      return genres.map((genre) => ({
        value: genre?.name!,
        label: genre?.name!
      }))
    }

    return []
  }, [genreFullListData?.getGenreFullList?.items])

  const channelOptions = useMemo(() => {
    const channels = channelListData?.getChannelList?.items

    if (channels) {
      return channels.map((channel) => ({
        value: channel?.name!,
        label: channel?.name!
      }))
    }

    return []
  }, [channelListData?.getChannelList?.items])

  const clearFilters = () => {
    Object.keys(defaultFormValues).forEach((key: string) => {
      formMethods.setValue(key as keyof typeof defaultFormValues, defaultFormValues[key as keyof typeof defaultFormValues])
    })
  }

  return (
    <Paper>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className={styles.row}>
            <FormControl name="name" label="Video Title" required inputStyle={inputStyles} className={styles.child} />
            <FormSelect
              labelWidth={70}
              className={styles.child}
              name="region"
              label="Region"
              selectStyle={inputStyles}
              showSearch
              onSearch={setRegionSearch}
              withEmptyOption
              loading={regionFullListLoading}
              options={regionOptions}
            />
            <FormSelect
              labelWidth={70}
              className={styles.child}
              name="parentLabel"
              label="Label"
              selectStyle={inputStyles}
              withEmptyOption
              showSearch
              onSearch={setParentLabelSearch}
              loading={parentLabelListLoading}
              options={labelOptions}
            />
          </div>

          <div className={styles.row}>
            <FormSelect
              labelWidth={70}
              className={styles.child}
              name="channel"
              label="Channel"
              selectStyle={inputStyles}
              showSearch
              onSearch={setChannelSearch}
              withEmptyOption
              loading={channelListLoading}
              options={channelOptions}
            />
            <FormSelect
              labelWidth={70}
              className={styles.child}
              name="genre"
              label="Genre"
              showSearch
              selectStyle={inputStyles}
              onSearch={setGenreSearch}
              withEmptyOption
              loading={genreFullListLoading}
              options={genreOptions}
            />
            <FormCheckbox name="applied" label="Show videos without campaigns applied" className={styles.checkbox} />
          </div>

          <div className={styles.row}>
            <div className={styles.button}>
              <Button shape="round" htmlType="submit" loading={searching}>
                Search
              </Button>
              <Button shape="round" htmlType="submit" onClick={clearFilters}>
                Clear
              </Button>
            </div>
          </div>
        </form>
      </FormProvider>
    </Paper>
  )
}
export default VideoFilters
