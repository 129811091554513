import { Route, Switch } from 'react-router-dom'
import { SecureRoute, LoginCallback } from '@okta/okta-react'


import {
  MasterChannelListPage,
  MasterChannelsDetailPage,
  NotFoundPage,
  CampaignsPage,
  CampaignDetailsPage,
  CreateCampaignPage,
  EditCampaignPage,
  EditDefaultsPage,
  AddVideosPage,
  ReportsPage,
  UsersPage,
  EditUserPage,
  AddUserPage,
  CampaignPerformancePage,
  ArtistReportPage,
  GenrePerformancePage,
  RegionPerformancePage,
  VideoReportPage,
  CreateDefaultsTemplatePage,
  EditDefaultsTemplatePage,
  GoogleAuthCallback
} from 'pages'

const Routes = () => (
  <Switch>
    <SecureRoute exact path="/" component={MasterChannelListPage} />
    <SecureRoute exact path="/google/auth/" component={GoogleAuthCallback} />
    <SecureRoute exact path="/campaigns" component={CampaignsPage} />
    <SecureRoute exact path="/channels/edit/:channelId" component={MasterChannelsDetailPage} />
    <SecureRoute exact path="/campaigns/create-campaign" component={CreateCampaignPage} />
    <SecureRoute exact path="/campaigns/details/:campaignId" component={CampaignDetailsPage} />
    <SecureRoute exact path="/campaigns/edit/:campaignId" component={EditCampaignPage} />
    <SecureRoute exact path="/defaults" component={EditDefaultsPage} />
    <SecureRoute exact path="/defaults/createTemplate" component={CreateDefaultsTemplatePage} />
    <SecureRoute exact path="/defaults/edit/:templateId" component={EditDefaultsTemplatePage} />
    <SecureRoute exact path="/campaigns/add-videos/:campaignId" component={AddVideosPage} />
    <SecureRoute exact path="/admin" component={UsersPage} />
    <SecureRoute exact path="/admin/edit/:userId" component={EditUserPage} />
    <SecureRoute exact path="/admin/create" component={AddUserPage} />
    <SecureRoute exact path="/reports" component={ReportsPage} />
    <SecureRoute exact path="/reports/campaignPerformance" component={CampaignPerformancePage} />
    <SecureRoute exact path="/reports/artistReport" component={ArtistReportPage} />
    <SecureRoute exact path="/reports/genrePerformance" component={GenrePerformancePage} />
    <SecureRoute exact path="/reports/regionPerformance" component={RegionPerformancePage} />
    <SecureRoute exact path="/reports/videoReport" component={VideoReportPage} />
    <Route path={process.env.REACT_APP_OKTA_AUTH_CALLBACK} component={LoginCallback} />
    <Route component={NotFoundPage} />
  </Switch>
)

export default Routes
