import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  EndScreenDefaultElement,
  EndScreenElementType,
  useGetEndScreenTemplateQuery,
  useGetEndScreenTemplateThumbnailLazyQuery,
  useUpdateEndScreensTemplateMutation
} from 'shared/graphql'
import { toast } from 'react-toastify'
import { useHistory, useParams } from 'react-router-dom'
import { ApolloError } from 'apollo-client'
import { useUpdateEffect } from 'react-use'
import { Tooltip, List, Button } from 'antd'
import getElementTypeLabel from 'shared/features/EndScreenElements/components/EndScreenTable/utils/getElementTypeLabel'
import { Heading } from 'components'
import EndScreenElementInputWithPositionAndHeight from 'shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight'
import getEndScreenHeight from 'shared/utils/getEndScreenHeight'
import EndScreenDefaultElementsWrapper from './EndScreenDefaultElementsWrapper'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import disabledElementsDefault from 'shared/features/EndScreenElements/components/EndScreenElements/functions/disabledElementsDefault'
import removeEndScreenElement from 'shared/features/EndScreenElements/components/EndScreenElements/functions/removeEndScreenElement'
import handleChangeEditor from 'shared/features/EndScreenElements/components/EndScreenElements/functions/handleChangeEditor'
import handleChangeTableRow from 'shared/features/EndScreenElements/components/EndScreenElements/functions/handleChangeTableRow'
import addInitialElement from 'shared/features/EndScreenElements/components/EndScreenElements/functions/addInitialElement'

const EditCustomTemplate = () => {
  const { templateId } = useParams<{ templateId: string }>()
  const [initialElements, setInitialElements] = useState<EndScreenElementInputWithPositionAndHeight[] | []>([])
  const [errorByRestriction, setErrorByRestriction] = useState<string>('');
  const [isShowCreateEndScreen, setIsShowCreateEndScreen] = useState<boolean>(false);

  const { goBack } = useHistory()

  const mapper = useCallback((endscreens) => endscreens && endscreens.length ? endscreens.map((item: any) => ({
    ...item,
    height: getEndScreenHeight(item.type, item.height)
  })): [], [])

  const { data: templateData } = useGetEndScreenTemplateQuery({
    variables: { input: { templateId } },
    onError(error: ApolloError) {
      toast.error(error.message)
    }
  })

  const [getEndScreenOfTemplateThumbnailLazyQuery, { data: getEndScreenOfTemplateThumbnailLazyQueryData }] = useGetEndScreenTemplateThumbnailLazyQuery({
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const getEndScreenOfTemplateThumbnailLazyQueryHandler = useCallback(
    (endscreenId: string, youtubeId: string, endscreenType: EndScreenElementType) => {
      getEndScreenOfTemplateThumbnailLazyQuery({
          variables: {
            input: {
              endscreenId,
              youtubeId,
              endscreenType
            }
          }
        })
    },
    [getEndScreenOfTemplateThumbnailLazyQuery]
  )

  useMemo(() => {
    const elem = getEndScreenOfTemplateThumbnailLazyQueryData?.getEndScreenTemplateThumbnail;

    if (elem && elem.endscreenId) {
      const element = initialElements.find(item => item.id === elem.endscreenId);
      if (element) {
        setInitialElements((prevState) => [
          ...prevState.filter(item => item.id !== elem.endscreenId),
          {...element, thumbnail: { thumbnail: elem.thumbnail, name: elem.name }, youtubeId: elem.youtubeId }
        ])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEndScreenOfTemplateThumbnailLazyQueryData?.getEndScreenTemplateThumbnail])

  useUpdateEffect(() => {
    setIsShowCreateEndScreen(true);
    if (initialElements.length >= 4) {
      setIsShowCreateEndScreen(false);
    }
  }, [initialElements])

  useEffect(() => {
    const endScreens = templateData?.getEndScreenTemplate?.endscreens as unknown as EndScreenDefaultElement[]
    setInitialElements(mapper(endScreens))
  }, [mapper, templateData])

  const [updateTemplate] = useUpdateEndScreensTemplateMutation({
    refetchQueries: ['GetEndScreenTemplateList'],
    onCompleted() {
      toast.success('The template has been changed')
      goBack()
    },
    onError(error: ApolloError) {
      toast.error(error.message)
    },
    update(cache) {
      cache.modify({
        fields: {
          getEndScreenTemplateList() {}
        }
      })
    }
  })

  const handleSave = useCallback(
    (input, name) => {
      if (!errorByRestriction) {
        updateTemplate({ variables: { input: { elements: input.elements, name, id: templateId } } })
      }
    },
    [errorByRestriction, templateId, updateTemplate]
  )

  return (
    <>
      <Heading level={3} noMargin>
        Endscreen elements
        <Tooltip color="#fff" title={
          <List
            bordered
            dataSource={Object.values(EndScreenElementType)}
            renderItem={type => (
              <Button onClick={() => addInitialElement(type, setInitialElements)} block disabled={disabledElementsDefault(type, initialElements)}>
                {getElementTypeLabel(type)}
              </Button>
            )}
          />
        }>
          <Button style={{ margin: '0 20px', borderRadius: '15px', display: isShowCreateEndScreen ? 'inline-block' : 'none' }}>+</Button>
        </Tooltip>
      </Heading>
      <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
        <span style={{ color: 'red', fontWeight: 700 }}>{errorByRestriction}</span>
      </div>
      {initialElements && initialElements.length ? <EndScreenDefaultElementsWrapper
          elements={initialElements}
          onSave={handleSave}
          initialName={templateData?.getEndScreenTemplate?.name ?? ''}
          showNameField
          removeEndScreenElement={(id) => removeEndScreenElement(id, setInitialElements)}
          setErrorByRestriction={setErrorByRestriction}
          handleChangeTableRow={(data: EndScreenElementInputWithPositionAndHeight) => handleChangeTableRow(data, setInitialElements, getEndScreenOfTemplateThumbnailLazyQueryHandler)}
          handleChangeEditor={(position: number, data: EndScreenPreviewItemChangeResult) => handleChangeEditor(position, data, setInitialElements)}
        /> : null}
    </>
  )
}

export default EditCustomTemplate
