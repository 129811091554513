const colors = [
    {
        borderColor: '#D42B3A',
        backgroundColor: '#D42B3A'
    },
    {
        borderColor: '#0061AB',
        backgroundColor: '#0061AB'
    },
    {
        borderColor: '#008A3D',
        backgroundColor: '#008A3D'
    },
    {
        borderColor: '#ff8A3D',
        backgroundColor: '#ff8A3D'
    }
]

export default colors;