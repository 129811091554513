const countPercentage = (current: number, previous: number): string => {
  if (!previous && !current) {
    return '0%'
  }
  if (!previous && current) {
    return '100%'
  }
  if (!current) {
    return '-100%'
  }

  if (previous === current) {
    return '0%'
  }

  const decrease = previous > current

  if (decrease) {
    return `-${(((previous - current ) / previous ) * 100).toFixed()} %`
  }
  return `${(Math.abs(((previous - current ) / current ) * 100)).toFixed()} %`
}

export default countPercentage
