import { yupResolver } from '@hookform/resolvers/yup'
import { Form, Col, Row } from 'antd'

import { Paper } from 'components'
import { formatDate } from 'shared/utils'
import { FormControl, FormSelect } from 'components/form'
import { useHistory } from 'react-router-dom'
import React, { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { UserFormEditProps } from './UserFormEdit.props'
import { updateUserValidationSchema } from '../../constants/constants'
import { userRoles, userStatusArray } from '../../constants/constants'
import styles from './UserFormEdit.module.scss'
import MCNButton from '../../../../components/Button'

const UserFormEdit: FC<UserFormEditProps> = ({ onSave, data }) => {
  const history = useHistory()

  const formMethods = useForm({ resolver: yupResolver(updateUserValidationSchema) })

  useEffect(() => {
    if (data) {
      formMethods.setValue('name', data.name)
      formMethods.setValue('email', data.email)
      formMethods.setValue('role', data.role)
      formMethods.setValue('status', data.status)
    }
  }, [data, formMethods])

  const redirectToListPage = () => history.push('/admin')

  return (
    <FormProvider {...formMethods}>
      <Paper>
        <Form
          layout="vertical"
          onFinish={formMethods.handleSubmit((data) => {
            onSave(data)
          })}
        >
          <div className={styles.innerContainer}>
            <Row gutter={[8, 8]}>
              <Col span={7}></Col>
              <Col span={10}>
                <div>
                  <FormControl name="name" label="Name:" required labelWidth="80px" className={styles.horizontal}/>
                  <FormControl name="email" label="Email:" required labelWidth="80px" className={styles.horizontal} disabled />
                  <FormSelect
                    name="role"
                    label="Role:"
                    labelWidth="80px"
                    className={styles.horizontal}
                    options={userRoles.map((item) => ({ value: item, label: item }))}
                  />
                  <FormSelect
                    name="status"
                    label="Status:"
                    labelWidth="80px"
                    className={`${styles.horizontal}`}
                    options={userStatusArray.map((item) => ({ value: item, label: item }))}
                  />
                  <FormControl name="createdOn" label="Created on:" required labelWidth="80px" className={styles.horizontal} disabled value={formatDate(data?.createdAt)} />
                  <FormControl name="lastLogin" label="Last login:" required labelWidth="80px" className={styles.horizontal} disabled value={formatDate(data?.lastAction)} />
                </div>
                <Row gutter={[8, 8]} className={styles.justify}>
                  <div className={styles.innerContainer}>
                  <div className={styles.info}>
                    <div className={styles.buttons}>
                      <MCNButton text="Save" htmlType="submit" type="primary" />
                      <MCNButton text="Cancel" onClick={redirectToListPage} />
                    </div>
                  </div>
                  </div>
                </Row>
              </Col>
            </Row>
          </div>
        </Form>
      </Paper>
    </FormProvider>
  )
}

export default UserFormEdit
