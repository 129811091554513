import React, { useMemo } from 'react'
import ReportCard from '../ReportCard'
import styles from './ReportCardList.module.scss'
import {
  useGetAllViewsQuery,
  useGetEndScreenViewsQuery,
  useGetEndScreenViewsAvgQuery,
  useGetEndScreenAvgCtrQuery
} from '../../../../shared/graphql'
import { toast } from 'react-toastify'
import { nFormatter } from '../../../../shared/utils/NumberFormatter'
import countPercentage from '../../../../shared/utils/countPercentage'
import { v4 as uuidv4 } from 'uuid'
import getProgressType from './getProgressType'

const ReportCardList = () => {
  const getAllViewsQuery = useGetAllViewsQuery({
    variables: {},
    onError(error) {
      toast.error(error.message)
    }
  })
const getStatItem = (data:any) => ({currentMonth: Number(data?.currentMonth),
  previousMonth: Number(data?.previousMonth)})

  const getAllViews = useMemo(() => {
    const data = getAllViewsQuery.data?.getAllViews
    const item = getStatItem(data)
    if (item) {
      return {
        key: uuidv4(),
        progressType: getProgressType(item?.currentMonth || 0, item?.previousMonth || 0),
        score: `${nFormatter(item?.currentMonth.toFixed()) || 0}`,
        scoreDescription: `${countPercentage(item?.currentMonth, item?.previousMonth)} vs last month`,
        title: 'Total Views',
        titleDescription: 'Total views generated accross all Warner Music MCN'
      }
    }

    return {
      key: uuidv4(),
      progressType: 'equal',
      score: '0',
      scoreDescription: '0% vs last month',
      title: 'Total Views',
      titleDescription: 'Total views generated accross all Warner Music MCN'
    }
  }, [getAllViewsQuery.data?.getAllViews])

  const getEndScreenViewsQuery = useGetEndScreenViewsQuery({
    variables: {},
    onError(error) {
      toast.error(error.message)
    }
  })

  const getEndScreenViews = useMemo(() => {
   const data = getEndScreenViewsQuery.data?.getEndScreenViews
    const item = getStatItem(data)
    if (item) {
      return {
        key: uuidv4(),
        progressType: getProgressType(item?.currentMonth || 0, item?.previousMonth || 0),
        score: `${nFormatter(item?.currentMonth.toFixed()) || 0}`,
        scoreDescription: `${countPercentage(item?.currentMonth, item?.previousMonth)} vs last month`,
        title: 'EndScreen Views',
        titleDescription: 'Total views delivered by end screens across the Warner Music MCN'
      }
    }

    return {
      key: uuidv4(),
      progressType: 'equal',
      score: '0',
      scoreDescription: '0% vs last month',
      title: 'EndScreen Views',
      titleDescription: 'Total views delivered by end screens across the Warner Music MCN'
    }
  }, [getEndScreenViewsQuery.data?.getEndScreenViews])

  const getEndScreenViewsAvgQuery = useGetEndScreenViewsAvgQuery({
    variables: {},
    onError(error) {
      toast.error(error.message)
    }
  })

  const getEndScreenViewsAvg = useMemo(() => {
    const data =  getEndScreenViewsAvgQuery.data?.getEndScreenViewsAvg
    const item = getStatItem(data)
    if (item) {
      return {
        key: uuidv4(),
        progressType: getProgressType(item?.currentMonth || 0, item?.previousMonth || 0),
        score: `${nFormatter(item?.currentMonth.toFixed()) || 0}`,
        scoreDescription: `${countPercentage(item?.currentMonth, item?.previousMonth)} vs last month`,
        title: 'Average Views',
        titleDescription: 'The average number of views delivered per end screen'
      }
    }

    return {
      key: uuidv4(),
      progressType: 'equal',
      score: '0',
      scoreDescription: '0% vs last month',
      title: 'Average Views',
      titleDescription: 'The average number of views delivered per end screen'
    }
  }, [getEndScreenViewsAvgQuery.data?.getEndScreenViewsAvg])

  const getEndScreenAvgCTRQuery = useGetEndScreenAvgCtrQuery({
    variables: {},
    onError(error) {
      toast.error(error.message)
    }
  })

  const getEndScreenAvgCTR = useMemo(() => {
   const data = getEndScreenAvgCTRQuery.data?.getEndScreenAvgCTR
    const item = getStatItem(data)
    if (item) {
      return {
        key: uuidv4(),
        progressType: getProgressType(item?.currentMonth || 0, item?.previousMonth || 0),
        score: `${nFormatter(item?.currentMonth.toFixed(2)) || 0}`,
        scoreDescription: `${countPercentage(item?.currentMonth, item?.previousMonth)} vs last month`,
        title: 'Average CTR',
        titleDescription: 'Average click-through rate per video end screen'
      }
    }

    return {
      key: uuidv4(),
      progressType: 'equal',
      score: '0',
      scoreDescription: '0% vs last month',
      title: 'Average CTR',
      titleDescription: 'Average click-through rate per video end screen'
    }
  }, [getEndScreenAvgCTRQuery.data?.getEndScreenAvgCTR])

  const cards = [getAllViews, getEndScreenViews, getEndScreenViewsAvg, getEndScreenAvgCTR]

  return (
    <div className={styles.container}>
      {cards.map((card) => (
        <ReportCard key={card.key} card={card} />
      ))}
    </div>
  )
}

export default ReportCardList
