import { FC , useState } from 'react'
import MCNButton from '../../../../../components/Button'
import Modal from '../../../../../components/Modal'
import CenteredRow from '../../../../../components/Centered'

type UploadVideoListButtonProps = {
  onClick: any
  unSavedWarning: boolean
  onSave: any
}
const UploadVideoListButton: FC<UploadVideoListButtonProps> = ({ onClick, unSavedWarning, onSave }) => {
  const [showModal, setShowModal] = useState(false)
  const showUnSavedWarningModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const onSaveAndContinue = () => {
    showUnSavedWarningModal()
    onSave()
    onClick()
  }

  return <>
    <MCNButton text={`Upload Video List`} onClick={unSavedWarning ? showUnSavedWarningModal : onClick} />

    <Modal
      visible={showModal}
      title={`You have unsaved Endscreen element updates`}
      onCancel={showUnSavedWarningModal}
      footer={null}
    >
      <CenteredRow>
        <MCNButton text={`Save & Continue`} type={`primary`} style={{ marginRight: 8 }} onClick={onSaveAndContinue} />

        <MCNButton text={`Discard & Continue`} type={`dashed`} style={{ marginRight: 8 }} onClick={onClick} />

        <MCNButton text={`Cancel`} onClick={showUnSavedWarningModal} />
      </CenteredRow>
    </Modal>
  </>
}

export default UploadVideoListButton
