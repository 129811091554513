import { FC } from 'react'
import {  Modal } from 'antd'

type ModalProps = {
  visible?: boolean
  title?: string
  children?: any
  onCancel?: any
  footer?: any
}

const MCNModal: FC<ModalProps> = ({ visible, title, children, onCancel, footer }) => {
  return (
    <Modal title={title} visible={visible || false} onCancel={onCancel} footer={footer}>
      {children}
    </Modal>
  )
}

export default MCNModal
