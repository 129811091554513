import React, {FC} from 'react'
import {Heading} from 'components'
import toPercentage from 'shared/utils/toPercentage'
import VideoListInfoProps from './VideoListInfo.props'
import styles from './VideoListInfo.module.scss'
import {Tooltip} from "antd";

const VideoListInfo: FC<VideoListInfoProps> = ({total, failed, successed, stats}) => {
  const processedResult = toPercentage(successed, total)
  const failedResult = toPercentage(failed, total)


  return (
    <div className={styles.container}>

      <Heading
        level={3}
        noMargin
      >
        Video List
      </Heading>

      <div className={styles.info}>
        <span className={styles.processed}>
          {successed}/{total} ({isNaN(processedResult) ? 0 : processedResult}%) processed
        </span>
        <Tooltip placement="bottom" title={
          <>
            {
              stats && Object.keys(stats).map((stat) => {
                if (!stats?.[stat] || stat === '__typename') {
                  return ''
                }
                return <div>{`${stat} : ${stats[stat]}`}</div>
              })
            }
          </>
        }>
          <span>
          {failed} failed ({isNaN(failedResult) ? 0 : failedResult}%)
        </span>
        </Tooltip>
      </div>

    </div>
  )
}

export default VideoListInfo
