import React from 'react'
import { CreateDefaultTemplate } from 'features/Defaults/containers'

const CreateDefaultsTemplatePage = () => {
  return (
    <>
      <CreateDefaultTemplate />
    </>
  )
}

export default CreateDefaultsTemplatePage
