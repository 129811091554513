import React, { useCallback, useState, useMemo, FC } from 'react'
import { Space, Popconfirm } from 'antd'
import styles from './VideoListActions.module.scss'
import UploadVideoList from '../UploadVideoList'
import { useParams } from 'react-router-dom'
import { CampaignParamTypes } from 'features/Campaigns/types/campaign.params.type'
import { useCreateCampaignVideoExportLinkMutation, useGetCampaignVideosUploadSampleQuery } from 'shared/graphql'
import { DownloadButton } from 'components'
import downloadFile from '../../../../utils/downloadFile'
import AddVideoButton from '../AddVideoButton'
import UploadVideoListButton from '../UploadVideoListButton'
import MCNButton from '../../../../../components/Button'

type VideoListActionsProps = {
  downloadButtonOptions: any
  unSavedWarning: any
  saveCampaignHandler?: any
  disableDeleteSelected?: boolean
  disableDeleteAll?: boolean
  deleteAll?: any
  deleteSelected?: any
}

const VideoListActions: FC<VideoListActionsProps> = ({
  downloadButtonOptions,
  unSavedWarning,
  saveCampaignHandler,
  disableDeleteSelected,
  disableDeleteAll,
  deleteAll,
  deleteSelected
}) => {
  const [showUploadVideoList, setShowUploadVideoList] = useState(false)

  const handleTriggerUpload = useCallback(() => {
    setShowUploadVideoList((show) => !show)
  }, [])

  const { campaignId } = useParams<CampaignParamTypes>()

  const getUploadSampleQuery = useGetCampaignVideosUploadSampleQuery({
    variables: {
      input: {
        load: ''
      }
    },
    onError(error) {}
  })

  const downloadUrl = useMemo(() => {
    const url = getUploadSampleQuery.data?.getCampaignVideosUploadSample?.url

    if (url) {
      return url
    }

    return []
  }, [getUploadSampleQuery.data?.getCampaignVideosUploadSample?.url])

  const [downloadCampaignVideos, { loading: loadingDownloadCampaignVideos }] = useCreateCampaignVideoExportLinkMutation(
    {
      onCompleted(data: any) {
        downloadFile(data?.createCampaignVideoExportLink?.url, 'campaign_videos_export.csv')
      }
    }
  )

  const handleDownload = useCallback(() => {
    downloadCampaignVideos({
      variables: {
        input: {
          campaignId,
          filter: downloadButtonOptions.filter,
          sortOrder: downloadButtonOptions.sortOrder
        }
      }
    })
  }, [downloadCampaignVideos, downloadButtonOptions, campaignId])

  return (
    <>
      <UploadVideoList
        visible={showUploadVideoList}
        onCancel={handleTriggerUpload}
        downloadUrl={`${downloadUrl}`}
        toggleVisible={setShowUploadVideoList}
      />
      <div className={styles.wrapper}>
        <Space>
          <Popconfirm
            title="Are you sure to delete all videos from this campaign?"
            onConfirm={deleteSelected}
            okText="Yes"
            cancelText="No"
          >
            <MCNButton text={`Delete Selected`} disabled={!disableDeleteSelected} />
          </Popconfirm>

          <Popconfirm
            title="Are you sure to delete all videos from this campaign?"
            onConfirm={deleteAll}
            okText="Yes"
            cancelText="No"
          >
            <MCNButton text={`Delete All`} disabled={!disableDeleteAll} />
          </Popconfirm>

          <DownloadButton handler={handleDownload} loading={loadingDownloadCampaignVideos} />
          <AddVideoButton campaignId={campaignId} unSavedWarning={unSavedWarning} onSave={saveCampaignHandler} />
          <UploadVideoListButton
            onClick={handleTriggerUpload}
            unSavedWarning={unSavedWarning}
            onSave={saveCampaignHandler}
          />
        </Space>
      </div>
    </>
  )
}

export default VideoListActions
