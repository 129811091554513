import React, { FC } from 'react'
import  { Empty as AntdEmpty } from 'antd'
import EmptyProps from './Empty.props'

const Empty: FC<EmptyProps> = ({ text }) => {
  return (
 <>
   <AntdEmpty
     image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
     imageStyle={{
       height: 160,
     }}
     description={
       <span>
        {text}
      </span>
     }
   >
   </AntdEmpty>
 </>
  )
}

export default Empty
