import React, { FC } from 'react'
import styles from './TableColumns.module.scss'
import { Tooltip } from 'antd'
import { RenderFunction, TooltipPlacement } from 'antd/lib/tooltip'

type ColumnHeadingProps = {
  title: string
  tooltip: React.ReactNode | RenderFunction
  placement?: TooltipPlacement | undefined
  isForEndScreen?: boolean
}
const ColumnHeading: FC<ColumnHeadingProps> = ({ title, tooltip, placement, isForEndScreen }) => {
  return (
    <div className={styles.columnHeading} style={ title === 'Action' && isForEndScreen ? { padding: '16px' } : {}}>
      <Tooltip placement={placement || 'left'} title={tooltip}>
      <div style={{fontSize: 13}}>{title}</div>
      </Tooltip>
        {/*<i className='fa-light fa-circle-info' style={{ fontSize: '0.8em' }} />*/}
    </div>
  )
}

export default ColumnHeading
