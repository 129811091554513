import { FC, useCallback, useEffect, useState } from 'react'
import { EndScreenDefaultElement, UpdateEndScreenDefaultListInput } from 'shared/graphql'
import EndScreenTable from 'shared/features/EndScreenElements/components/EndScreenTable'
import EndScreenEditor from 'shared/features/EndScreenElements/components/EndScreenEditor'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import styles from './EndScreenElements.module.scss'
import validateElements from './functions/validateElements'
import { Input } from '@wmg-ae/legato'
import MCNButton from 'components/Button'
import EndScreenElementInputWithPositionAndHeight from '../../types/EndScreenElementInputWithPositionAndHeight'
import checkRestrictionsForDefaultEndscreens from '../../utils/checkRestrictionsForDefaults'

type EndScreenElementsProps = {
  elements: any[] | []
  removeEndScreenElement: (id: string) => void
  handleChangeTableRow: (value: any) => void
  onSave: (input: UpdateEndScreenDefaultListInput, name: string) => void
  initialName?: string
  showNameField?: boolean
  handleChangeEditor: (position: number, data: EndScreenPreviewItemChangeResult) => void
  setErrorByRestriction: (error: string) => void
}

const EndScreenElements: FC<EndScreenElementsProps> = ({
  elements,
  handleChangeTableRow,
  handleChangeEditor,
  showNameField = false,
  initialName,
  onSave,
  setErrorByRestriction,
  removeEndScreenElement
}) => {
  const [error, setError] = useState(false)
  const [name, setName] = useState('')

  useEffect(() => {
    setName(initialName ?? '')
  }, [initialName])

  const updateEndScreenDefaultHandler = useCallback(
    (items: EndScreenElementInputWithPositionAndHeight[]) => {
      if (!error) {
        onSave({
          elements: items.map((item) => ({
            id: item.id,
            durationMs: item.durationMs,
            left: item.left,
            message: item.message,
            position: item.position,
            top: item.top,
            type: item.type,
            urlId: item.urlId,
            width: item.width,
            height: item.height,
            ...(item.imageDataUrl && {imageDataUrl: item.imageDataUrl}),
            ...(item.externalLinkCTA && {externalLinkCTA: item.externalLinkCTA}),
          }))
        }, name)
      }
    },
    [error, name, onSave]
  )

  useEffect(() => {
    const validatedElements = validateElements(elements)
    setError(validatedElements)
    setErrorByRestriction(checkRestrictionsForDefaultEndscreens(elements as unknown as EndScreenDefaultElement[]))
  }, [elements, setErrorByRestriction])

  return (
    <div className={styles.container}>
      <div style={{ flexGrow: 1 }}>
        {showNameField && <Input onChange={setName} placeholder="Template name" value={name} />}
        <EndScreenTable elements={elements} onChangeRow={handleChangeTableRow} remove={removeEndScreenElement} />
      </div>
      <div style={{ flexBasis: 500 }}>
        <EndScreenEditor elements={elements} onChange={handleChangeEditor} error={error} />
        {!error && (
          <div
            className={styles.buttonContainer}
            style={{ display: 'flex', justifyContent: 'center', margin: '10px 0 0 0' }}
          >
            <MCNButton text={'Save'}  type="primary" htmlType="submit" onClick={() => updateEndScreenDefaultHandler(elements)}/>
          </div>
        )}
      </div>
    </div>
  )
}

export default EndScreenElements
