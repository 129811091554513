import React, { FC, FormEvent, useState } from 'react'
import { Button } from 'antd'
import { Input } from '@wmg-ae/legato'
import FilterDropdownProps from './FilterDropdown.props'
import styles from './FilterDropdown.module.scss'

const FilterDropdown: FC<FilterDropdownProps> = ({ confirm, onSearch, placeholder }) => {
  const [searchText, setSearchText] = useState('')

  const handleSearch = (e: FormEvent) => {
    e.preventDefault()
    onSearch(searchText)
    confirm()
  }

  return (
    <form className={styles.container} onSubmit={handleSearch}>
      <Input placeholder={placeholder || 'Search'} onChange={setSearchText} />
      <Button shape="round" htmlType="submit">
        Ok
      </Button>
    </form>
  )
}

export default FilterDropdown
