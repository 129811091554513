type Element = {
  left: number
  top: number
  width: number
  height: number
}

const isOverlapping = (e1: Element, e2: Element) => {
  return !(
    e1.left + e1.width < e2.left ||
    e1.left > e2.left + e2.width ||
    e1.top + e1.height < e2.top ||
    e1.top > e2.top + e2.height
  )
}

export default isOverlapping
