import { Link } from 'react-router-dom'
import { SortOrder } from 'antd/lib/table/interface'
import { Switch, Button, Tooltip } from 'antd'
import FilterDropdown from '../FilterDropdown'
import { FilterDropdownProps as AntFilterDropdownProps } from 'antd/lib/table/interface'
import { ChannelListFieldName } from 'shared/graphql'
import DoubleFilterDropdown from '../DoubleFilterDropdown'
import RangePicker from '../RangePicker'
import React from 'react'
import ColumnHeading from './ColumnHeading'
import { nFormatter } from '../../../../shared/utils/NumberFormatter'
import { SelectOutlined, CopyOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { SyncOutlined, EyeOutlined } from '@ant-design/icons'

type ColumnProps = {
  setFilters: (value: any) => any
  updatePriority: (value: any) => any
  setSwitcher: (value: any) => any
  syncChannelHandler: (id: string) => any
  filters:  any
}
const columns = (props: ColumnProps) => {
  const { setFilters, filters } = props
  const copyValue = (text: any) => {
    navigator.clipboard.writeText(text).then(function () {
      toast.success('Copied to clipboard!')
    })
  }
  console.log(filters)
  return [
    {
      title: () => (
        <ColumnHeading title={`Channel ID`} tooltip={`Channel's unique Youtube ID. Column cells are clickable`} />
      ),
      width: 120,
      dataIndex: 'channelId',
      hidden: false,
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, youtubeId: searchText }))}
        />
      ),
      render: (text: null, record: any) => {
        return (
          <div>
            <a href={`https://www.youtube.com/channel/${record.channelId}`} target="_blank" rel="noreferrer">
              Open <SelectOutlined />
            </a>
            <div
              onClick={() => copyValue(`https://www.youtube.com/channel/${record.channelId}`)}
              style={{ cursor: 'pointer' }}
            >
              Copy <CopyOutlined />
            </div>
          </div>
        )
      }
    },
    {
      title: () => (
        <ColumnHeading
          title={`Name`}
          tooltip={`Name of Youtube Channel. Click to sort/cancel sorting. Click filter icon to search`}
        />
      ),
      width: 120,
      sorter: true,
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, name: searchText }))}
        />
      ),
      dataIndex: ChannelListFieldName.Name,
      key: ChannelListFieldName.Name,
      defaultSortOrder: 'descend' as SortOrder,
      hidden: false,
      render: (text: null, record: any) => {
        return <div style={{ fontSize: 12 }}>{record.name}</div>
      }
    },
    // {
    //   title: () => <ColumnHeading title={`Artist`} tooltip={`Youtube Artist's original name. Click filter icon to search`}/>,
    //   width: 100,
    //   dataIndex: 'artist',
    //   key: 'artist',
    //   hidden: false,
    //   filterDropdown: (props: AntFilterDropdownProps) => (
    //     <FilterDropdown
    //       {...props}
    //       onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, artist: searchText }))}
    //     />
    //   ),
    //   render: (text: null, record: any) => record.artist
    // },
    {
      title: () => <ColumnHeading title={`Parent Label`} tooltip={`Channel's main brand`} />,
      width: 110,
      sorter: true,
      dataIndex: 'parentLabel',
      key: 'parentLabel',
      hidden: false,
      render: (text: null, record: any) => {
        return <div>{record.parentLabel && record.parentLabel !== ' ' ? record.parentLabel : '-'}</div>
      }
    },
    {
      title: () => <ColumnHeading title={`Sub Label`} tooltip={`Channel's subordinate brand`} />,
      width: 95,
      sorter: true,
      dataIndex: 'subLabel',
      key: 'subLabel',
      hidden: false,
      render: (text: null, record: any) => {
        return (
          <div>{record.subLabel && record.subLabel !== ' ' && record.subLabel !== '  ' ? record.subLabel : '-'}</div>
        )
      }
    },
    {
      title: () => (
        <ColumnHeading
          title={`Playlist`}
          tooltip={`Youtube Channel's playlist with uploaded videos. Column cells are clickable`}
        />
      ),
      width: 80,
      dataIndex: 'playlist',
      key: 'playlist',
      hidden: false,
      render: (text: null, record: any) => {
        return (
          <div>
            <a href={`https://www.youtube.com/playlist?list=${record.playlist}`} target="_blank" rel="noreferrer">
              Open <SelectOutlined />
            </a>
            <div
              onClick={() => copyValue(`https://www.youtube.com/playlist?list=${record.playlist}`)}
              style={{ cursor: 'pointer' }}
            >
              Copy <CopyOutlined />
            </div>
          </div>
        )
      }
    },

    {
      title: () => (
        <ColumnHeading
          title={`Date Added`}
          tooltip={`Date when a channel was published to Youtube. Click to sort/cancel sorting`}
        />
      ),
      width: 100,
      sorter: true,
      dataIndex: ChannelListFieldName.AddedAt,
      key: ChannelListFieldName.AddedAt,
      hidden: false
    },
    {
      title: () => (
        <ColumnHeading
          title={`Tier`}
          tooltip={`Sets the priority of the channel, according to which EndScreens are applied to its videos during a campaign. Click to sort/cancel sorting`}
        />
      ),
      width: 80,
      sorter: true,
      dataIndex: ChannelListFieldName.Tier,
      key: ChannelListFieldName.Tier,
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, tier: searchText }))}
        />
      ),
      hidden: false
    },
    {
      title: () => (
        <ColumnHeading
          title={`Region`}
          tooltip={`Channel's region, set in Snowflake. Click to sort/cancel sorting. Click filter icon to search`}
        />
      ),
      width: 100,
      sorter: true,
      dataIndex: ChannelListFieldName.Region,
      key: ChannelListFieldName.Region,
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, region: searchText }))}
        />
      ),
      hidden: false
    },
    {
      title: () => (
        <ColumnHeading
          title={`Genre`}
          tooltip={`Channel's Genre, set in Snowflake. Click to sort/cancel sorting. Click filter icon to search`}
        />
      ),
      width: 110,
      sorter: true,
      dataIndex: ChannelListFieldName.Genre,
      key: ChannelListFieldName.Genre,
      filterDropdown: (props: AntFilterDropdownProps) => (
        <FilterDropdown
          {...props}
          onSearch={(searchText: string) => setFilters((filters: any) => ({ ...filters, genre: searchText }))}
        />
      ),
      hidden: false
    },
    {
      title: () => (
        <ColumnHeading
          title={`Subscribers`}
          tooltip={`The total channel's subscriber count. Click to sort/cancel sorting. Click filter icon to search`}
        />
      ),
      width: 115,
      sorter: true,
      dataIndex: ChannelListFieldName.SubscriberCount,
      key: ChannelListFieldName.SubscriberCount,
      render: (text: null, record: any) => nFormatter(record.subscriberCount),
      filterDropdown: (props: AntFilterDropdownProps) => (
        <>
          <RangePicker
            min={'0'}
            max={'100000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, subscribersFrom: min }))
              setFilters((filters: any) => ({ ...filters, subscribersTo: max }))
            }}
          />
          <RangePicker
            min={'100000'}
            max={'1000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, subscribersFrom: min }))
              setFilters((filters: any) => ({ ...filters, subscribersTo: max }))
            }}
          />
          <RangePicker
            min={'1000000'}
            max={'10000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, subscribersFrom: min }))
              setFilters((filters: any) => ({ ...filters, subscribersTo: max }))
            }}
          />
          <RangePicker
            min={'10000000'}
            max={'100000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, subscribersFrom: min }))
              setFilters((filters: any) => ({ ...filters, subscribersTo: max }))
            }}
          />
          <RangePicker
            min={'100000000'}
            max={'1000000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, subscribersFrom: min }))
              setFilters((filters: any) => ({ ...filters, subscribersTo: max }))
            }}
          />

          <DoubleFilterDropdown
            {...props}
            placeholder1="From"
            value1={filters.subscribersFrom || ''}
            value2={filters.subscribersTo || ''}
            onSearch1={(searchText: string) =>
              setFilters((filters: any) => ({
                ...filters,
                subscribersFrom: searchText
              }))
            }
            placeholder2="To"
            onSearch2={(searchText: string) =>
              setFilters((filters: any) => ({
                ...filters,
                subscribersTo: searchText
              }))
            }
          />
        </>
      ),
      hidden: false
    },
    {
      title: () => (
        <ColumnHeading
          title={`Views`}
          tooltip={`The total channel's views count. Only views longer than 15 seconds are taken into account. Click to sort/cancel sorting. Click filter icon to search`}
        />
      ),
      width: 100,
      sorter: true,
      dataIndex: ChannelListFieldName.ViewCount,
      key: ChannelListFieldName.ViewCount,
      hidden: false,
      render: (text: null, record: any) => nFormatter(record.viewCount),
      filterDropdown: (props: AntFilterDropdownProps) => (
        <>
          <RangePicker
            min={'0'}
            max={'100000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <RangePicker
            min={'100000'}
            max={'1000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <RangePicker
            min={'1000000'}
            max={'10000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <RangePicker
            min={'10000000'}
            max={'100000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <RangePicker
            min={'100000000'}
            max={'1000000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <RangePicker
            min={'1000000000'}
            max={'10000000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <RangePicker
            min={'10000000000'}
            max={'1000000000000'}
            onSearch={(min, max) => {
              setFilters((filters: any) => ({ ...filters, viewsFrom: min }))
              setFilters((filters: any) => ({ ...filters, viewsTo: max }))
            }}
          />
          <DoubleFilterDropdown
            {...props}
            placeholder1="From"
            value1={filters.viewsFrom}
            value2={filters.viewsTo}
            onSearch1={(searchText: string) => setFilters((filters: any) => ({ ...filters, viewsFrom: searchText }))}
            placeholder2="To"
            onSearch2={(searchText: string) => setFilters((filters: any) => ({ ...filters, viewsTo: searchText }))}
          />
        </>
      )
    },
    {
      title: () => <ColumnHeading title={`Prioritized`} tooltip={`Allows processing channels out of order`} />,
      width: 70,
      dataIndex: 'prioritized',
      key: 'prioritized',
      hidden: false,
      render: (text: null, record: any) => (
        <Switch
          defaultChecked
          onChange={() => {
            props.setSwitcher((prevState: any) => ({ ...prevState, [record.key]: !record.prioritized }))
            props.updatePriority({
              id: record.key,
              prioritized: record.prioritized
            })
          }}
          checked={record.prioritized}
        />
      )
    },
    {
      title: () => <ColumnHeading title={`Actions`} tooltip={`Detailed info about channel`} />,
      width: 80,
      dataIndex: 'action',
      key: 'action',
      hidden: false,
      render: (text: null, record: any) => (
        <div>
          <Link to={`/channels/edit/${record.channelId}`}>
            <Button title="Details" size="small" icon={<EyeOutlined />} />
          </Link>
          <Tooltip placement={'left'} title={'Sync videos from this channel'}>
            <Button
              style={{ marginLeft: 4 }}
              title="Sync Videos"
              size="small"
              icon={<SyncOutlined />}
              onClick={() => {
                props.syncChannelHandler(record.key)
              }}
            />
          </Tooltip>
        </div>
      )
    }
  ]
}

export default columns
