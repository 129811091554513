import React, {FC, useCallback, useEffect, useState} from 'react'
import {Button, Drawer, Modal, Spin} from "antd";
import {FormControl} from "../form";
import {FormProvider, useForm} from "react-hook-form";
import styles from './YouTubeAuth.module.scss'
import {useGetYoutubeCredentialsQuery, useUpdateYoutubeCredentialsMutation} from "../../shared/graphql";
import {toast} from "react-toastify";
import MCNButton from "../Button";

const YouTubeAuth: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const {data, loading} = useGetYoutubeCredentialsQuery({
    variables: {}
  })

  const [updateCredentials] = useUpdateYoutubeCredentialsMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('YouTube Credentials Updated')
      cache.modify({
        fields: {
          getYoutubeCredentials() {
          },
        }
      })
    }
  })

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const formMethods = useForm()

  useEffect(() => {
    if (data?.getYoutubeCredentials) {
      formMethods.reset(data.getYoutubeCredentials)
    }
    return () => {
    }
  }, [data, formMethods])

  const onSave = useCallback(
    (data: any) => {
      updateCredentials({
        variables: {
          input: {
            sid: data.sid,
            hsid: data.hsid,
            ssid: data.ssid,
            apisid: data.apisid,
            sapisid: data.sapisid,
            info: data.info,
            token: data.token
          }
        }
      }).then((response) => {
        setIsModalOpen(false)
      })
    },
    [updateCredentials, setIsModalOpen]
  )

  return (
    <>
      <Button type="primary" onClick={showModal} className={styles.button}>
        Update YouTube Credentials
      </Button>
      <Modal title="Update YouTube Credentials" visible={isModalOpen} onOk={formMethods.handleSubmit((data) => {
        onSave(data)
      })} onCancel={handleCancel}>
          <div className={styles.instruction}>
            <MCNButton text="Show instruction" className={styles.instructionButton} type="primary" onClick={showDrawer}/>
          </div>

        {loading ? <Spin/> : <FormProvider {...formMethods}>
          <form
            className={styles.form}
            onSubmit={formMethods.handleSubmit((data) => {
              onSave(data)
            })}
          >
            <h4>Session token</h4>
            <hr/>
            <FormControl name="token" className={styles.token} label="TOKEN" required/>
            <h4>Cookies</h4>
            <hr/>
            <FormControl name="sid" className={styles.item} label="SID" required/>
            <FormControl name="hsid" className={styles.item} label="HSID" required/>
            <FormControl name="ssid" className={styles.item} label="SSID" required/>
            <FormControl name="apisid" className={styles.item} label="APISID" required/>
            <FormControl name="sapisid" className={styles.item} label="SAPISID" required/>
            <FormControl name="info" className={styles.item} label="INFO" required/>
          </form>
        </FormProvider>}
      </Modal>
      <Drawer title="Instruction" placement="right" onClose={onClose} visible={open} width={600}>
        <div>
          <p>Initial configuration includes some easy steps to copy the token & the cookies</p>
        <p>When the videos are not processing, updating the session token is usually enough </p>
        <p>But sometimes your cookies can expire as well. In this case you will have to update both the cookies & session token</p>
        <h5>Copying the token</h5>
        <ul>
          <li>Open <a href="studio.youtube.com" target="_blank" rel="noreferrer">studio.youtube.com</a></li>
          <li>Make sure you authenticated with mcn@wmg.com account</li>
          <li>Hit "F12", and go to Network Tab</li>
          <li>Use search filter, type "grst", click on it</li>
          <li>Select preview subtab and copy "sessionToken"</li>
          <li>Paste sessionToken into this form</li>
          <li>Hit "Ok"</li>
        </ul>
        <h5>Copying the cookies</h5>
        <ul>
          <li>Open <a href="studio.youtube.com" target="_blank" rel="noreferrer">studio.youtube.com</a></li>
          <li>Make sure you authenticated with mcn@wmg.com account</li>
          <li>Hit "F12", and go to Application Tab</li>
          <li>On the left menu, select "Cookies", then "https://studio.youtube.com"</li>
          <li>Sort the table by "domain", copy only lines where ".youtube.com" is set</li>
          <li>Copy SID, HSID, SSID, APISID, SAPISID, LOGIN_INFO into this form</li>
          <li>Hit "Ok"</li>
        </ul>
      </div>
        <a href="https://wmg-my.sharepoint.com/:v:/p/amal_adeomi/EQcsY_oTUPBCvMe-rnm7FUoBUktSPsU9ZNMgEs1MZtuSQg" target="_blank" rel="noreferrer">Video Instruction</a>
      </Drawer>
    </>
  )
}

export default YouTubeAuth
