import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useSyncChannelsSupervisorMutation, useTaskExistsQuery } from '../../../../shared/graphql'
import { gql, useSubscription } from '@apollo/client'
import { Button } from 'antd'
import { SyncOutlined } from '@ant-design/icons'

const SUBSCRIPTION_ONCREATE_TASK = gql`
    subscription OnCreateTask {
        onCreateTask {
            name
        }
    }
`

const SUBSCRIPTION_ONDELETE_TASK = gql`
    subscription OnDeleteTask {
        onDeleteTask {
            name
        }
    }
`

const ChannelSyncButton: FC = () => {
  const [loadingChannelSync, setLoadingChannelSync] = useState(false)

  const [syncChannels, { loading: loadingSync }] = useSyncChannelsSupervisorMutation({
    onCompleted () {
      // toast.success('Sync Requested')
    }
  })

  useEffect(() => {
    setLoadingChannelSync(loadingSync)
  }, [loadingSync])

  const syncHandler = useCallback(() => {
    syncChannels({
      variables: {}
    })
  }, [syncChannels])

  const { data: onCreateTaskEvent } = useSubscription(SUBSCRIPTION_ONCREATE_TASK)

  useEffect(() => {
    if (onCreateTaskEvent?.onCreateTask?.name) {
      setLoadingChannelSync(true)
    }
  }, [onCreateTaskEvent])

  const { data: onDeleteTaskEvent } = useSubscription(SUBSCRIPTION_ONDELETE_TASK)

  useEffect(() => {
    if (onDeleteTaskEvent?.onDeleteTask?.name) {
      setLoadingChannelSync(false)
    }
  }, [onDeleteTaskEvent])

  const taskExistsQuery = useTaskExistsQuery({
    variables: {
      input: {
        name: 'channels:sync'
      }
    },
    onError (error) {
      toast.error(error.message)
    }
  })

  const taskExists = useMemo(() => {
    const exists = taskExistsQuery.data?.taskExists?.exists

    if (exists) {
      setLoadingChannelSync(true)
      return true
    }
    return false
  }, [taskExistsQuery.data?.taskExists?.exists])
  return (
    <Button icon={<SyncOutlined />}
            shape='round'
            loading={taskExists || loadingChannelSync}
            onClick={syncHandler}
    >Sync</Button>
  )
}

export default ChannelSyncButton
