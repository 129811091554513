import React, { FC } from 'react'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import EndScreenDefaultElements from 'shared/features/EndScreenElements/components/EndScreenElements/EndScreenDefaultElements'
import { UpdateEndScreenDefaultListInput } from 'shared/graphql'

type EndScreenDefaultElementsWrapperProps = {
  elements: any[] | []
  removeEndScreenElement: (id: string) => void
  handleChangeTableRow: (value: any) => void
  onSave: (input: UpdateEndScreenDefaultListInput, name: string) => void
  initialName?: string
  showNameField?: boolean
  handleChangeEditor: (position: number, data: EndScreenPreviewItemChangeResult) => void
  setErrorByRestriction: (error: string) => void
}

const EndScreenDefaultElementsWrapper: FC<EndScreenDefaultElementsWrapperProps> = ({ ...props}) => <EndScreenDefaultElements {...props}/>

export default EndScreenDefaultElementsWrapper
