import { FC, useState, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Typography, Space } from 'antd'
import { toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChannelFormProps } from './ChannelForm.props'
import { FormSelect } from 'components/form'
import { useGetTierFullListQuery } from 'shared/graphql'
import { updateChannelValidationSchema } from 'features/MasterChannelDetails/consts'
import styles from './ChannelForm.module.scss'

const ChannelForm: FC<ChannelFormProps> = ({ onSave, data }) => {
  const [disabled, toggleButtons] = useState(true)

  const formMethods = useForm({
    resolver: yupResolver(updateChannelValidationSchema),
    defaultValues: { Tier: 'standard' }
  })

  useEffect(() => {
    formMethods.setValue('Tier', data.tier)

    return () => formMethods.reset()
  }, [formMethods, data.tier])

  const getTiersQuery = useGetTierFullListQuery({
    variables: {},
    onError(error) {
      toast.error(error.message)
    }
  })

  const tiers = useMemo(() => {
    const items = getTiersQuery.data?.getTierFullList?.items

    if (items) {
      return items.map((item) => ({
        value: `${item?.id}`,
        label: `${item?.name}`
      }))
    }

    return []
  }, [getTiersQuery])

  const onSelectChange = () => {
    toggleButtons(false)
  }

  const onCancel = () => {
    toggleButtons(true)
    formMethods.setValue('Tier', data.tier)
  }

  return (
    <div className={styles._container}>
      <FormProvider {...formMethods}>
        <form
          className={styles.form}
          onSubmit={formMethods.handleSubmit((formData) => {
            toggleButtons(true)
            onSave({ ...formData, id: data.key })
          })}
        >
          <Space align="baseline" className={styles._container}>
            <Typography.Paragraph>Tier :</Typography.Paragraph>
            <div>
              <FormSelect name={'Tier'} width={160} onSelectChange={onSelectChange} options={tiers} />
            </div>
            <Button htmlType="submit" disabled={disabled}>
              Save
            </Button>
            <Button disabled={disabled} onClick={onCancel}>
              Cancel
            </Button>
          </Space>
        </form>
      </FormProvider>
    </div>
  )
}

export default ChannelForm
