import React, { FC, useCallback, useState } from 'react'
import { Checkbox } from '@wmg-ae/legato'
import { Button, Popover } from 'antd'
import MasterChannelActionsProps from './MasterChannelActions.props'
import styles from './MasterChannelActions.module.scss'
import DownloadButton from '../../../../components/DownloadButton'
import { ChannelSyncButton } from '../index'
import { SettingOutlined } from '@ant-design/icons'
import { useCreateChannelExportLinkMutation } from '../../../../shared/graphql'
import downloadFile from '../../../../shared/utils/downloadFile'

const MasterChannelActions: FC<MasterChannelActionsProps> = ({ onChangeColumn, columns, downloadButtonOptions }) => {
  const [showMoreColumnsPopover, setShowMoreColumnsPopover] = useState(false)

  const toggleShowMoreColumnsPopover = useCallback(() => {
    setShowMoreColumnsPopover((show) => !show)
  }, [])

  const [downloadChannels, { loading }] = useCreateChannelExportLinkMutation({
    onCompleted (data: any) {
      downloadFile(data?.createChannelExportLink?.url, 'channels_export.csv')
    }
  })

  const handleDownload = useCallback(() => {
    downloadChannels({
      variables: {
        input: {
          filter: downloadButtonOptions.filter,
          sortOrder: downloadButtonOptions.sortOrder,
        }
      }
    })
  }, [downloadChannels, downloadButtonOptions])

  return (
    <div className={styles.container}>
      <Popover
        content={
          <div>
            {columns.map((column) => (
              <Checkbox
                checked={!column.hidden}
                key={column.dataIndex}
                id={column.dataIndex}
                label={column.title().props.title}
                onChange={onChangeColumn}
              />
            ))}
          </div>
        }
        trigger='click'
        placement='bottomLeft'
        visible={showMoreColumnsPopover}
        onVisibleChange={toggleShowMoreColumnsPopover}
      >
        <Button shape='round' icon={<SettingOutlined />}>Columns</Button>
      </Popover>

      <div className={styles.actionsRight}>
        <ChannelSyncButton />
        <DownloadButton handler={handleDownload} loading={loading}/>
      </div>
    </div>
  )
}

export default MasterChannelActions
