import {FC} from 'react'
import {Content} from 'antd/lib/layout/layout'
import {Header} from 'components'
import {Layout} from 'antd'
import styles from './MainLayout.module.scss'
import Footer from "../Footer/Footer";

const MainLayout: FC = ({children}) => (
  <Layout className={styles.mainLayoutContainer}>
    <Header/>
    <Content className={styles.content}>
      <div className={styles.contentContainer}>{children}</div>
    </Content>
    <Footer/>
  </Layout>
)

export default MainLayout
