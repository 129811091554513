import React, { FC } from 'react'
import { Table } from 'antd'
import VideListProps from './VideoList.props'
import columns from './columns'

const SelectVideoList: FC<VideListProps<any>> = ({ videos, query, pagination, toggleSelectAll }) => {

  return (
    <Table
      size='small'
      pagination={pagination}
      rowKey={(record) => record.id}
      loading={query.loading}
      columns={columns(query, toggleSelectAll)}
      dataSource={videos}
      sticky
    />
  )
}

export default SelectVideoList
