import { FC } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import MCNButton from '../Button'

type DownloadButtonProps = {
  text?: string
  handler?: any
  loading?: boolean
  size?: string
  style?: any
}

const DownloadButton: FC<DownloadButtonProps> = ({ text, loading, handler, size, style }) => {
  return (
 <>
   <MCNButton
     shape='round'
     icon={<DownloadOutlined />}
     type='primary'
     text='Download'
     loading={loading}
     onClick={handler}
     size={size}
     style={style}
   >{text || 'Download'}</MCNButton>
 </>
  )
}

export default DownloadButton
