import { useState, useMemo, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Typography } from 'antd'
import { useParams } from 'react-router-dom'
import { GetVideoListFilters, GetVideoListInput, useGetVideosFullListQuery, useToggleVideoSelectionsMutation } from 'shared/graphql'
import { VideoFilters, SelectVideoList } from '../components'
import TableExtras from '../components/TableExtras'
import { CampaignParamTypes } from '../../Campaigns/types/campaign.params.type'
import { defaultFormValues } from '../consts'
import styles  from './AddVideos.module.scss'
import {
  MCN_PAGINATION_CONFIG,
} from '../../../shared/utils/tableHelpers'
import MCNButton from '../../../components/Button'


const AddVideosContainer = () => {
  const [currentPage, setCurrentpage] = useState(1)
  const [pageSize, setPagesize] = useState(10)
  const [filters, setFilters] = useState<GetVideoListFilters>(defaultFormValues)
  const { campaignId } = useParams<CampaignParamTypes>()

  const getVideoQuery = useGetVideosFullListQuery({
    variables: {
      input: {
        offset: (currentPage - 1) * pageSize,
        limit: pageSize,
        filters,
        campaignId
      } as GetVideoListInput
    },
    onError(error: any) {
      toast.error(error.message)
    }
  })

  const videos = useMemo(() => {
    const items = getVideoQuery.data?.getVideosFullList?.items

    if (items) {
      return items.map((item: any) => ({
        id: `${item?.id}`,
        thumbnail: `${item?.thumbnail}`,
        name: `${item?.name}`,
        channel: `${item?.channel?.name}`,
        artist: item?.artist?.name && item.artist.name !== 'null' ? `${item.artist.name}` : '-',
        applied: item.applied,
        views: `${item?.viewCount}`,
        likes: `${item?.likesCount}`,
        selected: Boolean(item?.selected),
        youtubeId: `${item?.youtubeId}`,
        parentLabel: `${item?.parentLabel?.name}`,
        genre: `${item?.channel?.genre?.name}`,
        region: `${item?.channel?.region?.name}`,
      }))
    }

    return []
  }, [getVideoQuery])

  const pagination = {
    ...MCN_PAGINATION_CONFIG,
    total: getVideoQuery.data?.getVideosFullList?.count ? getVideoQuery.data?.getVideosFullList?.count : 0,
    current: currentPage,
    onChange: (page: number, size: number) => {
      setCurrentpage(page)
      setPagesize(size)
    }
  }

  const handleSearch = useCallback((filter: GetVideoListFilters) => {
    setFilters(filter)
  }, [])


  const [useToggleVideoSelections] = useToggleVideoSelectionsMutation({
    onError: (error) => {
    },
    update(cache) {
      cache.modify({
        fields: {
          getVideosFullList() {
          }
        }
      })
    }
  })

  const toggleSelectAllHandler = useCallback(
    () => {
      const videoIds = videos.filter((video: any) => !video.applied.length).map((video: any) => video.id)
      console.log(videoIds.length)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useToggleVideoSelections({
        variables: {
          input: { campaignId, videoIds }
        }
      })
    },
    [useToggleVideoSelections, campaignId, videos]
  )


  return (
    <div>
      <Typography.Title>Search Videos</Typography.Title>
      <VideoFilters onSearch={handleSearch} searching={getVideoQuery.loading} />
      <TableExtras addAllVideosQuery={{
        offset: 0,
        limit: 100,
        filters,
        campaignId
      }} filters={filters} disabledAddAllButton={false}/>
      <SelectVideoList videos={videos} query={getVideoQuery} pagination={pagination} toggleSelectAll={toggleSelectAllHandler} />
      <div className={styles.done}> <Link to={`/campaigns/edit/${campaignId}`}>  <MCNButton text='Done' type='primary'/> </Link> </div>
    </div>
  )
}

export default AddVideosContainer
