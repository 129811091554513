import { EndScreenElement, EndScreenElementType } from "shared/graphql";

const ELEMENTS_AT_LEAST_ONE_SHOULD_BE_PRESENT = [
    EndScreenElementType.PlaylistById,
    EndScreenElementType.VideoBestForViewers,
    EndScreenElementType.VideoById,
    EndScreenElementType.VideoRecentUpload
]

const ELEMENTS_WHERE_URL_ID_SHOULD_BE_PRESENT = [
    EndScreenElementType.PlaylistById,
    EndScreenElementType.VideoById,
    EndScreenElementType.SubscribeChannelById,
    EndScreenElementType.ExternalLink
]

const containerWidth = 458; // client/src/shared/features/EndScreenElements/components/EndScreenEditor/EndScreenEditor.module.scss width

const checkRestriction = (endscreens: EndScreenElement[]): string => {
    if (!endscreens || !endscreens.length) {
        return '';
      }
  
      if (!endscreens.some((item) => ELEMENTS_AT_LEAST_ONE_SHOULD_BE_PRESENT.includes(item.type))) {
        return 'At least one element must be a video or a playlist.';
      }
  
      if (endscreens.length > 4) {
        return '4 elements is the limit';
      }
  
      if (endscreens.filter((item) => item.type === EndScreenElementType.SubscribeChannel).length > 1) {
        return 'You can add only one element with type Channel Subscribe';
      }
  
      if (
        new Set(endscreens.filter((item) => item.urlId).map((item) => item.urlId)).size !==
        endscreens.filter((item) => item.urlId).length
      ) {
        return 'You cannot set elements with the same url ids';
      }
  
      if (endscreens.filter((item) => item.type === EndScreenElementType.VideoBestForViewers).length > 1) {
        return 'You can set only one element with type Video best for viewers';
      }
  
      if (endscreens.filter((item) => item.type === EndScreenElementType.VideoRecentUpload).length > 1) {
        return 'You can set only one element with type Latest Video';
      }
  
      if (endscreens.filter((item) => item.type === EndScreenElementType.ExternalLink).length > 1) {
        return 'You can set only one element with type External Link';
      }

      if (endscreens.find((item) => Number(item.width) + Number(item.left) > containerWidth)) {
        return 'Item width or position relative to the left edge should be lesser';
      }
  
      const endScreenWithoutUrlId = endscreens.find((item) =>
        ELEMENTS_WHERE_URL_ID_SHOULD_BE_PRESENT.includes(item.type) && !item.urlId
      );
      if (endScreenWithoutUrlId) {
        return `EndScreen with position ${endScreenWithoutUrlId.position} doesn't have url`;
      }

      const externalLinkElement = endscreens.find((item) => item.type === EndScreenElementType.ExternalLink);      
      if (externalLinkElement && (!externalLinkElement.imageDataUrl || !externalLinkElement.externalLinkCTA || !externalLinkElement.message)) {
        return 'Provide special message, image and Call to action name for External Link Element';
      }

      return '';
}


export default checkRestriction;