import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import App from 'App'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_STAGE === 'dev') {
  const varsScript = document.createElement('script');
  varsScript.innerHTML = `window.__ASF_APP_ID__ = 'b559922e-846b-4914-9b3e-658830170c8a'`;
  const asfScript = document.createElement('script');
  asfScript.src = 'https://d34wl05sticy0l.cloudfront.net/asf-init.js';
  document.body.appendChild(varsScript);
  document.body.appendChild(asfScript);
}
