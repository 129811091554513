import { ExternalLinkCta } from 'shared/graphql'

export const labels = {
  [ExternalLinkCta.CtaBuy]: 'Buy',
  [ExternalLinkCta.CtaDownload]: 'Download',
  [ExternalLinkCta.CtaLearnMore]: 'Learn more',
  [ExternalLinkCta.CtaOrder]: 'Order',
  [ExternalLinkCta.CtaShop]: 'Product overview',
  [ExternalLinkCta.CtaSignUp]: 'Sign up',
  [ExternalLinkCta.CtaSupport]: 'Support',
  [ExternalLinkCta.CtaVisit]: 'Visit'
}

export const getExternalLinkCta = (cta: ExternalLinkCta): string => labels[cta]
