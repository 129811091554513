import { Typography } from 'antd'

export interface ITableEntry {
  [key: string | number]: string | number | JSX.Element | Function
}

type TTypeClicks = {
  clicksQuantity: string
  percentage: string
}

const getCampaignClicksTableColumns = (): ITableEntry[] => [
  {
    title: 'Element type',
    dataIndex: 'elementType',
    key: 'elementType'
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url'
  },
  {
    title: 'Click Through',
    width: 200,
    dataIndex: 'clicks',
    key: 'clicks',
    render: (text: TTypeClicks) => {
      return <Typography.Paragraph>{`${text.clicksQuantity} (${text.percentage}%)`}</Typography.Paragraph>
    }
  }
]

export default getCampaignClicksTableColumns
