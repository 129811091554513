import React, { useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import {
  CampaignListOrderFieldName,
  OrderDirection,
  useGetCampaignListQuery
} from 'shared/graphql'
import { CampaignsList } from 'features/Campaigns/components'
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from 'shared/constants'
import {
  MCN_PAGINATION_CONFIG,
} from 'shared/utils/tableHelpers'

const CampaignsListContainer = () => {

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const [sortOrder, setSortOrder] = useState({
    field: CampaignListOrderFieldName.CreatedAt,
    direction: OrderDirection.Desc
  })
  const [filters, setFilters] = useState({})


  const getCampaignListQuery = useGetCampaignListQuery({
    variables: {
      input: {
        sortOrder,
        filter: filters,
        offset: (currentPage - 1) * pageSize,
        limit: pageSize
      }
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const data: any = useMemo(() => {
    const items = getCampaignListQuery.data?.getCampaignList?.items
    if (items) {
      return items.map((item: any) => ({
        ...item,
        createdAt: dayjs(item.createdAt).format(DATE_FORMAT),
        endDate: dayjs(item.endDate).format(DATE_FORMAT),
        startDate: dayjs(item.startDate).format(DATE_FORMAT),
        updatedAt: dayjs(item.updatedAt).format(DATE_FORMAT_WITH_TIME)
      }))
    }

    return []
  }, [getCampaignListQuery.data?.getCampaignList?.items])

  const { loading } = getCampaignListQuery

  const pagination = {
    ...MCN_PAGINATION_CONFIG,
    total: getCampaignListQuery.data?.getCampaignList?.count,
    current: currentPage,
  }

  const downloadButtonOptions = {
    sortOrder,
    filter: filters
  }
  return <CampaignsList loading={loading} downloadButtonOptions={downloadButtonOptions} campaigns={data}
                        pagination={pagination} setSortOrder={setSortOrder} setFilters={setFilters}
                        setCurrentPage={setCurrentPage} setPageSize={setPageSize} />
}

export default CampaignsListContainer
