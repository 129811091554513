import React, { FC } from 'react'
import { Form } from 'antd'
import FormItemProps from './FormItem.props'
import FormFeedback from '../FormFeedback'
import styles from './FormItem.module.scss'

const FormItem: FC<FormItemProps> = ({ className, labelWidth, children, label, width, error }) => {
  return (
    <Form.Item
      className={className}
      label={
        label ? (
          <span style={{ width: labelWidth }} className={styles.label}>
            {label}
          </span>
        ) : null
      }
      validateStatus={error ? 'error' : undefined}
      style={{ width }}
    >
      {children}
      <FormFeedback>{error?.message || error?.type || ''}</FormFeedback>
    </Form.Item>
  )
}

export default FormItem
