const downloadFile = (uri: string | undefined, fileName: string) => {
  if (uri) {
    const url = uri
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    if (link?.parentNode) {
      link.parentNode.removeChild(link)
    }
  }
}

export default downloadFile
