import React, { FC } from 'react'
import ReportCardProps from './ReportCard.props'
import styles from './ReportCard.module.scss'
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons'

const ReportCard: FC<ReportCardProps> = ({ card }) => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.score}>{card.score}</div>
        <div className={styles.scoreDescription}>
          {card.progressType === 'increase' && <ArrowUpOutlined style={{
            color: 'green'
          }} />}
          {card.progressType === 'decrease' && <ArrowDownOutlined style={{
            color: 'red'
          }} />}
          {/*<ArrowDownOutlined style={{*/}
          {/*  color: 'red'*/}
          {/*}} />*/}
          {card.scoreDescription}
        </div>
      </div>
      <div>
        <div className={styles.title}>{card.title}</div>
        <div>{card.titleDescription}</div>
      </div>
    </div>
  )
}

export default ReportCard
