import { EndScreenElementType } from 'shared/graphql'
import EndScreenRoundedSizes from 'shared/features/EndScreenElements/types/EndScreenRoundedSizes'
import EndScreenSquaredSizes from 'shared/features/EndScreenElements/types/EndScreenSquaredSizes'
import EndScreenExternalLinkSizes from 'shared/features/EndScreenElements/types/EndScreenExternalLinkSizes'

const getEndScreenWidth = (type: EndScreenElementType | undefined, width?: string | number): number => {
  if (width) { return +width; }
  if (type === EndScreenElementType.ExternalLink) { return EndScreenExternalLinkSizes.width; }
  if (type === EndScreenElementType.SubscribeChannelById || type === EndScreenElementType.SubscribeChannel) {
    return EndScreenRoundedSizes.width;
  }
  
  return EndScreenSquaredSizes.width
}

export default getEndScreenWidth;
