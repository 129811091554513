import { FC } from 'react'
import { Link, Redirect } from 'react-router-dom'
import CenteredRow from '../Centered'
import MCNButton from '../Button'
import Modal from '../Modal'

type UnSavedWarningModalProps = {
  redirectLink: string
  visible?: boolean
  enableRedirect?: boolean
  title?: string
  children?: any
  onSaveAndContinue?: any
  onCancel?: any
}

const UnSavedWarningModal: FC<UnSavedWarningModalProps> = ({ visible, enableRedirect, redirectLink, title, children, onSaveAndContinue, onCancel }) => {
  return (
    <Modal title={title} visible={visible || false} onCancel={onCancel} footer={null}>
        <CenteredRow>
          {enableRedirect ? <Redirect to={redirectLink} /> : null}
          <MCNButton text={`Save & Continue`} type={`primary`} style={{ marginRight: 8 }} onClick={onSaveAndContinue} />

          <Link to={redirectLink}>
            <MCNButton text={`Discard & Continue`} type={`dashed`} style={{ marginRight: 8 }} />
          </Link>

          <MCNButton text={`Cancel`} onClick={onCancel} />
        </CenteredRow>

      {children}
    </Modal>
  )
}

export default UnSavedWarningModal
