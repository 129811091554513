import React, { FC } from 'react'
import { EndScreenElementType } from 'shared/graphql'
import getElementTypeLabel from '../../../EndScreenTable/utils/getElementTypeLabel'
import styles from './EndscreenSquaredItem.module.scss'
import endScreenStyles from '../../../EndScreenElements/EndScreenElements.module.scss'

type EndscreenSquaredItemProps = {
  position: number,
  thumbnail?: string
  elementName?: string
  elementType: EndScreenElementType
}

const EndscreenSquaredItem: FC<EndscreenSquaredItemProps> = ({ elementType, position, thumbnail, elementName }) => {
  return <div className={styles.container}>
    { !thumbnail && <div className={styles.containerForPosition}>
      <div className={endScreenStyles.endScreenNumber}>{position}</div>
      <div className={endScreenStyles.endScreenText}>{getElementTypeLabel(elementType)}</div>
    </div>}

    { thumbnail && <img className={styles.thumbnail} src={thumbnail} alt={elementName} /> }
    { elementName && <div className={styles.name}>{elementName.substring(0, 23)}{elementName.length > 24 ? '...' : ''}</div> }
    <div className={styles.containerInner}></div>
  </div>
}

export default EndscreenSquaredItem
