import React from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, Tooltip } from 'antd'
import SelectVideoItem from '../SelectVideoItem'
import { ApolloQueryResult } from '@apollo/client/core/types'
import styles from './SelectVideoList.module.scss'
import ColumnHeading from '../../../MasterChannelList/components/TableColumns/ColumnHeading'

const columns = (query: ApolloQueryResult<any>, toggleSelectAll: any) => {
  return [
    {
      title: <Checkbox checked={false} onChange={toggleSelectAll}/>,
      dataIndex: 'select',
      key: 'select',
      width: 40,
      render: (_: any, record: any) => {
        return <SelectVideoItem id={record.id} selected={record.selected} query={query} disabled={!!record.applied.length}/>
      }
    },
    {
      title: <ColumnHeading title={`Thumbnail`} tooltip={`Video image cover`} />,
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      width: 100,
      render: (_: any, record: any) => {
        return (
          <a href={`https://www.youtube.com/watch?v=${record.youtubeId}`} style={{ color: '#000000d9' }}>
            <img className={styles.thumbnail} src={record.thumbnail} alt={record.thumbnail} />
          </a>
        )
      }
    },
    { title: <ColumnHeading title={`Video`} tooltip={`Video title`} />, dataIndex: 'name', key: 'name' },
    { title: <ColumnHeading title={`Channel`} tooltip={`Channel name`} />, dataIndex: 'channel', key: 'channel' },
    { title: <ColumnHeading title={`Label`} tooltip={`Parent Label name`} />, dataIndex: 'parentLabel', key: 'parentLabel', render: (_: any, record: any) => {
      return _ === 'undefined' ? '' : _
      } },
    { title: <ColumnHeading title={`Genre`} tooltip={`Channel Genre`} />, dataIndex: 'genre', key: 'genre', render: (_: any, record: any) => {
      return _ === 'undefined' ? '' : _
      } },
    { title: <ColumnHeading title={`Region`} tooltip={`Region`} />, dataIndex: 'region', key: 'region', render: (_: any, record: any) => {
      return _ === 'undefined' ? '' : _
      } },
    {
      title: <ColumnHeading title={`Artist`} tooltip={`Artist's (Youtube Channel Owner's) name`} />,
      dataIndex: 'artist',
      key: 'artist'
    },
    {
      title: (
        <ColumnHeading
          title={`Currently Applied`}
          tooltip={`Displays whether the video is selected in a different campaign. Possible values: "Yes" or "No"`}
        />
      ),
      dataIndex: 'applied',
      key: 'applied',
      render: (_: any, record: any) => {
        return record.applied.length ? (
          <div>
            Yes{' '}
            <Tooltip
              overlayStyle={{ maxWidth: '560px' }}
              color="volcano"
              placement={'left'}
              title={
                <div style={{ cursor: 'pointer', fontSize: 16, padding: 12 }}>
                  Applied to:
                  {record.applied.map((campaign: any) => (
                    <Link to={`/campaigns/edit/${campaign.id}`} target="_blank" rel="noopener noreferrer" style={{
                      color: '#fff',
                      border: '1px solid #fff',
                      display:'block',
                      padding: 12
                    }}>
                      <div>
                        {campaign.name} | {campaign.startDate} - {campaign.endDate}{' '}
                        <i className="fa-light fa-arrow-up-right" style={{ fontSize: '0.8em' }} />
                      </div>
                    </Link>
                  ))}
                </div>
              }
            >
              <span style={{ cursor: 'pointer' }}>
                <i className="fa-light fa-circle-info" style={{ fontSize: '0.8em' }} />
              </span>
            </Tooltip>
          </div>
        ) : (
          'No'
        )
      }
    },
    {
      title: <ColumnHeading title={`Views`} tooltip={`Amount of views for current video`} />,
      dataIndex: 'views',
      key: 'views'
    }
    // { title: <ColumnHeading title={`Likes`} tooltip={`Amount of likes per video`} />, dataIndex: 'likes', key: 'likes' }
  ]
}

export default columns
