import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { SearchCriteria, ReportChart } from '../components'
import SearchCriteriaFilters from '../types/SearchCriteriaFilters'
import { colors } from '../utils'
import { Heading } from '../../../components'
import {
  CampaignListOrderFieldName,
  OrderDirection,
  useCreateCampaignPerformanceExportLinkMutation,
  useGetCampaignListQuery,
  useGetCampaignPerformanceLazyQuery
} from 'shared/graphql'
import { toast } from 'react-toastify'
import moment from 'moment'
import SelectTags from '../types/SelectTags'
import uniqueArrayOfObjectsByField from '../../../shared/utils/uniqueArrayOfObjectsByField'
import downloadFile from '../../../shared/utils/downloadFile'

const CampaignPerformance = () => {
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaFilters | null>(null)
  const [allDates, setAllDates] = useState<string[]>([])
  const [campaigns, setCampaigns] = useState<any[]>([])
  const [searchCampaigns, setSearchCampaigns] = useState('')
  const [campaignOptions, setCampaignOptions] = useState<SelectTags[]>([])

  const [getCampaignPerformanceLazyQuery, { data: getCampaignPerformanceLazyQueryData, loading }] =
    useGetCampaignPerformanceLazyQuery({
      variables: {
        input: {
          campaignIds: [],
          startDate: searchCriteria?.startDate || '2022-01-01',
          endDate: searchCriteria?.endDate || '2022-10-20'
        }
      },
      onError: (error) => {
        toast.error(error.message)
      }
    })

  const getCampaignListQuery = useGetCampaignListQuery({
    variables: {
      input: {
        sortOrder: {
          field: CampaignListOrderFieldName.CreatedAt,
          direction: OrderDirection.Desc
        },
        filter: {
          name: searchCampaigns
        },
        offset: 0,
        limit: 5
      }
    },
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const campaignList: any = useMemo(() => {
    const items = getCampaignListQuery.data?.getCampaignList?.items
    if (items) {
      return items.map((item: any) => ({
        value: item.id,
        label: item.name
      }))
    }

    return []
  }, [getCampaignListQuery.data?.getCampaignList?.items])

  useEffect(() => {
    setCampaignOptions((prevState: SelectTags[]) => {
      const newList = [...campaignList, ...prevState]
      return uniqueArrayOfObjectsByField(newList, 'value')
    })

    return () => {}
  }, [campaignList])

  const onSearch = useCallback(
    (campaignIds: string[], startDate: string, endDate: string) => {
      getCampaignPerformanceLazyQuery({
        variables: {
          input: {
            campaignIds,
            startDate,
            endDate
          }
        }
      })
    },
    [getCampaignPerformanceLazyQuery]
  )

  useEffect(() => {
    if (searchCriteria?.tags && searchCriteria?.startDate && searchCriteria?.endDate) {
      if (!searchCriteria?.tags.length) {
        toast.error('Please select campaign(s)')
      } else {
        onSearch(searchCriteria?.tags, searchCriteria?.startDate, searchCriteria?.endDate)
      }
    }
    return () => {}
  }, [searchCriteria, onSearch])

  useMemo(() => {
    const data = getCampaignPerformanceLazyQueryData?.getCampaignPerformance?.data
    if (data && data.length) {
      const allCoveredDays = data
        .map((item) => item?.metrics.map((item) => item?.dayCovered))
        .flat(1)
        .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any))

      const dateArray: string[] = []
      let startDate = moment(allCoveredDays[0])
      const endDate = moment(allCoveredDays[allCoveredDays.length - 1])
      while (startDate <= endDate) {
        dateArray.push(moment(startDate).format('YYYY-MM-DD'))
        startDate = moment(startDate).add(1, 'days')
      }

      setAllDates(dateArray)

      const filledGapsCampaigns = data.map((item, index, selfArr) => {
        const elementCoveredDays = item?.metrics.map((item) => item?.dayCovered)
        return {
          ...item,
          metrics: dateArray.map((day) => {
            const indexOfDay = elementCoveredDays?.indexOf(day || '')
            if (indexOfDay === -1) {
              return {
                dayCovered: day,
                clicksCount: 0,
                impressionsCount: 0,
                ctr: 0
              }
            }

            return selfArr[index]?.metrics[indexOfDay || 0]
          })
        }
      })
      setCampaigns(filledGapsCampaigns)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCampaignPerformanceLazyQueryData?.getCampaignPerformance])

  const [downloadCampaignPerformance, { loading: loadingDownloadCampaignPerformance }] = useCreateCampaignPerformanceExportLinkMutation({
    onCompleted(data: any) {
      downloadFile(data?.createCampaignPerformanceExportLink?.url, 'campaign_performance_export.csv')
    }
  })

  const handleDownload = useCallback(() => {
    downloadCampaignPerformance({
      variables: {
        input: {
          campaignIds: searchCriteria?.tags || [],
          startDate: searchCriteria?.startDate || '',
          endDate: searchCriteria?.endDate || ''
        }
      }
    })
  }, [downloadCampaignPerformance, searchCriteria])

  return (
    <div>
      <Heading>Campaign Performance Report</Heading>
      <SearchCriteria
        onSearch={setSearchCriteria}
        onSearchSelect={setSearchCampaigns}
        loadingSelect={loading}
        tagsLabel="Campaign(s)"
        tags={campaignOptions}
        downloadEnabled={!!campaigns.length}
        onDownload={handleDownload}
        loadingDownload={loadingDownloadCampaignPerformance}
      />

      {campaigns.length ? (
        <ReportChart
          datasets={campaigns.map((item, index) => ({
            label: item.name,
            data: item.metrics.map((item: any) => item.clicksCount),
            tension: 0.1,
            ...colors[index]
          }))}
          labels={allDates}
        />
      ) : null}
    </div>
  )
}

export default CampaignPerformance
