import { FC, useEffect, useCallback } from 'react'
import { Spin } from 'antd'
import { getSearchParam } from '../shared/utils/getSearchParam'
import { useSaveGoogleTokensMutation } from '../shared/graphql'
import { toast } from 'react-toastify'

const GoogleAuthCallback: FC = () => {
  const code = getSearchParam('code')

  const [saveGoogleTokens] = useSaveGoogleTokensMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      window.location.href = '/'
      cache.modify({
        fields: {
          checkGoogleAuth() {}
        }
      })
    }
  })

  const saveGoogleTokensHandler = useCallback(
    (data: any) => {
      if (data) {
        saveGoogleTokens({
          variables: {
            input: { code: data }
          }
        })
      }
    },
    [saveGoogleTokens]
  )

  
  useEffect(() => {

    if (code) {
      saveGoogleTokensHandler(code)
    }
    return () => {}
  }, [code, saveGoogleTokensHandler])

  return (
    <div style={{ width: '100%', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Spin />
    </div>
  )
}

export default GoogleAuthCallback
