import { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import MCNButton from '../../../../../components/Button'
import UnSavedWarningModal from '../../../../../components/UnSavedWarning'

type AddVideoButtonProps = {
  campaignId: string
  unSavedWarning: boolean
  onSave: any
}
const AddVideoButton: FC<AddVideoButtonProps> = ({ campaignId, unSavedWarning, onSave }) => {
  const [showModal, setShowModal] = useState(false)
  const [enableRedirect, setEnableRedirect] = useState(false)

  const showUnSavedWarningModal = () => {
    setShowModal((prevState) => !prevState)
  }

  const onSaveAndContinue = () => {
    onSave()
    setEnableRedirect(true)
  }

  const redirectLink = `/campaigns/add-videos/${campaignId}`
  return (
    <div>
      {unSavedWarning ? (
        <MCNButton text={`Add Video(s)`} onClick={showUnSavedWarningModal} />
      ) : (
        <Link to={redirectLink}>
          <MCNButton text={`Add Video(s)`} />
        </Link>
      )}
      <UnSavedWarningModal
        visible={showModal}
        title={`You have unsaved Endscreen element updates`}
        redirectLink={redirectLink}
        enableRedirect={enableRedirect}
        onSaveAndContinue={onSaveAndContinue}
        onCancel={showUnSavedWarningModal}
      />
    </div>
  )
}

export default AddVideoButton
