import { InMemoryCache } from '@apollo/client'

import { CampaignsStore } from 'features/Campaigns/graphql/store'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...CampaignsStore
      }
    }
  }
})
