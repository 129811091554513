import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  AWSDate: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  AWSDateTime: any;
  /** A field whose value conforms to the standard internet email address format as specified in RFC822: https://www.w3.org/Protocols/rfc822/. */
  AWSEmail: any;
  /** The AWSIPAddress scalar type represents a valid IPv4 or IPv6 address string. */
  AWSIPAddress: any;
  /** The AWSJSON scalar type represents a valid json object serialized as a string. */
  AWSJSON: any;
  /** AWSPhone */
  AWSPhone: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  AWSTime: any;
  /** The AWSTimestamp scalar type represents the number of seconds that have elapsed since 1970-01-01T00:00Z. Timestamps are serialized and deserialized as numbers. Negative values are also accepted and these represent the number of seconds till 1970-01-01T00:00Z. */
  AWSTimestamp: any;
  /** A field whose value conforms to the standard URL format as specified in RFC3986: https://www.ietf.org/rfc/rfc3986.txt. */
  AWSURL: any;
};

export type AddAllVideosFilters = {
  applied?: Maybe<Scalars['Boolean']>;
  artist?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type AddAllVideosInput = {
  campaignId: Scalars['String'];
  filters?: Maybe<GetVideoListFilters>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type AddAllVideosResponse = {
  __typename?: 'AddAllVideosResponse';
  requested: Scalars['Boolean'];
};

export type AddSelectedVideos = {
  campaignId: Scalars['String'];
};

export type AddSnowflakeVideoChunkInput = {
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
  videos: Array<Maybe<SfVideo>>;
};

export type AddSnowflakeVideoChunkResponse = {
  __typename?: 'AddSnowflakeVideoChunkResponse';
  requested: Scalars['Boolean'];
};

export type AddVideosResponse = {
  __typename?: 'AddVideosResponse';
  requested: Scalars['Boolean'];
};

export type AppSyncAuth = {
  auth: Scalars['String'];
  type: Scalars['String'];
};

export type ApplyCampaignResponse = {
  __typename?: 'ApplyCampaignResponse';
  createdAt?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  views: Scalars['Int'];
};

export type ApplyEndScreensToCampaignVideoInput = {
  campaignId: Scalars['String'];
  campaignStart: Scalars['Boolean'];
  current?: Maybe<Scalars['Int']>;
  videoId: Scalars['String'];
};

export type ApplyEndScreensToCampaignVideoResponse = {
  __typename?: 'ApplyEndScreensToCampaignVideoResponse';
  completed: Scalars['Int'];
  failed?: Maybe<Scalars['Int']>;
};

export type Artist = Entity & {
  __typename?: 'Artist';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ArtistListResponse = {
  __typename?: 'ArtistListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Artist>>;
};

export type Campaign = Entity & {
  __typename?: 'Campaign';
  createdAt?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  views: Scalars['Int'];
};

export type CampaignEndScreensResponse = {
  __typename?: 'CampaignEndScreensResponse';
  count: Scalars['Int'];
  items: Array<EndScreenElement>;
};

export type CampaignListFilters = {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export enum CampaignListOrderFieldName {
  CreatedAt = 'createdAt',
  EndDate = 'endDate',
  StartDate = 'startDate',
  Status = 'status',
  Views = 'views'
}

export type CampaignListResponse = {
  __typename?: 'CampaignListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Campaign>>;
};

export type CampaignListSortOrder = {
  direction: OrderDirection;
  field: CampaignListOrderFieldName;
};

export type CampaignResponse = Entity & {
  __typename?: 'CampaignResponse';
  createdAt?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  id: Scalars['ID'];
  item: CampaignWithRelations;
  name: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  views: Scalars['Int'];
};

export type CampaignStatusInput = {
  campaignId: Scalars['String'];
};

export type CampaignVideoItem = {
  __typename?: 'CampaignVideoItem';
  artist: Artist;
  channel: Channel;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  likesCount: Scalars['String'];
  name: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  thumbnail: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export enum CampaignVideoListFieldName {
  Channel = 'channel',
  Name = 'name',
  Status = 'status'
}

export type CampaignVideoListFilters = {
  channelName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  videoName?: Maybe<Scalars['String']>;
};

export type CampaignVideoListResponse = {
  __typename?: 'CampaignVideoListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<CampaignVideoItem>>;
};

export type CampaignVideoListSortOrder = {
  direction: OrderDirection;
  field: CampaignVideoListFieldName;
};

export type CampaignVideoStatus = {
  __typename?: 'CampaignVideoStatus';
  cancelled?: Maybe<Scalars['Int']>;
  complete?: Maybe<Scalars['Int']>;
  delete?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
  in_progress?: Maybe<Scalars['Int']>;
  pending?: Maybe<Scalars['Int']>;
  postprocessing?: Maybe<Scalars['Int']>;
  processing?: Maybe<Scalars['Int']>;
};

export type CampaignVideoStatusResponse = {
  __typename?: 'CampaignVideoStatusResponse';
  campaignId: Scalars['String'];
  reason: Scalars['String'];
  start?: Maybe<Scalars['Boolean']>;
  stats?: Maybe<CampaignVideoStatus>;
  status: Scalars['String'];
  videoId: Scalars['String'];
};

export type CampaignWithRelations = Entity & {
  __typename?: 'CampaignWithRelations';
  createdAt: Scalars['String'];
  endDate: Scalars['String'];
  endScreens: Array<EndScreenElement>;
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  views: Scalars['Int'];
};

export type ChangeGenreForChannelsInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ChangeGenreForChannelsResponse = {
  __typename?: 'ChangeGenreForChannelsResponse';
  done: Scalars['Boolean'];
};

export type ChangeRegionForChannelsInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type ChangeRegionForChannelsResponse = {
  __typename?: 'ChangeRegionForChannelsResponse';
  done: Scalars['Boolean'];
};

export type Channel = Entity & {
  __typename?: 'Channel';
  addedAt: Scalars['AWSDate'];
  artist: Artist;
  censorship: Scalars['Boolean'];
  claimUploads: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  genre?: Maybe<Genre>;
  id: Scalars['ID'];
  monetized: Scalars['Boolean'];
  name: Scalars['String'];
  parentLabel?: Maybe<Label>;
  playlist: Scalars['String'];
  prioritized?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Region>;
  subLabel?: Maybe<Label>;
  subscriberCount: Scalars['String'];
  tier?: Maybe<Tier>;
  updatedAt: Scalars['AWSDateTime'];
  videoCount?: Maybe<Scalars['Float']>;
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export enum ChannelListFieldName {
  AddedAt = 'addedAt',
  CreatedAt = 'createdAt',
  Genre = 'genre',
  Name = 'name',
  ParentLabel = 'parentLabel',
  Region = 'region',
  SubLabel = 'subLabel',
  SubscriberCount = 'subscriberCount',
  Tier = 'tier',
  ViewCount = 'viewCount'
}

export type ChannelListFilters = {
  artist?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  subscribersFrom?: Maybe<Scalars['String']>;
  subscribersTo?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  viewsFrom?: Maybe<Scalars['String']>;
  viewsTo?: Maybe<Scalars['String']>;
  youtubeId?: Maybe<Scalars['String']>;
};

export type ChannelListResponse = {
  __typename?: 'ChannelListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Channel>>;
};

export type ChannelListSortOrder = {
  direction: OrderDirection;
  field: ChannelListFieldName;
};

export type ChannelResponse = {
  __typename?: 'ChannelResponse';
  item: Channel;
};

export type CheckGoogleAuthResponse = {
  __typename?: 'CheckGoogleAuthResponse';
  googleLoginUri: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type CountCampaignViewsInput = {
  id: Scalars['ID'];
  startDate: Scalars['String'];
};

export type CountCampaignViewsResponse = {
  __typename?: 'CountCampaignViewsResponse';
  counted?: Maybe<Scalars['Boolean']>;
};

export type Country = Entity & {
  __typename?: 'Country';
  code: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
};

export type CountryListResponse = {
  __typename?: 'CountryListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Country>>;
};

export type CreateArtistInput = {
  name: Scalars['String'];
};

export type CreateBatchForEndScreenReportInput = {
  data?: Maybe<Array<Maybe<ReportEndscreenDataFromS3>>>;
};

export type CreateBatchForEndScreenReportResponse = {
  __typename?: 'CreateBatchForEndScreenReportResponse';
  success: Scalars['Boolean'];
};

export type CreateCampaignEndScreenInput = {
  campaignId: Scalars['ID'];
  element: EndScreenInput;
};

export type CreateCampaignExportLinkInput = {
  filter?: Maybe<CampaignListFilters>;
  sortOrder: CampaignListSortOrder;
};

export type CreateCampaignExportLinkResponse = {
  __typename?: 'CreateCampaignExportLinkResponse';
  url: Scalars['String'];
};

export type CreateCampaignInput = {
  endDate: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type CreateCampaignStatusInput = {
  campaignId: Scalars['String'];
};

export type CreateCampaignStatusResponse = {
  __typename?: 'CreateCampaignStatusResponse';
  created: Scalars['Boolean'];
};

export type CreateCampaignVideoExportLinkInput = {
  campaignId: Scalars['String'];
  filter?: Maybe<CampaignVideoListFilters>;
  sortOrder: CampaignVideoListSortOrder;
};

export type CreateCampaignVideoExportLinkResponse = {
  __typename?: 'CreateCampaignVideoExportLinkResponse';
  url: Scalars['String'];
};

export type CreateCampaignViewsResponse = {
  __typename?: 'CreateCampaignViewsResponse';
  created?: Maybe<Scalars['Boolean']>;
};

export type CreateChannelExportLinkInput = {
  filter?: Maybe<ChannelListFilters>;
  sortOrder: ChannelListSortOrder;
};

export type CreateChannelExportLinkResponse = {
  __typename?: 'CreateChannelExportLinkResponse';
  url: Scalars['String'];
};

export type CreateChannelInput = {
  addedAt: Scalars['AWSDate'];
  artistId: Scalars['ID'];
  genreId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  playlist: Scalars['String'];
  regionId?: Maybe<Scalars['ID']>;
  subscriberCount: Scalars['String'];
  tierId?: Maybe<Scalars['ID']>;
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type CreateCountryInput = {
  code: Scalars['String'];
};

export type CreateExportLinkResponse = {
  __typename?: 'CreateExportLinkResponse';
  url: Scalars['String'];
};

export type CreateGenreInput = {
  name: Scalars['String'];
};

export type CreateLabelInput = {
  name: Scalars['String'];
};

export type CreateRegionInput = {
  name: Scalars['String'];
};

export type CreateTierInput = {
  name: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  name: Scalars['String'];
  role: Scalars['String'];
  status: UserStatus;
};

export type CreateVideoExportLinkInput = {
  campaignId: Scalars['String'];
  filters?: Maybe<GetVideoListFilters>;
};

export type CreateVideoExportLinkResponse = {
  __typename?: 'CreateVideoExportLinkResponse';
  url: Scalars['String'];
};

export type CreateVideoSelectionInput = {
  campaign?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type CreateVideoSelectionResponse = {
  __typename?: 'CreateVideoSelectionResponse';
  executed?: Maybe<Scalars['Boolean']>;
};

export type DeleteAllVideosFromCampaignInput = {
  campaignId: Scalars['ID'];
};

export type DeleteCampaignInput = {
  id: Scalars['ID'];
};

export type DeleteCampaignStatusInput = {
  campaignId: Scalars['String'];
};

export type DeleteCampaignStatusResponse = {
  __typename?: 'DeleteCampaignStatusResponse';
  removed: Scalars['Boolean'];
};

export type DeleteCampaignVideoInput = {
  campaignId: Scalars['String'];
  videoId: Scalars['String'];
};

export type DeleteCampaignVideoResponse = {
  __typename?: 'DeleteCampaignVideoResponse';
  removed: Scalars['Boolean'];
};

export type DeleteCampaignVideosListInput = {
  campaignId: Scalars['ID'];
  videoIds: Array<Scalars['String']>;
};

export type DeleteEndScreenDefault = {
  id: Scalars['ID'];
};

export type DeleteEndScreenTemplateResponse = {
  __typename?: 'DeleteEndScreenTemplateResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DeleteUserInput = {
  id: Scalars['ID'];
};

export type DeleteVideoSelectionInput = {
  campaign?: Maybe<Scalars['String']>;
  video?: Maybe<Scalars['String']>;
};

export type DeleteVideoSelectionResponse = {
  __typename?: 'DeleteVideoSelectionResponse';
  executed?: Maybe<Scalars['Boolean']>;
};

export type DeleteVideosInput = {
  youtubeIds?: Maybe<Array<Scalars['String']>>;
};

export type DeleteVideosResponse = {
  __typename?: 'DeleteVideosResponse';
  done: Scalars['Boolean'];
};

export type DuplicateCampaignInput = {
  id: Scalars['ID'];
};

export type DuplicateCampaignResponse = {
  __typename?: 'DuplicateCampaignResponse';
  done: Scalars['Boolean'];
};

export type EndCampaignsResponse = {
  __typename?: 'EndCampaignsResponse';
  requested: Scalars['String'];
};

export type EndScreenDefaultElement = Entity & {
  __typename?: 'EndScreenDefaultElement';
  createdAt: Scalars['String'];
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  thumbnail?: Maybe<EndScreenElementThumbnail>;
  top: Scalars['Float'];
  type: EndScreenElementType;
  updatedAt: Scalars['String'];
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenDefaultListInput = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenDefaultListResponse = {
  __typename?: 'EndScreenDefaultListResponse';
  items: Array<EndScreenDefaultElement>;
};

export type EndScreenElement = Entity & {
  __typename?: 'EndScreenElement';
  createdAt: Scalars['String'];
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  imageDataUrl?: Maybe<Scalars['String']>;
  isDefault: Scalars['Boolean'];
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  thumbnail?: Maybe<EndScreenElementThumbnail>;
  top: Scalars['Float'];
  type: EndScreenElementType;
  updatedAt: Scalars['String'];
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenElementInput = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  id: Scalars['ID'];
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenElementOfTemplate = {
  __typename?: 'EndScreenElementOfTemplate';
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  templateId: Scalars['ID'];
  thumbnail?: Maybe<EndScreenElementThumbnail>;
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenElementPerformance = {
  __typename?: 'EndScreenElementPerformance';
  durationMs: Scalars['Int'];
  id: Scalars['ID'];
  isDefault: Scalars['Boolean'];
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  metrics: Array<ReportEndScreenPerformance>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenElementThumbnail = {
  __typename?: 'EndScreenElementThumbnail';
  name?: Maybe<Scalars['String']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export enum EndScreenElementType {
  ExternalLink = 'EXTERNAL_LINK',
  PlaylistById = 'PLAYLIST_BY_ID',
  SubscribeChannel = 'SUBSCRIBE_CHANNEL',
  SubscribeChannelById = 'SUBSCRIBE_CHANNEL_BY_ID',
  VideoBestForViewers = 'VIDEO_BEST_FOR_VIEWERS',
  VideoById = 'VIDEO_BY_ID',
  VideoRecentUpload = 'VIDEO_RECENT_UPLOAD'
}

export type EndScreenInput = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  id: Scalars['ID'];
  imageDataUrl?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenInputWithoutId = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenTemplate = Entity & {
  __typename?: 'EndScreenTemplate';
  endscreens: Array<Maybe<EndScreenElementOfTemplate>>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EndScreenTemplateInput = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type EndScreenTemplateResponse = {
  __typename?: 'EndScreenTemplateResponse';
  count: Scalars['Int'];
  items: Array<EndScreenTemplate>;
};

export type EndScreenTemplateWithIdInput = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  height: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type Entity = {
  id: Scalars['ID'];
};

export enum ExternalLinkCta {
  CtaBuy = 'CTA_BUY',
  CtaDownload = 'CTA_DOWNLOAD',
  CtaLearnMore = 'CTA_LEARN_MORE',
  CtaOrder = 'CTA_ORDER',
  CtaShop = 'CTA_SHOP',
  CtaSignUp = 'CTA_SIGN_UP',
  CtaSupport = 'CTA_SUPPORT',
  CtaVisit = 'CTA_VISIT'
}

export type Genre = Entity & {
  __typename?: 'Genre';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type GenreListResponse = {
  __typename?: 'GenreListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Genre>>;
};

export type GetAddVideoLoadingStatusInput = {
  campaignId: Scalars['String'];
};

export type GetAllViewsResponse = {
  __typename?: 'GetAllViewsResponse';
  currentMonth: Scalars['String'];
  previousMonth: Scalars['String'];
};

export type GetArtistListInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type GetCampaignDetailsPerformanceInput = {
  campaignId: Scalars['ID'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetCampaignDetailsPerformanceResponse = {
  __typename?: 'GetCampaignDetailsPerformanceResponse';
  campaign: Campaign;
  endscreens: Array<Maybe<EndScreenElementPerformance>>;
};

export type GetCampaignInput = {
  id: Scalars['String'];
};

export type GetCampaignListInput = {
  filter?: Maybe<CampaignListFilters>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder: CampaignListSortOrder;
};

export type GetCampaignPerformanceInput = {
  campaignIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetCampaignPerformanceResponse = {
  __typename?: 'GetCampaignPerformanceResponse';
  data?: Maybe<Array<Maybe<GetCampaignPerformanceResponseData>>>;
};

export type GetCampaignPerformanceResponseData = {
  __typename?: 'GetCampaignPerformanceResponseData';
  createdAt?: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
  id: Scalars['ID'];
  metrics: Array<Maybe<ReportEndScreenPerformance>>;
  name: Scalars['String'];
  startDate: Scalars['String'];
  status: Scalars['String'];
  updatedAt: Scalars['String'];
  views: Scalars['Int'];
};

export type GetCampaignStatusInput = {
  campaignId: Scalars['String'];
};

export type GetCampaignStatusResponse = {
  __typename?: 'GetCampaignStatusResponse';
  completed: Scalars['Int'];
  failed: Scalars['Int'];
  stats?: Maybe<CampaignVideoStatus>;
  total: Scalars['Int'];
};

export type GetCampaignVideoListInput = {
  campaignId: Scalars['String'];
  filter?: Maybe<CampaignVideoListFilters>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  sortOrder: CampaignVideoListSortOrder;
};

export type GetCampaignVideosUploadSampleInput = {
  load: Scalars['String'];
};

export type GetCampaignVideosUploadSampleResponse = {
  __typename?: 'GetCampaignVideosUploadSampleResponse';
  url: Scalars['String'];
};

export type GetCampaignVideosUploadUrlInput = {
  load: Scalars['String'];
};

export type GetCampaignViewsInput = {
  campaignId: Scalars['ID'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetCampaignViewsResponse = {
  __typename?: 'GetCampaignViewsResponse';
  views: Scalars['Int'];
};

export type GetChannelInput = {
  id: Scalars['String'];
};

export type GetChannelListInput = {
  filter?: Maybe<ChannelListFilters>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  sortOrder: ChannelListSortOrder;
};

export type GetChannelPerformanceInput = {
  channelIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetChannelPerformanceResponse = {
  __typename?: 'GetChannelPerformanceResponse';
  data?: Maybe<Array<Maybe<GetChannelPerformanceResponseData>>>;
};

export type GetChannelPerformanceResponseData = {
  __typename?: 'GetChannelPerformanceResponseData';
  addedAt: Scalars['AWSDate'];
  censorship: Scalars['Boolean'];
  claimUploads: Scalars['Boolean'];
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  metrics: Array<Maybe<ReportEndScreenPerformance>>;
  monetized: Scalars['Boolean'];
  name: Scalars['String'];
  playlist: Scalars['String'];
  subscriberCount: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  videoCount?: Maybe<Scalars['Float']>;
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type GetCountryListInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type GetCountryPerformanceInput = {
  countryIds: Array<Scalars['ID']>;
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type GetCountryPerformanceResponse = {
  __typename?: 'GetCountryPerformanceResponse';
  data?: Maybe<Array<Maybe<GetCountryPerformanceResponseData>>>;
};

export type GetCountryPerformanceResponseData = {
  __typename?: 'GetCountryPerformanceResponseData';
  code: Scalars['String'];
  id: Scalars['ID'];
  metrics: Array<Maybe<ReportEndScreenPerformance>>;
};

export type GetEndScreenDefaultThumbnailInput = {
  endscreenId: Scalars['String'];
  endscreenType: EndScreenElementType;
  youtubeId: Scalars['String'];
};

export type GetEndScreenDefaultThumbnailResponse = {
  __typename?: 'GetEndScreenDefaultThumbnailResponse';
  endscreenId: Scalars['String'];
  name: Scalars['String'];
  thumbnail: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type GetEndScreenStatsResponse = {
  __typename?: 'GetEndScreenStatsResponse';
  currentMonth: Scalars['Float'];
  previousMonth: Scalars['Float'];
};

export type GetEndScreenTemplateThumbnailInput = {
  endscreenId: Scalars['String'];
  endscreenType: EndScreenElementType;
  youtubeId: Scalars['String'];
};

export type GetEndScreenTemplateThumbnailResponse = {
  __typename?: 'GetEndScreenTemplateThumbnailResponse';
  endscreenId: Scalars['String'];
  name: Scalars['String'];
  thumbnail: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type GetEndScreenThumbnailInput = {
  endscreenId: Scalars['String'];
  endscreenType: EndScreenElementType;
  youtubeId: Scalars['String'];
};

export type GetEndScreenThumbnailResponse = {
  __typename?: 'GetEndScreenThumbnailResponse';
  endscreenId: Scalars['String'];
  name: Scalars['String'];
  thumbnail: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type GetGenreListInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
};

export type GetGenrePerformanceInput = {
  endDate: Scalars['String'];
  genreIds: Array<Scalars['ID']>;
  startDate: Scalars['String'];
};

export type GetGenrePerformanceResponse = {
  __typename?: 'GetGenrePerformanceResponse';
  data?: Maybe<Array<Maybe<GetGenrePerformanceResponseData>>>;
};

export type GetGenrePerformanceResponseData = {
  __typename?: 'GetGenrePerformanceResponseData';
  id: Scalars['ID'];
  metrics: Array<Maybe<ReportEndScreenPerformance>>;
  name: Scalars['String'];
};

export type GetLabelListInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type GetRegionListInput = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};

export type GetTaskResponse = {
  __typename?: 'GetTaskResponse';
  exists: Scalars['Boolean'];
};

export type GetUserInput = {
  id: Scalars['ID'];
};

export type GetUserListInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type GetVideoListFilters = {
  applied?: Maybe<Scalars['Boolean']>;
  artist?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  genre?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentLabel?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type GetVideoListInput = {
  campaignId: Scalars['String'];
  filters?: Maybe<GetVideoListFilters>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GetVideoPerformanceInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
  videoIds: Array<Scalars['ID']>;
};

export type GetVideoPerformanceResponse = {
  __typename?: 'GetVideoPerformanceResponse';
  data?: Maybe<Array<Maybe<GetVideoPerformanceResponseData>>>;
};

export type GetVideoPerformanceResponseData = {
  __typename?: 'GetVideoPerformanceResponseData';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  likesCount: Scalars['String'];
  metrics: Array<Maybe<ReportEndScreenPerformance>>;
  name: Scalars['String'];
  thumbnail: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type GetVideosFilters = {
  name?: Maybe<Scalars['String']>;
};

export type GetVideosInput = {
  filters?: Maybe<GetVideosFilters>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type IncrementCampaignCountersInput = {
  id: Scalars['ID'];
  success?: Maybe<Scalars['Boolean']>;
};

export type IncrementCampaignCountersResponse = {
  __typename?: 'IncrementCampaignCountersResponse';
  campaignId: Scalars['String'];
  completed: Scalars['Int'];
  failed: Scalars['Int'];
  total: Scalars['Int'];
};

export type Label = Entity & {
  __typename?: 'Label';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type LabelListResponse = {
  __typename?: 'LabelListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Label>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAllVideos?: Maybe<AddAllVideosResponse>;
  addManyVideosToCampaign?: Maybe<AddAllVideosResponse>;
  addSelectedVideos?: Maybe<AddVideosResponse>;
  addSnowflakeVideoChunk?: Maybe<AddSnowflakeVideoChunkResponse>;
  addVideoChunkToCampaign?: Maybe<AddAllVideosResponse>;
  applyAllCampaignVideoEndscreens?: Maybe<ApplyCampaignResponse>;
  applyEndScreensToCampaignVideo?: Maybe<ApplyEndScreensToCampaignVideoResponse>;
  changeGenreForChannels?: Maybe<ChangeGenreForChannelsResponse>;
  changeRegionForChannels?: Maybe<ChangeRegionForChannelsResponse>;
  countCampaignViews?: Maybe<CountCampaignViewsResponse>;
  createArtist?: Maybe<Artist>;
  createBatchForEndScreenReport?: Maybe<CreateBatchForEndScreenReportResponse>;
  createCampaign?: Maybe<Campaign>;
  createCampaignEndScreen?: Maybe<EndScreenElement>;
  createCampaignExportLink?: Maybe<CreateCampaignExportLinkResponse>;
  createCampaignPerformanceExportLink?: Maybe<CreateExportLinkResponse>;
  createCampaignStatus?: Maybe<CreateCampaignStatusResponse>;
  createCampaignVideoExportLink?: Maybe<CreateCampaignVideoExportLinkResponse>;
  createCampaignViews?: Maybe<CreateCampaignViewsResponse>;
  createChannel?: Maybe<Channel>;
  createChannelExportLink?: Maybe<CreateChannelExportLinkResponse>;
  createChannelPerformanceExportLink?: Maybe<CreateExportLinkResponse>;
  createCountry?: Maybe<Country>;
  createCountryPerformanceExportLink?: Maybe<CreateExportLinkResponse>;
  createEndScreensTemplate?: Maybe<EndScreenTemplate>;
  createGenre?: Maybe<Genre>;
  createGenrePerformanceExportLink?: Maybe<CreateExportLinkResponse>;
  createLabel?: Maybe<Label>;
  createRegion?: Maybe<Region>;
  createTask?: Maybe<TaskResponse>;
  createTier?: Maybe<Tier>;
  createUser?: Maybe<User>;
  createVideoExportLink?: Maybe<CreateVideoExportLinkResponse>;
  createVideoPerformanceExportLink?: Maybe<CreateExportLinkResponse>;
  createVideoSelection?: Maybe<CreateVideoSelectionResponse>;
  deleteAllVideosFromCampaign?: Maybe<DeleteCampaignVideoResponse>;
  deleteArtist?: Maybe<Artist>;
  deleteCampaign?: Maybe<Campaign>;
  deleteCampaignEndScreen?: Maybe<EndScreenElement>;
  deleteCampaignStatus?: Maybe<DeleteCampaignStatusResponse>;
  deleteCampaignVideo?: Maybe<DeleteCampaignVideoResponse>;
  deleteCampaignVideos?: Maybe<DeleteCampaignVideoResponse>;
  deleteChannel?: Maybe<Channel>;
  deleteCountry?: Maybe<Country>;
  deleteEndScreenDefault?: Maybe<EndScreenDefaultElement>;
  deleteEndScreensTemplate?: Maybe<DeleteEndScreenTemplateResponse>;
  deleteGenre?: Maybe<Genre>;
  deleteLabel?: Maybe<Label>;
  deleteRegion?: Maybe<Region>;
  deleteTask?: Maybe<TaskResponse>;
  deleteTier?: Maybe<Tier>;
  deleteUser?: Maybe<User>;
  deleteVideoSelection?: Maybe<DeleteVideoSelectionResponse>;
  deleteVideos?: Maybe<DeleteVideosResponse>;
  duplicateCampaign?: Maybe<DuplicateCampaignResponse>;
  endAllCampaignVideoEndscreens?: Maybe<ApplyCampaignResponse>;
  endCampaigns?: Maybe<EndCampaignsResponse>;
  incrementCampaignCounters?: Maybe<IncrementCampaignCountersResponse>;
  refreshGoogleTokens?: Maybe<RefreshGoogleTokensResponse>;
  saveGoogleTokens?: Maybe<SaveGoogleTokensResponse>;
  setCampaignVideoViews?: Maybe<SetCampaignVideoViewsResponse>;
  startCampaigns?: Maybe<StartCampaignsResponse>;
  streamReportFromS3?: Maybe<StreamReportFromS3Response>;
  streamReportToS3?: Maybe<StreamReportToS3Response>;
  syncChannel?: Maybe<SyncChannelResponse>;
  syncChannelVideos?: Maybe<SyncChannelVideosResponse>;
  syncChannels?: Maybe<SyncChannelResponse>;
  syncChannelsBatch?: Maybe<SyncChannelResponse>;
  syncChannelsSupervisor?: Maybe<SyncChannelResponse>;
  syncReports?: Maybe<SyncReportsResponse>;
  syncSfVideosFromOneChannel?: Maybe<SyncChannelVideosResponse>;
  syncVideosFromOneChannel?: Maybe<SyncChannelVideosResponse>;
  toggleAddVideoLoadingStatus?: Maybe<ToggleAddVideoLoadingStatusResponse>;
  toggleVideoSelections?: Maybe<CreateVideoSelectionResponse>;
  updateCampaign?: Maybe<Campaign>;
  updateCampaignEndScreens?: Maybe<CampaignEndScreensResponse>;
  updateCampaignProcessingStatus?: Maybe<CampaignResponse>;
  updateCampaignStatus?: Maybe<UpdateCampaignStatusResponse>;
  updateCampaignVideoProcessingStatus?: Maybe<CampaignVideoStatusResponse>;
  updateCampaignViewsCounter?: Maybe<CountCampaignViewsResponse>;
  updateChannel?: Maybe<Channel>;
  updateEndScreenDefaultList?: Maybe<EndScreenDefaultListResponse>;
  updateEndScreenOfTemplate?: Maybe<EndScreenElementOfTemplate>;
  updateEndScreensTemplate?: Maybe<EndScreenTemplate>;
  updateUser?: Maybe<User>;
  updateYoutubeCredentials?: Maybe<YoutubeCredentialsResponse>;
  uploadVideoList?: Maybe<UploadVideoListResponse>;
};


export type MutationAddAllVideosArgs = {
  input: AddAllVideosInput;
};


export type MutationAddManyVideosToCampaignArgs = {
  input: AddAllVideosInput;
};


export type MutationAddSelectedVideosArgs = {
  input: AddSelectedVideos;
};


export type MutationAddSnowflakeVideoChunkArgs = {
  input: AddSnowflakeVideoChunkInput;
};


export type MutationAddVideoChunkToCampaignArgs = {
  input: AddAllVideosInput;
};


export type MutationApplyAllCampaignVideoEndscreensArgs = {
  input?: Maybe<ApplyAllCampaignVideoEndscreensInput>;
};


export type MutationApplyEndScreensToCampaignVideoArgs = {
  input: ApplyEndScreensToCampaignVideoInput;
};


export type MutationChangeGenreForChannelsArgs = {
  input: ChangeGenreForChannelsInput;
};


export type MutationChangeRegionForChannelsArgs = {
  input: ChangeRegionForChannelsInput;
};


export type MutationCountCampaignViewsArgs = {
  input: CountCampaignViewsInput;
};


export type MutationCreateArtistArgs = {
  input: CreateArtistInput;
};


export type MutationCreateBatchForEndScreenReportArgs = {
  input: CreateBatchForEndScreenReportInput;
};


export type MutationCreateCampaignArgs = {
  input: CreateCampaignInput;
};


export type MutationCreateCampaignEndScreenArgs = {
  input?: Maybe<CreateCampaignEndScreenInput>;
};


export type MutationCreateCampaignExportLinkArgs = {
  input: CreateCampaignExportLinkInput;
};


export type MutationCreateCampaignPerformanceExportLinkArgs = {
  input: GetCampaignPerformanceInput;
};


export type MutationCreateCampaignStatusArgs = {
  input: CreateCampaignStatusInput;
};


export type MutationCreateCampaignVideoExportLinkArgs = {
  input: CreateCampaignVideoExportLinkInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationCreateChannelExportLinkArgs = {
  input: CreateChannelExportLinkInput;
};


export type MutationCreateChannelPerformanceExportLinkArgs = {
  input: GetChannelPerformanceInput;
};


export type MutationCreateCountryArgs = {
  input: CreateCountryInput;
};


export type MutationCreateCountryPerformanceExportLinkArgs = {
  input: GetCountryPerformanceInput;
};


export type MutationCreateEndScreensTemplateArgs = {
  input: CreateEndScreensTemplateInput;
};


export type MutationCreateGenreArgs = {
  input: CreateGenreInput;
};


export type MutationCreateGenrePerformanceExportLinkArgs = {
  input: GetGenrePerformanceInput;
};


export type MutationCreateLabelArgs = {
  input: CreateLabelInput;
};


export type MutationCreateRegionArgs = {
  input: CreateRegionInput;
};


export type MutationCreateTierArgs = {
  input: CreateTierInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateVideoExportLinkArgs = {
  input: CreateVideoExportLinkInput;
};


export type MutationCreateVideoPerformanceExportLinkArgs = {
  input: GetVideoPerformanceInput;
};


export type MutationCreateVideoSelectionArgs = {
  input: CreateVideoSelectionInput;
};


export type MutationDeleteAllVideosFromCampaignArgs = {
  input: DeleteAllVideosFromCampaignInput;
};


export type MutationDeleteArtistArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCampaignArgs = {
  input: DeleteCampaignInput;
};


export type MutationDeleteCampaignEndScreenArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCampaignStatusArgs = {
  input: DeleteCampaignStatusInput;
};


export type MutationDeleteCampaignVideoArgs = {
  input: DeleteCampaignVideoInput;
};


export type MutationDeleteCampaignVideosArgs = {
  input: DeleteCampaignVideosListInput;
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCountryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEndScreenDefaultArgs = {
  input: DeleteEndScreenDefault;
};


export type MutationDeleteEndScreensTemplateArgs = {
  input: DeleteEndScreensTemplateInput;
};


export type MutationDeleteGenreArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLabelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRegionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDeleteVideoSelectionArgs = {
  input: DeleteVideoSelectionInput;
};


export type MutationDeleteVideosArgs = {
  input: DeleteVideosInput;
};


export type MutationDuplicateCampaignArgs = {
  input: DuplicateCampaignInput;
};


export type MutationEndAllCampaignVideoEndscreensArgs = {
  input?: Maybe<EndAllCampaignVideoEndscreensInput>;
};


export type MutationIncrementCampaignCountersArgs = {
  input: IncrementCampaignCountersInput;
};


export type MutationSaveGoogleTokensArgs = {
  input: SaveGoogleTokensInput;
};


export type MutationSetCampaignVideoViewsArgs = {
  input: SetCampaignVideoViewsInput;
};


export type MutationStreamReportFromS3Args = {
  input: StreamReportFromS3Input;
};


export type MutationStreamReportToS3Args = {
  input: StreamReportToS3Input;
};


export type MutationSyncChannelArgs = {
  input: SyncChannelInput;
};


export type MutationSyncChannelsBatchArgs = {
  input: SyncChannelsBatchInput;
};


export type MutationSyncSfVideosFromOneChannelArgs = {
  input: SyncVideosFromOneChannelInput;
};


export type MutationSyncVideosFromOneChannelArgs = {
  input: SyncVideosFromOneChannelInput;
};


export type MutationToggleAddVideoLoadingStatusArgs = {
  input: ToggleAddVideoLoadingStatusInput;
};


export type MutationToggleVideoSelectionsArgs = {
  input: ToggleVideoSelectionInput;
};


export type MutationUpdateCampaignArgs = {
  input: UpdateCampaignInput;
};


export type MutationUpdateCampaignEndScreensArgs = {
  input?: Maybe<UpdateCampaignEndScreensInput>;
};


export type MutationUpdateCampaignProcessingStatusArgs = {
  input: UpdateCampaignProcessingStatusInput;
};


export type MutationUpdateCampaignStatusArgs = {
  input: UpdateCampaignStatusInput;
};


export type MutationUpdateCampaignVideoProcessingStatusArgs = {
  input: UpdateCampaignVideoProcessingStatusInput;
};


export type MutationUpdateCampaignViewsCounterArgs = {
  input: UpdateCampaignViewsCounterInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationUpdateEndScreenDefaultListArgs = {
  input: UpdateEndScreenDefaultListInput;
};


export type MutationUpdateEndScreenOfTemplateArgs = {
  input: UpdateEndScreenOfTemplateInput;
};


export type MutationUpdateEndScreensTemplateArgs = {
  input: UpdateEndScreensTemplateInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateYoutubeCredentialsArgs = {
  input: UpdateYoutubeCredentialsInput;
};


export type MutationUploadVideoListArgs = {
  input: UploadVideoListInput;
};

export type OnUpdateCampaignStatusInput = {
  campaignId: Scalars['String'];
};

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type Query = {
  __typename?: 'Query';
  checkGoogleAuth?: Maybe<CheckGoogleAuthResponse>;
  getAddVideoLoadingStatus?: Maybe<AddAllVideosResponse>;
  getAllViews?: Maybe<GetAllViewsResponse>;
  getArtistList?: Maybe<ArtistListResponse>;
  getCampaign?: Maybe<CampaignResponse>;
  getCampaignDetailsPerformance?: Maybe<GetCampaignDetailsPerformanceResponse>;
  getCampaignEndScreens?: Maybe<CampaignEndScreensResponse>;
  getCampaignList?: Maybe<CampaignListResponse>;
  getCampaignPerformance?: Maybe<GetCampaignPerformanceResponse>;
  getCampaignStatus?: Maybe<GetCampaignStatusResponse>;
  getCampaignVideosFullList?: Maybe<CampaignVideoListResponse>;
  getCampaignVideosUploadSample?: Maybe<GetCampaignVideosUploadSampleResponse>;
  getCampaignVideosUploadURL?: Maybe<GetCampaignVideosUploadUrlResponse>;
  getCampaignViews?: Maybe<GetCampaignViewsResponse>;
  getChannel?: Maybe<ChannelResponse>;
  getChannelList?: Maybe<ChannelListResponse>;
  getChannelPerformance?: Maybe<GetChannelPerformanceResponse>;
  getChannelSyncStatus?: Maybe<SyncStatusResponse>;
  getCountryList?: Maybe<CountryListResponse>;
  getCountryPerformance?: Maybe<GetCountryPerformanceResponse>;
  getEndScreenAvgCTR?: Maybe<GetEndScreenStatsResponse>;
  getEndScreenDefaultList?: Maybe<EndScreenDefaultListResponse>;
  getEndScreenDefaultThumbnail?: Maybe<GetEndScreenDefaultThumbnailResponse>;
  getEndScreenOfTemplate?: Maybe<EndScreenElementOfTemplate>;
  getEndScreenTemplate?: Maybe<EndScreenTemplate>;
  getEndScreenTemplateList?: Maybe<EndScreenTemplateResponse>;
  getEndScreenTemplateThumbnail?: Maybe<GetEndScreenTemplateThumbnailResponse>;
  getEndScreenThumbnail?: Maybe<GetEndScreenThumbnailResponse>;
  getEndScreenViews?: Maybe<GetEndScreenStatsResponse>;
  getEndScreenViewsAvg?: Maybe<GetEndScreenStatsResponse>;
  getGenreFullList?: Maybe<GenreListResponse>;
  getGenrePerformance?: Maybe<GetGenrePerformanceResponse>;
  getLabelList?: Maybe<LabelListResponse>;
  getRegionFullList?: Maybe<RegionListResponse>;
  getTierFullList?: Maybe<TierListResponse>;
  getUser?: Maybe<User>;
  getUserList?: Maybe<UserListResponse>;
  getVideoPerformance?: Maybe<GetVideoPerformanceResponse>;
  getVideos?: Maybe<VideoListResponse>;
  getVideosFullList?: Maybe<VideoListResponse>;
  getYoutubeCredentials?: Maybe<YoutubeCredentialsResponse>;
  reportTest?: Maybe<Scalars['String']>;
  taskExists?: Maybe<GetTaskResponse>;
};


export type QueryGetAddVideoLoadingStatusArgs = {
  input: GetAddVideoLoadingStatusInput;
};


export type QueryGetArtistListArgs = {
  input: GetArtistListInput;
};


export type QueryGetCampaignArgs = {
  input?: Maybe<GetCampaignInput>;
};


export type QueryGetCampaignDetailsPerformanceArgs = {
  input: GetCampaignDetailsPerformanceInput;
};


export type QueryGetCampaignEndScreensArgs = {
  campaignId: Scalars['ID'];
};


export type QueryGetCampaignListArgs = {
  input: GetCampaignListInput;
};


export type QueryGetCampaignPerformanceArgs = {
  input: GetCampaignPerformanceInput;
};


export type QueryGetCampaignStatusArgs = {
  input: GetCampaignStatusInput;
};


export type QueryGetCampaignVideosFullListArgs = {
  input: GetCampaignVideoListInput;
};


export type QueryGetCampaignVideosUploadSampleArgs = {
  input: GetCampaignVideosUploadSampleInput;
};


export type QueryGetCampaignVideosUploadUrlArgs = {
  input: GetCampaignVideosUploadUrlInput;
};


export type QueryGetCampaignViewsArgs = {
  input: GetCampaignViewsInput;
};


export type QueryGetChannelArgs = {
  input?: Maybe<GetChannelInput>;
};


export type QueryGetChannelListArgs = {
  input?: Maybe<GetChannelListInput>;
};


export type QueryGetChannelPerformanceArgs = {
  input?: Maybe<GetChannelPerformanceInput>;
};


export type QueryGetCountryListArgs = {
  input: GetCountryListInput;
};


export type QueryGetCountryPerformanceArgs = {
  input?: Maybe<GetCountryPerformanceInput>;
};


export type QueryGetEndScreenDefaultThumbnailArgs = {
  input: GetEndScreenDefaultThumbnailInput;
};


export type QueryGetEndScreenOfTemplateArgs = {
  input: GetEndScreenOfTemplateInput;
};


export type QueryGetEndScreenTemplateArgs = {
  input: GetEndScreenTemplateInput;
};


export type QueryGetEndScreenTemplateListArgs = {
  input: GetEndScreenTemplateListInput;
};


export type QueryGetEndScreenTemplateThumbnailArgs = {
  input: GetEndScreenTemplateThumbnailInput;
};


export type QueryGetEndScreenThumbnailArgs = {
  input: GetEndScreenThumbnailInput;
};


export type QueryGetGenreFullListArgs = {
  input?: Maybe<GetGenreListInput>;
};


export type QueryGetGenrePerformanceArgs = {
  input: GetGenrePerformanceInput;
};


export type QueryGetLabelListArgs = {
  input: GetLabelListInput;
};


export type QueryGetRegionFullListArgs = {
  input?: Maybe<GetRegionListInput>;
};


export type QueryGetUserArgs = {
  input: GetUserInput;
};


export type QueryGetUserListArgs = {
  input: GetUserListInput;
};


export type QueryGetVideoPerformanceArgs = {
  input: GetVideoPerformanceInput;
};


export type QueryGetVideosArgs = {
  input: GetVideosInput;
};


export type QueryGetVideosFullListArgs = {
  input: GetVideoListInput;
};


export type QueryTaskExistsArgs = {
  input: TaskExistsInput;
};

export type RefreshGoogleTokensResponse = {
  __typename?: 'RefreshGoogleTokensResponse';
  refreshed: Scalars['Boolean'];
};

export type Region = Entity & {
  __typename?: 'Region';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type RegionListResponse = {
  __typename?: 'RegionListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Region>>;
};

export type ReportEndScreenPerformance = {
  __typename?: 'ReportEndScreenPerformance';
  clicksCount: Scalars['Int'];
  ctr: Scalars['Float'];
  dayCovered: Scalars['String'];
  impressionsCount: Scalars['Int'];
};

export type ReportEndscreenDataFromS3 = {
  country_code: Scalars['String'];
  date: Scalars['String'];
  end_screen_element_clicks: Scalars['String'];
  end_screen_element_id: Scalars['String'];
  end_screen_element_impressions: Scalars['String'];
  video_id: Scalars['String'];
};

export type SaveGoogleTokensInput = {
  code: Scalars['String'];
};

export type SaveGoogleTokensResponse = {
  __typename?: 'SaveGoogleTokensResponse';
  saved: Scalars['Boolean'];
};

export type SetCampaignVideoViewsInput = {
  campaignId?: Maybe<Scalars['String']>;
  dayCovered?: Maybe<Scalars['String']>;
  youtubeIds: Array<Maybe<Scalars['String']>>;
};

export type SetCampaignVideoViewsResponse = {
  __typename?: 'SetCampaignVideoViewsResponse';
  counted?: Maybe<Scalars['Boolean']>;
};

export type SfVideo = {
  channel: Scalars['String'];
  name: Scalars['String'];
  thumbnail: Scalars['String'];
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type StartCampaignsResponse = {
  __typename?: 'StartCampaignsResponse';
  requested: Scalars['String'];
};

export type StreamReportFromS3Input = {
  reportId: Scalars['String'];
};

export type StreamReportFromS3Response = {
  __typename?: 'StreamReportFromS3Response';
  count: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type StreamReportToS3Input = {
  createTime: Scalars['String'];
  downloadUrl: Scalars['String'];
  endTime: Scalars['String'];
  id: Scalars['String'];
  jobId: Scalars['String'];
  reportTypeId: Scalars['String'];
  startTime: Scalars['String'];
};

export type StreamReportToS3Response = {
  __typename?: 'StreamReportToS3Response';
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateTask?: Maybe<TaskResponse>;
  onDeleteTask?: Maybe<TaskResponse>;
  onIncrementCampaignCounters?: Maybe<IncrementCampaignCountersResponse>;
  onUpdateAddVideoLoadingStatus?: Maybe<ToggleAddVideoLoadingStatusResponse>;
  onUpdateCampaignProcessingStatus?: Maybe<CampaignResponse>;
  onUpdateCampaignStatus?: Maybe<UpdateCampaignStatusResponse>;
  onUpdateCampaignVideoProcessingStatus?: Maybe<CampaignVideoStatusResponse>;
};

export type SyncChannelInput = {
  channel?: Maybe<SyncChannelItemInput>;
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
};

export type SyncChannelItemInput = {
  ACTIVE?: Maybe<Scalars['String']>;
  CHANNEL_ID: Scalars['String'];
  GENRE?: Maybe<Scalars['String']>;
  GPS_ALLOWED?: Maybe<Scalars['String']>;
  LEGACY?: Maybe<Scalars['String']>;
  LINKED_DATE?: Maybe<Scalars['String']>;
  MONETIZE_UPLOADS?: Maybe<Scalars['Boolean']>;
  NEW?: Maybe<Scalars['String']>;
  NOTES?: Maybe<Scalars['String']>;
  PARENT_LABEL?: Maybe<Scalars['String']>;
  PUBLIC_NAME?: Maybe<Scalars['String']>;
  REGION?: Maybe<Scalars['String']>;
  STRAY?: Maybe<Scalars['String']>;
  SUB_LABEL?: Maybe<Scalars['String']>;
  UNLINKED?: Maybe<Scalars['String']>;
  YOUTUBE_SPECIALIST_EMAIL?: Maybe<Scalars['String']>;
  YOUTUBE_SPECIALIST_NAME?: Maybe<Scalars['String']>;
};

export type SyncChannelResponse = {
  __typename?: 'SyncChannelResponse';
  requested: Scalars['Boolean'];
};

export type SyncChannelVideosInput = {
  start?: Maybe<Scalars['Int']>;
};

export type SyncChannelVideosResponse = {
  __typename?: 'SyncChannelVideosResponse';
  created: Scalars['Boolean'];
};

export type SyncChannelsBatchInput = {
  channels?: Maybe<Array<Maybe<SyncChannelItemInput>>>;
  isFirst: Scalars['Boolean'];
  isLast: Scalars['Boolean'];
};

export type SyncReportsInput = {
  reportTypeId: Scalars['String'];
};

export type SyncReportsResponse = {
  __typename?: 'SyncReportsResponse';
  success: Scalars['Boolean'];
};

export type SyncStatusResponse = {
  __typename?: 'SyncStatusResponse';
  loading: Scalars['Boolean'];
};

export type SyncVideosFromOneChannelInput = {
  id: Scalars['ID'];
};

export type TaskExistsInput = {
  name: Scalars['String'];
};

export type TaskResponse = {
  __typename?: 'TaskResponse';
  name: Scalars['String'];
};

export type Tier = Entity & {
  __typename?: 'Tier';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type TierListResponse = {
  __typename?: 'TierListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<Tier>>;
};

export type ToggleAddVideoLoadingStatusInput = {
  campaignId: Scalars['String'];
  requested: Scalars['Boolean'];
};

export type ToggleAddVideoLoadingStatusResponse = {
  __typename?: 'ToggleAddVideoLoadingStatusResponse';
  campaignId: Scalars['String'];
  requested: Scalars['Boolean'];
};

export type ToggleVideoSelectionInput = {
  campaignId?: Maybe<Scalars['String']>;
  videoIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateCampaignEndScreensInput = {
  campaignId: Scalars['ID'];
  completive: Array<Maybe<EndScreenInput>>;
  operative: Array<Maybe<EndScreenInput>>;
};

export type UpdateCampaignInput = {
  endDate: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  startDate: Scalars['String'];
};

export type UpdateCampaignProcessingStatusInput = {
  id: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type UpdateCampaignStatusInput = {
  campaignId: Scalars['String'];
  completed?: Maybe<Scalars['Int']>;
  failed?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type UpdateCampaignStatusResponse = {
  __typename?: 'UpdateCampaignStatusResponse';
  campaignId: Scalars['String'];
  completed: Scalars['Int'];
  failed: Scalars['Int'];
  total: Scalars['Int'];
};

export type UpdateCampaignVideoProcessingStatusInput = {
  campaignId: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
  videoId: Scalars['String'];
};

export type UpdateCampaignViewsCounterInput = {
  campaignId: Scalars['ID'];
};

export type UpdateChannelInput = {
  id: Scalars['String'];
  prioritized?: Maybe<Scalars['Boolean']>;
  tier?: Maybe<Scalars['String']>;
};

export type UpdateEndScreenDefaultListInput = {
  elements: Array<Maybe<EndScreenDefaultListInput>>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  role: UserRole;
  status: UserStatus;
};

export type UploadVideoListInput = {
  campaignId: Scalars['ID'];
  youtubeIds: Array<Maybe<Scalars['String']>>;
};

export type UploadVideoListResponse = {
  __typename?: 'UploadVideoListResponse';
  uploaded: Scalars['Boolean'];
};

export type User = Entity & {
  __typename?: 'User';
  createdAt: Scalars['AWSDateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  lastAction: Scalars['AWSDateTime'];
  name: Scalars['String'];
  role: UserRole;
  status: UserStatus;
  updatedAt: Scalars['AWSDateTime'];
};

export type UserListResponse = {
  __typename?: 'UserListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<User>>;
};

export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
  Viewer = 'viewer'
}

export enum UserStatus {
  Active = 'active',
  Deactivated = 'deactivated'
}

export type VideoItem = {
  __typename?: 'VideoItem';
  applied?: Maybe<Array<Maybe<Campaign>>>;
  artist: Artist;
  campaign?: Maybe<Campaign>;
  channel: Channel;
  createdAt: Scalars['AWSDateTime'];
  genre?: Maybe<Genre>;
  id: Scalars['ID'];
  likesCount: Scalars['String'];
  name: Scalars['String'];
  parentLabel?: Maybe<Label>;
  region?: Maybe<Region>;
  selected: Scalars['Boolean'];
  thumbnail: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  viewCount: Scalars['String'];
  youtubeId: Scalars['String'];
};

export type VideoListResponse = {
  __typename?: 'VideoListResponse';
  count: Scalars['Int'];
  items: Array<Maybe<VideoItem>>;
};

export type YoutubeCredentialsResponse = {
  __typename?: 'YoutubeCredentialsResponse';
  apisid: Scalars['String'];
  hsid: Scalars['String'];
  info: Scalars['String'];
  sapisid: Scalars['String'];
  sid: Scalars['String'];
  ssid: Scalars['String'];
  token: Scalars['String'];
};

export type ApplyAllCampaignVideoEndscreensInput = {
  id: Scalars['ID'];
};

export type CreateEndScreensTemplateInput = {
  elements: Array<EndScreenTemplateInput>;
  name: Scalars['String'];
};

export type DeleteEndScreensTemplateInput = {
  endScreenTemplateId: Scalars['String'];
};

export type EndAllCampaignVideoEndscreensInput = {
  id: Scalars['ID'];
};

export type GetCampaignVideosUploadUrlResponse = {
  __typename?: 'getCampaignVideosUploadURLResponse';
  url: Scalars['String'];
};

export type GetEndScreenOfTemplateInput = {
  endScreenId: Scalars['ID'];
};

export type GetEndScreenTemplateInput = {
  templateId: Scalars['ID'];
};

export type GetEndScreenTemplateListInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type UpdateEndScreenOfTemplateInput = {
  durationMs: Scalars['Int'];
  externalLinkCTA?: Maybe<ExternalLinkCta>;
  id: Scalars['ID'];
  imageDataUrl?: Maybe<Scalars['String']>;
  left: Scalars['Float'];
  message?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  templateId: Scalars['ID'];
  top: Scalars['Float'];
  type: EndScreenElementType;
  urlId?: Maybe<Scalars['String']>;
  width: Scalars['Float'];
};

export type UpdateEndScreensTemplateInput = {
  elements: Array<EndScreenTemplateWithIdInput>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateYoutubeCredentialsInput = {
  apisid: Scalars['String'];
  hsid: Scalars['String'];
  info: Scalars['String'];
  sapisid: Scalars['String'];
  sid: Scalars['String'];
  ssid: Scalars['String'];
  token: Scalars['String'];
};

export type GetCampaignVideosListQueryVariables = Exact<{
  input: GetCampaignVideoListInput;
}>;


export type GetCampaignVideosListQuery = { __typename?: 'Query', getCampaignVideosFullList?: { __typename?: 'CampaignVideoListResponse', count: number, items: Array<{ __typename?: 'CampaignVideoItem', id: string, createdAt: any, name: string, thumbnail: string, updatedAt: any, viewCount: string, status: string, youtubeId: string, likesCount: string, reason?: string | null | undefined, channel: { __typename?: 'Channel', name: string } } | null | undefined> } | null | undefined };

export type GetCampaignVideosUploadSampleQueryVariables = Exact<{
  input: GetCampaignVideosUploadSampleInput;
}>;


export type GetCampaignVideosUploadSampleQuery = { __typename?: 'Query', getCampaignVideosUploadSample?: { __typename?: 'GetCampaignVideosUploadSampleResponse', url: string } | null | undefined };

export type CampaignWithRelationsFragment = { __typename?: 'CampaignWithRelations', id: string, name: string, createdAt: string, startDate: string, endDate: string, updatedAt: string, views: number, status: string, endScreens: Array<{ __typename?: 'EndScreenElement', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined, isDefault: boolean, createdAt: string, updatedAt: string, thumbnail?: { __typename?: 'EndScreenElementThumbnail', name?: string | null | undefined, thumbnail?: string | null | undefined } | null | undefined }> };

export type EndScreenElementOfTemplateFragment = { __typename?: 'EndScreenElementOfTemplate', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, templateId: string, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined };

export type EndScreenTemplateFragment = { __typename?: 'EndScreenTemplate', id: string, name: string, endscreens: Array<{ __typename?: 'EndScreenElementOfTemplate', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, templateId: string, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined } | null | undefined> };

export type AddAllVideosMutationVariables = Exact<{
  input: AddAllVideosInput;
}>;


export type AddAllVideosMutation = { __typename?: 'Mutation', addAllVideos?: { __typename?: 'AddAllVideosResponse', requested: boolean } | null | undefined };

export type AddManyVideosToCampaignMutationVariables = Exact<{
  input: AddAllVideosInput;
}>;


export type AddManyVideosToCampaignMutation = { __typename?: 'Mutation', addManyVideosToCampaign?: { __typename?: 'AddAllVideosResponse', requested: boolean } | null | undefined };

export type AddSelectedVideosMutationVariables = Exact<{
  input: AddSelectedVideos;
}>;


export type AddSelectedVideosMutation = { __typename?: 'Mutation', addSelectedVideos?: { __typename?: 'AddVideosResponse', requested: boolean } | null | undefined };

export type ApplyAllCampaignVideoEndscreensMutationVariables = Exact<{
  input: ApplyAllCampaignVideoEndscreensInput;
}>;


export type ApplyAllCampaignVideoEndscreensMutation = { __typename?: 'Mutation', applyAllCampaignVideoEndscreens?: { __typename?: 'ApplyCampaignResponse', id: string } | null | undefined };

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;


export type CreateCampaignMutation = { __typename?: 'Mutation', createCampaign?: { __typename?: 'Campaign', id: string } | null | undefined };

export type CreateCampaignExportLinkMutationVariables = Exact<{
  input: CreateCampaignExportLinkInput;
}>;


export type CreateCampaignExportLinkMutation = { __typename?: 'Mutation', createCampaignExportLink?: { __typename?: 'CreateCampaignExportLinkResponse', url: string } | null | undefined };

export type CreateCampaignPerformanceExportLinkMutationVariables = Exact<{
  input: GetCampaignPerformanceInput;
}>;


export type CreateCampaignPerformanceExportLinkMutation = { __typename?: 'Mutation', createCampaignPerformanceExportLink?: { __typename?: 'CreateExportLinkResponse', url: string } | null | undefined };

export type CreateCampaignVideoExportLinkMutationVariables = Exact<{
  input: CreateCampaignVideoExportLinkInput;
}>;


export type CreateCampaignVideoExportLinkMutation = { __typename?: 'Mutation', createCampaignVideoExportLink?: { __typename?: 'CreateCampaignVideoExportLinkResponse', url: string } | null | undefined };

export type CreateChannelExportLinkMutationVariables = Exact<{
  input: CreateChannelExportLinkInput;
}>;


export type CreateChannelExportLinkMutation = { __typename?: 'Mutation', createChannelExportLink?: { __typename?: 'CreateChannelExportLinkResponse', url: string } | null | undefined };

export type CreateChannelPerformanceExportLinkMutationVariables = Exact<{
  input: GetChannelPerformanceInput;
}>;


export type CreateChannelPerformanceExportLinkMutation = { __typename?: 'Mutation', createChannelPerformanceExportLink?: { __typename?: 'CreateExportLinkResponse', url: string } | null | undefined };

export type CreateCountryPerformanceExportLinkMutationVariables = Exact<{
  input: GetCountryPerformanceInput;
}>;


export type CreateCountryPerformanceExportLinkMutation = { __typename?: 'Mutation', createCountryPerformanceExportLink?: { __typename?: 'CreateExportLinkResponse', url: string } | null | undefined };

export type CreateEndScreensTemplateMutationVariables = Exact<{
  input: CreateEndScreensTemplateInput;
}>;


export type CreateEndScreensTemplateMutation = { __typename?: 'Mutation', createEndScreensTemplate?: { __typename?: 'EndScreenTemplate', id: string, name: string, endscreens: Array<{ __typename?: 'EndScreenElementOfTemplate', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, templateId: string, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined } | null | undefined> } | null | undefined };

export type CreateGenrePerformanceExportLinkMutationVariables = Exact<{
  input: GetGenrePerformanceInput;
}>;


export type CreateGenrePerformanceExportLinkMutation = { __typename?: 'Mutation', createGenrePerformanceExportLink?: { __typename?: 'CreateExportLinkResponse', url: string } | null | undefined };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string, name: string, email: string, role: UserRole, status: UserStatus, createdAt: any, updatedAt: any, lastAction: any } | null | undefined };

export type CreateVideoExportLinkMutationVariables = Exact<{
  input: CreateVideoExportLinkInput;
}>;


export type CreateVideoExportLinkMutation = { __typename?: 'Mutation', createVideoExportLink?: { __typename?: 'CreateVideoExportLinkResponse', url: string } | null | undefined };

export type CreateVideoPerformanceExportLinkMutationVariables = Exact<{
  input: GetVideoPerformanceInput;
}>;


export type CreateVideoPerformanceExportLinkMutation = { __typename?: 'Mutation', createVideoPerformanceExportLink?: { __typename?: 'CreateExportLinkResponse', url: string } | null | undefined };

export type CreateVideoSelectionMutationVariables = Exact<{
  input: CreateVideoSelectionInput;
}>;


export type CreateVideoSelectionMutation = { __typename?: 'Mutation', createVideoSelection?: { __typename?: 'CreateVideoSelectionResponse', executed?: boolean | null | undefined } | null | undefined };

export type DeleteAllVideosFromCampaignMutationVariables = Exact<{
  input: DeleteAllVideosFromCampaignInput;
}>;


export type DeleteAllVideosFromCampaignMutation = { __typename?: 'Mutation', deleteAllVideosFromCampaign?: { __typename?: 'DeleteCampaignVideoResponse', removed: boolean } | null | undefined };

export type DeleteCampaignMutationVariables = Exact<{
  input: DeleteCampaignInput;
}>;


export type DeleteCampaignMutation = { __typename?: 'Mutation', deleteCampaign?: { __typename?: 'Campaign', id: string } | null | undefined };

export type DeleteCampaignEndScreenMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCampaignEndScreenMutation = { __typename?: 'Mutation', deleteCampaignEndScreen?: { __typename?: 'EndScreenElement', id: string } | null | undefined };

export type DeleteCampaignVideoMutationVariables = Exact<{
  input: DeleteCampaignVideoInput;
}>;


export type DeleteCampaignVideoMutation = { __typename?: 'Mutation', deleteCampaignVideo?: { __typename?: 'DeleteCampaignVideoResponse', removed: boolean } | null | undefined };

export type DeleteCampaignVideosMutationVariables = Exact<{
  input: DeleteCampaignVideosListInput;
}>;


export type DeleteCampaignVideosMutation = { __typename?: 'Mutation', deleteCampaignVideos?: { __typename?: 'DeleteCampaignVideoResponse', removed: boolean } | null | undefined };

export type DeleteEndScreenDefaultMutationVariables = Exact<{
  input: DeleteEndScreenDefault;
}>;


export type DeleteEndScreenDefaultMutation = { __typename?: 'Mutation', deleteEndScreenDefault?: { __typename?: 'EndScreenDefaultElement', id: string } | null | undefined };

export type DeleteEndScreensTemplateMutationVariables = Exact<{
  input: DeleteEndScreensTemplateInput;
}>;


export type DeleteEndScreensTemplateMutation = { __typename?: 'Mutation', deleteEndScreensTemplate?: { __typename?: 'DeleteEndScreenTemplateResponse', id: string, name: string } | null | undefined };

export type DeleteUserMutationVariables = Exact<{
  input: DeleteUserInput;
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'User', id: string, name: string, email: string, role: UserRole, status: UserStatus, createdAt: any, updatedAt: any, lastAction: any } | null | undefined };

export type DeleteVideoSelectionMutationVariables = Exact<{
  input: DeleteVideoSelectionInput;
}>;


export type DeleteVideoSelectionMutation = { __typename?: 'Mutation', deleteVideoSelection?: { __typename?: 'DeleteVideoSelectionResponse', executed?: boolean | null | undefined } | null | undefined };

export type DuplicateCampaignMutationVariables = Exact<{
  input: DuplicateCampaignInput;
}>;


export type DuplicateCampaignMutation = { __typename?: 'Mutation', duplicateCampaign?: { __typename?: 'DuplicateCampaignResponse', done: boolean } | null | undefined };

export type EndAllCampaignVideoEndscreensMutationVariables = Exact<{
  input: EndAllCampaignVideoEndscreensInput;
}>;


export type EndAllCampaignVideoEndscreensMutation = { __typename?: 'Mutation', endAllCampaignVideoEndscreens?: { __typename?: 'ApplyCampaignResponse', id: string } | null | undefined };

export type SaveGoogleTokensMutationVariables = Exact<{
  input: SaveGoogleTokensInput;
}>;


export type SaveGoogleTokensMutation = { __typename?: 'Mutation', saveGoogleTokens?: { __typename?: 'SaveGoogleTokensResponse', saved: boolean } | null | undefined };

export type SyncChannelsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncChannelsMutation = { __typename?: 'Mutation', syncChannels?: { __typename?: 'SyncChannelResponse', requested: boolean } | null | undefined };

export type SyncChannelsSupervisorMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncChannelsSupervisorMutation = { __typename?: 'Mutation', syncChannelsSupervisor?: { __typename?: 'SyncChannelResponse', requested: boolean } | null | undefined };

export type SyncReportsMutationVariables = Exact<{ [key: string]: never; }>;


export type SyncReportsMutation = { __typename?: 'Mutation', syncReports?: { __typename?: 'SyncReportsResponse', success: boolean } | null | undefined };

export type SyncVideosFromOneChannelMutationVariables = Exact<{
  input: SyncVideosFromOneChannelInput;
}>;


export type SyncVideosFromOneChannelMutation = { __typename?: 'Mutation', syncVideosFromOneChannel?: { __typename?: 'SyncChannelVideosResponse', created: boolean } | null | undefined };

export type ToggleVideoSelectionsMutationVariables = Exact<{
  input: ToggleVideoSelectionInput;
}>;


export type ToggleVideoSelectionsMutation = { __typename?: 'Mutation', toggleVideoSelections?: { __typename?: 'CreateVideoSelectionResponse', executed?: boolean | null | undefined } | null | undefined };

export type UpdateCampaignMutationVariables = Exact<{
  input: UpdateCampaignInput;
}>;


export type UpdateCampaignMutation = { __typename?: 'Mutation', updateCampaign?: { __typename?: 'Campaign', createdAt?: string | null | undefined, endDate: string, id: string, name: string, startDate: string, status: string, updatedAt: string, views: number } | null | undefined };

export type UpdateCampaignEndScreensMutationVariables = Exact<{
  input: UpdateCampaignEndScreensInput;
}>;


export type UpdateCampaignEndScreensMutation = { __typename?: 'Mutation', updateCampaignEndScreens?: { __typename?: 'CampaignEndScreensResponse', items: Array<{ __typename?: 'EndScreenElement', id: string }> } | null | undefined };

export type UpdateCampaignWithEndScreensMutationVariables = Exact<{
  campaignEndScreensInput: UpdateCampaignEndScreensInput;
  campaignInput: UpdateCampaignInput;
}>;


export type UpdateCampaignWithEndScreensMutation = { __typename?: 'Mutation', updateCampaignEndScreens?: { __typename?: 'CampaignEndScreensResponse', items: Array<{ __typename?: 'EndScreenElement', id: string }> } | null | undefined, updateCampaign?: { __typename?: 'Campaign', createdAt?: string | null | undefined, endDate: string, id: string, name: string, startDate: string, status: string, updatedAt: string, views: number } | null | undefined };

export type UpdateChannelMutationVariables = Exact<{
  input: UpdateChannelInput;
}>;


export type UpdateChannelMutation = { __typename?: 'Mutation', updateChannel?: { __typename?: 'Channel', id: string, prioritized?: boolean | null | undefined, tier?: { __typename?: 'Tier', id: string } | null | undefined } | null | undefined };

export type UpdateEndScreenDefaultListMutationVariables = Exact<{
  input: UpdateEndScreenDefaultListInput;
}>;


export type UpdateEndScreenDefaultListMutation = { __typename?: 'Mutation', updateEndScreenDefaultList?: { __typename?: 'EndScreenDefaultListResponse', items: Array<{ __typename?: 'EndScreenDefaultElement', createdAt: string, durationMs: number, id: string, left: number, message?: string | null | undefined, position: number, top: number, type: EndScreenElementType, updatedAt: string, urlId?: string | null | undefined, width: number, height: number, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined }> } | null | undefined };

export type UpdateEndScreensTemplateMutationVariables = Exact<{
  input: UpdateEndScreensTemplateInput;
}>;


export type UpdateEndScreensTemplateMutation = { __typename?: 'Mutation', updateEndScreensTemplate?: { __typename?: 'EndScreenTemplate', id: string, name: string } | null | undefined };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, name: string, email: string, role: UserRole, status: UserStatus, createdAt: any, updatedAt: any, lastAction: any } | null | undefined };

export type UpdateYoutubeCredentialsMutationVariables = Exact<{
  input: UpdateYoutubeCredentialsInput;
}>;


export type UpdateYoutubeCredentialsMutation = { __typename?: 'Mutation', updateYoutubeCredentials?: { __typename?: 'YoutubeCredentialsResponse', apisid: string, hsid: string, info: string, sapisid: string, sid: string, ssid: string, token: string } | null | undefined };

export type UploadVideoListMutationVariables = Exact<{
  input: UploadVideoListInput;
}>;


export type UploadVideoListMutation = { __typename?: 'Mutation', uploadVideoList?: { __typename?: 'UploadVideoListResponse', uploaded: boolean } | null | undefined };

export type CheckGoogleAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckGoogleAuthQuery = { __typename?: 'Query', checkGoogleAuth?: { __typename?: 'CheckGoogleAuthResponse', valid: boolean, googleLoginUri: string } | null | undefined };

export type GetAddVideoLoadingStatusQueryVariables = Exact<{
  input: GetAddVideoLoadingStatusInput;
}>;


export type GetAddVideoLoadingStatusQuery = { __typename?: 'Query', getAddVideoLoadingStatus?: { __typename?: 'AddAllVideosResponse', requested: boolean } | null | undefined };

export type GetAllViewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllViewsQuery = { __typename?: 'Query', getAllViews?: { __typename?: 'GetAllViewsResponse', currentMonth: string, previousMonth: string } | null | undefined };

export type GetArtistListQueryVariables = Exact<{
  input: GetArtistListInput;
}>;


export type GetArtistListQuery = { __typename?: 'Query', getArtistList?: { __typename?: 'ArtistListResponse', count: number, items: Array<{ __typename?: 'Artist', updatedAt: any, createdAt: any, name: string, id: string } | null | undefined> } | null | undefined };

export type GetCampaignQueryVariables = Exact<{
  input: GetCampaignInput;
}>;


export type GetCampaignQuery = { __typename?: 'Query', getCampaign?: { __typename?: 'CampaignResponse', item: { __typename?: 'CampaignWithRelations', id: string, name: string, createdAt: string, startDate: string, endDate: string, updatedAt: string, views: number, status: string, endScreens: Array<{ __typename?: 'EndScreenElement', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined, isDefault: boolean, createdAt: string, updatedAt: string, thumbnail?: { __typename?: 'EndScreenElementThumbnail', name?: string | null | undefined, thumbnail?: string | null | undefined } | null | undefined }> } } | null | undefined };

export type GetCampaignDetailsPerformanceQueryVariables = Exact<{
  input: GetCampaignDetailsPerformanceInput;
}>;


export type GetCampaignDetailsPerformanceQuery = { __typename?: 'Query', getCampaignDetailsPerformance?: { __typename?: 'GetCampaignDetailsPerformanceResponse', campaign: { __typename?: 'Campaign', id: string, endDate: string, createdAt?: string | null | undefined, name: string, startDate: string, status: string, updatedAt: string, views: number }, endscreens: Array<{ __typename?: 'EndScreenElementPerformance', durationMs: number, id: string, isDefault: boolean, left: number, message?: string | null | undefined, top: number, position: number, type: EndScreenElementType, urlId?: string | null | undefined, width: number, metrics: Array<{ __typename?: 'ReportEndScreenPerformance', clicksCount: number, dayCovered: string, impressionsCount: number, ctr: number }> } | null | undefined> } | null | undefined };

export type GetCampaignListQueryVariables = Exact<{
  input: GetCampaignListInput;
}>;


export type GetCampaignListQuery = { __typename?: 'Query', getCampaignList?: { __typename?: 'CampaignListResponse', count: number, items: Array<{ __typename?: 'Campaign', createdAt?: string | null | undefined, endDate: string, id: string, name: string, startDate: string, status: string, updatedAt: string, views: number } | null | undefined> } | null | undefined };

export type GetCampaignPerformanceQueryVariables = Exact<{
  input: GetCampaignPerformanceInput;
}>;


export type GetCampaignPerformanceQuery = { __typename?: 'Query', getCampaignPerformance?: { __typename?: 'GetCampaignPerformanceResponse', data?: Array<{ __typename?: 'GetCampaignPerformanceResponseData', id: string, endDate: string, createdAt?: string | null | undefined, name: string, startDate: string, status: string, updatedAt: string, views: number, metrics: Array<{ __typename?: 'ReportEndScreenPerformance', clicksCount: number, dayCovered: string, impressionsCount: number, ctr: number } | null | undefined> } | null | undefined> | null | undefined } | null | undefined };

export type GetCampaignStatusQueryVariables = Exact<{
  input: GetCampaignStatusInput;
}>;


export type GetCampaignStatusQuery = { __typename?: 'Query', getCampaignStatus?: { __typename?: 'GetCampaignStatusResponse', completed: number, failed: number, total: number, stats?: { __typename?: 'CampaignVideoStatus', pending?: number | null | undefined, processing?: number | null | undefined, postprocessing?: number | null | undefined, complete?: number | null | undefined, failed?: number | null | undefined, in_progress?: number | null | undefined, cancelled?: number | null | undefined, delete?: number | null | undefined } | null | undefined } | null | undefined };

export type GetCampaignViewsQueryVariables = Exact<{
  input: GetCampaignViewsInput;
}>;


export type GetCampaignViewsQuery = { __typename?: 'Query', getCampaignViews?: { __typename?: 'GetCampaignViewsResponse', views: number } | null | undefined };

export type GetChannelQueryVariables = Exact<{
  input: GetChannelInput;
}>;


export type GetChannelQuery = { __typename?: 'Query', getChannel?: { __typename?: 'ChannelResponse', item: { __typename?: 'Channel', id: string, createdAt: any, name: string, subscriberCount: string, updatedAt: any, viewCount: string, playlist: string, addedAt: any, claimUploads: boolean, censorship: boolean, monetized: boolean, videoCount?: number | null | undefined, genre?: { __typename?: 'Genre', name: string } | null | undefined, tier?: { __typename?: 'Tier', name: string } | null | undefined, region?: { __typename?: 'Region', name: string } | null | undefined, artist: { __typename?: 'Artist', name: string } } } | null | undefined };

export type GetChannelListQueryVariables = Exact<{
  input?: Maybe<GetChannelListInput>;
}>;


export type GetChannelListQuery = { __typename?: 'Query', getChannelList?: { __typename?: 'ChannelListResponse', count: number, items: Array<{ __typename?: 'Channel', createdAt: any, id: string, name: string, subscriberCount: string, updatedAt: any, viewCount: string, youtubeId: string, playlist: string, addedAt: any, claimUploads: boolean, censorship: boolean, monetized: boolean, videoCount?: number | null | undefined, prioritized?: boolean | null | undefined, artist: { __typename?: 'Artist', name: string }, tier?: { __typename?: 'Tier', name: string } | null | undefined, region?: { __typename?: 'Region', name: string } | null | undefined, genre?: { __typename?: 'Genre', name: string } | null | undefined, parentLabel?: { __typename?: 'Label', name: string } | null | undefined, subLabel?: { __typename?: 'Label', name: string } | null | undefined } | null | undefined> } | null | undefined };

export type GetChannelPerformanceQueryVariables = Exact<{
  input: GetChannelPerformanceInput;
}>;


export type GetChannelPerformanceQuery = { __typename?: 'Query', getChannelPerformance?: { __typename?: 'GetChannelPerformanceResponse', data?: Array<{ __typename?: 'GetChannelPerformanceResponseData', id: string, youtubeId: string, name: string, playlist: string, subscriberCount: string, viewCount: string, addedAt: any, createdAt: any, updatedAt: any, claimUploads: boolean, censorship: boolean, monetized: boolean, videoCount?: number | null | undefined, metrics: Array<{ __typename?: 'ReportEndScreenPerformance', clicksCount: number, dayCovered: string, impressionsCount: number, ctr: number } | null | undefined> } | null | undefined> | null | undefined } | null | undefined };

export type GetCountryListQueryVariables = Exact<{
  input: GetCountryListInput;
}>;


export type GetCountryListQuery = { __typename?: 'Query', getCountryList?: { __typename?: 'CountryListResponse', items: Array<{ __typename?: 'Country', id: string, code: string } | null | undefined> } | null | undefined };

export type GetCountryPerformanceQueryVariables = Exact<{
  input: GetCountryPerformanceInput;
}>;


export type GetCountryPerformanceQuery = { __typename?: 'Query', getCountryPerformance?: { __typename?: 'GetCountryPerformanceResponse', data?: Array<{ __typename?: 'GetCountryPerformanceResponseData', id: string, code: string, metrics: Array<{ __typename?: 'ReportEndScreenPerformance', clicksCount: number, dayCovered: string, impressionsCount: number, ctr: number } | null | undefined> } | null | undefined> | null | undefined } | null | undefined };

export type GetEndScreenAvgCtrQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEndScreenAvgCtrQuery = { __typename?: 'Query', getEndScreenAvgCTR?: { __typename?: 'GetEndScreenStatsResponse', currentMonth: number, previousMonth: number } | null | undefined };

export type GetEndScreenDefaultListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEndScreenDefaultListQuery = { __typename?: 'Query', getEndScreenDefaultList?: { __typename?: 'EndScreenDefaultListResponse', items: Array<{ __typename?: 'EndScreenDefaultElement', id: string, durationMs: number, createdAt: string, left: number, message?: string | null | undefined, position: number, top: number, type: EndScreenElementType, updatedAt: string, urlId?: string | null | undefined, width: number, height: number, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined }> } | null | undefined };

export type GetEndScreenDefaultThumbnailQueryVariables = Exact<{
  input: GetEndScreenDefaultThumbnailInput;
}>;


export type GetEndScreenDefaultThumbnailQuery = { __typename?: 'Query', getEndScreenDefaultThumbnail?: { __typename?: 'GetEndScreenDefaultThumbnailResponse', thumbnail: string, name: string, endscreenId: string, youtubeId: string } | null | undefined };

export type GetEndScreenTemplateQueryVariables = Exact<{
  input: GetEndScreenTemplateInput;
}>;


export type GetEndScreenTemplateQuery = { __typename?: 'Query', getEndScreenTemplate?: { __typename?: 'EndScreenTemplate', id: string, name: string, endscreens: Array<{ __typename?: 'EndScreenElementOfTemplate', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, templateId: string, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined } | null | undefined> } | null | undefined };

export type GetEndScreenTemplateListQueryVariables = Exact<{
  input: GetEndScreenTemplateListInput;
}>;


export type GetEndScreenTemplateListQuery = { __typename?: 'Query', getEndScreenTemplateList?: { __typename?: 'EndScreenTemplateResponse', count: number, items: Array<{ __typename?: 'EndScreenTemplate', id: string, name: string, endscreens: Array<{ __typename?: 'EndScreenElementOfTemplate', id: string, type: EndScreenElementType, durationMs: number, position: number, urlId?: string | null | undefined, left: number, top: number, width: number, height: number, message?: string | null | undefined, templateId: string, externalLinkCTA?: ExternalLinkCta | null | undefined, imageDataUrl?: string | null | undefined } | null | undefined> }> } | null | undefined };

export type GetEndScreenTemplateThumbnailQueryVariables = Exact<{
  input: GetEndScreenTemplateThumbnailInput;
}>;


export type GetEndScreenTemplateThumbnailQuery = { __typename?: 'Query', getEndScreenTemplateThumbnail?: { __typename?: 'GetEndScreenTemplateThumbnailResponse', thumbnail: string, name: string, endscreenId: string, youtubeId: string } | null | undefined };

export type GetEndScreenThumbnailQueryVariables = Exact<{
  input: GetEndScreenThumbnailInput;
}>;


export type GetEndScreenThumbnailQuery = { __typename?: 'Query', getEndScreenThumbnail?: { __typename?: 'GetEndScreenThumbnailResponse', thumbnail: string, name: string, endscreenId: string, youtubeId: string } | null | undefined };

export type GetEndScreenViewsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEndScreenViewsQuery = { __typename?: 'Query', getEndScreenViews?: { __typename?: 'GetEndScreenStatsResponse', currentMonth: number, previousMonth: number } | null | undefined };

export type GetEndScreenViewsAvgQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEndScreenViewsAvgQuery = { __typename?: 'Query', getEndScreenViewsAvg?: { __typename?: 'GetEndScreenStatsResponse', currentMonth: number, previousMonth: number } | null | undefined };

export type GetGenreFullListQueryVariables = Exact<{
  input?: Maybe<GetGenreListInput>;
}>;


export type GetGenreFullListQuery = { __typename?: 'Query', getGenreFullList?: { __typename?: 'GenreListResponse', count: number, items: Array<{ __typename?: 'Genre', name: string, id: string, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type GetGenrePerformanceQueryVariables = Exact<{
  input: GetGenrePerformanceInput;
}>;


export type GetGenrePerformanceQuery = { __typename?: 'Query', getGenrePerformance?: { __typename?: 'GetGenrePerformanceResponse', data?: Array<{ __typename?: 'GetGenrePerformanceResponseData', id: string, name: string, metrics: Array<{ __typename?: 'ReportEndScreenPerformance', clicksCount: number, dayCovered: string, impressionsCount: number, ctr: number } | null | undefined> } | null | undefined> | null | undefined } | null | undefined };

export type GetLabelFullListQueryVariables = Exact<{
  input: GetLabelListInput;
}>;


export type GetLabelFullListQuery = { __typename?: 'Query', getLabelList?: { __typename?: 'LabelListResponse', count: number, items: Array<{ __typename?: 'Label', name: string, id: string, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type GetRegionFullListQueryVariables = Exact<{
  input?: Maybe<GetRegionListInput>;
}>;


export type GetRegionFullListQuery = { __typename?: 'Query', getRegionFullList?: { __typename?: 'RegionListResponse', count: number, items: Array<{ __typename?: 'Region', id: string, name: string, createdAt: any, updatedAt: any } | null | undefined> } | null | undefined };

export type GetChannelSyncStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type GetChannelSyncStatusQuery = { __typename?: 'Query', getChannelSyncStatus?: { __typename?: 'SyncStatusResponse', loading: boolean } | null | undefined };

export type GetTierFullListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTierFullListQuery = { __typename?: 'Query', getTierFullList?: { __typename?: 'TierListResponse', items: Array<{ __typename?: 'Tier', id: string, name: string } | null | undefined> } | null | undefined };

export type GetUserQueryVariables = Exact<{
  input: GetUserInput;
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string, name: string, email: string, role: UserRole, status: UserStatus, createdAt: any, updatedAt: any, lastAction: any } | null | undefined };

export type GetUserListQueryVariables = Exact<{
  input: GetUserListInput;
}>;


export type GetUserListQuery = { __typename?: 'Query', getUserList?: { __typename?: 'UserListResponse', count: number, items: Array<{ __typename?: 'User', id: string, name: string, email: string, role: UserRole, status: UserStatus, createdAt: any, updatedAt: any, lastAction: any } | null | undefined> } | null | undefined };

export type GetVideoPerformanceQueryVariables = Exact<{
  input: GetVideoPerformanceInput;
}>;


export type GetVideoPerformanceQuery = { __typename?: 'Query', getVideoPerformance?: { __typename?: 'GetVideoPerformanceResponse', data?: Array<{ __typename?: 'GetVideoPerformanceResponseData', id: string, name: string, thumbnail: string, viewCount: string, likesCount: string, youtubeId: string, createdAt: any, updatedAt: any, metrics: Array<{ __typename?: 'ReportEndScreenPerformance', clicksCount: number, dayCovered: string, impressionsCount: number, ctr: number } | null | undefined> } | null | undefined> | null | undefined } | null | undefined };

export type GetVideosQueryVariables = Exact<{
  input: GetVideosInput;
}>;


export type GetVideosQuery = { __typename?: 'Query', getVideos?: { __typename?: 'VideoListResponse', count: number, items: Array<{ __typename?: 'VideoItem', id: string, name: string } | null | undefined> } | null | undefined };

export type GetVideosFullListQueryVariables = Exact<{
  input: GetVideoListInput;
}>;


export type GetVideosFullListQuery = { __typename?: 'Query', getVideosFullList?: { __typename?: 'VideoListResponse', count: number, items: Array<{ __typename?: 'VideoItem', id: string, likesCount: string, name: string, thumbnail: string, viewCount: string, youtubeId: string, selected: boolean, artist: { __typename?: 'Artist', name: string, id: string }, parentLabel?: { __typename?: 'Label', name: string, id: string } | null | undefined, campaign?: { __typename?: 'Campaign', id: string, name: string } | null | undefined, channel: { __typename?: 'Channel', id: string, name: string, genre?: { __typename?: 'Genre', name: string, id: string } | null | undefined, region?: { __typename?: 'Region', name: string, id: string } | null | undefined }, applied?: Array<{ __typename?: 'Campaign', id: string, startDate: string, endDate: string, name: string } | null | undefined> | null | undefined } | null | undefined> } | null | undefined };

export type GetYoutubeCredentialsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetYoutubeCredentialsQuery = { __typename?: 'Query', getYoutubeCredentials?: { __typename?: 'YoutubeCredentialsResponse', hsid: string, apisid: string, info: string, sapisid: string, sid: string, ssid: string, token: string } | null | undefined };

export type TaskExistsQueryVariables = Exact<{
  input: TaskExistsInput;
}>;


export type TaskExistsQuery = { __typename?: 'Query', taskExists?: { __typename?: 'GetTaskResponse', exists: boolean } | null | undefined };

export type OnIncrementCampaignCountersSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnIncrementCampaignCountersSubscription = { __typename?: 'Subscription', onIncrementCampaignCounters?: { __typename?: 'IncrementCampaignCountersResponse', campaignId: string, completed: number, total: number, failed: number } | null | undefined };

export type OnUpdateAddVideoLoadingStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateAddVideoLoadingStatusSubscription = { __typename?: 'Subscription', onUpdateAddVideoLoadingStatus?: { __typename?: 'ToggleAddVideoLoadingStatusResponse', requested: boolean } | null | undefined };

export type OnUpdateCampaignProcessingStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateCampaignProcessingStatusSubscription = { __typename?: 'Subscription', onUpdateCampaignProcessingStatus?: { __typename?: 'CampaignResponse', id: string, status: string } | null | undefined };

export type OnUpdateCampaignStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateCampaignStatusSubscription = { __typename?: 'Subscription', onUpdateCampaignStatus?: { __typename?: 'UpdateCampaignStatusResponse', campaignId: string, completed: number, total: number, failed: number } | null | undefined };

export type OnUpdateCampaignVideoProcessingStatusSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnUpdateCampaignVideoProcessingStatusSubscription = { __typename?: 'Subscription', onUpdateCampaignVideoProcessingStatus?: { __typename?: 'CampaignVideoStatusResponse', campaignId: string, videoId: string, status: string, reason: string, start?: boolean | null | undefined, stats?: { __typename?: 'CampaignVideoStatus', pending?: number | null | undefined, processing?: number | null | undefined, postprocessing?: number | null | undefined, complete?: number | null | undefined, failed?: number | null | undefined, in_progress?: number | null | undefined, cancelled?: number | null | undefined, delete?: number | null | undefined } | null | undefined } | null | undefined };

export type AddTaskSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type AddTaskSubscription = { __typename?: 'Subscription', onCreateTask?: { __typename?: 'TaskResponse', name: string } | null | undefined };

export type DeleteTaskSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type DeleteTaskSubscription = { __typename?: 'Subscription', onDeleteTask?: { __typename?: 'TaskResponse', name: string } | null | undefined };

export const CampaignWithRelationsFragmentDoc = gql`
    fragment CampaignWithRelations on CampaignWithRelations {
  id
  name
  createdAt
  startDate
  endDate
  updatedAt
  views
  status
  endScreens {
    id
    type
    durationMs
    position
    urlId
    left
    top
    width
    height
    message
    externalLinkCTA
    imageDataUrl
    thumbnail {
      name
      thumbnail
    }
    isDefault
    createdAt
    updatedAt
  }
}
    `;
export const EndScreenElementOfTemplateFragmentDoc = gql`
    fragment EndScreenElementOfTemplate on EndScreenElementOfTemplate {
  id
  type
  durationMs
  position
  urlId
  left
  top
  width
  height
  message
  templateId
  externalLinkCTA
  imageDataUrl
}
    `;
export const EndScreenTemplateFragmentDoc = gql`
    fragment EndScreenTemplate on EndScreenTemplate {
  id
  name
  endscreens {
    ...EndScreenElementOfTemplate
  }
}
    ${EndScreenElementOfTemplateFragmentDoc}`;
export const GetCampaignVideosListDocument = gql`
    query GetCampaignVideosList($input: GetCampaignVideoListInput!) {
  getCampaignVideosFullList(input: $input) {
    count
    items {
      id
      createdAt
      name
      thumbnail
      updatedAt
      viewCount
      status
      youtubeId
      likesCount
      reason
      channel {
        name
      }
    }
  }
}
    `;

/**
 * __useGetCampaignVideosListQuery__
 *
 * To run a query within a React component, call `useGetCampaignVideosListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignVideosListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignVideosListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignVideosListQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignVideosListQuery, GetCampaignVideosListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignVideosListQuery, GetCampaignVideosListQueryVariables>(GetCampaignVideosListDocument, options);
      }
export function useGetCampaignVideosListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignVideosListQuery, GetCampaignVideosListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignVideosListQuery, GetCampaignVideosListQueryVariables>(GetCampaignVideosListDocument, options);
        }
export type GetCampaignVideosListQueryHookResult = ReturnType<typeof useGetCampaignVideosListQuery>;
export type GetCampaignVideosListLazyQueryHookResult = ReturnType<typeof useGetCampaignVideosListLazyQuery>;
export type GetCampaignVideosListQueryResult = Apollo.QueryResult<GetCampaignVideosListQuery, GetCampaignVideosListQueryVariables>;
export const GetCampaignVideosUploadSampleDocument = gql`
    query GetCampaignVideosUploadSample($input: GetCampaignVideosUploadSampleInput!) {
  getCampaignVideosUploadSample(input: $input) {
    url
  }
}
    `;

/**
 * __useGetCampaignVideosUploadSampleQuery__
 *
 * To run a query within a React component, call `useGetCampaignVideosUploadSampleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignVideosUploadSampleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignVideosUploadSampleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignVideosUploadSampleQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignVideosUploadSampleQuery, GetCampaignVideosUploadSampleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignVideosUploadSampleQuery, GetCampaignVideosUploadSampleQueryVariables>(GetCampaignVideosUploadSampleDocument, options);
      }
export function useGetCampaignVideosUploadSampleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignVideosUploadSampleQuery, GetCampaignVideosUploadSampleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignVideosUploadSampleQuery, GetCampaignVideosUploadSampleQueryVariables>(GetCampaignVideosUploadSampleDocument, options);
        }
export type GetCampaignVideosUploadSampleQueryHookResult = ReturnType<typeof useGetCampaignVideosUploadSampleQuery>;
export type GetCampaignVideosUploadSampleLazyQueryHookResult = ReturnType<typeof useGetCampaignVideosUploadSampleLazyQuery>;
export type GetCampaignVideosUploadSampleQueryResult = Apollo.QueryResult<GetCampaignVideosUploadSampleQuery, GetCampaignVideosUploadSampleQueryVariables>;
export const AddAllVideosDocument = gql`
    mutation AddAllVideos($input: AddAllVideosInput!) {
  addAllVideos(input: $input) {
    requested
  }
}
    `;
export type AddAllVideosMutationFn = Apollo.MutationFunction<AddAllVideosMutation, AddAllVideosMutationVariables>;

/**
 * __useAddAllVideosMutation__
 *
 * To run a mutation, you first call `useAddAllVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAllVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAllVideosMutation, { data, loading, error }] = useAddAllVideosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAllVideosMutation(baseOptions?: Apollo.MutationHookOptions<AddAllVideosMutation, AddAllVideosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAllVideosMutation, AddAllVideosMutationVariables>(AddAllVideosDocument, options);
      }
export type AddAllVideosMutationHookResult = ReturnType<typeof useAddAllVideosMutation>;
export type AddAllVideosMutationResult = Apollo.MutationResult<AddAllVideosMutation>;
export type AddAllVideosMutationOptions = Apollo.BaseMutationOptions<AddAllVideosMutation, AddAllVideosMutationVariables>;
export const AddManyVideosToCampaignDocument = gql`
    mutation AddManyVideosToCampaign($input: AddAllVideosInput!) {
  addManyVideosToCampaign(input: $input) {
    requested
  }
}
    `;
export type AddManyVideosToCampaignMutationFn = Apollo.MutationFunction<AddManyVideosToCampaignMutation, AddManyVideosToCampaignMutationVariables>;

/**
 * __useAddManyVideosToCampaignMutation__
 *
 * To run a mutation, you first call `useAddManyVideosToCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManyVideosToCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManyVideosToCampaignMutation, { data, loading, error }] = useAddManyVideosToCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddManyVideosToCampaignMutation(baseOptions?: Apollo.MutationHookOptions<AddManyVideosToCampaignMutation, AddManyVideosToCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddManyVideosToCampaignMutation, AddManyVideosToCampaignMutationVariables>(AddManyVideosToCampaignDocument, options);
      }
export type AddManyVideosToCampaignMutationHookResult = ReturnType<typeof useAddManyVideosToCampaignMutation>;
export type AddManyVideosToCampaignMutationResult = Apollo.MutationResult<AddManyVideosToCampaignMutation>;
export type AddManyVideosToCampaignMutationOptions = Apollo.BaseMutationOptions<AddManyVideosToCampaignMutation, AddManyVideosToCampaignMutationVariables>;
export const AddSelectedVideosDocument = gql`
    mutation AddSelectedVideos($input: AddSelectedVideos!) {
  addSelectedVideos(input: $input) {
    requested
  }
}
    `;
export type AddSelectedVideosMutationFn = Apollo.MutationFunction<AddSelectedVideosMutation, AddSelectedVideosMutationVariables>;

/**
 * __useAddSelectedVideosMutation__
 *
 * To run a mutation, you first call `useAddSelectedVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSelectedVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSelectedVideosMutation, { data, loading, error }] = useAddSelectedVideosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSelectedVideosMutation(baseOptions?: Apollo.MutationHookOptions<AddSelectedVideosMutation, AddSelectedVideosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSelectedVideosMutation, AddSelectedVideosMutationVariables>(AddSelectedVideosDocument, options);
      }
export type AddSelectedVideosMutationHookResult = ReturnType<typeof useAddSelectedVideosMutation>;
export type AddSelectedVideosMutationResult = Apollo.MutationResult<AddSelectedVideosMutation>;
export type AddSelectedVideosMutationOptions = Apollo.BaseMutationOptions<AddSelectedVideosMutation, AddSelectedVideosMutationVariables>;
export const ApplyAllCampaignVideoEndscreensDocument = gql`
    mutation ApplyAllCampaignVideoEndscreens($input: applyAllCampaignVideoEndscreensInput!) {
  applyAllCampaignVideoEndscreens(input: $input) {
    id
  }
}
    `;
export type ApplyAllCampaignVideoEndscreensMutationFn = Apollo.MutationFunction<ApplyAllCampaignVideoEndscreensMutation, ApplyAllCampaignVideoEndscreensMutationVariables>;

/**
 * __useApplyAllCampaignVideoEndscreensMutation__
 *
 * To run a mutation, you first call `useApplyAllCampaignVideoEndscreensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyAllCampaignVideoEndscreensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyAllCampaignVideoEndscreensMutation, { data, loading, error }] = useApplyAllCampaignVideoEndscreensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyAllCampaignVideoEndscreensMutation(baseOptions?: Apollo.MutationHookOptions<ApplyAllCampaignVideoEndscreensMutation, ApplyAllCampaignVideoEndscreensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyAllCampaignVideoEndscreensMutation, ApplyAllCampaignVideoEndscreensMutationVariables>(ApplyAllCampaignVideoEndscreensDocument, options);
      }
export type ApplyAllCampaignVideoEndscreensMutationHookResult = ReturnType<typeof useApplyAllCampaignVideoEndscreensMutation>;
export type ApplyAllCampaignVideoEndscreensMutationResult = Apollo.MutationResult<ApplyAllCampaignVideoEndscreensMutation>;
export type ApplyAllCampaignVideoEndscreensMutationOptions = Apollo.BaseMutationOptions<ApplyAllCampaignVideoEndscreensMutation, ApplyAllCampaignVideoEndscreensMutationVariables>;
export const CreateCampaignDocument = gql`
    mutation CreateCampaign($input: CreateCampaignInput!) {
  createCampaign(input: $input) {
    id
  }
}
    `;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<CreateCampaignMutation, CreateCampaignMutationVariables>;
export const CreateCampaignExportLinkDocument = gql`
    mutation CreateCampaignExportLink($input: CreateCampaignExportLinkInput!) {
  createCampaignExportLink(input: $input) {
    url
  }
}
    `;
export type CreateCampaignExportLinkMutationFn = Apollo.MutationFunction<CreateCampaignExportLinkMutation, CreateCampaignExportLinkMutationVariables>;

/**
 * __useCreateCampaignExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateCampaignExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignExportLinkMutation, { data, loading, error }] = useCreateCampaignExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignExportLinkMutation, CreateCampaignExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignExportLinkMutation, CreateCampaignExportLinkMutationVariables>(CreateCampaignExportLinkDocument, options);
      }
export type CreateCampaignExportLinkMutationHookResult = ReturnType<typeof useCreateCampaignExportLinkMutation>;
export type CreateCampaignExportLinkMutationResult = Apollo.MutationResult<CreateCampaignExportLinkMutation>;
export type CreateCampaignExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateCampaignExportLinkMutation, CreateCampaignExportLinkMutationVariables>;
export const CreateCampaignPerformanceExportLinkDocument = gql`
    mutation CreateCampaignPerformanceExportLink($input: GetCampaignPerformanceInput!) {
  createCampaignPerformanceExportLink(input: $input) {
    url
  }
}
    `;
export type CreateCampaignPerformanceExportLinkMutationFn = Apollo.MutationFunction<CreateCampaignPerformanceExportLinkMutation, CreateCampaignPerformanceExportLinkMutationVariables>;

/**
 * __useCreateCampaignPerformanceExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateCampaignPerformanceExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignPerformanceExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignPerformanceExportLinkMutation, { data, loading, error }] = useCreateCampaignPerformanceExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignPerformanceExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignPerformanceExportLinkMutation, CreateCampaignPerformanceExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignPerformanceExportLinkMutation, CreateCampaignPerformanceExportLinkMutationVariables>(CreateCampaignPerformanceExportLinkDocument, options);
      }
export type CreateCampaignPerformanceExportLinkMutationHookResult = ReturnType<typeof useCreateCampaignPerformanceExportLinkMutation>;
export type CreateCampaignPerformanceExportLinkMutationResult = Apollo.MutationResult<CreateCampaignPerformanceExportLinkMutation>;
export type CreateCampaignPerformanceExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateCampaignPerformanceExportLinkMutation, CreateCampaignPerformanceExportLinkMutationVariables>;
export const CreateCampaignVideoExportLinkDocument = gql`
    mutation CreateCampaignVideoExportLink($input: CreateCampaignVideoExportLinkInput!) {
  createCampaignVideoExportLink(input: $input) {
    url
  }
}
    `;
export type CreateCampaignVideoExportLinkMutationFn = Apollo.MutationFunction<CreateCampaignVideoExportLinkMutation, CreateCampaignVideoExportLinkMutationVariables>;

/**
 * __useCreateCampaignVideoExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateCampaignVideoExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignVideoExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignVideoExportLinkMutation, { data, loading, error }] = useCreateCampaignVideoExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignVideoExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignVideoExportLinkMutation, CreateCampaignVideoExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignVideoExportLinkMutation, CreateCampaignVideoExportLinkMutationVariables>(CreateCampaignVideoExportLinkDocument, options);
      }
export type CreateCampaignVideoExportLinkMutationHookResult = ReturnType<typeof useCreateCampaignVideoExportLinkMutation>;
export type CreateCampaignVideoExportLinkMutationResult = Apollo.MutationResult<CreateCampaignVideoExportLinkMutation>;
export type CreateCampaignVideoExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateCampaignVideoExportLinkMutation, CreateCampaignVideoExportLinkMutationVariables>;
export const CreateChannelExportLinkDocument = gql`
    mutation CreateChannelExportLink($input: CreateChannelExportLinkInput!) {
  createChannelExportLink(input: $input) {
    url
  }
}
    `;
export type CreateChannelExportLinkMutationFn = Apollo.MutationFunction<CreateChannelExportLinkMutation, CreateChannelExportLinkMutationVariables>;

/**
 * __useCreateChannelExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateChannelExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelExportLinkMutation, { data, loading, error }] = useCreateChannelExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelExportLinkMutation, CreateChannelExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelExportLinkMutation, CreateChannelExportLinkMutationVariables>(CreateChannelExportLinkDocument, options);
      }
export type CreateChannelExportLinkMutationHookResult = ReturnType<typeof useCreateChannelExportLinkMutation>;
export type CreateChannelExportLinkMutationResult = Apollo.MutationResult<CreateChannelExportLinkMutation>;
export type CreateChannelExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateChannelExportLinkMutation, CreateChannelExportLinkMutationVariables>;
export const CreateChannelPerformanceExportLinkDocument = gql`
    mutation CreateChannelPerformanceExportLink($input: GetChannelPerformanceInput!) {
  createChannelPerformanceExportLink(input: $input) {
    url
  }
}
    `;
export type CreateChannelPerformanceExportLinkMutationFn = Apollo.MutationFunction<CreateChannelPerformanceExportLinkMutation, CreateChannelPerformanceExportLinkMutationVariables>;

/**
 * __useCreateChannelPerformanceExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateChannelPerformanceExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelPerformanceExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelPerformanceExportLinkMutation, { data, loading, error }] = useCreateChannelPerformanceExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelPerformanceExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelPerformanceExportLinkMutation, CreateChannelPerformanceExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelPerformanceExportLinkMutation, CreateChannelPerformanceExportLinkMutationVariables>(CreateChannelPerformanceExportLinkDocument, options);
      }
export type CreateChannelPerformanceExportLinkMutationHookResult = ReturnType<typeof useCreateChannelPerformanceExportLinkMutation>;
export type CreateChannelPerformanceExportLinkMutationResult = Apollo.MutationResult<CreateChannelPerformanceExportLinkMutation>;
export type CreateChannelPerformanceExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateChannelPerformanceExportLinkMutation, CreateChannelPerformanceExportLinkMutationVariables>;
export const CreateCountryPerformanceExportLinkDocument = gql`
    mutation CreateCountryPerformanceExportLink($input: GetCountryPerformanceInput!) {
  createCountryPerformanceExportLink(input: $input) {
    url
  }
}
    `;
export type CreateCountryPerformanceExportLinkMutationFn = Apollo.MutationFunction<CreateCountryPerformanceExportLinkMutation, CreateCountryPerformanceExportLinkMutationVariables>;

/**
 * __useCreateCountryPerformanceExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateCountryPerformanceExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCountryPerformanceExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCountryPerformanceExportLinkMutation, { data, loading, error }] = useCreateCountryPerformanceExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCountryPerformanceExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateCountryPerformanceExportLinkMutation, CreateCountryPerformanceExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCountryPerformanceExportLinkMutation, CreateCountryPerformanceExportLinkMutationVariables>(CreateCountryPerformanceExportLinkDocument, options);
      }
export type CreateCountryPerformanceExportLinkMutationHookResult = ReturnType<typeof useCreateCountryPerformanceExportLinkMutation>;
export type CreateCountryPerformanceExportLinkMutationResult = Apollo.MutationResult<CreateCountryPerformanceExportLinkMutation>;
export type CreateCountryPerformanceExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateCountryPerformanceExportLinkMutation, CreateCountryPerformanceExportLinkMutationVariables>;
export const CreateEndScreensTemplateDocument = gql`
    mutation CreateEndScreensTemplate($input: createEndScreensTemplateInput!) {
  createEndScreensTemplate(input: $input) {
    ...EndScreenTemplate
  }
}
    ${EndScreenTemplateFragmentDoc}`;
export type CreateEndScreensTemplateMutationFn = Apollo.MutationFunction<CreateEndScreensTemplateMutation, CreateEndScreensTemplateMutationVariables>;

/**
 * __useCreateEndScreensTemplateMutation__
 *
 * To run a mutation, you first call `useCreateEndScreensTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEndScreensTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEndScreensTemplateMutation, { data, loading, error }] = useCreateEndScreensTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEndScreensTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateEndScreensTemplateMutation, CreateEndScreensTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEndScreensTemplateMutation, CreateEndScreensTemplateMutationVariables>(CreateEndScreensTemplateDocument, options);
      }
export type CreateEndScreensTemplateMutationHookResult = ReturnType<typeof useCreateEndScreensTemplateMutation>;
export type CreateEndScreensTemplateMutationResult = Apollo.MutationResult<CreateEndScreensTemplateMutation>;
export type CreateEndScreensTemplateMutationOptions = Apollo.BaseMutationOptions<CreateEndScreensTemplateMutation, CreateEndScreensTemplateMutationVariables>;
export const CreateGenrePerformanceExportLinkDocument = gql`
    mutation CreateGenrePerformanceExportLink($input: GetGenrePerformanceInput!) {
  createGenrePerformanceExportLink(input: $input) {
    url
  }
}
    `;
export type CreateGenrePerformanceExportLinkMutationFn = Apollo.MutationFunction<CreateGenrePerformanceExportLinkMutation, CreateGenrePerformanceExportLinkMutationVariables>;

/**
 * __useCreateGenrePerformanceExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateGenrePerformanceExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenrePerformanceExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenrePerformanceExportLinkMutation, { data, loading, error }] = useCreateGenrePerformanceExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGenrePerformanceExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateGenrePerformanceExportLinkMutation, CreateGenrePerformanceExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGenrePerformanceExportLinkMutation, CreateGenrePerformanceExportLinkMutationVariables>(CreateGenrePerformanceExportLinkDocument, options);
      }
export type CreateGenrePerformanceExportLinkMutationHookResult = ReturnType<typeof useCreateGenrePerformanceExportLinkMutation>;
export type CreateGenrePerformanceExportLinkMutationResult = Apollo.MutationResult<CreateGenrePerformanceExportLinkMutation>;
export type CreateGenrePerformanceExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateGenrePerformanceExportLinkMutation, CreateGenrePerformanceExportLinkMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    name
    email
    role
    status
    createdAt
    updatedAt
    lastAction
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateVideoExportLinkDocument = gql`
    mutation CreateVideoExportLink($input: CreateVideoExportLinkInput!) {
  createVideoExportLink(input: $input) {
    url
  }
}
    `;
export type CreateVideoExportLinkMutationFn = Apollo.MutationFunction<CreateVideoExportLinkMutation, CreateVideoExportLinkMutationVariables>;

/**
 * __useCreateVideoExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateVideoExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoExportLinkMutation, { data, loading, error }] = useCreateVideoExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoExportLinkMutation, CreateVideoExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoExportLinkMutation, CreateVideoExportLinkMutationVariables>(CreateVideoExportLinkDocument, options);
      }
export type CreateVideoExportLinkMutationHookResult = ReturnType<typeof useCreateVideoExportLinkMutation>;
export type CreateVideoExportLinkMutationResult = Apollo.MutationResult<CreateVideoExportLinkMutation>;
export type CreateVideoExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateVideoExportLinkMutation, CreateVideoExportLinkMutationVariables>;
export const CreateVideoPerformanceExportLinkDocument = gql`
    mutation CreateVideoPerformanceExportLink($input: GetVideoPerformanceInput!) {
  createVideoPerformanceExportLink(input: $input) {
    url
  }
}
    `;
export type CreateVideoPerformanceExportLinkMutationFn = Apollo.MutationFunction<CreateVideoPerformanceExportLinkMutation, CreateVideoPerformanceExportLinkMutationVariables>;

/**
 * __useCreateVideoPerformanceExportLinkMutation__
 *
 * To run a mutation, you first call `useCreateVideoPerformanceExportLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoPerformanceExportLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoPerformanceExportLinkMutation, { data, loading, error }] = useCreateVideoPerformanceExportLinkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoPerformanceExportLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoPerformanceExportLinkMutation, CreateVideoPerformanceExportLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoPerformanceExportLinkMutation, CreateVideoPerformanceExportLinkMutationVariables>(CreateVideoPerformanceExportLinkDocument, options);
      }
export type CreateVideoPerformanceExportLinkMutationHookResult = ReturnType<typeof useCreateVideoPerformanceExportLinkMutation>;
export type CreateVideoPerformanceExportLinkMutationResult = Apollo.MutationResult<CreateVideoPerformanceExportLinkMutation>;
export type CreateVideoPerformanceExportLinkMutationOptions = Apollo.BaseMutationOptions<CreateVideoPerformanceExportLinkMutation, CreateVideoPerformanceExportLinkMutationVariables>;
export const CreateVideoSelectionDocument = gql`
    mutation CreateVideoSelection($input: CreateVideoSelectionInput!) {
  createVideoSelection(input: $input) {
    executed
  }
}
    `;
export type CreateVideoSelectionMutationFn = Apollo.MutationFunction<CreateVideoSelectionMutation, CreateVideoSelectionMutationVariables>;

/**
 * __useCreateVideoSelectionMutation__
 *
 * To run a mutation, you first call `useCreateVideoSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoSelectionMutation, { data, loading, error }] = useCreateVideoSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateVideoSelectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoSelectionMutation, CreateVideoSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoSelectionMutation, CreateVideoSelectionMutationVariables>(CreateVideoSelectionDocument, options);
      }
export type CreateVideoSelectionMutationHookResult = ReturnType<typeof useCreateVideoSelectionMutation>;
export type CreateVideoSelectionMutationResult = Apollo.MutationResult<CreateVideoSelectionMutation>;
export type CreateVideoSelectionMutationOptions = Apollo.BaseMutationOptions<CreateVideoSelectionMutation, CreateVideoSelectionMutationVariables>;
export const DeleteAllVideosFromCampaignDocument = gql`
    mutation DeleteAllVideosFromCampaign($input: DeleteAllVideosFromCampaignInput!) {
  deleteAllVideosFromCampaign(input: $input) {
    removed
  }
}
    `;
export type DeleteAllVideosFromCampaignMutationFn = Apollo.MutationFunction<DeleteAllVideosFromCampaignMutation, DeleteAllVideosFromCampaignMutationVariables>;

/**
 * __useDeleteAllVideosFromCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteAllVideosFromCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllVideosFromCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllVideosFromCampaignMutation, { data, loading, error }] = useDeleteAllVideosFromCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAllVideosFromCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllVideosFromCampaignMutation, DeleteAllVideosFromCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAllVideosFromCampaignMutation, DeleteAllVideosFromCampaignMutationVariables>(DeleteAllVideosFromCampaignDocument, options);
      }
export type DeleteAllVideosFromCampaignMutationHookResult = ReturnType<typeof useDeleteAllVideosFromCampaignMutation>;
export type DeleteAllVideosFromCampaignMutationResult = Apollo.MutationResult<DeleteAllVideosFromCampaignMutation>;
export type DeleteAllVideosFromCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteAllVideosFromCampaignMutation, DeleteAllVideosFromCampaignMutationVariables>;
export const DeleteCampaignDocument = gql`
    mutation DeleteCampaign($input: DeleteCampaignInput!) {
  deleteCampaign(input: $input) {
    id
  }
}
    `;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<DeleteCampaignMutation, DeleteCampaignMutationVariables>;

/**
 * __useDeleteCampaignMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignMutation, { data, loading, error }] = useDeleteCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignMutation, DeleteCampaignMutationVariables>(DeleteCampaignDocument, options);
      }
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<DeleteCampaignMutation>;
export type DeleteCampaignMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>;
export const DeleteCampaignEndScreenDocument = gql`
    mutation DeleteCampaignEndScreen($id: ID!) {
  deleteCampaignEndScreen(id: $id) {
    id
  }
}
    `;
export type DeleteCampaignEndScreenMutationFn = Apollo.MutationFunction<DeleteCampaignEndScreenMutation, DeleteCampaignEndScreenMutationVariables>;

/**
 * __useDeleteCampaignEndScreenMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignEndScreenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignEndScreenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignEndScreenMutation, { data, loading, error }] = useDeleteCampaignEndScreenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCampaignEndScreenMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignEndScreenMutation, DeleteCampaignEndScreenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignEndScreenMutation, DeleteCampaignEndScreenMutationVariables>(DeleteCampaignEndScreenDocument, options);
      }
export type DeleteCampaignEndScreenMutationHookResult = ReturnType<typeof useDeleteCampaignEndScreenMutation>;
export type DeleteCampaignEndScreenMutationResult = Apollo.MutationResult<DeleteCampaignEndScreenMutation>;
export type DeleteCampaignEndScreenMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignEndScreenMutation, DeleteCampaignEndScreenMutationVariables>;
export const DeleteCampaignVideoDocument = gql`
    mutation DeleteCampaignVideo($input: DeleteCampaignVideoInput!) {
  deleteCampaignVideo(input: $input) {
    removed
  }
}
    `;
export type DeleteCampaignVideoMutationFn = Apollo.MutationFunction<DeleteCampaignVideoMutation, DeleteCampaignVideoMutationVariables>;

/**
 * __useDeleteCampaignVideoMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignVideoMutation, { data, loading, error }] = useDeleteCampaignVideoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCampaignVideoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignVideoMutation, DeleteCampaignVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignVideoMutation, DeleteCampaignVideoMutationVariables>(DeleteCampaignVideoDocument, options);
      }
export type DeleteCampaignVideoMutationHookResult = ReturnType<typeof useDeleteCampaignVideoMutation>;
export type DeleteCampaignVideoMutationResult = Apollo.MutationResult<DeleteCampaignVideoMutation>;
export type DeleteCampaignVideoMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignVideoMutation, DeleteCampaignVideoMutationVariables>;
export const DeleteCampaignVideosDocument = gql`
    mutation DeleteCampaignVideos($input: DeleteCampaignVideosListInput!) {
  deleteCampaignVideos(input: $input) {
    removed
  }
}
    `;
export type DeleteCampaignVideosMutationFn = Apollo.MutationFunction<DeleteCampaignVideosMutation, DeleteCampaignVideosMutationVariables>;

/**
 * __useDeleteCampaignVideosMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignVideosMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignVideosMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignVideosMutation, { data, loading, error }] = useDeleteCampaignVideosMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCampaignVideosMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignVideosMutation, DeleteCampaignVideosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignVideosMutation, DeleteCampaignVideosMutationVariables>(DeleteCampaignVideosDocument, options);
      }
export type DeleteCampaignVideosMutationHookResult = ReturnType<typeof useDeleteCampaignVideosMutation>;
export type DeleteCampaignVideosMutationResult = Apollo.MutationResult<DeleteCampaignVideosMutation>;
export type DeleteCampaignVideosMutationOptions = Apollo.BaseMutationOptions<DeleteCampaignVideosMutation, DeleteCampaignVideosMutationVariables>;
export const DeleteEndScreenDefaultDocument = gql`
    mutation DeleteEndScreenDefault($input: DeleteEndScreenDefault!) {
  deleteEndScreenDefault(input: $input) {
    id
  }
}
    `;
export type DeleteEndScreenDefaultMutationFn = Apollo.MutationFunction<DeleteEndScreenDefaultMutation, DeleteEndScreenDefaultMutationVariables>;

/**
 * __useDeleteEndScreenDefaultMutation__
 *
 * To run a mutation, you first call `useDeleteEndScreenDefaultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEndScreenDefaultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEndScreenDefaultMutation, { data, loading, error }] = useDeleteEndScreenDefaultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEndScreenDefaultMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEndScreenDefaultMutation, DeleteEndScreenDefaultMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEndScreenDefaultMutation, DeleteEndScreenDefaultMutationVariables>(DeleteEndScreenDefaultDocument, options);
      }
export type DeleteEndScreenDefaultMutationHookResult = ReturnType<typeof useDeleteEndScreenDefaultMutation>;
export type DeleteEndScreenDefaultMutationResult = Apollo.MutationResult<DeleteEndScreenDefaultMutation>;
export type DeleteEndScreenDefaultMutationOptions = Apollo.BaseMutationOptions<DeleteEndScreenDefaultMutation, DeleteEndScreenDefaultMutationVariables>;
export const DeleteEndScreensTemplateDocument = gql`
    mutation DeleteEndScreensTemplate($input: deleteEndScreensTemplateInput!) {
  deleteEndScreensTemplate(input: $input) {
    id
    name
  }
}
    `;
export type DeleteEndScreensTemplateMutationFn = Apollo.MutationFunction<DeleteEndScreensTemplateMutation, DeleteEndScreensTemplateMutationVariables>;

/**
 * __useDeleteEndScreensTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteEndScreensTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEndScreensTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEndScreensTemplateMutation, { data, loading, error }] = useDeleteEndScreensTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteEndScreensTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEndScreensTemplateMutation, DeleteEndScreensTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEndScreensTemplateMutation, DeleteEndScreensTemplateMutationVariables>(DeleteEndScreensTemplateDocument, options);
      }
export type DeleteEndScreensTemplateMutationHookResult = ReturnType<typeof useDeleteEndScreensTemplateMutation>;
export type DeleteEndScreensTemplateMutationResult = Apollo.MutationResult<DeleteEndScreensTemplateMutation>;
export type DeleteEndScreensTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteEndScreensTemplateMutation, DeleteEndScreensTemplateMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    id
    name
    email
    role
    status
    createdAt
    updatedAt
    lastAction
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteVideoSelectionDocument = gql`
    mutation DeleteVideoSelection($input: DeleteVideoSelectionInput!) {
  deleteVideoSelection(input: $input) {
    executed
  }
}
    `;
export type DeleteVideoSelectionMutationFn = Apollo.MutationFunction<DeleteVideoSelectionMutation, DeleteVideoSelectionMutationVariables>;

/**
 * __useDeleteVideoSelectionMutation__
 *
 * To run a mutation, you first call `useDeleteVideoSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVideoSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVideoSelectionMutation, { data, loading, error }] = useDeleteVideoSelectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteVideoSelectionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVideoSelectionMutation, DeleteVideoSelectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVideoSelectionMutation, DeleteVideoSelectionMutationVariables>(DeleteVideoSelectionDocument, options);
      }
export type DeleteVideoSelectionMutationHookResult = ReturnType<typeof useDeleteVideoSelectionMutation>;
export type DeleteVideoSelectionMutationResult = Apollo.MutationResult<DeleteVideoSelectionMutation>;
export type DeleteVideoSelectionMutationOptions = Apollo.BaseMutationOptions<DeleteVideoSelectionMutation, DeleteVideoSelectionMutationVariables>;
export const DuplicateCampaignDocument = gql`
    mutation DuplicateCampaign($input: DuplicateCampaignInput!) {
  duplicateCampaign(input: $input) {
    done
  }
}
    `;
export type DuplicateCampaignMutationFn = Apollo.MutationFunction<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>;

/**
 * __useDuplicateCampaignMutation__
 *
 * To run a mutation, you first call `useDuplicateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateCampaignMutation, { data, loading, error }] = useDuplicateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>(DuplicateCampaignDocument, options);
      }
export type DuplicateCampaignMutationHookResult = ReturnType<typeof useDuplicateCampaignMutation>;
export type DuplicateCampaignMutationResult = Apollo.MutationResult<DuplicateCampaignMutation>;
export type DuplicateCampaignMutationOptions = Apollo.BaseMutationOptions<DuplicateCampaignMutation, DuplicateCampaignMutationVariables>;
export const EndAllCampaignVideoEndscreensDocument = gql`
    mutation EndAllCampaignVideoEndscreens($input: endAllCampaignVideoEndscreensInput!) {
  endAllCampaignVideoEndscreens(input: $input) {
    id
  }
}
    `;
export type EndAllCampaignVideoEndscreensMutationFn = Apollo.MutationFunction<EndAllCampaignVideoEndscreensMutation, EndAllCampaignVideoEndscreensMutationVariables>;

/**
 * __useEndAllCampaignVideoEndscreensMutation__
 *
 * To run a mutation, you first call `useEndAllCampaignVideoEndscreensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndAllCampaignVideoEndscreensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endAllCampaignVideoEndscreensMutation, { data, loading, error }] = useEndAllCampaignVideoEndscreensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndAllCampaignVideoEndscreensMutation(baseOptions?: Apollo.MutationHookOptions<EndAllCampaignVideoEndscreensMutation, EndAllCampaignVideoEndscreensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndAllCampaignVideoEndscreensMutation, EndAllCampaignVideoEndscreensMutationVariables>(EndAllCampaignVideoEndscreensDocument, options);
      }
export type EndAllCampaignVideoEndscreensMutationHookResult = ReturnType<typeof useEndAllCampaignVideoEndscreensMutation>;
export type EndAllCampaignVideoEndscreensMutationResult = Apollo.MutationResult<EndAllCampaignVideoEndscreensMutation>;
export type EndAllCampaignVideoEndscreensMutationOptions = Apollo.BaseMutationOptions<EndAllCampaignVideoEndscreensMutation, EndAllCampaignVideoEndscreensMutationVariables>;
export const SaveGoogleTokensDocument = gql`
    mutation SaveGoogleTokens($input: SaveGoogleTokensInput!) {
  saveGoogleTokens(input: $input) {
    saved
  }
}
    `;
export type SaveGoogleTokensMutationFn = Apollo.MutationFunction<SaveGoogleTokensMutation, SaveGoogleTokensMutationVariables>;

/**
 * __useSaveGoogleTokensMutation__
 *
 * To run a mutation, you first call `useSaveGoogleTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGoogleTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGoogleTokensMutation, { data, loading, error }] = useSaveGoogleTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveGoogleTokensMutation(baseOptions?: Apollo.MutationHookOptions<SaveGoogleTokensMutation, SaveGoogleTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveGoogleTokensMutation, SaveGoogleTokensMutationVariables>(SaveGoogleTokensDocument, options);
      }
export type SaveGoogleTokensMutationHookResult = ReturnType<typeof useSaveGoogleTokensMutation>;
export type SaveGoogleTokensMutationResult = Apollo.MutationResult<SaveGoogleTokensMutation>;
export type SaveGoogleTokensMutationOptions = Apollo.BaseMutationOptions<SaveGoogleTokensMutation, SaveGoogleTokensMutationVariables>;
export const SyncChannelsDocument = gql`
    mutation SyncChannels {
  syncChannels {
    requested
  }
}
    `;
export type SyncChannelsMutationFn = Apollo.MutationFunction<SyncChannelsMutation, SyncChannelsMutationVariables>;

/**
 * __useSyncChannelsMutation__
 *
 * To run a mutation, you first call `useSyncChannelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncChannelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncChannelsMutation, { data, loading, error }] = useSyncChannelsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncChannelsMutation(baseOptions?: Apollo.MutationHookOptions<SyncChannelsMutation, SyncChannelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncChannelsMutation, SyncChannelsMutationVariables>(SyncChannelsDocument, options);
      }
export type SyncChannelsMutationHookResult = ReturnType<typeof useSyncChannelsMutation>;
export type SyncChannelsMutationResult = Apollo.MutationResult<SyncChannelsMutation>;
export type SyncChannelsMutationOptions = Apollo.BaseMutationOptions<SyncChannelsMutation, SyncChannelsMutationVariables>;
export const SyncChannelsSupervisorDocument = gql`
    mutation SyncChannelsSupervisor {
  syncChannelsSupervisor {
    requested
  }
}
    `;
export type SyncChannelsSupervisorMutationFn = Apollo.MutationFunction<SyncChannelsSupervisorMutation, SyncChannelsSupervisorMutationVariables>;

/**
 * __useSyncChannelsSupervisorMutation__
 *
 * To run a mutation, you first call `useSyncChannelsSupervisorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncChannelsSupervisorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncChannelsSupervisorMutation, { data, loading, error }] = useSyncChannelsSupervisorMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncChannelsSupervisorMutation(baseOptions?: Apollo.MutationHookOptions<SyncChannelsSupervisorMutation, SyncChannelsSupervisorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncChannelsSupervisorMutation, SyncChannelsSupervisorMutationVariables>(SyncChannelsSupervisorDocument, options);
      }
export type SyncChannelsSupervisorMutationHookResult = ReturnType<typeof useSyncChannelsSupervisorMutation>;
export type SyncChannelsSupervisorMutationResult = Apollo.MutationResult<SyncChannelsSupervisorMutation>;
export type SyncChannelsSupervisorMutationOptions = Apollo.BaseMutationOptions<SyncChannelsSupervisorMutation, SyncChannelsSupervisorMutationVariables>;
export const SyncReportsDocument = gql`
    mutation SyncReports {
  syncReports {
    success
  }
}
    `;
export type SyncReportsMutationFn = Apollo.MutationFunction<SyncReportsMutation, SyncReportsMutationVariables>;

/**
 * __useSyncReportsMutation__
 *
 * To run a mutation, you first call `useSyncReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncReportsMutation, { data, loading, error }] = useSyncReportsMutation({
 *   variables: {
 *   },
 * });
 */
export function useSyncReportsMutation(baseOptions?: Apollo.MutationHookOptions<SyncReportsMutation, SyncReportsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncReportsMutation, SyncReportsMutationVariables>(SyncReportsDocument, options);
      }
export type SyncReportsMutationHookResult = ReturnType<typeof useSyncReportsMutation>;
export type SyncReportsMutationResult = Apollo.MutationResult<SyncReportsMutation>;
export type SyncReportsMutationOptions = Apollo.BaseMutationOptions<SyncReportsMutation, SyncReportsMutationVariables>;
export const SyncVideosFromOneChannelDocument = gql`
    mutation SyncVideosFromOneChannel($input: SyncVideosFromOneChannelInput!) {
  syncVideosFromOneChannel(input: $input) {
    created
  }
}
    `;
export type SyncVideosFromOneChannelMutationFn = Apollo.MutationFunction<SyncVideosFromOneChannelMutation, SyncVideosFromOneChannelMutationVariables>;

/**
 * __useSyncVideosFromOneChannelMutation__
 *
 * To run a mutation, you first call `useSyncVideosFromOneChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncVideosFromOneChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncVideosFromOneChannelMutation, { data, loading, error }] = useSyncVideosFromOneChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSyncVideosFromOneChannelMutation(baseOptions?: Apollo.MutationHookOptions<SyncVideosFromOneChannelMutation, SyncVideosFromOneChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncVideosFromOneChannelMutation, SyncVideosFromOneChannelMutationVariables>(SyncVideosFromOneChannelDocument, options);
      }
export type SyncVideosFromOneChannelMutationHookResult = ReturnType<typeof useSyncVideosFromOneChannelMutation>;
export type SyncVideosFromOneChannelMutationResult = Apollo.MutationResult<SyncVideosFromOneChannelMutation>;
export type SyncVideosFromOneChannelMutationOptions = Apollo.BaseMutationOptions<SyncVideosFromOneChannelMutation, SyncVideosFromOneChannelMutationVariables>;
export const ToggleVideoSelectionsDocument = gql`
    mutation ToggleVideoSelections($input: ToggleVideoSelectionInput!) {
  toggleVideoSelections(input: $input) {
    executed
  }
}
    `;
export type ToggleVideoSelectionsMutationFn = Apollo.MutationFunction<ToggleVideoSelectionsMutation, ToggleVideoSelectionsMutationVariables>;

/**
 * __useToggleVideoSelectionsMutation__
 *
 * To run a mutation, you first call `useToggleVideoSelectionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleVideoSelectionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleVideoSelectionsMutation, { data, loading, error }] = useToggleVideoSelectionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleVideoSelectionsMutation(baseOptions?: Apollo.MutationHookOptions<ToggleVideoSelectionsMutation, ToggleVideoSelectionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleVideoSelectionsMutation, ToggleVideoSelectionsMutationVariables>(ToggleVideoSelectionsDocument, options);
      }
export type ToggleVideoSelectionsMutationHookResult = ReturnType<typeof useToggleVideoSelectionsMutation>;
export type ToggleVideoSelectionsMutationResult = Apollo.MutationResult<ToggleVideoSelectionsMutation>;
export type ToggleVideoSelectionsMutationOptions = Apollo.BaseMutationOptions<ToggleVideoSelectionsMutation, ToggleVideoSelectionsMutationVariables>;
export const UpdateCampaignDocument = gql`
    mutation UpdateCampaign($input: UpdateCampaignInput!) {
  updateCampaign(input: $input) {
    createdAt
    endDate
    id
    name
    startDate
    status
    updatedAt
    views
  }
}
    `;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<UpdateCampaignMutation, UpdateCampaignMutationVariables>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>;
export const UpdateCampaignEndScreensDocument = gql`
    mutation UpdateCampaignEndScreens($input: UpdateCampaignEndScreensInput!) {
  updateCampaignEndScreens(input: $input) {
    items {
      id
    }
  }
}
    `;
export type UpdateCampaignEndScreensMutationFn = Apollo.MutationFunction<UpdateCampaignEndScreensMutation, UpdateCampaignEndScreensMutationVariables>;

/**
 * __useUpdateCampaignEndScreensMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignEndScreensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignEndScreensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignEndScreensMutation, { data, loading, error }] = useUpdateCampaignEndScreensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignEndScreensMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignEndScreensMutation, UpdateCampaignEndScreensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignEndScreensMutation, UpdateCampaignEndScreensMutationVariables>(UpdateCampaignEndScreensDocument, options);
      }
export type UpdateCampaignEndScreensMutationHookResult = ReturnType<typeof useUpdateCampaignEndScreensMutation>;
export type UpdateCampaignEndScreensMutationResult = Apollo.MutationResult<UpdateCampaignEndScreensMutation>;
export type UpdateCampaignEndScreensMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignEndScreensMutation, UpdateCampaignEndScreensMutationVariables>;
export const UpdateCampaignWithEndScreensDocument = gql`
    mutation UpdateCampaignWithEndScreens($campaignEndScreensInput: UpdateCampaignEndScreensInput!, $campaignInput: UpdateCampaignInput!) {
  updateCampaignEndScreens(input: $campaignEndScreensInput) {
    items {
      id
    }
  }
  updateCampaign(input: $campaignInput) {
    createdAt
    endDate
    id
    name
    startDate
    status
    updatedAt
    views
  }
}
    `;
export type UpdateCampaignWithEndScreensMutationFn = Apollo.MutationFunction<UpdateCampaignWithEndScreensMutation, UpdateCampaignWithEndScreensMutationVariables>;

/**
 * __useUpdateCampaignWithEndScreensMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignWithEndScreensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignWithEndScreensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignWithEndScreensMutation, { data, loading, error }] = useUpdateCampaignWithEndScreensMutation({
 *   variables: {
 *      campaignEndScreensInput: // value for 'campaignEndScreensInput'
 *      campaignInput: // value for 'campaignInput'
 *   },
 * });
 */
export function useUpdateCampaignWithEndScreensMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignWithEndScreensMutation, UpdateCampaignWithEndScreensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignWithEndScreensMutation, UpdateCampaignWithEndScreensMutationVariables>(UpdateCampaignWithEndScreensDocument, options);
      }
export type UpdateCampaignWithEndScreensMutationHookResult = ReturnType<typeof useUpdateCampaignWithEndScreensMutation>;
export type UpdateCampaignWithEndScreensMutationResult = Apollo.MutationResult<UpdateCampaignWithEndScreensMutation>;
export type UpdateCampaignWithEndScreensMutationOptions = Apollo.BaseMutationOptions<UpdateCampaignWithEndScreensMutation, UpdateCampaignWithEndScreensMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($input: UpdateChannelInput!) {
  updateChannel(input: $input) {
    id
    prioritized
    tier {
      id
    }
  }
}
    `;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, options);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const UpdateEndScreenDefaultListDocument = gql`
    mutation updateEndScreenDefaultList($input: UpdateEndScreenDefaultListInput!) {
  updateEndScreenDefaultList(input: $input) {
    items {
      createdAt
      durationMs
      id
      left
      message
      position
      top
      type
      updatedAt
      urlId
      width
      height
      externalLinkCTA
      imageDataUrl
    }
  }
}
    `;
export type UpdateEndScreenDefaultListMutationFn = Apollo.MutationFunction<UpdateEndScreenDefaultListMutation, UpdateEndScreenDefaultListMutationVariables>;

/**
 * __useUpdateEndScreenDefaultListMutation__
 *
 * To run a mutation, you first call `useUpdateEndScreenDefaultListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndScreenDefaultListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndScreenDefaultListMutation, { data, loading, error }] = useUpdateEndScreenDefaultListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEndScreenDefaultListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEndScreenDefaultListMutation, UpdateEndScreenDefaultListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEndScreenDefaultListMutation, UpdateEndScreenDefaultListMutationVariables>(UpdateEndScreenDefaultListDocument, options);
      }
export type UpdateEndScreenDefaultListMutationHookResult = ReturnType<typeof useUpdateEndScreenDefaultListMutation>;
export type UpdateEndScreenDefaultListMutationResult = Apollo.MutationResult<UpdateEndScreenDefaultListMutation>;
export type UpdateEndScreenDefaultListMutationOptions = Apollo.BaseMutationOptions<UpdateEndScreenDefaultListMutation, UpdateEndScreenDefaultListMutationVariables>;
export const UpdateEndScreensTemplateDocument = gql`
    mutation UpdateEndScreensTemplate($input: updateEndScreensTemplateInput!) {
  updateEndScreensTemplate(input: $input) {
    id
    name
  }
}
    `;
export type UpdateEndScreensTemplateMutationFn = Apollo.MutationFunction<UpdateEndScreensTemplateMutation, UpdateEndScreensTemplateMutationVariables>;

/**
 * __useUpdateEndScreensTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateEndScreensTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndScreensTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndScreensTemplateMutation, { data, loading, error }] = useUpdateEndScreensTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEndScreensTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEndScreensTemplateMutation, UpdateEndScreensTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEndScreensTemplateMutation, UpdateEndScreensTemplateMutationVariables>(UpdateEndScreensTemplateDocument, options);
      }
export type UpdateEndScreensTemplateMutationHookResult = ReturnType<typeof useUpdateEndScreensTemplateMutation>;
export type UpdateEndScreensTemplateMutationResult = Apollo.MutationResult<UpdateEndScreensTemplateMutation>;
export type UpdateEndScreensTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateEndScreensTemplateMutation, UpdateEndScreensTemplateMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    name
    email
    role
    status
    createdAt
    updatedAt
    lastAction
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateYoutubeCredentialsDocument = gql`
    mutation UpdateYoutubeCredentials($input: updateYoutubeCredentialsInput!) {
  updateYoutubeCredentials(input: $input) {
    apisid
    hsid
    info
    sapisid
    sid
    ssid
    token
  }
}
    `;
export type UpdateYoutubeCredentialsMutationFn = Apollo.MutationFunction<UpdateYoutubeCredentialsMutation, UpdateYoutubeCredentialsMutationVariables>;

/**
 * __useUpdateYoutubeCredentialsMutation__
 *
 * To run a mutation, you first call `useUpdateYoutubeCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateYoutubeCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateYoutubeCredentialsMutation, { data, loading, error }] = useUpdateYoutubeCredentialsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateYoutubeCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateYoutubeCredentialsMutation, UpdateYoutubeCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateYoutubeCredentialsMutation, UpdateYoutubeCredentialsMutationVariables>(UpdateYoutubeCredentialsDocument, options);
      }
export type UpdateYoutubeCredentialsMutationHookResult = ReturnType<typeof useUpdateYoutubeCredentialsMutation>;
export type UpdateYoutubeCredentialsMutationResult = Apollo.MutationResult<UpdateYoutubeCredentialsMutation>;
export type UpdateYoutubeCredentialsMutationOptions = Apollo.BaseMutationOptions<UpdateYoutubeCredentialsMutation, UpdateYoutubeCredentialsMutationVariables>;
export const UploadVideoListDocument = gql`
    mutation UploadVideoList($input: UploadVideoListInput!) {
  uploadVideoList(input: $input) {
    uploaded
  }
}
    `;
export type UploadVideoListMutationFn = Apollo.MutationFunction<UploadVideoListMutation, UploadVideoListMutationVariables>;

/**
 * __useUploadVideoListMutation__
 *
 * To run a mutation, you first call `useUploadVideoListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVideoListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVideoListMutation, { data, loading, error }] = useUploadVideoListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadVideoListMutation(baseOptions?: Apollo.MutationHookOptions<UploadVideoListMutation, UploadVideoListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadVideoListMutation, UploadVideoListMutationVariables>(UploadVideoListDocument, options);
      }
export type UploadVideoListMutationHookResult = ReturnType<typeof useUploadVideoListMutation>;
export type UploadVideoListMutationResult = Apollo.MutationResult<UploadVideoListMutation>;
export type UploadVideoListMutationOptions = Apollo.BaseMutationOptions<UploadVideoListMutation, UploadVideoListMutationVariables>;
export const CheckGoogleAuthDocument = gql`
    query CheckGoogleAuth {
  checkGoogleAuth {
    valid
    googleLoginUri
  }
}
    `;

/**
 * __useCheckGoogleAuthQuery__
 *
 * To run a query within a React component, call `useCheckGoogleAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckGoogleAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckGoogleAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useCheckGoogleAuthQuery(baseOptions?: Apollo.QueryHookOptions<CheckGoogleAuthQuery, CheckGoogleAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckGoogleAuthQuery, CheckGoogleAuthQueryVariables>(CheckGoogleAuthDocument, options);
      }
export function useCheckGoogleAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckGoogleAuthQuery, CheckGoogleAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckGoogleAuthQuery, CheckGoogleAuthQueryVariables>(CheckGoogleAuthDocument, options);
        }
export type CheckGoogleAuthQueryHookResult = ReturnType<typeof useCheckGoogleAuthQuery>;
export type CheckGoogleAuthLazyQueryHookResult = ReturnType<typeof useCheckGoogleAuthLazyQuery>;
export type CheckGoogleAuthQueryResult = Apollo.QueryResult<CheckGoogleAuthQuery, CheckGoogleAuthQueryVariables>;
export const GetAddVideoLoadingStatusDocument = gql`
    query GetAddVideoLoadingStatus($input: GetAddVideoLoadingStatusInput!) {
  getAddVideoLoadingStatus(input: $input) {
    requested
  }
}
    `;

/**
 * __useGetAddVideoLoadingStatusQuery__
 *
 * To run a query within a React component, call `useGetAddVideoLoadingStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddVideoLoadingStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddVideoLoadingStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAddVideoLoadingStatusQuery(baseOptions: Apollo.QueryHookOptions<GetAddVideoLoadingStatusQuery, GetAddVideoLoadingStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAddVideoLoadingStatusQuery, GetAddVideoLoadingStatusQueryVariables>(GetAddVideoLoadingStatusDocument, options);
      }
export function useGetAddVideoLoadingStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAddVideoLoadingStatusQuery, GetAddVideoLoadingStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAddVideoLoadingStatusQuery, GetAddVideoLoadingStatusQueryVariables>(GetAddVideoLoadingStatusDocument, options);
        }
export type GetAddVideoLoadingStatusQueryHookResult = ReturnType<typeof useGetAddVideoLoadingStatusQuery>;
export type GetAddVideoLoadingStatusLazyQueryHookResult = ReturnType<typeof useGetAddVideoLoadingStatusLazyQuery>;
export type GetAddVideoLoadingStatusQueryResult = Apollo.QueryResult<GetAddVideoLoadingStatusQuery, GetAddVideoLoadingStatusQueryVariables>;
export const GetAllViewsDocument = gql`
    query GetAllViews {
  getAllViews {
    currentMonth
    previousMonth
  }
}
    `;

/**
 * __useGetAllViewsQuery__
 *
 * To run a query within a React component, call `useGetAllViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllViewsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllViewsQuery, GetAllViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllViewsQuery, GetAllViewsQueryVariables>(GetAllViewsDocument, options);
      }
export function useGetAllViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllViewsQuery, GetAllViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllViewsQuery, GetAllViewsQueryVariables>(GetAllViewsDocument, options);
        }
export type GetAllViewsQueryHookResult = ReturnType<typeof useGetAllViewsQuery>;
export type GetAllViewsLazyQueryHookResult = ReturnType<typeof useGetAllViewsLazyQuery>;
export type GetAllViewsQueryResult = Apollo.QueryResult<GetAllViewsQuery, GetAllViewsQueryVariables>;
export const GetArtistListDocument = gql`
    query GetArtistList($input: GetArtistListInput!) {
  getArtistList(input: $input) {
    items {
      updatedAt
      createdAt
      name
      id
    }
    count
  }
}
    `;

/**
 * __useGetArtistListQuery__
 *
 * To run a query within a React component, call `useGetArtistListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtistListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtistListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetArtistListQuery(baseOptions: Apollo.QueryHookOptions<GetArtistListQuery, GetArtistListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArtistListQuery, GetArtistListQueryVariables>(GetArtistListDocument, options);
      }
export function useGetArtistListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArtistListQuery, GetArtistListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArtistListQuery, GetArtistListQueryVariables>(GetArtistListDocument, options);
        }
export type GetArtistListQueryHookResult = ReturnType<typeof useGetArtistListQuery>;
export type GetArtistListLazyQueryHookResult = ReturnType<typeof useGetArtistListLazyQuery>;
export type GetArtistListQueryResult = Apollo.QueryResult<GetArtistListQuery, GetArtistListQueryVariables>;
export const GetCampaignDocument = gql`
    query GetCampaign($input: GetCampaignInput!) {
  getCampaign(input: $input) {
    item {
      ...CampaignWithRelations
    }
  }
}
    ${CampaignWithRelationsFragmentDoc}`;

/**
 * __useGetCampaignQuery__
 *
 * To run a query within a React component, call `useGetCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
      }
export function useGetCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignQuery, GetCampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignQuery, GetCampaignQueryVariables>(GetCampaignDocument, options);
        }
export type GetCampaignQueryHookResult = ReturnType<typeof useGetCampaignQuery>;
export type GetCampaignLazyQueryHookResult = ReturnType<typeof useGetCampaignLazyQuery>;
export type GetCampaignQueryResult = Apollo.QueryResult<GetCampaignQuery, GetCampaignQueryVariables>;
export const GetCampaignDetailsPerformanceDocument = gql`
    query GetCampaignDetailsPerformance($input: GetCampaignDetailsPerformanceInput!) {
  getCampaignDetailsPerformance(input: $input) {
    campaign {
      id
      endDate
      createdAt
      name
      startDate
      status
      updatedAt
      views
    }
    endscreens {
      metrics {
        clicksCount
        dayCovered
        impressionsCount
        ctr
      }
      durationMs
      id
      isDefault
      left
      message
      top
      position
      type
      urlId
      width
    }
  }
}
    `;

/**
 * __useGetCampaignDetailsPerformanceQuery__
 *
 * To run a query within a React component, call `useGetCampaignDetailsPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignDetailsPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignDetailsPerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignDetailsPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignDetailsPerformanceQuery, GetCampaignDetailsPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignDetailsPerformanceQuery, GetCampaignDetailsPerformanceQueryVariables>(GetCampaignDetailsPerformanceDocument, options);
      }
export function useGetCampaignDetailsPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignDetailsPerformanceQuery, GetCampaignDetailsPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignDetailsPerformanceQuery, GetCampaignDetailsPerformanceQueryVariables>(GetCampaignDetailsPerformanceDocument, options);
        }
export type GetCampaignDetailsPerformanceQueryHookResult = ReturnType<typeof useGetCampaignDetailsPerformanceQuery>;
export type GetCampaignDetailsPerformanceLazyQueryHookResult = ReturnType<typeof useGetCampaignDetailsPerformanceLazyQuery>;
export type GetCampaignDetailsPerformanceQueryResult = Apollo.QueryResult<GetCampaignDetailsPerformanceQuery, GetCampaignDetailsPerformanceQueryVariables>;
export const GetCampaignListDocument = gql`
    query GetCampaignList($input: GetCampaignListInput!) {
  getCampaignList(input: $input) {
    count
    items {
      createdAt
      endDate
      id
      name
      startDate
      status
      updatedAt
      views
    }
  }
}
    `;

/**
 * __useGetCampaignListQuery__
 *
 * To run a query within a React component, call `useGetCampaignListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignListQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignListQuery, GetCampaignListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignListQuery, GetCampaignListQueryVariables>(GetCampaignListDocument, options);
      }
export function useGetCampaignListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignListQuery, GetCampaignListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignListQuery, GetCampaignListQueryVariables>(GetCampaignListDocument, options);
        }
export type GetCampaignListQueryHookResult = ReturnType<typeof useGetCampaignListQuery>;
export type GetCampaignListLazyQueryHookResult = ReturnType<typeof useGetCampaignListLazyQuery>;
export type GetCampaignListQueryResult = Apollo.QueryResult<GetCampaignListQuery, GetCampaignListQueryVariables>;
export const GetCampaignPerformanceDocument = gql`
    query GetCampaignPerformance($input: GetCampaignPerformanceInput!) {
  getCampaignPerformance(input: $input) {
    data {
      id
      endDate
      createdAt
      name
      startDate
      status
      updatedAt
      views
      metrics {
        clicksCount
        dayCovered
        impressionsCount
        ctr
      }
    }
  }
}
    `;

/**
 * __useGetCampaignPerformanceQuery__
 *
 * To run a query within a React component, call `useGetCampaignPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignPerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignPerformanceQuery, GetCampaignPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignPerformanceQuery, GetCampaignPerformanceQueryVariables>(GetCampaignPerformanceDocument, options);
      }
export function useGetCampaignPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignPerformanceQuery, GetCampaignPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignPerformanceQuery, GetCampaignPerformanceQueryVariables>(GetCampaignPerformanceDocument, options);
        }
export type GetCampaignPerformanceQueryHookResult = ReturnType<typeof useGetCampaignPerformanceQuery>;
export type GetCampaignPerformanceLazyQueryHookResult = ReturnType<typeof useGetCampaignPerformanceLazyQuery>;
export type GetCampaignPerformanceQueryResult = Apollo.QueryResult<GetCampaignPerformanceQuery, GetCampaignPerformanceQueryVariables>;
export const GetCampaignStatusDocument = gql`
    query GetCampaignStatus($input: GetCampaignStatusInput!) {
  getCampaignStatus(input: $input) {
    completed
    failed
    total
    stats {
      pending
      processing
      postprocessing
      complete
      failed
      in_progress
      cancelled
      delete
    }
  }
}
    `;

/**
 * __useGetCampaignStatusQuery__
 *
 * To run a query within a React component, call `useGetCampaignStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignStatusQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignStatusQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignStatusQuery, GetCampaignStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignStatusQuery, GetCampaignStatusQueryVariables>(GetCampaignStatusDocument, options);
      }
export function useGetCampaignStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignStatusQuery, GetCampaignStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignStatusQuery, GetCampaignStatusQueryVariables>(GetCampaignStatusDocument, options);
        }
export type GetCampaignStatusQueryHookResult = ReturnType<typeof useGetCampaignStatusQuery>;
export type GetCampaignStatusLazyQueryHookResult = ReturnType<typeof useGetCampaignStatusLazyQuery>;
export type GetCampaignStatusQueryResult = Apollo.QueryResult<GetCampaignStatusQuery, GetCampaignStatusQueryVariables>;
export const GetCampaignViewsDocument = gql`
    query GetCampaignViews($input: GetCampaignViewsInput!) {
  getCampaignViews(input: $input) {
    views
  }
}
    `;

/**
 * __useGetCampaignViewsQuery__
 *
 * To run a query within a React component, call `useGetCampaignViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignViewsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCampaignViewsQuery(baseOptions: Apollo.QueryHookOptions<GetCampaignViewsQuery, GetCampaignViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCampaignViewsQuery, GetCampaignViewsQueryVariables>(GetCampaignViewsDocument, options);
      }
export function useGetCampaignViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCampaignViewsQuery, GetCampaignViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCampaignViewsQuery, GetCampaignViewsQueryVariables>(GetCampaignViewsDocument, options);
        }
export type GetCampaignViewsQueryHookResult = ReturnType<typeof useGetCampaignViewsQuery>;
export type GetCampaignViewsLazyQueryHookResult = ReturnType<typeof useGetCampaignViewsLazyQuery>;
export type GetCampaignViewsQueryResult = Apollo.QueryResult<GetCampaignViewsQuery, GetCampaignViewsQueryVariables>;
export const GetChannelDocument = gql`
    query GetChannel($input: GetChannelInput!) {
  getChannel(input: $input) {
    item {
      id
      createdAt
      name
      subscriberCount
      updatedAt
      viewCount
      playlist
      subscriberCount
      viewCount
      addedAt
      claimUploads
      censorship
      monetized
      videoCount
      genre {
        name
      }
      tier {
        name
      }
      region {
        name
      }
      artist {
        name
      }
    }
  }
}
    `;

/**
 * __useGetChannelQuery__
 *
 * To run a query within a React component, call `useGetChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChannelQuery(baseOptions: Apollo.QueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, options);
      }
export function useGetChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelQuery, GetChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelQuery, GetChannelQueryVariables>(GetChannelDocument, options);
        }
export type GetChannelQueryHookResult = ReturnType<typeof useGetChannelQuery>;
export type GetChannelLazyQueryHookResult = ReturnType<typeof useGetChannelLazyQuery>;
export type GetChannelQueryResult = Apollo.QueryResult<GetChannelQuery, GetChannelQueryVariables>;
export const GetChannelListDocument = gql`
    query GetChannelList($input: GetChannelListInput) {
  getChannelList(input: $input) {
    count
    items {
      createdAt
      id
      name
      subscriberCount
      updatedAt
      viewCount
      youtubeId
      playlist
      viewCount
      addedAt
      createdAt
      artist {
        name
      }
      tier {
        name
      }
      region {
        name
      }
      genre {
        name
      }
      claimUploads
      censorship
      monetized
      videoCount
      prioritized
      parentLabel {
        name
      }
      subLabel {
        name
      }
    }
  }
}
    `;

/**
 * __useGetChannelListQuery__
 *
 * To run a query within a React component, call `useGetChannelListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChannelListQuery(baseOptions?: Apollo.QueryHookOptions<GetChannelListQuery, GetChannelListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelListQuery, GetChannelListQueryVariables>(GetChannelListDocument, options);
      }
export function useGetChannelListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelListQuery, GetChannelListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelListQuery, GetChannelListQueryVariables>(GetChannelListDocument, options);
        }
export type GetChannelListQueryHookResult = ReturnType<typeof useGetChannelListQuery>;
export type GetChannelListLazyQueryHookResult = ReturnType<typeof useGetChannelListLazyQuery>;
export type GetChannelListQueryResult = Apollo.QueryResult<GetChannelListQuery, GetChannelListQueryVariables>;
export const GetChannelPerformanceDocument = gql`
    query GetChannelPerformance($input: GetChannelPerformanceInput!) {
  getChannelPerformance(input: $input) {
    data {
      id
      youtubeId
      name
      playlist
      subscriberCount
      viewCount
      addedAt
      createdAt
      updatedAt
      claimUploads
      censorship
      monetized
      videoCount
      metrics {
        clicksCount
        dayCovered
        impressionsCount
        ctr
      }
    }
  }
}
    `;

/**
 * __useGetChannelPerformanceQuery__
 *
 * To run a query within a React component, call `useGetChannelPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelPerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetChannelPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetChannelPerformanceQuery, GetChannelPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelPerformanceQuery, GetChannelPerformanceQueryVariables>(GetChannelPerformanceDocument, options);
      }
export function useGetChannelPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelPerformanceQuery, GetChannelPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelPerformanceQuery, GetChannelPerformanceQueryVariables>(GetChannelPerformanceDocument, options);
        }
export type GetChannelPerformanceQueryHookResult = ReturnType<typeof useGetChannelPerformanceQuery>;
export type GetChannelPerformanceLazyQueryHookResult = ReturnType<typeof useGetChannelPerformanceLazyQuery>;
export type GetChannelPerformanceQueryResult = Apollo.QueryResult<GetChannelPerformanceQuery, GetChannelPerformanceQueryVariables>;
export const GetCountryListDocument = gql`
    query GetCountryList($input: GetCountryListInput!) {
  getCountryList(input: $input) {
    items {
      id
      code
    }
  }
}
    `;

/**
 * __useGetCountryListQuery__
 *
 * To run a query within a React component, call `useGetCountryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCountryListQuery(baseOptions: Apollo.QueryHookOptions<GetCountryListQuery, GetCountryListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryListQuery, GetCountryListQueryVariables>(GetCountryListDocument, options);
      }
export function useGetCountryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryListQuery, GetCountryListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryListQuery, GetCountryListQueryVariables>(GetCountryListDocument, options);
        }
export type GetCountryListQueryHookResult = ReturnType<typeof useGetCountryListQuery>;
export type GetCountryListLazyQueryHookResult = ReturnType<typeof useGetCountryListLazyQuery>;
export type GetCountryListQueryResult = Apollo.QueryResult<GetCountryListQuery, GetCountryListQueryVariables>;
export const GetCountryPerformanceDocument = gql`
    query GetCountryPerformance($input: GetCountryPerformanceInput!) {
  getCountryPerformance(input: $input) {
    data {
      id
      code
      metrics {
        clicksCount
        dayCovered
        impressionsCount
        ctr
      }
    }
  }
}
    `;

/**
 * __useGetCountryPerformanceQuery__
 *
 * To run a query within a React component, call `useGetCountryPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCountryPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCountryPerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCountryPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetCountryPerformanceQuery, GetCountryPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountryPerformanceQuery, GetCountryPerformanceQueryVariables>(GetCountryPerformanceDocument, options);
      }
export function useGetCountryPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountryPerformanceQuery, GetCountryPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountryPerformanceQuery, GetCountryPerformanceQueryVariables>(GetCountryPerformanceDocument, options);
        }
export type GetCountryPerformanceQueryHookResult = ReturnType<typeof useGetCountryPerformanceQuery>;
export type GetCountryPerformanceLazyQueryHookResult = ReturnType<typeof useGetCountryPerformanceLazyQuery>;
export type GetCountryPerformanceQueryResult = Apollo.QueryResult<GetCountryPerformanceQuery, GetCountryPerformanceQueryVariables>;
export const GetEndScreenAvgCtrDocument = gql`
    query GetEndScreenAvgCTR {
  getEndScreenAvgCTR {
    currentMonth
    previousMonth
  }
}
    `;

/**
 * __useGetEndScreenAvgCtrQuery__
 *
 * To run a query within a React component, call `useGetEndScreenAvgCtrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenAvgCtrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenAvgCtrQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEndScreenAvgCtrQuery(baseOptions?: Apollo.QueryHookOptions<GetEndScreenAvgCtrQuery, GetEndScreenAvgCtrQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenAvgCtrQuery, GetEndScreenAvgCtrQueryVariables>(GetEndScreenAvgCtrDocument, options);
      }
export function useGetEndScreenAvgCtrLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenAvgCtrQuery, GetEndScreenAvgCtrQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenAvgCtrQuery, GetEndScreenAvgCtrQueryVariables>(GetEndScreenAvgCtrDocument, options);
        }
export type GetEndScreenAvgCtrQueryHookResult = ReturnType<typeof useGetEndScreenAvgCtrQuery>;
export type GetEndScreenAvgCtrLazyQueryHookResult = ReturnType<typeof useGetEndScreenAvgCtrLazyQuery>;
export type GetEndScreenAvgCtrQueryResult = Apollo.QueryResult<GetEndScreenAvgCtrQuery, GetEndScreenAvgCtrQueryVariables>;
export const GetEndScreenDefaultListDocument = gql`
    query GetEndScreenDefaultList {
  getEndScreenDefaultList {
    items {
      id
      durationMs
      createdAt
      left
      message
      position
      top
      type
      updatedAt
      urlId
      width
      height
      externalLinkCTA
      imageDataUrl
    }
  }
}
    `;

/**
 * __useGetEndScreenDefaultListQuery__
 *
 * To run a query within a React component, call `useGetEndScreenDefaultListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenDefaultListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenDefaultListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEndScreenDefaultListQuery(baseOptions?: Apollo.QueryHookOptions<GetEndScreenDefaultListQuery, GetEndScreenDefaultListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenDefaultListQuery, GetEndScreenDefaultListQueryVariables>(GetEndScreenDefaultListDocument, options);
      }
export function useGetEndScreenDefaultListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenDefaultListQuery, GetEndScreenDefaultListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenDefaultListQuery, GetEndScreenDefaultListQueryVariables>(GetEndScreenDefaultListDocument, options);
        }
export type GetEndScreenDefaultListQueryHookResult = ReturnType<typeof useGetEndScreenDefaultListQuery>;
export type GetEndScreenDefaultListLazyQueryHookResult = ReturnType<typeof useGetEndScreenDefaultListLazyQuery>;
export type GetEndScreenDefaultListQueryResult = Apollo.QueryResult<GetEndScreenDefaultListQuery, GetEndScreenDefaultListQueryVariables>;
export const GetEndScreenDefaultThumbnailDocument = gql`
    query GetEndScreenDefaultThumbnail($input: GetEndScreenDefaultThumbnailInput!) {
  getEndScreenDefaultThumbnail(input: $input) {
    thumbnail
    name
    endscreenId
    youtubeId
  }
}
    `;

/**
 * __useGetEndScreenDefaultThumbnailQuery__
 *
 * To run a query within a React component, call `useGetEndScreenDefaultThumbnailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenDefaultThumbnailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenDefaultThumbnailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndScreenDefaultThumbnailQuery(baseOptions: Apollo.QueryHookOptions<GetEndScreenDefaultThumbnailQuery, GetEndScreenDefaultThumbnailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenDefaultThumbnailQuery, GetEndScreenDefaultThumbnailQueryVariables>(GetEndScreenDefaultThumbnailDocument, options);
      }
export function useGetEndScreenDefaultThumbnailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenDefaultThumbnailQuery, GetEndScreenDefaultThumbnailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenDefaultThumbnailQuery, GetEndScreenDefaultThumbnailQueryVariables>(GetEndScreenDefaultThumbnailDocument, options);
        }
export type GetEndScreenDefaultThumbnailQueryHookResult = ReturnType<typeof useGetEndScreenDefaultThumbnailQuery>;
export type GetEndScreenDefaultThumbnailLazyQueryHookResult = ReturnType<typeof useGetEndScreenDefaultThumbnailLazyQuery>;
export type GetEndScreenDefaultThumbnailQueryResult = Apollo.QueryResult<GetEndScreenDefaultThumbnailQuery, GetEndScreenDefaultThumbnailQueryVariables>;
export const GetEndScreenTemplateDocument = gql`
    query GetEndScreenTemplate($input: getEndScreenTemplateInput!) {
  getEndScreenTemplate(input: $input) {
    ...EndScreenTemplate
  }
}
    ${EndScreenTemplateFragmentDoc}`;

/**
 * __useGetEndScreenTemplateQuery__
 *
 * To run a query within a React component, call `useGetEndScreenTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenTemplateQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndScreenTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetEndScreenTemplateQuery, GetEndScreenTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenTemplateQuery, GetEndScreenTemplateQueryVariables>(GetEndScreenTemplateDocument, options);
      }
export function useGetEndScreenTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenTemplateQuery, GetEndScreenTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenTemplateQuery, GetEndScreenTemplateQueryVariables>(GetEndScreenTemplateDocument, options);
        }
export type GetEndScreenTemplateQueryHookResult = ReturnType<typeof useGetEndScreenTemplateQuery>;
export type GetEndScreenTemplateLazyQueryHookResult = ReturnType<typeof useGetEndScreenTemplateLazyQuery>;
export type GetEndScreenTemplateQueryResult = Apollo.QueryResult<GetEndScreenTemplateQuery, GetEndScreenTemplateQueryVariables>;
export const GetEndScreenTemplateListDocument = gql`
    query GetEndScreenTemplateList($input: getEndScreenTemplateListInput!) {
  getEndScreenTemplateList(input: $input) {
    items {
      ...EndScreenTemplate
    }
    count
  }
}
    ${EndScreenTemplateFragmentDoc}`;

/**
 * __useGetEndScreenTemplateListQuery__
 *
 * To run a query within a React component, call `useGetEndScreenTemplateListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenTemplateListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenTemplateListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndScreenTemplateListQuery(baseOptions: Apollo.QueryHookOptions<GetEndScreenTemplateListQuery, GetEndScreenTemplateListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenTemplateListQuery, GetEndScreenTemplateListQueryVariables>(GetEndScreenTemplateListDocument, options);
      }
export function useGetEndScreenTemplateListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenTemplateListQuery, GetEndScreenTemplateListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenTemplateListQuery, GetEndScreenTemplateListQueryVariables>(GetEndScreenTemplateListDocument, options);
        }
export type GetEndScreenTemplateListQueryHookResult = ReturnType<typeof useGetEndScreenTemplateListQuery>;
export type GetEndScreenTemplateListLazyQueryHookResult = ReturnType<typeof useGetEndScreenTemplateListLazyQuery>;
export type GetEndScreenTemplateListQueryResult = Apollo.QueryResult<GetEndScreenTemplateListQuery, GetEndScreenTemplateListQueryVariables>;
export const GetEndScreenTemplateThumbnailDocument = gql`
    query GetEndScreenTemplateThumbnail($input: GetEndScreenTemplateThumbnailInput!) {
  getEndScreenTemplateThumbnail(input: $input) {
    thumbnail
    name
    endscreenId
    youtubeId
  }
}
    `;

/**
 * __useGetEndScreenTemplateThumbnailQuery__
 *
 * To run a query within a React component, call `useGetEndScreenTemplateThumbnailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenTemplateThumbnailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenTemplateThumbnailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndScreenTemplateThumbnailQuery(baseOptions: Apollo.QueryHookOptions<GetEndScreenTemplateThumbnailQuery, GetEndScreenTemplateThumbnailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenTemplateThumbnailQuery, GetEndScreenTemplateThumbnailQueryVariables>(GetEndScreenTemplateThumbnailDocument, options);
      }
export function useGetEndScreenTemplateThumbnailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenTemplateThumbnailQuery, GetEndScreenTemplateThumbnailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenTemplateThumbnailQuery, GetEndScreenTemplateThumbnailQueryVariables>(GetEndScreenTemplateThumbnailDocument, options);
        }
export type GetEndScreenTemplateThumbnailQueryHookResult = ReturnType<typeof useGetEndScreenTemplateThumbnailQuery>;
export type GetEndScreenTemplateThumbnailLazyQueryHookResult = ReturnType<typeof useGetEndScreenTemplateThumbnailLazyQuery>;
export type GetEndScreenTemplateThumbnailQueryResult = Apollo.QueryResult<GetEndScreenTemplateThumbnailQuery, GetEndScreenTemplateThumbnailQueryVariables>;
export const GetEndScreenThumbnailDocument = gql`
    query GetEndScreenThumbnail($input: GetEndScreenThumbnailInput!) {
  getEndScreenThumbnail(input: $input) {
    thumbnail
    name
    endscreenId
    youtubeId
  }
}
    `;

/**
 * __useGetEndScreenThumbnailQuery__
 *
 * To run a query within a React component, call `useGetEndScreenThumbnailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenThumbnailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenThumbnailQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEndScreenThumbnailQuery(baseOptions: Apollo.QueryHookOptions<GetEndScreenThumbnailQuery, GetEndScreenThumbnailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenThumbnailQuery, GetEndScreenThumbnailQueryVariables>(GetEndScreenThumbnailDocument, options);
      }
export function useGetEndScreenThumbnailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenThumbnailQuery, GetEndScreenThumbnailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenThumbnailQuery, GetEndScreenThumbnailQueryVariables>(GetEndScreenThumbnailDocument, options);
        }
export type GetEndScreenThumbnailQueryHookResult = ReturnType<typeof useGetEndScreenThumbnailQuery>;
export type GetEndScreenThumbnailLazyQueryHookResult = ReturnType<typeof useGetEndScreenThumbnailLazyQuery>;
export type GetEndScreenThumbnailQueryResult = Apollo.QueryResult<GetEndScreenThumbnailQuery, GetEndScreenThumbnailQueryVariables>;
export const GetEndScreenViewsDocument = gql`
    query GetEndScreenViews {
  getEndScreenViews {
    currentMonth
    previousMonth
  }
}
    `;

/**
 * __useGetEndScreenViewsQuery__
 *
 * To run a query within a React component, call `useGetEndScreenViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenViewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEndScreenViewsQuery(baseOptions?: Apollo.QueryHookOptions<GetEndScreenViewsQuery, GetEndScreenViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenViewsQuery, GetEndScreenViewsQueryVariables>(GetEndScreenViewsDocument, options);
      }
export function useGetEndScreenViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenViewsQuery, GetEndScreenViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenViewsQuery, GetEndScreenViewsQueryVariables>(GetEndScreenViewsDocument, options);
        }
export type GetEndScreenViewsQueryHookResult = ReturnType<typeof useGetEndScreenViewsQuery>;
export type GetEndScreenViewsLazyQueryHookResult = ReturnType<typeof useGetEndScreenViewsLazyQuery>;
export type GetEndScreenViewsQueryResult = Apollo.QueryResult<GetEndScreenViewsQuery, GetEndScreenViewsQueryVariables>;
export const GetEndScreenViewsAvgDocument = gql`
    query GetEndScreenViewsAvg {
  getEndScreenViewsAvg {
    currentMonth
    previousMonth
  }
}
    `;

/**
 * __useGetEndScreenViewsAvgQuery__
 *
 * To run a query within a React component, call `useGetEndScreenViewsAvgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEndScreenViewsAvgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEndScreenViewsAvgQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEndScreenViewsAvgQuery(baseOptions?: Apollo.QueryHookOptions<GetEndScreenViewsAvgQuery, GetEndScreenViewsAvgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEndScreenViewsAvgQuery, GetEndScreenViewsAvgQueryVariables>(GetEndScreenViewsAvgDocument, options);
      }
export function useGetEndScreenViewsAvgLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEndScreenViewsAvgQuery, GetEndScreenViewsAvgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEndScreenViewsAvgQuery, GetEndScreenViewsAvgQueryVariables>(GetEndScreenViewsAvgDocument, options);
        }
export type GetEndScreenViewsAvgQueryHookResult = ReturnType<typeof useGetEndScreenViewsAvgQuery>;
export type GetEndScreenViewsAvgLazyQueryHookResult = ReturnType<typeof useGetEndScreenViewsAvgLazyQuery>;
export type GetEndScreenViewsAvgQueryResult = Apollo.QueryResult<GetEndScreenViewsAvgQuery, GetEndScreenViewsAvgQueryVariables>;
export const GetGenreFullListDocument = gql`
    query GetGenreFullList($input: GetGenreListInput) {
  getGenreFullList(input: $input) {
    items {
      name
      id
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useGetGenreFullListQuery__
 *
 * To run a query within a React component, call `useGetGenreFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenreFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenreFullListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGenreFullListQuery(baseOptions?: Apollo.QueryHookOptions<GetGenreFullListQuery, GetGenreFullListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenreFullListQuery, GetGenreFullListQueryVariables>(GetGenreFullListDocument, options);
      }
export function useGetGenreFullListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenreFullListQuery, GetGenreFullListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenreFullListQuery, GetGenreFullListQueryVariables>(GetGenreFullListDocument, options);
        }
export type GetGenreFullListQueryHookResult = ReturnType<typeof useGetGenreFullListQuery>;
export type GetGenreFullListLazyQueryHookResult = ReturnType<typeof useGetGenreFullListLazyQuery>;
export type GetGenreFullListQueryResult = Apollo.QueryResult<GetGenreFullListQuery, GetGenreFullListQueryVariables>;
export const GetGenrePerformanceDocument = gql`
    query GetGenrePerformance($input: GetGenrePerformanceInput!) {
  getGenrePerformance(input: $input) {
    data {
      id
      name
      metrics {
        clicksCount
        dayCovered
        impressionsCount
        ctr
      }
    }
  }
}
    `;

/**
 * __useGetGenrePerformanceQuery__
 *
 * To run a query within a React component, call `useGetGenrePerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenrePerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenrePerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetGenrePerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetGenrePerformanceQuery, GetGenrePerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenrePerformanceQuery, GetGenrePerformanceQueryVariables>(GetGenrePerformanceDocument, options);
      }
export function useGetGenrePerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenrePerformanceQuery, GetGenrePerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenrePerformanceQuery, GetGenrePerformanceQueryVariables>(GetGenrePerformanceDocument, options);
        }
export type GetGenrePerformanceQueryHookResult = ReturnType<typeof useGetGenrePerformanceQuery>;
export type GetGenrePerformanceLazyQueryHookResult = ReturnType<typeof useGetGenrePerformanceLazyQuery>;
export type GetGenrePerformanceQueryResult = Apollo.QueryResult<GetGenrePerformanceQuery, GetGenrePerformanceQueryVariables>;
export const GetLabelFullListDocument = gql`
    query GetLabelFullList($input: GetLabelListInput!) {
  getLabelList(input: $input) {
    items {
      name
      id
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useGetLabelFullListQuery__
 *
 * To run a query within a React component, call `useGetLabelFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelFullListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLabelFullListQuery(baseOptions: Apollo.QueryHookOptions<GetLabelFullListQuery, GetLabelFullListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLabelFullListQuery, GetLabelFullListQueryVariables>(GetLabelFullListDocument, options);
      }
export function useGetLabelFullListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLabelFullListQuery, GetLabelFullListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLabelFullListQuery, GetLabelFullListQueryVariables>(GetLabelFullListDocument, options);
        }
export type GetLabelFullListQueryHookResult = ReturnType<typeof useGetLabelFullListQuery>;
export type GetLabelFullListLazyQueryHookResult = ReturnType<typeof useGetLabelFullListLazyQuery>;
export type GetLabelFullListQueryResult = Apollo.QueryResult<GetLabelFullListQuery, GetLabelFullListQueryVariables>;
export const GetRegionFullListDocument = gql`
    query GetRegionFullList($input: GetRegionListInput) {
  getRegionFullList(input: $input) {
    items {
      id
      name
      createdAt
      updatedAt
    }
    count
  }
}
    `;

/**
 * __useGetRegionFullListQuery__
 *
 * To run a query within a React component, call `useGetRegionFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionFullListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetRegionFullListQuery(baseOptions?: Apollo.QueryHookOptions<GetRegionFullListQuery, GetRegionFullListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRegionFullListQuery, GetRegionFullListQueryVariables>(GetRegionFullListDocument, options);
      }
export function useGetRegionFullListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRegionFullListQuery, GetRegionFullListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRegionFullListQuery, GetRegionFullListQueryVariables>(GetRegionFullListDocument, options);
        }
export type GetRegionFullListQueryHookResult = ReturnType<typeof useGetRegionFullListQuery>;
export type GetRegionFullListLazyQueryHookResult = ReturnType<typeof useGetRegionFullListLazyQuery>;
export type GetRegionFullListQueryResult = Apollo.QueryResult<GetRegionFullListQuery, GetRegionFullListQueryVariables>;
export const GetChannelSyncStatusDocument = gql`
    query GetChannelSyncStatus {
  getChannelSyncStatus {
    loading
  }
}
    `;

/**
 * __useGetChannelSyncStatusQuery__
 *
 * To run a query within a React component, call `useGetChannelSyncStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChannelSyncStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChannelSyncStatusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChannelSyncStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetChannelSyncStatusQuery, GetChannelSyncStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChannelSyncStatusQuery, GetChannelSyncStatusQueryVariables>(GetChannelSyncStatusDocument, options);
      }
export function useGetChannelSyncStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChannelSyncStatusQuery, GetChannelSyncStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChannelSyncStatusQuery, GetChannelSyncStatusQueryVariables>(GetChannelSyncStatusDocument, options);
        }
export type GetChannelSyncStatusQueryHookResult = ReturnType<typeof useGetChannelSyncStatusQuery>;
export type GetChannelSyncStatusLazyQueryHookResult = ReturnType<typeof useGetChannelSyncStatusLazyQuery>;
export type GetChannelSyncStatusQueryResult = Apollo.QueryResult<GetChannelSyncStatusQuery, GetChannelSyncStatusQueryVariables>;
export const GetTierFullListDocument = gql`
    query GetTierFullList {
  getTierFullList {
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTierFullListQuery__
 *
 * To run a query within a React component, call `useGetTierFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTierFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTierFullListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTierFullListQuery(baseOptions?: Apollo.QueryHookOptions<GetTierFullListQuery, GetTierFullListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTierFullListQuery, GetTierFullListQueryVariables>(GetTierFullListDocument, options);
      }
export function useGetTierFullListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTierFullListQuery, GetTierFullListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTierFullListQuery, GetTierFullListQueryVariables>(GetTierFullListDocument, options);
        }
export type GetTierFullListQueryHookResult = ReturnType<typeof useGetTierFullListQuery>;
export type GetTierFullListLazyQueryHookResult = ReturnType<typeof useGetTierFullListLazyQuery>;
export type GetTierFullListQueryResult = Apollo.QueryResult<GetTierFullListQuery, GetTierFullListQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($input: GetUserInput!) {
  getUser(input: $input) {
    id
    name
    email
    role
    status
    createdAt
    updatedAt
    lastAction
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserListDocument = gql`
    query GetUserList($input: GetUserListInput!) {
  getUserList(input: $input) {
    count
    items {
      id
      name
      email
      role
      status
      createdAt
      updatedAt
      lastAction
    }
  }
}
    `;

/**
 * __useGetUserListQuery__
 *
 * To run a query within a React component, call `useGetUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetUserListQuery(baseOptions: Apollo.QueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, options);
      }
export function useGetUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserListQuery, GetUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserListQuery, GetUserListQueryVariables>(GetUserListDocument, options);
        }
export type GetUserListQueryHookResult = ReturnType<typeof useGetUserListQuery>;
export type GetUserListLazyQueryHookResult = ReturnType<typeof useGetUserListLazyQuery>;
export type GetUserListQueryResult = Apollo.QueryResult<GetUserListQuery, GetUserListQueryVariables>;
export const GetVideoPerformanceDocument = gql`
    query GetVideoPerformance($input: GetVideoPerformanceInput!) {
  getVideoPerformance(input: $input) {
    data {
      id
      name
      thumbnail
      viewCount
      likesCount
      youtubeId
      createdAt
      updatedAt
      metrics {
        clicksCount
        dayCovered
        impressionsCount
        ctr
      }
    }
  }
}
    `;

/**
 * __useGetVideoPerformanceQuery__
 *
 * To run a query within a React component, call `useGetVideoPerformanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoPerformanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoPerformanceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVideoPerformanceQuery(baseOptions: Apollo.QueryHookOptions<GetVideoPerformanceQuery, GetVideoPerformanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoPerformanceQuery, GetVideoPerformanceQueryVariables>(GetVideoPerformanceDocument, options);
      }
export function useGetVideoPerformanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoPerformanceQuery, GetVideoPerformanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoPerformanceQuery, GetVideoPerformanceQueryVariables>(GetVideoPerformanceDocument, options);
        }
export type GetVideoPerformanceQueryHookResult = ReturnType<typeof useGetVideoPerformanceQuery>;
export type GetVideoPerformanceLazyQueryHookResult = ReturnType<typeof useGetVideoPerformanceLazyQuery>;
export type GetVideoPerformanceQueryResult = Apollo.QueryResult<GetVideoPerformanceQuery, GetVideoPerformanceQueryVariables>;
export const GetVideosDocument = gql`
    query GetVideos($input: GetVideosInput!) {
  getVideos(input: $input) {
    count
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useGetVideosQuery__
 *
 * To run a query within a React component, call `useGetVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVideosQuery(baseOptions: Apollo.QueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
      }
export function useGetVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideosQuery, GetVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideosQuery, GetVideosQueryVariables>(GetVideosDocument, options);
        }
export type GetVideosQueryHookResult = ReturnType<typeof useGetVideosQuery>;
export type GetVideosLazyQueryHookResult = ReturnType<typeof useGetVideosLazyQuery>;
export type GetVideosQueryResult = Apollo.QueryResult<GetVideosQuery, GetVideosQueryVariables>;
export const GetVideosFullListDocument = gql`
    query GetVideosFullList($input: GetVideoListInput!) {
  getVideosFullList(input: $input) {
    count
    items {
      id
      likesCount
      name
      thumbnail
      viewCount
      youtubeId
      selected
      artist {
        name
        id
      }
      parentLabel {
        name
        id
      }
      campaign {
        id
        name
      }
      channel {
        id
        name
        genre {
          name
          id
        }
        region {
          name
          id
        }
      }
      applied {
        id
        startDate
        endDate
        name
      }
    }
  }
}
    `;

/**
 * __useGetVideosFullListQuery__
 *
 * To run a query within a React component, call `useGetVideosFullListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideosFullListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideosFullListQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetVideosFullListQuery(baseOptions: Apollo.QueryHookOptions<GetVideosFullListQuery, GetVideosFullListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideosFullListQuery, GetVideosFullListQueryVariables>(GetVideosFullListDocument, options);
      }
export function useGetVideosFullListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideosFullListQuery, GetVideosFullListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideosFullListQuery, GetVideosFullListQueryVariables>(GetVideosFullListDocument, options);
        }
export type GetVideosFullListQueryHookResult = ReturnType<typeof useGetVideosFullListQuery>;
export type GetVideosFullListLazyQueryHookResult = ReturnType<typeof useGetVideosFullListLazyQuery>;
export type GetVideosFullListQueryResult = Apollo.QueryResult<GetVideosFullListQuery, GetVideosFullListQueryVariables>;
export const GetYoutubeCredentialsDocument = gql`
    query GetYoutubeCredentials {
  getYoutubeCredentials {
    hsid
    apisid
    info
    sapisid
    sid
    ssid
    token
  }
}
    `;

/**
 * __useGetYoutubeCredentialsQuery__
 *
 * To run a query within a React component, call `useGetYoutubeCredentialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetYoutubeCredentialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetYoutubeCredentialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetYoutubeCredentialsQuery(baseOptions?: Apollo.QueryHookOptions<GetYoutubeCredentialsQuery, GetYoutubeCredentialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetYoutubeCredentialsQuery, GetYoutubeCredentialsQueryVariables>(GetYoutubeCredentialsDocument, options);
      }
export function useGetYoutubeCredentialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetYoutubeCredentialsQuery, GetYoutubeCredentialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetYoutubeCredentialsQuery, GetYoutubeCredentialsQueryVariables>(GetYoutubeCredentialsDocument, options);
        }
export type GetYoutubeCredentialsQueryHookResult = ReturnType<typeof useGetYoutubeCredentialsQuery>;
export type GetYoutubeCredentialsLazyQueryHookResult = ReturnType<typeof useGetYoutubeCredentialsLazyQuery>;
export type GetYoutubeCredentialsQueryResult = Apollo.QueryResult<GetYoutubeCredentialsQuery, GetYoutubeCredentialsQueryVariables>;
export const TaskExistsDocument = gql`
    query TaskExists($input: TaskExistsInput!) {
  taskExists(input: $input) {
    exists
  }
}
    `;

/**
 * __useTaskExistsQuery__
 *
 * To run a query within a React component, call `useTaskExistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskExistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskExistsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTaskExistsQuery(baseOptions: Apollo.QueryHookOptions<TaskExistsQuery, TaskExistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskExistsQuery, TaskExistsQueryVariables>(TaskExistsDocument, options);
      }
export function useTaskExistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskExistsQuery, TaskExistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskExistsQuery, TaskExistsQueryVariables>(TaskExistsDocument, options);
        }
export type TaskExistsQueryHookResult = ReturnType<typeof useTaskExistsQuery>;
export type TaskExistsLazyQueryHookResult = ReturnType<typeof useTaskExistsLazyQuery>;
export type TaskExistsQueryResult = Apollo.QueryResult<TaskExistsQuery, TaskExistsQueryVariables>;
export const OnIncrementCampaignCountersDocument = gql`
    subscription OnIncrementCampaignCounters {
  onIncrementCampaignCounters {
    campaignId
    completed
    total
    failed
  }
}
    `;

/**
 * __useOnIncrementCampaignCountersSubscription__
 *
 * To run a query within a React component, call `useOnIncrementCampaignCountersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnIncrementCampaignCountersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnIncrementCampaignCountersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnIncrementCampaignCountersSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnIncrementCampaignCountersSubscription, OnIncrementCampaignCountersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnIncrementCampaignCountersSubscription, OnIncrementCampaignCountersSubscriptionVariables>(OnIncrementCampaignCountersDocument, options);
      }
export type OnIncrementCampaignCountersSubscriptionHookResult = ReturnType<typeof useOnIncrementCampaignCountersSubscription>;
export type OnIncrementCampaignCountersSubscriptionResult = Apollo.SubscriptionResult<OnIncrementCampaignCountersSubscription>;
export const OnUpdateAddVideoLoadingStatusDocument = gql`
    subscription OnUpdateAddVideoLoadingStatus {
  onUpdateAddVideoLoadingStatus {
    requested
  }
}
    `;

/**
 * __useOnUpdateAddVideoLoadingStatusSubscription__
 *
 * To run a query within a React component, call `useOnUpdateAddVideoLoadingStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateAddVideoLoadingStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateAddVideoLoadingStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateAddVideoLoadingStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateAddVideoLoadingStatusSubscription, OnUpdateAddVideoLoadingStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateAddVideoLoadingStatusSubscription, OnUpdateAddVideoLoadingStatusSubscriptionVariables>(OnUpdateAddVideoLoadingStatusDocument, options);
      }
export type OnUpdateAddVideoLoadingStatusSubscriptionHookResult = ReturnType<typeof useOnUpdateAddVideoLoadingStatusSubscription>;
export type OnUpdateAddVideoLoadingStatusSubscriptionResult = Apollo.SubscriptionResult<OnUpdateAddVideoLoadingStatusSubscription>;
export const OnUpdateCampaignProcessingStatusDocument = gql`
    subscription OnUpdateCampaignProcessingStatus {
  onUpdateCampaignProcessingStatus {
    id
    status
  }
}
    `;

/**
 * __useOnUpdateCampaignProcessingStatusSubscription__
 *
 * To run a query within a React component, call `useOnUpdateCampaignProcessingStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCampaignProcessingStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCampaignProcessingStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateCampaignProcessingStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateCampaignProcessingStatusSubscription, OnUpdateCampaignProcessingStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateCampaignProcessingStatusSubscription, OnUpdateCampaignProcessingStatusSubscriptionVariables>(OnUpdateCampaignProcessingStatusDocument, options);
      }
export type OnUpdateCampaignProcessingStatusSubscriptionHookResult = ReturnType<typeof useOnUpdateCampaignProcessingStatusSubscription>;
export type OnUpdateCampaignProcessingStatusSubscriptionResult = Apollo.SubscriptionResult<OnUpdateCampaignProcessingStatusSubscription>;
export const OnUpdateCampaignStatusDocument = gql`
    subscription OnUpdateCampaignStatus {
  onUpdateCampaignStatus {
    campaignId
    completed
    total
    failed
  }
}
    `;

/**
 * __useOnUpdateCampaignStatusSubscription__
 *
 * To run a query within a React component, call `useOnUpdateCampaignStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCampaignStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCampaignStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateCampaignStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateCampaignStatusSubscription, OnUpdateCampaignStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateCampaignStatusSubscription, OnUpdateCampaignStatusSubscriptionVariables>(OnUpdateCampaignStatusDocument, options);
      }
export type OnUpdateCampaignStatusSubscriptionHookResult = ReturnType<typeof useOnUpdateCampaignStatusSubscription>;
export type OnUpdateCampaignStatusSubscriptionResult = Apollo.SubscriptionResult<OnUpdateCampaignStatusSubscription>;
export const OnUpdateCampaignVideoProcessingStatusDocument = gql`
    subscription OnUpdateCampaignVideoProcessingStatus {
  onUpdateCampaignVideoProcessingStatus {
    campaignId
    videoId
    status
    reason
    start
    stats {
      pending
      processing
      postprocessing
      complete
      failed
      in_progress
      cancelled
      delete
    }
  }
}
    `;

/**
 * __useOnUpdateCampaignVideoProcessingStatusSubscription__
 *
 * To run a query within a React component, call `useOnUpdateCampaignVideoProcessingStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnUpdateCampaignVideoProcessingStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnUpdateCampaignVideoProcessingStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnUpdateCampaignVideoProcessingStatusSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnUpdateCampaignVideoProcessingStatusSubscription, OnUpdateCampaignVideoProcessingStatusSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnUpdateCampaignVideoProcessingStatusSubscription, OnUpdateCampaignVideoProcessingStatusSubscriptionVariables>(OnUpdateCampaignVideoProcessingStatusDocument, options);
      }
export type OnUpdateCampaignVideoProcessingStatusSubscriptionHookResult = ReturnType<typeof useOnUpdateCampaignVideoProcessingStatusSubscription>;
export type OnUpdateCampaignVideoProcessingStatusSubscriptionResult = Apollo.SubscriptionResult<OnUpdateCampaignVideoProcessingStatusSubscription>;
export const AddTaskDocument = gql`
    subscription AddTask {
  onCreateTask {
    name
  }
}
    `;

/**
 * __useAddTaskSubscription__
 *
 * To run a query within a React component, call `useAddTaskSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAddTaskSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddTaskSubscription({
 *   variables: {
 *   },
 * });
 */
export function useAddTaskSubscription(baseOptions?: Apollo.SubscriptionHookOptions<AddTaskSubscription, AddTaskSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<AddTaskSubscription, AddTaskSubscriptionVariables>(AddTaskDocument, options);
      }
export type AddTaskSubscriptionHookResult = ReturnType<typeof useAddTaskSubscription>;
export type AddTaskSubscriptionResult = Apollo.SubscriptionResult<AddTaskSubscription>;
export const DeleteTaskDocument = gql`
    subscription DeleteTask {
  onDeleteTask {
    name
  }
}
    `;

/**
 * __useDeleteTaskSubscription__
 *
 * To run a query within a React component, call `useDeleteTaskSubscription` and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeleteTaskSubscription({
 *   variables: {
 *   },
 * });
 */
export function useDeleteTaskSubscription(baseOptions?: Apollo.SubscriptionHookOptions<DeleteTaskSubscription, DeleteTaskSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<DeleteTaskSubscription, DeleteTaskSubscriptionVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskSubscriptionHookResult = ReturnType<typeof useDeleteTaskSubscription>;
export type DeleteTaskSubscriptionResult = Apollo.SubscriptionResult<DeleteTaskSubscription>;