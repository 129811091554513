import React, { FC } from 'react'
import ReportTagsProps from './ReportTags.props'
import { Tag } from '@wmg-ae/legato'
import styles from './ReportTags.module.scss'
import { v4 as uuidv4 } from 'uuid'

const ReportTags: FC<ReportTagsProps> = ({ tags, onRemoveTag }) => {
  return (
    <div className={styles.container}>
      {tags.map((tag) => (
        <Tag key={uuidv4()} text={tag} onCloseClick={() => onRemoveTag(tag)} className={styles.tag} />
      ))}
    </div>
  )
}

export default ReportTags
