import EndScreenElementInputWithPositionAndHeight from "features/Defaults/types/EndScreenDefaultElementInputWithPositionAndHeight";
import { EndScreenElementType } from "shared/graphql";
import replaceEndScreenElement from "./replaceEndScreenElement";

const handleChangeTableRow = (
    data: EndScreenElementInputWithPositionAndHeight,
    setElements: (x: (prevState: EndScreenElementInputWithPositionAndHeight[]) => EndScreenElementInputWithPositionAndHeight[]) => void,
    getThumbnailHandler: (id: string, urlId: string, type: EndScreenElementType) => void) => {
        setElements((oldElements: EndScreenElementInputWithPositionAndHeight[]) => {
        if (data.urlId && (data.type === EndScreenElementType.VideoById || data.type === EndScreenElementType.SubscribeChannelById)) {
            getThumbnailHandler(data.id, data.urlId, data.type);
        }

        return replaceEndScreenElement(oldElements, data.position, data)
    })
}

export default handleChangeTableRow;