import { useHistory } from 'react-router-dom'
import { Security } from '@okta/okta-react'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { ToastContainer } from 'react-toastify'

import { OKTA_CONFIG } from 'shared/constants'
import { MainLayout } from 'components'

import AuthApolloProvider from './apollo'
import Routes from './routes'
import './App.scss'

const oktaAuth: OktaAuth = new OktaAuth(OKTA_CONFIG)

const App = () => {
  const history = useHistory()
  const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string): Promise<void> => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  }
  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <AuthApolloProvider>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <MainLayout>
          <Routes />
        </MainLayout>
      </AuthApolloProvider>
    </Security>
  )
}

export default App
