import { GetVideoListFilters } from 'shared/graphql'

export const defaultFormValues: GetVideoListFilters = {
  name: '',
  region: '',
  artist: '',
  channel: '',
  genre: '',
  parentLabel: '',
  applied: false
}
