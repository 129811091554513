import { gql, useSubscription } from '@apollo/client'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Table } from 'antd'
import { DownloadButton, Heading } from 'components'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import { campaignsListColumns } from 'features/Campaigns/consts'
import { CampaignsListProps } from './CampaignsList.props'
import { CampaignsListActions } from '..'
import {
  OrderDirection,
  useCreateCampaignExportLinkMutation,
  useDeleteCampaignMutation,
  useDuplicateCampaignMutation
} from 'shared/graphql'
import styles from './CampaignsList.module.scss'
import { PlusOutlined } from '@ant-design/icons'
import downloadFile from '../../../../shared/utils/downloadFile'

const { Column } = Table

const CampaignsList: FC<CampaignsListProps> = ({
  loading,
  campaigns,
  pagination,
  setSortOrder,
  setFilters,
  setCurrentPage,
  downloadButtonOptions,
  setPageSize
}) => {
  const [campaignlist, setCampaignList] = useState([])
  useEffect(() => {
    setCampaignList(campaigns)
    return () => {}
  }, [campaigns])

  const [deleteCampaign] = useDeleteCampaignMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      cache.modify({
        fields: {
          getCampaignList() {}
        }
      })
    }
  })

  const deleteCampaignHandler = useCallback(
    async (id: string) => {
      await deleteCampaign({
        variables: {
          input: {
            id
          }
        }
      })
    },
    [deleteCampaign]
  )

  const [duplicateCampaign, { loading: loadingDuplicate }] = useDuplicateCampaignMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Campaign Duplicated')
      cache.modify({
        fields: {
          getCampaignList() {}
        }
      })
    }
  })

  const duplicateCampaignHandler = useCallback(
    async (data: string) => {
      await duplicateCampaign({
        variables: {
          input: { id: data }
        }
      })
    },
    [duplicateCampaign]
  )

  const handleTableChange = useCallback(
    (pagination, filters, sorter) => {
      setCurrentPage(pagination.current)
      setPageSize(pagination.pageSize)
      setSortOrder({
        field: sorter.field,
        direction: sorter.order === 'ascend' ? OrderDirection.Asc : OrderDirection.Desc
      })
    },
    [setSortOrder, setCurrentPage, setPageSize]
  )

  const [downloadCampaigns, { loading: loadingDownloadCampaigns }] = useCreateCampaignExportLinkMutation({
    onCompleted(data: any) {
      downloadFile(data?.createCampaignExportLink?.url, 'channels_export.csv')
    }
  })

  const handleDownload = useCallback(() => {
    downloadCampaigns({
      variables: {
        input: {
          filter: downloadButtonOptions.filter,
          sortOrder: downloadButtonOptions.sortOrder
        }
      }
    })
  }, [downloadCampaigns, downloadButtonOptions])

  const SUBSCRIPTION_ONUPDATE_CAMPAIGN_STATUS = gql`
    subscription OnUpdateCampaignProcessingStatus {
      onUpdateCampaignProcessingStatus {
        id
        status
      }
    }
  `

  const { data: onUpdateCampaignStatusEvent } = useSubscription(SUBSCRIPTION_ONUPDATE_CAMPAIGN_STATUS)

  useEffect(() => {
    if (onUpdateCampaignStatusEvent?.onUpdateCampaignProcessingStatus?.id) {
      setCampaignList(campaigns.map((campaign: any) => {
        if (campaign.id === onUpdateCampaignStatusEvent.onUpdateCampaignProcessingStatus.id) {
          campaign.status = onUpdateCampaignStatusEvent.onUpdateCampaignProcessingStatus.status
        }
        return campaign
      }))
    }
  }, [onUpdateCampaignStatusEvent, campaigns])

  return (
    <>
      <div className={styles.subHeader}>
        <Heading>Campaigns List</Heading>
        <div className={styles.actions}>
          <DownloadButton handler={handleDownload} loading={loadingDownloadCampaigns} />
          <Link to="/campaigns/create-campaign" className={styles.link}>
            <Button type="primary" shape="round" icon={<PlusOutlined />}>
              Create Campaign
            </Button>
          </Link>
        </div>
      </div>
      <Table
        rowKey={(record: any) => record.id}
        loading={loading || loadingDuplicate}
        dataSource={campaignlist}
        onChange={handleTableChange}
        scroll={{ x: 1200 }}
        sticky
        pagination={pagination}
      >
        {campaignsListColumns(setFilters).map(
          ({ title, dataIndex, key, sorter, filterDropdown, width, defaultSortOrder, render }) =>
            key === 'actions' ? (
              <Column
                fixed={'right'}
                width={150}
                title={title}
                sorter={sorter}
                showSorterTooltip={false}
                dataIndex={dataIndex}
                key={key}
                render={(_, { id }: { id: string }) => (
                  <CampaignsListActions
                    id={id}
                    onDelete={deleteCampaignHandler}
                    onDuplicate={duplicateCampaignHandler}
                  />
                )}
              />
            ) : (
              <Column
                title={title}
                dataIndex={dataIndex}
                key={key}
                sorter={sorter}
                showSorterTooltip={false}
                filterDropdown={filterDropdown}
                defaultSortOrder={defaultSortOrder}
                width={width}
                render={render}
              />
            )
        )}
      </Table>
    </>
  )
}

export default CampaignsList
