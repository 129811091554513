import { EndScreenElementType } from 'shared/graphql'

const labels = {
  [EndScreenElementType.PlaylistById]: 'Playlist Link',
  [EndScreenElementType.VideoById]: 'Selected Video',
  [EndScreenElementType.SubscribeChannelById]: 'External Channel Link',
  [EndScreenElementType.VideoBestForViewers]: 'Video best for viewers',
  [EndScreenElementType.VideoRecentUpload]: 'Latest Video',
  [EndScreenElementType.ExternalLink]: 'External Link',
  [EndScreenElementType.SubscribeChannel]: 'Channel Subscribe'
}

const getElementTypeLabel = (elementType: EndScreenElementType): string => labels[elementType]

export default getElementTypeLabel
