import { FC, useState } from 'react'
import { CampaignDetailsProps } from './CampaignDetails.props'
import s from './CampaignDetails.module.scss'
import { Typography, Button, Spin, Table, Radio, Select } from 'antd'
import getCampaignClicksTableColumns from 'features/Campaigns/utils/getCampaignClicksTableColumns'
import { Typography as Typo, ECommonSizeType } from '@wmg-ae/legato'
import { Line } from 'react-chartjs-2'
import { VideoListContainer } from 'shared/features/VideoList/containers'
import { EGraphDataPeriod } from 'features/Campaigns/types/EGraphDataPeriod'
import { useHistory } from 'react-router-dom'
import getElementTypeLabel from 'shared/features/EndScreenElements/components/EndScreenTable/utils/getElementTypeLabel'
import { nFormatter } from '../../../../shared/utils/NumberFormatter'
import SwitchMode from '../SwitchMode/SwitchMode'

const { Option } = Select

enum EGraphType {
  VIEWS_VS_CLICKS = 'ctr',
  ENDSCREEN_VIEWS = 'impressionsCount',
  ELEMENT_CLICKS = 'clicksCount'
}

const plugin = {
  id: 'custom_canvas_background_color',
  beforeDraw: (chart: any) => {
    const ctx = chart.canvas.getContext('2d')
    ctx.save()
    ctx.globalCompositeOperation = 'destination-over'
    ctx.fillStyle = '#eeeeee'
    ctx.fillRect(0, 0, chart.width, chart.height)
    ctx.restore()
  }
}

const colors = [
  {
    borderColor: '#D42B3A',
    backgroundColor: '#D42B3A'
  },
  {
    borderColor: '#0061AB',
    backgroundColor: '#0061AB'
  },
  {
    borderColor: '#008A3D',
    backgroundColor: '#008A3D'
  },
  {
    borderColor: '#ff8A3D',
    backgroundColor: '#ff8A3D'
  }
  // add more colors for more possible endscreens
]

const CampaignDetails: FC<CampaignDetailsProps> = ({
  campaign,
  getCampaignDetailsPerformanceLazyQueryHandler,
  endscreens,
  aggregatedMetrics,
  allDates,
  period,
  setPeriod,
  campaignViews,
  onSwitchMode,
  switchMode,
}) => {
  let history = useHistory()
  const [graphType, setGraphType] = useState(EGraphType.VIEWS_VS_CLICKS)

  if (!campaign) {
    return <Spin />
  }

  return (
    <>
      <div className={s.headerContainer}>
        <div className={s.typeConatainer}>
          <Typography.Title>{campaign.name}</Typography.Title>
        </div>
        <div className={s.buttonContainer}>
          <Button
            shape="round"
            type="primary"
            className={[s.buttonWithMargin, s.roundedButton].join(' ')}
            onClick={() => {}}
          >
            {'End Campaign Now'}
          </Button>
          <Button className={s.roundedButton} onClick={() => history.push(`/campaigns/edit/${campaign.id}`)}>
            {'Edit'}
          </Button>
        </div>
      </div>
      <div className={s.campaignDatesContainer}>
        <Typography.Paragraph>Start date: {campaign.startDate}</Typography.Paragraph>
        <Typography.Paragraph className={s.endDate}>End date: {campaign.endDate}</Typography.Paragraph>
        <SwitchMode
          onSwitchMode={onSwitchMode}
          containerStyle={{ margin: '-12px 0 0 8px' }}
          switchMode={switchMode}
          preCampaignTooltip={'These Elements will be applied when campaign starts'}
          postCampaignTooltip={'These Elements will be applied when campaign ends'}
        />
      </div>
      <div className={s.campaignClicksInfoContainer}>
        <div className={s.campaignTableContainer}>
          <Table
            bordered
            dataSource={
              endscreens.length
                ? endscreens.map((item) => ({
                    id: item.id,
                    elementType: getElementTypeLabel(item.type),
                    url: item.urlId,
                    clicks: {
                      clicksQuantity: item.metrics.reduce((prev: any, cur: any) => prev + cur.clicksCount, 0),
                      percentage:
                        item.metrics.reduce((prev: any, cur: any) => prev + cur.impressionsCount, 0) === 0
                          ? 0
                          : Math.floor(
                              (item.metrics.reduce((prev: any, cur: any) => prev + cur.clicksCount, 0) /
                                item.metrics.reduce((prev: any, cur: any) => prev + cur.impressionsCount, 0)) *
                                100
                            )
                    }
                  }))
                : []
            }
            columns={getCampaignClicksTableColumns()}
            pagination={false}
          />
        </div>
        <div className={s.figuresSection}>
          <div className={s.figuresContainer}>
            <div className={s.figure}>
              <div className={s.figureValueContainer}>
                <p className={s.figureValue}>{nFormatter(campaignViews)}</p>
              </div>
              <div className={s.figureCaptionContainer}>
                <p className={s.figureCaption}>Video views</p>
              </div>
            </div>
          </div>
          <div className={s.figuresContainer}>
            <div className={s.figure}>
              <div className={s.figureValueContainer}>
                <p className={s.figureValue}>{nFormatter(aggregatedMetrics.impressionsCount)}</p>
              </div>
              <div className={s.figureCaptionContainer}>
                <p className={s.figureCaption}>Endscreen impressions</p>
              </div>
            </div>
          </div>
          <div className={s.figuresContainer}>
            <div className={s.figure}>
              <div className={s.figureValueContainer}>
                <p className={s.figureValue}>{nFormatter(aggregatedMetrics.ctr)}</p>
              </div>
              <div className={s.figureCaptionContainer}>
                <p className={s.figureCaption}>Endscreen CTR</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={s.reportsContainer}>
        <Typo.Text size={ECommonSizeType.lg}>Reports</Typo.Text>
        <div className={s.graphControlsContainer}>
          <Radio.Group defaultValue={graphType} onChange={(target) => setGraphType(target.target.value)}>
            <Radio.Button className={[s.radioButton, s.roundedButtonLeft].join(' ')} value={EGraphType.VIEWS_VS_CLICKS}>
              Views vs Click Through
            </Radio.Button>
            <Radio.Button className={s.radioButton} value={EGraphType.ENDSCREEN_VIEWS}>
              End Screen Views
            </Radio.Button>
            <Radio.Button className={[s.radioButton, s.roundedButtonRight].join(' ')} value={EGraphType.ELEMENT_CLICKS}>
              Element click throughs
            </Radio.Button>
          </Radio.Group>
          <Select
            defaultValue={period}
            style={{ width: 120, paddingTop: '5px' }}
            onChange={(value) => setPeriod(value)}
          >
            <Option value={EGraphDataPeriod.LAST_WEEK}>Last Week</Option>
            <Option value={EGraphDataPeriod.LAST_MONTH}>Last Month</Option>
            <Option value={EGraphDataPeriod.LAST_YEAR}>Last Year</Option>
          </Select>
        </div>
        {endscreens && (
          <Line
            height={75}
            plugins={[plugin]}
            data={{
              labels: allDates, //generateDatesForGraph(period),

              datasets: endscreens.map((item, index) => ({
                label: getElementTypeLabel(item.type),
                data: item.metrics.map((item: any) => item[graphType]),
                tension: 0.1,
                ...colors[index]
              }))
            }}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  display: true,
                  position: 'left'
                },
                title: {
                  display: false
                }
              }
            }}
          />
        )}
      </div>
      <VideoListContainer unSavedWarning={false} />
    </>
  )
}

export default CampaignDetails
