import { FC } from 'react'
import styles from './Container.module.scss'
import EndScreenIconPlay from '../Play'
import EndScreenIconRewind from '../Rewind'
import EndScreenIconForward from '../Forward'
import EndScreenIconVolume from '../Volume'

const EndScreenIconContainer: FC = ( ) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <EndScreenIconPlay />
        <EndScreenIconRewind />
        <EndScreenIconForward />
        <EndScreenIconVolume />
      </div>
    </div>
  )
}

export default EndScreenIconContainer
