import { FC } from 'react'
import { ApolloProvider, ApolloClient, HttpLink, concat, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { getMainDefinition } from '@apollo/client/utilities';
import { useOktaAuth } from '@okta/okta-react'
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { cache } from './cache'
import useGoogleAccessToken from 'shared/hooks/useGoogleAccessToken'

const AuthApolloProvider: FC = ({ children }) => {
  const { authState } = useOktaAuth()
  const [googleAccessToken] = useGoogleAccessToken()

  const authMiddleware = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: authState?.idToken?.idToken || '',
      gauth: googleAccessToken ?? ''
    }
  }))

  const subsAWSLink = createSubscriptionHandshakeLink({
    url: process.env.REACT_APP_API_URL as string,
    region: process.env.REACT_APP_AWS_REGION as string,
    auth: {
      type: 'OPENID_CONNECT',
      jwtToken: authState?.idToken?.idToken || ''
    }
  })

  const httpLink = new HttpLink({ uri: process.env.REACT_APP_API_URL })

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    subsAWSLink,
    concat(authMiddleware, httpLink)
  )

  const client = new ApolloClient({
    cache,
    link: splitLink,
    connectToDevTools: true
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default AuthApolloProvider
