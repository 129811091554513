const getProgressType = (current: number, prev: number) => {
  if (current === prev) {
    return 'equal'
  }
  if (current > prev) {
    return 'increase'
  }
  if (current < prev) {
    return 'decrease'
  }
  return 'equal'
}

export default getProgressType
