import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import {
  EndScreenDefaultElement,
  EndScreenElementType,
  EndScreenTemplateInput,
  useCreateEndScreensTemplateMutation,
  useGetEndScreenDefaultListQuery,
  useGetEndScreenTemplateThumbnailLazyQuery
} from 'shared/graphql'
import { ApolloError } from '@apollo/client/errors'
import { useUpdateEffect } from 'react-use'
import { Button, List, Tooltip } from 'antd'
import getElementTypeLabel from 'shared/features/EndScreenElements/components/EndScreenTable/utils/getElementTypeLabel'
import { Heading } from 'components'
import getEndScreenHeight from 'shared/utils/getEndScreenHeight'
import EndScreenElementInputWithPositionAndHeight from 'shared/features/EndScreenElements/types/EndScreenElementInputWithPositionAndHeight'
import removeEndScreenElement from 'shared/features/EndScreenElements/components/EndScreenElements/functions/removeEndScreenElement'
import EndScreenDefaultElementsWrapper from './EndScreenDefaultElementsWrapper'
import disabledElementsDefault from 'shared/features/EndScreenElements/components/EndScreenElements/functions/disabledElementsDefault'
import handleChangeTableRow from 'shared/features/EndScreenElements/components/EndScreenElements/functions/handleChangeTableRow'
import handleChangeEditor from 'shared/features/EndScreenElements/components/EndScreenElements/functions/handleChangeEditor'
import EndScreenPreviewItemChangeResult from 'shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult'
import addInitialElement from 'shared/features/EndScreenElements/components/EndScreenElements/functions/addInitialElement'

const CreateCustomTemplate = () => {
  const { push } = useHistory()
  const [defaultEndScreens, setDefaultEndScreens] = useState<EndScreenElementInputWithPositionAndHeight[] | []>([])
  const [errorByRestriction, setErrorByRestriction] = useState<string>('');
  const [isShowCreateEndScreen, setIsShowCreateEndScreen] = useState<boolean>(false);

  const mapper = useCallback((endscreens) => endscreens && endscreens.length ? endscreens.map((item: any) => ({
    ...item,
    height: getEndScreenHeight(item.type, item.height)
  })): [], [])

  useUpdateEffect(() => {
    setIsShowCreateEndScreen(true);
    if (defaultEndScreens?.length >= 4) {
      setIsShowCreateEndScreen(false);
    }
  }, [defaultEndScreens])


  const [createTemplate] = useCreateEndScreensTemplateMutation({
    onCompleted() {
      toast.success('The template has been created')
      push('/defaults')
    },
    update(cache) {
      cache.modify({
        fields: {
          getEndScreenTemplateList() {}
        }
      })
    },
    onError(error: ApolloError) {
      toast.error(error.message)
    }
  })

  const getEndScreenDefaultListQuery = useGetEndScreenDefaultListQuery({
    onError(error: ApolloError) {
      toast.error(error.message)
    }
  })

  useEffect(() => {
    const endScreens = getEndScreenDefaultListQuery.data?.getEndScreenDefaultList?.items as EndScreenDefaultElement[]
    setDefaultEndScreens(mapper(endScreens))
  }, [getEndScreenDefaultListQuery.data, mapper])

  const handleSave = useCallback(
    (input, name) => {
      if(!errorByRestriction) {
        createTemplate({
          variables: {
            input: {
              elements: input.elements.map(({ id, ...element }: any) => element) as EndScreenTemplateInput[],
              name
            }
          }
        })
      }
    },
    [errorByRestriction, createTemplate]
  )

  const [getEndScreenOfTemplateThumbnailLazyQuery, { data: getEndScreenOfTemplateThumbnailLazyQueryData }] = useGetEndScreenTemplateThumbnailLazyQuery({
    onError: (error) => {
      toast.error(error.message)
    }
  })

  const getEndScreenOfTemplateThumbnailLazyQueryHandler = useCallback(
    (endscreenId: string, youtubeId: string, endscreenType: EndScreenElementType) => {
      getEndScreenOfTemplateThumbnailLazyQuery({
          variables: {
            input: {
              endscreenId,
              youtubeId,
              endscreenType
            }
          }
        })
    },
    [getEndScreenOfTemplateThumbnailLazyQuery]
  )

  useMemo(() => {
    const elem = getEndScreenOfTemplateThumbnailLazyQueryData?.getEndScreenTemplateThumbnail;

    if (elem && elem.endscreenId) {
      const element = defaultEndScreens.find(item => item.id === elem.endscreenId);
      if (element) {
        setDefaultEndScreens((prevState) => [
          ...prevState.filter(item => item.id !== elem.endscreenId),
          {...element, thumbnail: { thumbnail: elem.thumbnail, name: elem.name }, youtubeId: elem.youtubeId }
        ])
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEndScreenOfTemplateThumbnailLazyQueryData?.getEndScreenTemplateThumbnail])

  return (
    <div>
      <Heading level={3} noMargin>
        Create custom template
        <Tooltip color="#fff" title={
          <List
            bordered
            dataSource={Object.values(EndScreenElementType)}
            renderItem={type => (
              <Button onClick={() => addInitialElement(type, setDefaultEndScreens)} block disabled={disabledElementsDefault(type, defaultEndScreens)}>
                {getElementTypeLabel(type)}
              </Button>
            )}
          />
        }>
          <Button style={{ margin: '0 20px', borderRadius: '15px', display: isShowCreateEndScreen ? 'inline-block' : 'none' }}>+</Button>
        </Tooltip>
      </Heading>
      <div style={{ display: 'flex', alignItems: 'center', margin: '15px 0' }}>
        <span style={{ color: 'red', fontWeight: 700 }}>{errorByRestriction}</span>
      </div>
      {defaultEndScreens && defaultEndScreens.length ? <EndScreenDefaultElementsWrapper
        elements={defaultEndScreens}
        removeEndScreenElement={(id) => removeEndScreenElement(id, setDefaultEndScreens)}
        onSave={handleSave}
        showNameField
        setErrorByRestriction={setErrorByRestriction}
        handleChangeTableRow={(data: EndScreenElementInputWithPositionAndHeight) => handleChangeTableRow(data, setDefaultEndScreens, getEndScreenOfTemplateThumbnailLazyQueryHandler)}
        handleChangeEditor={(position: number, data: EndScreenPreviewItemChangeResult) => handleChangeEditor(position, data, setDefaultEndScreens)}
        /> : null}
    </div>
  )
}

export default CreateCustomTemplate
