import React, { FC, useState } from 'react'
import { Button } from 'antd'
import { TableOutlined } from '@ant-design/icons'
import CustomTemplatesButtonProps from './CustomTemplatesButton.props'
import CustomTemplatesModal from '../CustomTemplatesModal'

const CustomTemplatesButton: FC<CustomTemplatesButtonProps> = ({
  onApplyTemplate,
  applyTemplateButtonText,
  showActions = true
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div>
      <CustomTemplatesModal
        isVisible={showModal}
        onClose={() => setShowModal(false)}
        onOk={onApplyTemplate}
        buttonText={applyTemplateButtonText}
        showActions={showActions}
      />
      <Button shape='round' onClick={() => setShowModal(true)} icon={<TableOutlined />}>
        Show Custom Templates
      </Button>
    </div>
  )
}

export default CustomTemplatesButton
