import { FC } from 'react'
import style from '../index.module.scss'

const EndScreenIconPlay: FC = ( ) => {
  return (
    <div>
      <svg viewBox="0 0 24 24" className={style.icon}>
      <g className="style-scope tp-yt-iron-icon">
        <path d="M8 5v14l11-7z" className="style-scope tp-yt-iron-icon"></path>
      </g>
    </svg>
    </div>
  )
}

export default EndScreenIconPlay
