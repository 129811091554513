import { useCallback } from 'react'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { CampaignForm } from 'features/Campaigns/components'
import { useCreateCampaignMutation } from 'shared/graphql'
import { DATE_FORMAT } from 'shared/constants'
import Empty from '../../../components/Empty/Empty'

const CreateCampaignContainer = () => {
  const history = useHistory()

  const [createCampaign] = useCreateCampaignMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      cache.modify({
        fields: {
          getCampaignList() {}
        }
      })
    }
  })

  const createCampaignHandler = useCallback(
    (data: { name: string; date: moment.Moment[] }) => {
      createCampaign({
        variables: {
          input: {
            name: data.name,
            startDate: data.date[0].format(DATE_FORMAT),
            endDate: data.date[1].format(DATE_FORMAT)
          }
        }
      }).then((response) => {
        history.push(`/campaigns/edit/${response?.data?.createCampaign?.id}`)
      })
    },
    [createCampaign, history]
  )

  return (
    <>
      <CampaignForm onSave={createCampaignHandler} />
      <Empty text="Save the campaign to enable End Screen & Video List Editors" />
    </>
  )
}

export default CreateCampaignContainer
