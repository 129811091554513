import React, { FC } from 'react'
import { Icon, Typography } from '@wmg-ae/legato'
import { Button, Tooltip } from 'antd'
import HeadingProps from './Heading.props'
import styles from './Heading.module.scss'

const Heading: FC<HeadingProps> = ({ children, tooltip, level, noMargin }) => {
  return (
    <Typography.Heading className={styles.container} level={level} noMargin={noMargin}>
      <span>{children}</span>{' '}
      {tooltip && (
        <Tooltip placement="bottom" {...tooltip}>
          <Button icon={<Icon name="fa-solid fa-circle-info" />} shape="circle" />
        </Tooltip>
      )}
    </Typography.Heading>
  )
}

export default Heading
