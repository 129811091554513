import React, { FC, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Heading } from 'components'
import { Table } from 'antd'
import { useDeleteUserMutation, UserStatus, useUpdateUserMutation, useSyncReportsMutation } from 'shared/graphql'
import { UserListProps } from './UserList.props'
import { userListColumns } from '../../constants/constants'
import { UserListActions } from '..'
import styles from './UserList.module.scss'
import MCNButton from '../../../../components/Button'

const { Column } = Table

const UserList: FC<UserListProps> = ({ loading, users, pagination }) => {
  const [deleteUser] = useDeleteUserMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('User is successfully deleted')
      cache.modify({
        fields: {
          getUserList() {}
        }
      })
    }
  })

  const deleteUserHandler = useCallback(
    (id: string) => {
      deleteUser({
        variables: {
          input: {
            id
          }
        }
      })
    },
    [deleteUser]
  )

  const [updateUser] = useUpdateUserMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update() {
      toast.success('User is successfully updated')
    }
  })

  const updateUserHandler = useCallback(
    (id: string) => {
      const user = users.find((item: any) => item.id === id)
      if (user) {
        updateUser({
          variables: {
            input: {
              id,
              name: user.name,
              role: user.role,
              status: user.status === UserStatus.Active ? UserStatus.Deactivated : UserStatus.Active
            }
          }
        })
      } else {
        toast.error('Something went wrong')
      }
    },
    [updateUser, users]
  )
  const [syncReports] = useSyncReportsMutation({
    onError: (error) => {
      toast.error(error.message)
    },
    update(cache) {
      toast.success('Channels Synced')
    }
  })

  const updateSyncHandler = useCallback(
    (data: any) => {
      syncReports({
        variables: {}
      })
    },
    [syncReports]
  )

  return (
    <>
      <div className={styles.subHeader}>
        <Heading>User List</Heading>
        <Link to="/admin/create" className={styles.link}>
          <MCNButton text={'Add User'} type="primary" />
        </Link>
      </div>
      <Table
        rowKey={(record) => record.id}
        loading={loading}
        dataSource={users}
        scroll={{ x: 1200 }}
        pagination={pagination}
      >
        {userListColumns.map(({ title, dataIndex, key, width }) =>
          key === 'actions' ? (
            <Column
              fixed={'right'}
              width={150}
              title={title}
              dataIndex={dataIndex}
              key={key}
              render={(_, { id, status }: { id: string; status: string }) => (
                <UserListActions
                  id={id}
                  onDelete={deleteUserHandler}
                  onDeactivate={updateUserHandler}
                  isActive={status === UserStatus.Active}
                />
              )}
            />
          ) : (
            <Column title={title} dataIndex={dataIndex} key={key} width={width || 'auto'} />
          )
        )}
      </Table>
      <MCNButton text={'Sync Reports'} type="primary" style={{ opacity: 0 }} onClick={updateSyncHandler} />
    </>
  )
}

export default UserList
