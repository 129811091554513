import React from 'react'
import { Heading } from 'components'
import { AdditionalReportsList, ReportCardList } from '../components'

const sectionStyle: React.CSSProperties = {
  marginBottom: 48
}
const ReportsContainer = () => {
  return (
    <div>
      <div style={sectionStyle}>
        <Heading>MCN Performance Dashboard</Heading>
        <ReportCardList />
      </div>
      <div style={sectionStyle}>
        <Heading>Additional reports</Heading>
        <AdditionalReportsList />
      </div>
    </div>
  )
}

export default ReportsContainer
