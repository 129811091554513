import React, { useState, useMemo, useEffect, useCallback } from 'react'
import SearchCriteriaFilters from '../types/SearchCriteriaFilters'
import { ReportChart, SearchCriteria } from '../components'
import { colors } from '../utils'
import { Heading } from '../../../components'
import moment from 'moment'

import {
  ChannelListFieldName,
  OrderDirection, useCreateChannelPerformanceExportLinkMutation,
  useGetChannelListQuery,
  useGetChannelPerformanceQuery
} from '../../../shared/graphql'
import SelectTags from '../types/SelectTags'
import uniqueArrayOfObjectsByField from '../../../shared/utils/uniqueArrayOfObjectsByField'
import downloadFile from '../../../shared/utils/downloadFile'

const ArtistReport = () => {
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteriaFilters | null>(null)
  const [channelOptions, setChannelOptions]= useState<SelectTags[]>([])
  const [searchChannels, setSearchChannels] = useState('')
  const [allDates, setAllDates] = useState<string[]>([]);

  const getChannelListQuery = useGetChannelListQuery({
    variables: {
      input: {
        offset: 0,
        limit: 50,
        sortOrder: {
          field: ChannelListFieldName.Name,
          direction: OrderDirection.Desc
        },
        filter: {
          name: searchChannels
        }
      }
    }
  })

  const channelList = useMemo(() => {
    const items = getChannelListQuery.data?.getChannelList?.items
    if (items) {
      return items.map((item) => ({
        value: item?.id,
        label: item?.name,
      }))
    }

    return []
  }, [getChannelListQuery.data?.getChannelList?.items])

  useEffect(() => {
    setChannelOptions((prevState: SelectTags[]) => {
      const newList = [...channelList, ...prevState]
      return uniqueArrayOfObjectsByField(newList, 'value')
    })

    return () => {}
  }, [channelList])

  const { data: getChannelPerformanceQueryData } = useGetChannelPerformanceQuery({
    variables: {
      input: {
        channelIds: searchCriteria?.tags || [],
        startDate: searchCriteria?.startDate || '',
        endDate: searchCriteria?.endDate || ''
      }
    }
  })

  const channelPerformance = useMemo(() => {
    setAllDates([]);

    const data = getChannelPerformanceQueryData?.getChannelPerformance?.data;

        if (data && data.length) {
     const allCoveredDays = data
       .map(item => item?.metrics
         .map(item => item?.dayCovered))
       .flat(1)

       .sort((a: any, b: any) => (new Date(a) as any) - (new Date(b) as any));
     const dateArray: string[] = [];
     let startDate = moment(allCoveredDays[0]);
     const endDate = moment(allCoveredDays[allCoveredDays.length - 1]);
     while (startDate <= endDate) {
         dateArray.push( moment(startDate).format('YYYY-MM-DD') )
         startDate = moment(startDate).add(1, 'days');

     }

     setAllDates(dateArray)
     const fullFilledChannels = data
       .map((item, index, selfArr) => {
         const elementCoveredDays = item?.metrics.map(item => item?.dayCovered);
         return {
           ...item,
           metrics: dateArray.map(day => {
             const indexOfDay = elementCoveredDays?.indexOf(day || '');
             if (indexOfDay === -1) {
               return {
                 dayCovered: day,
                 clicksCount: 0,
                 impressionsCount: 0,
                 ctr: 0
               }

             }
             return selfArr[index]?.metrics[indexOfDay || 0]
           })
         }
       })
     return fullFilledChannels
   }

  }, [getChannelPerformanceQueryData?.getChannelPerformance])

 const [downloadChannelPerformance, { loading: loadingDownloadChannelPerformance }] = useCreateChannelPerformanceExportLinkMutation({
    onCompleted(data: any) {
      downloadFile(data?.createChannelPerformanceExportLink?.url, 'channel_performance_export.csv')
    }
  })

  const handleDownload = useCallback(() => {
    downloadChannelPerformance({
      variables: {
        input: {
          channelIds: searchCriteria?.tags || [],
          startDate: searchCriteria?.startDate || '',
          endDate: searchCriteria?.endDate || ''
        }
      }
    })
  }, [downloadChannelPerformance, searchCriteria])

  return (
    <div>
      <Heading>Artist (Channel) Performance Report</Heading>
      <SearchCriteria
        onSearch={setSearchCriteria}
        onSearchSelect={setSearchChannels}
        loadingSelect={getChannelListQuery.loading}
        tagsLabel="Channel(s)"
        tags={channelOptions}
        downloadEnabled={!!channelPerformance?.length}
        onDownload={handleDownload}
        loadingDownload={loadingDownloadChannelPerformance}
      />
      {searchCriteria && channelPerformance && channelPerformance.length && (
        <ReportChart
          datasets={channelPerformance.map((item, index) => ({
            label: item.name,
            data: item.metrics.map((item: any) => item.clicksCount),
            tension: 0.1,
            ...colors[index]
          }))}
          labels={allDates}
        />
      )}
    </div>
  )
}

export default ArtistReport
