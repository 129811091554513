import EndScreenElementInputWithPositionAndHeight from "features/Defaults/types/EndScreenDefaultElementInputWithPositionAndHeight";
import EndScreenPreviewItemChangeResult from "shared/features/EndScreenElements/types/EndScreenPreviewItemChangeResult";
import replaceEndScreenElement from "./replaceEndScreenElement";

  const handleChangeEditor = 
    (
        position: number,
        data: EndScreenPreviewItemChangeResult,
        setElements: (x: (prevState: EndScreenElementInputWithPositionAndHeight[]) => EndScreenElementInputWithPositionAndHeight[]) => void,
    ) => {
        setElements((oldElements: EndScreenElementInputWithPositionAndHeight[]) => replaceEndScreenElement(oldElements, position, data))
    }

  export default handleChangeEditor;