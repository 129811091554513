import React, { FC, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Modal, Upload } from 'antd'
import { RcFile } from 'rc-upload/lib/interface';
import { UploadOutlined } from '@ant-design/icons'
import UploadVideoListProps from './UploadVideoList.props'
import styles from './UploadVideoList.module.scss'
import {
  useUploadVideoListMutation
} from 'shared/graphql'
import { toast } from 'react-toastify'
import { CampaignParamTypes } from '../../../../../features/Campaigns/types/campaign.params.type'

const UploadVideoList: FC<UploadVideoListProps> = ({ visible, onCancel, downloadUrl, toggleVisible }) => {
  const { campaignId } = useParams<CampaignParamTypes>()

  const [uploadVideoList] = useUploadVideoListMutation({
      onError: (error) => {
        toast.error(error.message)
      },
      update(cache) {
        toast.success('Videos Uploaded')
        toggleVisible(false)
        cache.modify({
          fields: {
            getCampaignVideosFullList() {
            },
          }
        })
      }
    })

    const handleUpload = useCallback(
      (file: RcFile) => {
        const reader = new FileReader()
        reader.onload = e => {
          if (e?.target?.result) {
            const text = e.target.result
            if (typeof text === 'string') {
              let result = text.split(/\r?\n/)
              result = result.map((el: string) => {
               return el.split(',')[0] ? el.split(',')[0] : ''
              })
              if (result?.length) {
                result.shift()
                const youtubeIds = result.filter(value => !!value).map(value => value.replace('https://www.youtube.com/watch?v=', ''))
                uploadVideoList({
                  variables: {
                    input: { campaignId, youtubeIds }
                  }
                })
              }
            }
          }
        }
        reader.readAsText(file)
        return false
      }, [uploadVideoList, campaignId]
    )



  return (
    <Modal title='Upload Video List' visible={visible} onCancel={onCancel} footer={null}>
      <p>Uploads must be in the required format.</p>
      <p>
        <a href={downloadUrl}>Please download the CSV template here.</a>
      </p>
      <p>Upload your file below.</p>
      <div className={styles.uploadButtonWrapper}>
        <Upload
          accept='.txt, .csv'
          showUploadList={false}
          beforeUpload={handleUpload}
        >
          <Button icon={<UploadOutlined />} size='large'>
            Upload File
          </Button>
        </Upload>
      </div>
    </Modal>
  )
}

export default UploadVideoList
